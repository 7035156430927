import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import Slider from "../../components/Slider";
import TextLink from "../../components/TextLink";
import { useTheme } from "@emotion/react";

import { useEffect, useState } from "react";
import { getSubgroupVideos, postVideoLike } from "../../api/parsha";
import { useAuth } from "../../context/AuthContext";
import { formatDuration } from "../../utils/format";
import useLikeHandler from "../../hooks/useLikeHandler";
import { useNavigate } from "react-router-dom";
import ParshaCard from "../../components/parsha/ParshaCard";
import Toast from "../../components/Toast";
import usePlaybackSettings from "../../hooks/usePlaybackSettings";
import { useToastNotification } from "../../context/ToastNotificationContext";

const Parsa = ({ currentlyPlayingId, setCurrentlyPlayingId }) => {
  const theme = useTheme();

  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    toastNotificationMessage,
    setToastWithActionOpen,
    toastWithActionOpen,
  } = useToastNotification();

  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const [isPlaying, setIsPlaying] = useState(false);

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState({ videos: false });
  const [selectedChip, setSelectedChip] = useState(1146);

  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const navigate = useNavigate();

  //top panel feedback
  const [openSavedModeFeedback, setOpenSavedModeFeedback] = useState(false);
  const [openDownloadFeedback, setOpenDownloadFeedback] = useState(false);
  const [downloadFeedbackMsg, setDownloadFeedbackMsg] = useState("");

  const { updatePlaybackSettings } = usePlaybackSettings();

  const resetSettings = () => {
    updatePlaybackSettings({ download_mode: "ask" });
    setOpenSavedModeFeedback(false);
  };

  // Initialize the reusable like handler hook.
  const { handleLikeClick } = useLikeHandler({
    videos,
    setVideos,
    isAuthenticated,
    postVideoLike,
  });

  // Async function to fetch videos.
  const fetchVideos = async () => {
    setIsLoading({ ...isLoading, videos: true });
    try {
      const res = await getSubgroupVideos(selectedChip);
      let videosData = res.data.results;

      if (!isAuthenticated) {
        const likedVideos =
          JSON.parse(localStorage.getItem("likedVideos")) || {};

        videosData = videosData.map((video) => {
          if (likedVideos.hasOwnProperty(video.id)) {
            return {
              ...video,
              is_liked: likedVideos[video.id],
              likes:
                likedVideos[video.id] && !video.is_liked
                  ? video.likes + 1
                  : video.likes,
            };
          }
          return video;
        });
      }

      setVideos(videosData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading({ ...isLoading, videos: false });
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [selectedChip, isAuthenticated]);

  return (
    <>
      <Box
        mb={pxToRem(100)}
        sx={{
          "@media (max-width: 1330px)": { mb: "120px" },
          "@media (max-width: 767px)": { mb: "80px" },
          "@media (max-width: 576px)": { mb: pxToRem(70) },

        }}
      >
        <Container disableGutters >
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
            gap="15px"
            // mb={{ xs: "36px", sm: pxToRem(57.79) }}
          >
            <Typography variant="sectionTitle" component="h2">
              Weekly <span className="highlighted">Shiurim</span>
            </Typography>
            <TextLink href={"/videos"}>View all</TextLink>
          </Stack>
        </Container>

        <Box
          sx={{
            height: "480px",
            // backgroundColor:"pink",
            // mb:"150px"
          }}
        >
          <Slider
            slidesPerView={1}
            spaceBetween={20}
            arrows
            breakpoints={{
              562: { slidesPerView: 2 },
              820: { slidesPerView: 3 },
            }}
            style={{
              padding: `${pxToRem(30)} ${pxToRem(20)}`,
              margin: `0 ${pxToRem(-20)}`,
              height: "100%",
              // backgroundColor:"yellow",
              paddingTop:"60px"
         
            }}
          >
            {videos.map((video) => (
              <SwiperSlide
                key={video.id}
                sx={{
                  width: "100%",
                  boxSizing: "border-box",     
                  marginTop:"100px",
                  // backgroundColor:"green"
                }}
              >
                <ParshaCard
                  video={video}
                  text={video.text}
                  subscribers={video.subscribers}
                  duration={formatDuration(video.duration)}
                  labels={video.labels}
                  isPlaying={currentlyPlayingId === video.id}
                  setIsPlaying={setIsPlaying}
                  currentlyPlayingId={currentlyPlayingId}
                  setCurrentlyPlayingId={setCurrentlyPlayingId}
                  isVideoLibrary={true}
                  onLikeClick={handleLikeClick}
                  setOpenDownloadFeedback={setOpenDownloadFeedback}
                  setDownloadFeedbackMsg={setDownloadFeedbackMsg}
                  setOpenSavedModeFeedback={setOpenSavedModeFeedback}
                  isHomePage
                />
              </SwiperSlide>
            ))}
          </Slider>
        </Box>
        {/*  */}
      </Box>
      <Toast
        open={toastNotificationOpen}
        onClose={() => setToastNotificationOpen(false)}
        message={toastNotificationMessage}
      />
      <Toast
        open={toastWithActionOpen}
        onClose={() => setToastWithActionOpen(false)}
        message={toastNotificationMessage}
        button={"Change Download Option"}
        onUndo={resetSettings}
      />
    </>
  );
};

export default Parsa;
