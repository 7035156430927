import { Box, Stack, Skeleton, ThemeProvider } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { rtlTheme } from "../../theme";


const BookmarkItemSkeleton = ({coursesBookmarks}) => {
  return (
    <Box
      sx={{
        borderRadius: pxToRem(10),
        p: pxToRem(16),
        backgroundColor: "#fff",
        position: "relative",
        width: "100%",
      }}
    >
      <ThemeProvider theme={rtlTheme}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ cursor: "pointer" }}
        >
          <Stack
            direction={{ xs: "column", sssm: "row" }}
            alignItems={{ xs: "flex-end", sssm: "center" }}
            gap={{ xs: pxToRem(10), ssm: pxToRem(16) }}
            flexGrow={1}
          >
            <Stack
              gap={{ xs: pxToRem(4), ssm: pxToRem(8) }}
              flexGrow={1}
              alignItems="flex-end"
              order={{ xs: 2, ssm: 1 }}
            >
              {coursesBookmarks ? (
                <Skeleton variant="text" width="80%" height={pxToRem(28)} />
              ) : (
                <>
                  <Stack
                    direction="row"
                    spacing={pxToRem(8)}
                    alignItems="center"
                  >
                    <Skeleton
                      variant="text"
                      width={pxToRem(50)}
                      height={pxToRem(20)}
                    />
                    <Skeleton
                      variant="circular"
                      width={pxToRem(18)}
                      height={pxToRem(18)}
                    />
                    <Skeleton
                      variant="text"
                      width={pxToRem(50)}
                      height={pxToRem(20)}
                    />
                    <Skeleton
                      variant="circular"
                      width={pxToRem(18)}
                      height={pxToRem(18)}
                    />
                  </Stack>
                </>
              )}

              <Skeleton variant="text" width="70%" height={pxToRem(28)} />

              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  backgroundColor: "#EFEFEF",
                  borderRadius: pxToRem(50),
                  px: pxToRem(8),
                  gap: pxToRem(8),
                  minHeight: pxToRem(28),
                }}
              >
                <Skeleton
                  variant="circular"
                  width={pxToRem(11)}
                  height={pxToRem(11)}
                />
                <Skeleton
                  variant="text"
                  width={pxToRem(30)}
                  height={pxToRem(20)}
                />
              </Stack>
            </Stack>

            <Box
              sx={{
                maxWidth: { xs: pxToRem(125), ssm: pxToRem(155) },
                width: pxToRem(155),
                height: pxToRem(100),
                borderRadius: pxToRem(10),
                overflow: "hidden",
                position: "relative",
                flexShrink: 0,
                order: { xs: 1, sssm: 2 },
              }}
            >
              <Skeleton variant="rectangular" width="100%" height="100%" />
              {!coursesBookmarks && (
                <Box
                  sx={{
                    backgroundColor: "#FFF",
                    borderRadius: pxToRem(50),
                    px: pxToRem(8),
                    py: pxToRem(2),
                    position: "absolute",
                    top: pxToRem(8),
                    right: pxToRem(8),
                  }}
                >
                  <Skeleton
                    variant="text"
                    width={pxToRem(40)}
                    height={pxToRem(16)}
                  />
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </ThemeProvider>
    </Box>
  );
};

export default BookmarkItemSkeleton;
