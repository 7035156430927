import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Link,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import { SwiperSlide } from "swiper/react";
import Slider from "./Slider";
import { NavLink } from "react-router-dom";
import PlayBtn from "./PlayBtn";
import { ReactComponent as Play } from "../images/play.svg";
import { pxToRem } from "px2rem2px";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  height: 5,
  borderRadius: 0,
  zIndex: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#717171",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#FCE181",
  },
}));

const VideoSlide = (props) => {
  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        // height={{ xs: pxToRem(150), lg: pxToRem(190) }}
        borderRadius="19px"
        overflow="hidden"
        sx={{
          aspectRatio: "1/1",
        }}
      >
        <Link
          to={props.videoLink}
          component={NavLink}
          sx={{ position: "absolute", zIndex: 1 }}
        >
          <PlayBtn
            width={{ xs: "40px", sm: pxToRem(66) }}
            height={{ xs: "40px", sm: pxToRem(66) }}
          >
            <Play
              color="#026670"
              width={pxToRem(20)}
              height={pxToRem(24)}
              style={{ marginLeft: "6%" }}
            />
          </PlayBtn>
        </Link>
        <Box position="absolute" width="0.1px">
          <PlayBtn />
        </Box>
        {props?.isMyCourses && (
          <BorderLinearProgress
            className="video-progress-bar"
            variant="determinate"
            value={props.progress}
          />
        )}
        <img
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: pxToRem(20),
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          }}
          src={props.image}
          alt=""
        />
      </Box>
    </>
  );
};

const HoverBox = styled(Box)(
  ({
    theme,
    defaultColor = "#faf9f8",
    hoverColor = "rgb(237, 236, 232)",
    padding = "10px 10px 15px 10px",
    borderRadius = "20px",
    height = "85%",
    transition = "0.2s ease",
    progress,
    isMobile,
    isContinueWatching,
  }) => ({
    position: "relative",
    transition,
    padding,
    borderRadius,
    height,
    backgroundColor: defaultColor,
    cursor: "pointer",

    ...(isContinueWatching && {
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: "50%",
        width: `calc(100% - ${pxToRem(40)})`,
        height: pxToRem(20),
        backgroundColor: "#FCE181",
        borderRadius: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
        transform: "translateX(-50%)",
        zIndex: -1,
        transition: "0.2s ease",
      },
    }),

    "&:hover": {
      backgroundColor: hoverColor,
      "& .border-bottom": {
        bottom: progress
          ? isMobile
            ? pxToRem(-20)
            : pxToRem(-35)
          : pxToRem(-20),
      },
      ...(isContinueWatching && {
        "&::before": {
          top: pxToRem(-20),
        },
      }),
      "& .video-progress-bar": {
        bottom: "-5px",
      },
    },
  })
);

const VideoSlider = ({
  videos = [],
  loading = false,
  handleRedirect,
  mobile = false,
  mobile__ssm = false,
  sliderSettings = {},
  isMyCourses = false,
  isContinueWatching = false,
  type = "course",
  ...rest
}) => {
  const defaultSliderSettings = {
    slidesPerView: 1,
    spaceBetween: 10,
    arrows: !loading,
    breakpoints: {
      300: { slidesPerView: 2 },
      406: { slidesPerView: 2 },
      537: { slidesPerView: 3 },
      654: { slidesPerView: 3 },
      767: { slidesPerView: 4 },
      900: { slidesPerView: 3 },
      1017: { slidesPerView: 3 },
      1250: { slidesPerView: 4 },
    },
  };

  const mergedSliderSettings = { ...defaultSliderSettings, ...sliderSettings };

  useEffect(() => {
    console.log("isMyCourses", isMyCourses);
  }, [isMyCourses]);

  return (
    <Box
      mx={{ xs: "-25px", ssm: "0px" }}
      mb={{ xs: pxToRem(36), ssm: pxToRem(60) }}
      sx={{
        backgroundColor: { xs: "#fff", ssm: "unset" },
        p: {
          xs: `0 0 ${pxToRem(29)} 0`,
          ssm: `${pxToRem(24)} 0 0 0`,
        },
        "& .MuiContainer-root": { p: 0 },
        "& .swiper": {
          p: { xs: "0 0 0 0", sm: "10px" },
          m: { xs: 0, sm: "-10px -10px 0" },
          // mb: "20px !important",
        },
        "& .swiper-slide": { width: "auto" },
        "& .arrow-prev": { left: "-46px", zIndex: 1 },
        "& .arrow-next": { right: "-46px", zIndex: 1 },
      }}
      {...rest}
    >
      <Slider
        {...mergedSliderSettings}
        style={{
          backgroundColor: "#fff",
          borderRadius: mobile__ssm ? "" : "20px",
          padding: mobile__ssm
            ? `${pxToRem(49)} 25px ${pxToRem(20)} 25px`
            : "35px 25px",
          marginBottom: mobile__ssm ? "" : "20px",
          // minHeight: "260px",
        }}
      >
        {loading
          ? Array.from(new Array(4)).map((_, index) => (
              <SwiperSlide key={index}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: "20px",
                    height: mobile ? "150px" : "200px",
                    width: "100%",
                  }}
                />
              </SwiperSlide>
            ))
          : videos.map((v) => (
              <SwiperSlide key={v.id} style={{ height: "auto", flexShrink: 0 }}>
                <Box width={{ xs: "auto", sm: "auto" }}>
                  <HoverBox
                    onClick={() => handleRedirect(v.id)}
                    progress={v.progress}
                    isMobile={mobile}
                    isContinueWatching={isContinueWatching}
                    width={{ xs: "auto", sm: "auto" }}
                  >
                    <VideoSlide
                      image={v.thumb_nail}
                      videoLink={`/course/${v.slug}`}
                      progress={v.progress}
                      isMobile={mobile}
                      isMyCourses={isMyCourses}
                    />

                    <Typography
                      className="slideTitle"
                      fontSize={pxToRem(16)}
                      lineHeight={pxToRem(20)}
                      fontWeight={600}
                      mt={pxToRem(14)}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        textAlign: "center",
                      }}
                    >
                      {v.name}
                    </Typography>

                    {isContinueWatching && (
                      <Box
                        className="border-bottom"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          width: `calc(100% - ${pxToRem(40)})`,
                          height: v.progress
                            ? mobile
                              ? pxToRem(20)
                              : pxToRem(35)
                            : pxToRem(20),
                          backgroundColor: "#FCE181",
                          borderRadius: `0 0 ${pxToRem(20)} ${pxToRem(20)}`,
                          transform: "translateX(-50%)",
                          textAlign: "center",

                          zIndex: -2,
                          transition: "0.2s ease",
                        }}
                      >
                        {v.progress ? (
                          <Typography
                            color="#026670"
                            fontSize={pxToRem(14)}
                            lineHeight={pxToRem(18)}
                            fontWeight={500}
                            width="auto"
                          >
                            {v.progress.toFixed(0)}% Completed
                          </Typography>
                        ) : null}
                      </Box>
                    )}
                  </HoverBox>
                </Box>
              </SwiperSlide>
            ))}
      </Slider>
    </Box>
  );
};

export default VideoSlider;
