import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  DialogContent,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { changeTemporaryPassword } from "../../api/student";
import getErrorsArray from "../../utils/getErrorsArray";
import FormInputPhone from "../../components/FormInputPhone";
import ModalHeader from "../../components/ModalHeader";
import { captchaKey } from "../../api/auth";
import { pxToRem } from "px2rem2px";
import { ButtonLogin, TextFieldStyled } from "../../components/StyledComponent";
import { saveStudentLocal } from "../../context/AuthContext";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default function ChangeTempPassword({
  setAction,
  tempEmail,
  tempPass,
  fullScreen,
  ...props
}) {
  const [isloading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const captchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    email: "",
    temporaryPassword: "",
    password: "",
    confirmPassword: "",
  });

  // Autofill inputs
  useEffect(() => {
    if (tempEmail && tempPass) {
      setVerifyForm((prevForm) => ({ ...prevForm, email: tempEmail }));
      setVerifyForm((prevForm) => ({
        ...prevForm,
        temporaryPassword: tempPass,
      }));
    }
  }, [tempEmail, tempPass]);

  // State to track if fields have been touched and blurred
  const [fieldState, setFieldState] = useState({
    email: { touched: false, blurred: false },
    temporaryPassword: { touched: false, blurred: false },
    password: { touched: false, blurred: false },
    confirmPassword: { touched: false, blurred: false },
  });

  // Form errors state
  const [formErrors, setFormErrors] = useState({
    email: { required: false, pattern: false, server: "" },
    temporaryPassword: { required: false, server: "" },
    password: { length: false },
    confirmPassword: { length: false, match: false },
  });

  // Validation functions
  const validateEmailField = (email) => {
    const emailError = {
      required: !email,
      pattern: email && !validateEmail(email),
      server: formErrors.email.server, // Retain server error if any
    };
    return emailError;
  };

  const validateTempPasswordField = (temporaryPassword) => {
    const tempPasswordError = {
      required: !temporaryPassword,
      server: formErrors.temporaryPassword.server, // Retain server error if any
    };
    return tempPasswordError;
  };

  const validatePasswordField = (password) => {
    const passwordError = {
      length: password.length < 6,
    };
    return passwordError;
  };

  const validateConfirmPasswordField = (password, confirmPassword) => {
    const confirmPasswordError = {
      length: confirmPassword.length < 6,
      match: password !== confirmPassword,
    };
    return confirmPasswordError;
  };

  const validateEmail = (email) => {
    // Basic email pattern check
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    // Collect errors from validation functions
    const emailError = validateEmailField(verifyForm.email);
    const tempPasswordError = validateTempPasswordField(
      verifyForm.temporaryPassword
    );
    const passwordError = validatePasswordField(verifyForm.password);
    const confirmPasswordError = validateConfirmPasswordField(
      verifyForm.password,
      verifyForm.confirmPassword
    );

    const newFormErrors = {
      email: emailError,
      temporaryPassword: tempPasswordError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    };

    setFormErrors(newFormErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newFormErrors).some((error) =>
      Object.values(error).some((value) => value)
    );

    return !hasErrors;
  };

  async function handleVerify() {
    const isValid = validateForm();

    if (!isValid) {
      // Mark all fields as blurred to display errors
      setFieldState((prevState) => ({
        email: { ...prevState.email, blurred: true },
        temporaryPassword: { ...prevState.temporaryPassword, blurred: true },
        password: { ...prevState.password, blurred: true },
        confirmPassword: { ...prevState.confirmPassword, blurred: true },
      }));
      return; // Exit from submitting the request
    }

    const fpPromise = FingerprintJS.load();

    const fp = await fpPromise;
    const result = await fp.get();

    // visitor identifier:
    const visitorId = result.visitorId;

    const payload = {
      email: verifyForm.email,
      temporary_password: verifyForm.temporaryPassword,
      new_password: verifyForm.password,
      confirm_new_password: verifyForm.confirmPassword,
      captcha_value: captchaValue,
      device_fingerprint: visitorId,
    };

    setIsLoading(true);

    try {
      const res = await changeTemporaryPassword(payload);
      setIsLoading(false);
      window.localStorage.setItem("accessToken", res.data.access_token);
      window.localStorage.setItem("refreshToken", res.data.refresh_token);
      window.localStorage.setItem("visitorId", visitorId);
      saveStudentLocal().then((res) => {
        window.location.reload();
      });
    } catch (e) {
      const err = e.response?.data || {};
      const newErrors = getErrorsArray(err);

      if (err.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: {
            ...prevErrors.email,
            server: err.email[0],
          },
        }));
      } else if (err.non_field_errors) {
        const nonFieldError = err.non_field_errors[0];
        if (nonFieldError === "No user found with this email.") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            email: {
              ...prevErrors.email,
              server: nonFieldError,
            },
          }));
        } else if (nonFieldError === "The temporary password is not correct.") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            temporaryPassword: {
              ...prevErrors.temporaryPassword,
              server: nonFieldError,
            },
          }));
        }
      }

      setErrors(newErrors);
      setIsLoading(false);
    } finally {
      captchaRef.current.reset();
      setIsLoading(false);
    }
  }

  const handleCaptchaChange = (e) => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  return (
    <>
      <ModalHeader
        title="Change Temporary Password"
        inModal={props?.inModal}
        onClose={() => props.onClose()}
        textAlignment="center"
      />
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            minWidth: { ssm: "400px" },
            borderRadius: "10px",
            backgroundColor: "#f7f6f2",
            display: "flex",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            minHeight: fullScreen ? "100vh" : undefined,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#f7f6f2",
              padding: { xs: pxToRem(30), ssm: "40px" },
              borderRadius: "10px",
              width: "100%",
              // margin: "auto",
            }}
          >
            {/* Email Field */}
            <TextFieldStyled
              placeholder="Email"
              value={verifyForm.email}
              onChange={(e) => {
                const newEmail = e.target.value;
                setVerifyForm({ ...verifyForm, email: newEmail });

                // Validate on change
                const emailError = validateEmailField(newEmail);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  email: emailError,
                }));

                // Reset server error when the user types again
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  email: {
                    ...prevErrors.email,
                    server: "", // Reset server error when the user types again
                  },
                }));

                // Mark as touched
                setFieldState((prevState) => ({
                  ...prevState,
                  email: { ...prevState.email, touched: true },
                }));
              }}
              onBlur={() => {
                setFieldState((prevState) => ({
                  ...prevState,
                  email: { ...prevState.email, blurred: true },
                }));

                // Validate on blur
                const emailError = validateEmailField(verifyForm.email);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  email: emailError,
                }));
              }}
              error={
                (fieldState.email.blurred && formErrors.email?.required) ||
                formErrors.email?.pattern ||
                formErrors.email?.server
              }
              helperText={
                fieldState.email.blurred && formErrors.email?.required
                  ? "Email is required"
                  : formErrors.email?.pattern
                  ? "Enter a valid email address"
                  : formErrors.email?.server
                  ? formErrors.email.server // Display server error message
                  : ""
              }
            />

            {/* Temporary Password Field */}
            <TextFieldStyled
              placeholder="Temporary Password"
              value={verifyForm.temporaryPassword}
              onChange={(e) => {
                const newTempPassword = e.target.value;
                setVerifyForm({
                  ...verifyForm,
                  temporaryPassword: newTempPassword,
                });

                // Validate on change
                const tempPasswordError =
                  validateTempPasswordField(newTempPassword);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  temporaryPassword: tempPasswordError,
                }));

                // Reset server error when the user types again
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  temporaryPassword: {
                    ...prevErrors.temporaryPassword,
                    server: "", // Clear server error
                  },
                }));

                // Mark as touched
                setFieldState((prevState) => ({
                  ...prevState,
                  temporaryPassword: {
                    ...prevState.temporaryPassword,
                    touched: true,
                  },
                }));
              }}
              onBlur={() => {
                setFieldState((prevState) => ({
                  ...prevState,
                  temporaryPassword: {
                    ...prevState.temporaryPassword,
                    blurred: true,
                  },
                }));

                // Validate on blur
                const tempPasswordError = validateTempPasswordField(
                  verifyForm.temporaryPassword
                );
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  temporaryPassword: tempPasswordError,
                }));
              }}
              type="password"
              error={
                (fieldState.temporaryPassword.blurred &&
                  formErrors.temporaryPassword?.required) ||
                formErrors.temporaryPassword?.server
              }
              helperText={
                fieldState.temporaryPassword.blurred &&
                formErrors.temporaryPassword?.required
                  ? "Temporary password is required"
                  : formErrors.temporaryPassword?.server
                  ? formErrors.temporaryPassword.server // Display server error message
                  : ""
              }
            />

            {/* New Password Field */}
            <TextFieldStyled
              placeholder={"Type a New Password"}
              value={verifyForm.password}
              onChange={(e) => {
                const newPassword = e.target.value;
                setVerifyForm({ ...verifyForm, password: newPassword });

                // Validate on change
                const passwordError = validatePasswordField(newPassword);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  password: passwordError,
                }));

                // If confirm password is not empty, re-validate it
                if (verifyForm.confirmPassword) {
                  const confirmPasswordError = validateConfirmPasswordField(
                    newPassword,
                    verifyForm.confirmPassword
                  );
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    confirmPassword: confirmPasswordError,
                  }));
                }

                // Mark as touched
                setFieldState((prevState) => ({
                  ...prevState,
                  password: { ...prevState.password, touched: true },
                }));
              }}
              onBlur={() => {
                setFieldState((prevState) => ({
                  ...prevState,
                  password: { ...prevState.password, blurred: true },
                }));

                // Validate on blur
                const passwordError = validatePasswordField(
                  verifyForm.password
                );
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  password: passwordError,
                }));
              }}
              type="password"
              autoComplete="new-password"
              error={
                (fieldState.password.blurred && formErrors.password?.length) ||
                formErrors.password?.server
              }
              helperText={
                fieldState.password.blurred && formErrors.password?.length
                  ? "Password must be at least 6 characters"
                  : ""
              }
            />

            {/* Confirm Password Field */}
            <TextFieldStyled
              placeholder={"Confirm your New Password"}
              value={verifyForm.confirmPassword}
              onChange={(e) => {
                const newConfirmPassword = e.target.value;
                setVerifyForm({
                  ...verifyForm,
                  confirmPassword: newConfirmPassword,
                });

                // Validate on change
                const confirmPasswordError = validateConfirmPasswordField(
                  verifyForm.password,
                  newConfirmPassword
                );
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  confirmPassword: confirmPasswordError,
                }));

                // Mark as touched
                setFieldState((prevState) => ({
                  ...prevState,
                  confirmPassword: {
                    ...prevState.confirmPassword,
                    touched: true,
                  },
                }));
              }}
              onBlur={() => {
                setFieldState((prevState) => ({
                  ...prevState,
                  confirmPassword: {
                    ...prevState.confirmPassword,
                    blurred: true,
                  },
                }));

                // Validate on blur
                const confirmPasswordError = validateConfirmPasswordField(
                  verifyForm.password,
                  verifyForm.confirmPassword
                );
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  confirmPassword: confirmPasswordError,
                }));
              }}
              type="password"
              autoComplete="new-password"
              error={
                (fieldState.confirmPassword.blurred &&
                  (formErrors.confirmPassword.length ||
                    formErrors.confirmPassword.match)) ||
                formErrors.confirmPassword?.server
              }
              helperText={
                fieldState.confirmPassword.blurred &&
                formErrors.confirmPassword.match
                  ? "Passwords do not match"
                  : fieldState.confirmPassword.blurred &&
                    formErrors.confirmPassword.length
                  ? "Password must be at least 6 characters"
                  : ""
              }
            />

            <ReCAPTCHA
              ref={captchaRef}
              sitekey={captchaKey}
              onChange={handleCaptchaChange}
            />

            <ButtonLogin
              disabled={isloading}
              onClick={handleVerify}
              variant="yellow"
              color="primary"
            >
              Update Password
            </ButtonLogin>
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ borderColor: "#BFBEBB", mb: pxToRem(20) }} />
              <Typography
                onClick={() => setAction("login")}

                underline="hover"
                sx={{
                  fontWeight: 600,
                  lineHeight: pxToRem(18),
                  color: "#BFBEBB",
                  cursor: "pointer",
                  caretColor: "transparent",
                  textAlign: "center",
                  fontSize: "12.6px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}

                // sx={{
                //   fontWeight: 600,
                //   color: "#BFBEBB",
                //   cursor: "pointer",
                //   caretColor: "transparent",
                //   textAlign: "center",
                //   margin: "20px 0px",
                //   "&:hover": {
                //     textDecoration: "underline",
                //   },
                //   "@media(max-width: 380px)": {
                //     fontSize: "12px",
                //   },
                // }}
              >
                Go back to Login
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
}
