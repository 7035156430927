import React, { forwardRef } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import ModalHeader from "../../../components/ModalHeader";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Close } from "../../../images/cross-white.svg";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionFeedbackModal = ({
  open,
  handleClose,
  success,
  cancel,
  modalTitle,
  errorMessage,

  unsubscribe,

  showSubscriptionModal,
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();

  const navigate = useNavigate();
  const handleStartWatching = () => {
    handleClose();
  };

  const handleTryAgain = () => {
    handleClose();
    showSubscriptionModal();
  };

  const handleViewSettings = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("unsubscribe");
    window.history.replaceState({}, document.title, url);
    navigate("/user/notifications");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="ssm"
        keepMounted
        scroll={mobile_ssm ? "paper" : "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiDialog-paper": {
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            verticalAlign: "center",
            backgroundColor: "#F7F6F2",
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            maxWidth: { xs: "100%", ssm: "500px" },
            width: { xs: "100%", ssm: "500px" },
            maxHeight: { xs: "85vh", ssm: "none" },
          },
        }}
      >
        <ModalHeader
          title={modalTitle}
          inModal={true}
          onClose={() => handleClose()}
          // isFeedBack
        />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: {
              xs: `${pxToRem(30)} ${pxToRem(25)}`,
              ssm: `${pxToRem(40)} ${pxToRem(40)}`,
              sm: `${pxToRem(40)} ${pxToRem(60)}`,
            },
            backgroundColor: "#f7f6f2",
            overflow: "visible",
            position: "relative",
          }}
        >
          <Box textAlign="center">
            <Stack
              position="absolute"
              top={pxToRem(-27)}
              left="50%"
              alignItems="center"
              justifyContent="center"
              bgcolor={success ? "#026670" : "#C92727"}
              width={pxToRem(54)}
              height={pxToRem(54)}
              borderRadius="100%"
              sx={{ transform: "translatex(-50%)" }}
            >
              {success ? (
                <Check width={16} height={16} color="#FCE181" />
              ) : (
                <Close width={16} height={16} color="#FCE181" />
              )}
            </Stack>
            <Box sx={{ marginTop: pxToRem(10) }}>
              <Typography component="p" fontSize={pxToRem(20)} fontWeight={700}>
                {!unsubscribe ? (
                  <>
                    {success ? (
                      modalTitle !== "Invitation Feedback" ? (
                        <>
                          Thank you.
                          <br />
                          Payment Successful!
                        </>
                      ) : (
                        <>
                          {/* Thank you.
                      <br /> */}
                          Invitaion was rejected successful!
                        </>
                      )
                    ) : (
                      <>
                        Ooops!
                        <br />
                        That didn’t work!
                      </>
                    )}
                  </>
                ) : (
                  <>{/* unsubscribed successfully. */}</>
                )}
              </Typography>
              {success && modalTitle === "Gift Course" && (
                <Typography
                  variant="medium"
                  component="p"
                  fontSize={pxToRem(16)}
                  mt={pxToRem(20)}
                >
                  An email invitation has been sent to the recipient, and a
                  receipt has been sent to your email.
                </Typography>
              )}
              {success && modalTitle === "Add Partner" && (
                <Typography
                  variant="medium"
                  component="p"
                  fontSize={pxToRem(16)}
                  mt={pxToRem(20)}
                >
                  An email confirmation was sent.
                </Typography>
              )}

              {errorMessage && (
                <Typography
                  variant="medium"
                  component="p"
                  fontSize="16px"
                  mt={pxToRem(20)}
                  lineHeight="1.5"
                >
                  {errorMessage}
                </Typography>
              )}

              {success &&
                modalTitle !== "Add Partner" &&
                modalTitle !== "Gift Course" &&
                modalTitle !== "Invitation Feedback" &&
                modalTitle !== "Unsubscribe" && (
                  <Button
                    variant="yellow"
                    sx={{
                      width: { xs: "100%", ssm: "300px" },
                      maxWidth: { xs: "300px", ssm: "none" },
                      mt: pxToRem(40),
                      height: pxToRem(54),
                    }}
                    onClick={handleStartWatching}
                  >
                    Start Watching
                  </Button>
                )}

              {success && unsubscribe && isAuthenticated && (
                <Button
                  variant="yellow"
                  sx={{
                    width: { xs: "100%", ssm: "300px" },
                    maxWidth: { xs: "300px", ssm: "none" },
                    mt: pxToRem(40),
                    height: pxToRem(54),
                  }}
                  onClick={handleViewSettings}
                >
                  View Settings
                </Button>
              )}
              {cancel && (
                <Button
                  // onClick={openPurchaseModal}
                  variant="yellow"
                  sx={{
                    width: { xs: "100%", ssm: "300px" },
                    maxWidth: { xs: "300px", ssm: "none" },
                    mt: pxToRem(40),
                    height: pxToRem(54),
                  }}
                  onClick={handleTryAgain}
                >
                  Try Again
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionFeedbackModal;
