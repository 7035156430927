import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { useState, useEffect } from "react";
import BlockHeading from "../../../components/BlockHeading";
import { getCoursesNotes } from "../../../api/student";
import Notes from "./components/Notes";
const AccountActivity = () => {
  const [notes, setNotes] = useState([]);
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const fetchNotes = () => {
    getCoursesNotes()
      .then((res) => {
        console.log("fdsf", res.data.results);
        setNotes(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchNotes();
  }, []);
  const openNotes = () => {
    setNotesDialogOpen(true);
  };
  return (
    <>
      <BlockHeading>
        <Typography variant="h6">
          View and Manage your data from different portions of the site
        </Typography>
      </BlockHeading>

      <Box>
        {notes.length > 0 && (
          <>
            <Stack
              flexDirection={{ xs: "column", ssm: "row" }}
              alignItems={{ xs: "flex-start", ssm: "center" }}
              justifyContent="space-between"
              gap="15px"
            >
              <Box>
                <Typography fontSize={pxToRem(18)} fontWeight={600} mb="3px">
                  Course Notes
                </Typography>
                <Typography fontSize={pxToRem(16)} fontWeight={500}>
                  View and Manage Your Course Notes.
                </Typography>
              </Box>
              <Button variant="yellow" size="small" onClick={openNotes}>
                View
              </Button>
            </Stack>
            <Divider
              sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
            />
          </>
        )}
        {/*      <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          alignItems={{ xs: "flex-start", ssm: "center" }}
          justifyContent="space-between"
          gap="15px"
        >
          <Box>
            <Typography fontSize={pxToRem(18)} fontWeight={600} mb="3px">
              Q&A
            </Typography>
            <Typography fontSize={pxToRem(16)} fontWeight={500}>
              View and Manage Your Q&A Submissions
            </Typography>
          </Box>
          <Button variant="yellow" size="small">
            View
          </Button>
        </Stack>
        <Divider
          sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
        />
        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          alignItems={{ xs: "flex-start", ssm: "center" }}
          justifyContent="space-between"
          gap="15px"
        >
          <Box>
            <Typography fontSize={pxToRem(18)} fontWeight={600} mb="3px">
              Comments
            </Typography>
            <Typography fontSize={pxToRem(16)} fontWeight={500}>
              View and Manage Your Comments
            </Typography>
          </Box>
          <Button variant="yellow" size="small">
            View
          </Button>
        </Stack>
 */}
      </Box>
      <Notes open={notesDialogOpen} setOpen={setNotesDialogOpen} />
    </>
  );
};

export default AccountActivity;
