import React, { useState } from "react";
import {
  Button,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Switcher from "../../../components/Switcher";
import Toast from "../../../components/Toast";
import IconBtnCircular from "../../../components/IconBtnCircular";
import { ChevronRight } from "@mui/icons-material";
import { ReactComponent as Chevron } from "../../../images/chevron.svg";
import usePlaybackSettings from "../../../hooks/usePlaybackSettings";

const initialSettings = {
  is_newsletter_subscribed: false,
  playing_mode: "ask",
  default_play_speed: 1,
  download_mode: "ask",
  autoplay: false,
  fullscreen_on_rotate: true,
  pause_video_on_screen_lock: false,
};

const Playback = ({ closeDrawer, isHeader = false }) => {
  const { settings, error, isLoading, updatePlaybackSettings } =
    usePlaybackSettings(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectOpen2, setIsSelectOpen2] = useState(false);
  const [isSelectOpen3, setIsSelectOpen3] = useState(false);

  // if (isLoading) return <div>Loading playback settings...</div>;
  // if (error) return <div>Error loading playback settings.</div>;

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const updatedSettings = {
      ...settings,
      [name]: type === "checkbox" ? checked : value,
    };

    updatePlaybackSettings(updatedSettings);
    setToastOpen(true);
    setToastMessage("Settings Updated");
  };

  const handleResetSettings = () => {
    updatePlaybackSettings(initialSettings);
    setToastOpen(true);
    setToastMessage("Media settings were reset to default successfully");
  };

  return (
    <>
      <BlockHeading>
        <Box
          sx={{
            display: isHeader ? "flex" : "block",
            alignItems: "center",
            gap: 3,
            mb: isHeader ? 4 : 0,
          }}
        >
          {isHeader && (
            <IconBtnCircular onClick={closeDrawer}>
              <ChevronRight />
            </IconBtnCircular>
          )}
          <Typography component="h3" variant="blockTitle" mb={pxToRem(5)}>
            Customize Your Media Playback Settings
          </Typography>
        </Box>
        <Typography component="p" variant="medium">
          Adjust how media content plays based on your preferences
        </Typography>
      </BlockHeading>

      <Stack
        gap={pxToRem(20)}
        sx={{
          "& label": {
            alignItems: "flex-start",
            "& .MuiTypography-root": {
              fontSize: pxToRem(18),
              fontWeight: 600,
              color: "#333",
            },
          },
          "& .MuiSwitch-root": {
            mt: "4px !important",
          },
        }}
      >
        <FormControl sx={{ marginLeft: "-15px" }}>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Playing Mode
          </InputLabel>
          <Select
            value={settings?.playing_mode}
            onChange={handleChange}
            name="playing_mode"
            sx={{ width: "220px", textAlign: "left" }}
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: "transform 0.3s",
                  transform: isSelectOpen ? "rotate(-90deg)" : "rotate(90deg)",
                }}
              />
            )}
            onOpen={() => setIsSelectOpen(true)}
            onClose={() => setIsSelectOpen(false)}
          >
            <MenuItem value="watch">Watch</MenuItem>
            <MenuItem value="listen">Listen</MenuItem>
            <MenuItem value="ask">Ask Me Everytime</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ marginLeft: "-15px" }}>
          <Select
            value={settings?.default_play_speed}
            onChange={handleChange}
            name="default_play_speed"
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: "transform 0.3s",
                  transform: isSelectOpen2 ? "rotate(-90deg)" : "rotate(90deg)",
                }}
              />
            )}
            onOpen={() => setIsSelectOpen2(true)}
            onClose={() => setIsSelectOpen2(false)}
            sx={{ width: "220px", textAlign: "left" }}
          >
            <MenuItem value={0.25}>0.25</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.75}>0.75</MenuItem>
            <MenuItem value={1.0}>Normal</MenuItem>
            <MenuItem value={1.25}>1.25</MenuItem>
            <MenuItem value={1.5}>1.5</MenuItem>
            <MenuItem value={1.75}>1.75</MenuItem>
            <MenuItem value={2.0}>2</MenuItem>
          </Select>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Default Play Speed
          </InputLabel>
        </FormControl>

        <FormControl sx={{ marginLeft: "-15px" }}>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Download Mode
          </InputLabel>
          <Select
            value={settings?.download_mode}
            onChange={handleChange}
            name="download_mode"
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: "transform 0.3s",
                  transform: isSelectOpen3 ? "rotate(-90deg)" : "rotate(90deg)",
                }}
              />
            )}
            onOpen={() => setIsSelectOpen3(true)}
            onClose={() => setIsSelectOpen3(false)}
            sx={{ width: "220px", textAlign: "left" }}
          >
            <MenuItem value="ask">Ask</MenuItem>
            <MenuItem value="mp3">Audio</MenuItem>
            <MenuItem value="mp4">Video</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <Switcher
            checked={settings?.autoplay}
            onChange={handleChange}
            label="Autoplay (Weekly Shiurim)"
            name="autoplay"
          />
        </FormControl>

        <FormControl>
          <Switcher
            checked={settings?.fullscreen_on_rotate}
            onChange={handleChange}
            label="Rotate On Fullscreen"
            name="fullscreen_on_rotate"
          />
        </FormControl>

        <FormControl>
          <Switcher
            checked={settings?.pause_video_on_screen_lock}
            onChange={handleChange}
            label="Pause Video on Screen Lock"
            name="pause_video_on_screen_lock"
          />
        </FormControl>
      </Stack>

      <Divider
        sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
      />

      <Button size="small" variant="outlined" onClick={handleResetSettings}>
        Reset Settings
      </Button>

      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default Playback;
