import React from "react";
import { Link } from "react-router-dom";

const Logo = React.memo(({ logo, style }) => {
  return (
    <Link to="/" style={{ display: "flex" }}>
      {logo ? (
        <img src={logo} alt="Logo" style={style} />
      ) : (
        <div style={{ ...style }} />
      )}
    </Link>
  );
});

export default Logo;
