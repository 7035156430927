import { useState, useEffect, useRef } from "react";
import {
  Box,
  Chip,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as Search } from "../../images/search.svg";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import VideosTab from "./components/VideosTab";
import CoursesTab from "./components/CoursesTab";
import BlogsTab from "./components/BlogsTab";
import useDebounce from "../../hooks/useDebounce";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ pr: pxToRem(10), pt: pxToRem(18) }}>
        <Box
          sx={{
            maxHeight: {
              xs: "min(100vh - 178px)",
              ssm: `min(100vh - 264px,${pxToRem(530)})`,
            },
            overflowX: { xs: "visible", ssm: "hidden" },
            "&::-webkit-scrollbar": { width: pxToRem(6) },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#D9D9D9",
              borderRadius: pxToRem(100),
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#B6B6B6",
              borderRadius: pxToRem(100),
            },
          }}
        >
          {value === index && (
            <Box sx={{ p: `0 ${pxToRem(18)}` }}>{children}</Box>
          )}
        </Box>
      </Box>
    </div>
  );
}

/* const QATab = ({ tab }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };

  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll((prev) => !prev);
  };

  let visibleCourses;
  if (tab === 0) {
    visibleCourses = !showAll ? questionsAnswers.slice(0, 2) : questionsAnswers;
  } else {
    visibleCourses = !showAll ? questionsAnswers.slice(0, 4) : questionsAnswers;
  }

  return (
    <>
      <Box my={pxToRem(16)} mt={tab === 0 ? pxToRem(16) : pxToRem(10)}>
        <Stack rowGap={pxToRem(16)}>
          {visibleCourses.map((questionAnswer, index) => (
            <Accordion
              key={index}
              expanded={expandedIndex === index}
              onChange={handleChange(index)}
              sx={{
                borderRadius: `${pxToRem(6)} !important`,
                minHeight: pxToRem(45),
                margin: "0",
                backgroundColor: "#E8E8E8",
                "&:hover .MuiAccordionSummary-content p": { color: "#026670" },
                "&.Mui-expanded": {
                  "&::after": { content: "none" },
                  boxShadow: "none",
                  margin: 0,
                },
                "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded":
                  {
                    padding: {
                      xs: `0 ${pxToRem(30)} 0 ${pxToRem(20)} !important`,
                      ssm: `0 ${pxToRem(18)} !important`,
                    },
                  },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  transform: "none !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <KeyboardArrowDownIcon
                    sx={{
                      width: pxToRem(30),
                      transform:
                        expandedIndex === index ? "rotate(180deg)" : "none",
                      transition: "all 200ms ease-in-out",
                    }}
                  />
                }
                aria-controls="panel1-content"
                id={`panel${index}-header`}
                sx={{
                  minHeight: `${pxToRem(44)} !important`,
                  gap: `${pxToRem(20)} !important`,
                  "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded":
                    { margin: 0 },
                }}
              >
                <Typography
                  fontSize={pxToRem(16)}
                  fontWeight={500}
                  color="primary"
                >
                  {questionAnswer.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: pxToRem(14),
                  lineHeight: pxToRem(20),
                  padding: `0 ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(
                    20
                  )} !important`,
                }}
              >
                {questionAnswer.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Box>

      {tab === 0 && questionsAnswers.length > 2 && (
        <Typography
          variant="button"
          component="p"
          sx={{
            cursor: "pointer",
            fontWeight: "500",
            textTransform: "capitalize",
            textDecoration: "underline",
            "&:hover": { color: "#026670" },
            p: `0 0 ${pxToRem(20)} ${pxToRem(12)}`,
          }}
          onClick={handleViewMore}
        >
          {showAll ? "Show Less" : "View More"}
        </Typography>
      )}

      {tab !== 0 && questionsAnswers.length > 4 && (
        <Stack alignItems="center">
          <Box
            sx={{
              borderRadius: pxToRem(50),
              backgroundColor: "#E9E9E9",
              p: `${pxToRem(8)} ${pxToRem(18)}`,
              display: "inline-block",
              mt: pxToRem(10),
            }}
            onClick={handleViewMore}
          >
            <Typography
              variant="button"
              component="p"
              sx={{
                cursor: "pointer",
                fontWeight: "500",
                textTransform: "capitalize",
                "&:hover": { color: "#026670" },
              }}
            >
              {showAll ? "Show Less" : "View More Results"}
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  );
}; */

const HeaderSearchComponent = ({ toggleSearchTabs }) => {
  const searchTabsRef = useRef(null);
  const searchInputRef = useRef(null);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [coursesCount, setCoursesCount] = useState(0);
  const [videosCount, setVideosCount] = useState(0);
  const [blogsCount, setBlogsCount] = useState(0);
  const [searchDB, setSearchDB] = useDebounce();

  useEffect(() => {
    setSearchDB(search);
  }, [search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchTabsRef.current &&
        !searchTabsRef.current.contains(event.target) &&
        !event.target.closest(".search-icon")
      ) {
        toggleSearchTabs();
      }
    };
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSearchTabs]);

  const handleClearSearch = () => {
    setSearch("");
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <Box
      ref={searchTabsRef}
      sx={{
        borderRadius: { ssm: pxToRem(14.5) },
        py: `${pxToRem(37)}`,
        backgroundColor: "#FFFFFF",
        maxWidth: { xs: "100%", ssm: pxToRem(450) },
        width: { xs: "100%", ssm: "auto", sm: pxToRem(450) },
        position: { xs: "fixed", ssm: "absolute" },
        right: 0,
        top: { xs: 0, ssm: pxToRem(10) },
        zIndex: 2,
        filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.16))",
        maxHeight: "100vh", // Ensure it fits within the viewport
        overflowY: "auto", // Enable vertical scrolling
        "-webkit-overflow-scrolling": "touch", // Smooth scrolling on iOS
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={pxToRem(10)}
        mb={pxToRem(13)}
        px={pxToRem(30)}
      >
        <Box
          sx={{ pr: pxToRem(10), cursor: "pointer", display: { ssm: "none" } }}
          onClick={toggleSearchTabs}
        >
          <Chevron color="#C0C0C0" style={{ transform: "rotate(180deg)" }} />
        </Box>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            pl: pxToRem(23),
            py: pxToRem(8),
            flexGrow: 1,
            backgroundColor: "#F5F5F5",
            borderRadius: "100px",
          }}
        >
          <Search
            style={{ flexShrink: 0, color: "#C0C0C0", width: pxToRem(18) }}
          />
          <TextField
            id="filled-search"
            type="text"
            inputRef={searchInputRef}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search here…"
            autoFocus
            sx={{
              "& .MuiInputBase-input": {
                padding: `0 ${pxToRem(22)}`,
                fontSize: pxToRem(18),
                "&::-webkit-search-cancel-button": {
                  opacity: "1 !important",
                  cursor: "pointer",
                },
              },
              flexGrow: 1,
              "& .MuiInputBase-input::placeholder": {
                fontWeight: 400,
                fontSize: pxToRem(18),
              },
            }}
            InputProps={{
              endAdornment: search ? (
                <InputAdornment position="end">
                  <Close
                    onClick={handleClearSearch}
                    style={{
                      color: "#C0C0C0",
                      cursor: "pointer",
                      width: pxToRem(16),
                      height: pxToRem(16),
                    }}
                  />
                </InputAdornment>
              ) : null,
            }}
          />
        </Stack>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            p: { xs: "0", ssm: `0 ${pxToRem(30)}` },
          }}
        >
          <Tabs
            value={value}
            variant="scrollable"
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-flexContainer": {
                columnGap: { xs: pxToRem(30), ssm: pxToRem(37) },
              },
              "& .MuiTabScrollButton-root": { zIndex: 3 },
              minHeight: pxToRem(50),
            }}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              sx={{
                padding: { xs: `0 0 0 ${pxToRem(30)}`, ssm: 0 },
                minWidth: "auto",
                color: "#C0C0C0",
                fontSize: pxToRem(18),
                textTransform: "capitalize",
                "&.Mui-selected .MuiChip-root": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
                minHeight: pxToRem(50),
              }}
              label="All"
            />
            <Tab
              sx={{
                padding: 0,
                minWidth: "auto",
                color: "#C0C0C0",
                fontSize: pxToRem(18),
                textTransform: "capitalize",
                "&.Mui-selected .MuiChip-root": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
                minHeight: pxToRem(50),
              }}
              label={
                <Stack direction="row" alignItems="center">
                  Courses
                  <Chip
                    variant="solid"
                    sx={{
                      color: "#949494",
                      fontSize: pxToRem(13),
                      fontWeight: "500",
                      flexShrink: 0,
                      background: "#E3E3E3",
                      px: pxToRem(8),
                      py: pxToRem(1),
                      ml: pxToRem(5),
                      borderRadius: pxToRem(8),
                      "& .MuiChip-label": { padding: 0 },
                      height: "auto",
                    }}
                    label={coursesCount}
                  />
                </Stack>
              }
            />
            <Tab
              sx={{
                padding: 0,
                minWidth: "auto",
                color: "#C0C0C0",
                fontSize: pxToRem(18),
                textTransform: "capitalize",
                "&.Mui-selected .MuiChip-root": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
                minHeight: pxToRem(50),
              }}
              label={
                <Stack direction="row" alignItems="center">
                  Shiurim
                  <Chip
                    variant="solid"
                    sx={{
                      color: "#949494",
                      fontSize: pxToRem(13),
                      fontWeight: "500",
                      flexShrink: 0,
                      background: "#E3E3E3",
                      px: pxToRem(8),
                      py: pxToRem(1),
                      ml: pxToRem(5),
                      borderRadius: pxToRem(8),
                      "& .MuiChip-label": { padding: 0 },
                      height: "auto",
                    }}
                    label={videosCount}
                  />
                </Stack>
              }
            />
            <Tab
              sx={{
                padding: 0,
                minWidth: "auto",
                color: "#C0C0C0",
                fontSize: pxToRem(18),
                textTransform: "capitalize",
                "&.Mui-selected .MuiChip-root": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
                minHeight: pxToRem(50),
              }}
              label={
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pr: { xs: pxToRem(30), ssm: 0 } }}
                >
                  Blogs
                  <Chip
                    variant="solid"
                    sx={{
                      color: "#949494",
                      fontSize: pxToRem(13),
                      fontWeight: "500",
                      flexShrink: 0,
                      background: "#E3E3E3",
                      px: pxToRem(8),
                      py: pxToRem(1),
                      ml: pxToRem(5),
                      borderRadius: pxToRem(8),
                      "& .MuiChip-label": { padding: 0 },
                      height: "auto",
                    }}
                    label={blogsCount}
                  />
                </Stack>
              }
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="large"
            fontWeight="700"
            component="h4"
            lineHeight={pxToRem(25)}
            pl={pxToRem(12)}
          >
            Courses
          </Typography>
          <CoursesTab
            tab={0}
            search={searchDB}
            setCoursesCount={setCoursesCount}
            coursesCount={coursesCount}
          />
          <Divider sx={{ borderColor: "#D7D7D7" }} />
          <Typography
            variant="large"
            fontWeight="700"
            component="h4"
            lineHeight={pxToRem(25)}
            pl={pxToRem(12)}
            pt={pxToRem(22)}
          >
            Shiurim
          </Typography>
          <VideosTab
            tab={0}
            search={searchDB}
            setVideosCount={setVideosCount}
            videosCount={videosCount}
          />
          <Divider sx={{ borderColor: "#D7D7D7" }} />
          {/*  <Typography
            variant="large"
            fontWeight="700"
            component="h4"
            lineHeight={pxToRem(25)}
            pl={pxToRem(12)}
            pt={pxToRem(22)}
          >
            Q&A
          </Typography>
          <QATab tab={0} /> */}
          <Typography
            variant="large"
            fontWeight="700"
            component="h4"
            lineHeight={pxToRem(25)}
            pl={pxToRem(12)}
            pt={pxToRem(22)}
          >
            Blogs
          </Typography>
          <BlogsTab
            tab={0}
            search={searchDB}
            setBlogsCount={setBlogsCount}
            blogsCount={blogsCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CoursesTab
            search={searchDB}
            setCoursesCount={setCoursesCount}
            coursesCount={coursesCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <VideosTab
            search={searchDB}
            setVideosCount={setVideosCount}
            videosCount={videosCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <BlogsTab
            search={searchDB}
            setBlogsCount={setBlogsCount}
            blogsCount={blogsCount}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default HeaderSearchComponent;
