import { useTheme, useMediaQuery, Dialog, Slide } from "@mui/material";
import { pxToRem } from "px2rem2px";
import LoginPage from ".";
import VerifyEmail from "./VerifyEmail";
import PasswordResetRequest from "./PasswordResetRequest";
import ChangeTempPassword from "./ChangeTempPassword";
import { forwardRef, useEffect, useState } from "react";
import Toast from "../../components/Toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("ssm"));
  const { ...restProps } = props;
  const [action, setAction] = useState("login");
  const [successMessage, setSuccessMessage] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [tempPass, setTempPass] = useState("");

  // feedback
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openCodeSuccess, setOpenCodeSuccess] = useState(false);

  // toast fix
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  
const handleCloseToast = () => {
  setToastOpen(false);
  setTimeout(() => {
    setToastMessage("");
  }, 1000); 
};

  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));




  return (
    <>
      <Toast
        open={toastOpen}
        message={toastMessage}
        onClose={handleCloseToast}
      />
      <Dialog
        {...restProps}
        fullScreen={fullScreen}
        maxWidth="ssm"
        keepMounted
        scroll="body"
        TransitionComponent={mobile_ssm ? Transition : undefined}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            return;
          }
        }}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: { xs: "100%", ssm: "400px" },
            width: { xs: "100%", ssm: "400px" },
            borderRadius: {xs: 0, ssm: pxToRem(20)},
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            verticalAlign: "top",
            // maxHeight: {xs: "85vh", ssm: "none"},
          },
          "& .MuiDialog-container": {
            // overflowY: "scroll",
            alignItems: {xs: "flex-end", ssm: "center"},
          },
          zIndex: 1500,
        }}
      >
        {action === "login" && (
          <LoginPage
            inModal={true}
            setAction={setAction}
            {...restProps}
            setVerifyEmail={setVerifyEmail}
            setTempEmail={setTempEmail}
            setTempPass={setTempPass}
            successMessage={successMessage}
            onRedirectOpenModal={props?.onRedirectOpenModal}
            onRedirectActivateNotification={props?.onRedirectActivateNotification}
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            setOpenCodeSuccess={setOpenCodeSuccess}
            //
            setToastOpen={setToastOpen}
            setToastMessage={setToastMessage}
          />
        )}
        {action === "verifyEmail" && (
          <VerifyEmail
            inModal={true}
            {...restProps}
            setAction={setAction}
            verifyEmail={verifyEmail}
            setSuccessMessage={setSuccessMessage}
            onRedirectOpenModal={props?.onRedirectOpenModal}
            onRedirectActivateNotification={props?.onRedirectActivateNotification}
            openCodeSuccess={openCodeSuccess}
            setOpenCodeSuccess={setOpenCodeSuccess}
            //
            setToastOpen={setToastOpen}
            setToastMessage={setToastMessage}
          />
        )}
        {action === "passwordResetRequest" && (
          <PasswordResetRequest
            {...restProps}
            inModal={true}
            setAction={setAction}
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            toastOpen={toastOpen}
            setToastOpen={setToastOpen}
            setToastMessage={setToastMessage}
          />
        )}

        {action === "changeTempPassword" && (
          <ChangeTempPassword
            inModal={true}
            {...restProps}
            setAction={setAction}
            setSuccessMessage={setSuccessMessage}
            tempPass={tempPass}
            tempEmail={tempEmail}
            fullScreen={fullScreen}
          />
        )}
      </Dialog>
    </>
  );
};

export default LoginModal;
