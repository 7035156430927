import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import FormInputText from "../../../components/FormInputText";
import { useForm } from "react-hook-form";
import { editProfile } from "../../../api/student";
import ConfirmCodeModal from "./ConfirmCodeModal";

const EmailInputEdit = ({
  label,
  editLabel,
  value,
  type,
  rules,
  originalEmail,
  onEmailUpdated, 
}) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      outline: "2px solid #333",
      outlineOffset: "-2px",
      backgroundColor: "#fff",
      width: "268px",
      "&.Mui-disabled": {
        backgroundColor: "#EDECE8",
        outline: "none",
      },
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
    },
  };

  const [savedValue, setSavedValue] = useState(value || "");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  // React Hook Form
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors: formErrors },
    watch,
  } = useForm({
    defaultValues: {
      value: value || "",
    },
  });

  useEffect(() => {
    setSavedValue(value);
    setValue("value", value || ""); 
  }, [value, setValue]);

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);


  const getErrorsArray = (errorData) => {
    if (typeof errorData === "string") return [errorData];
    if (Array.isArray(errorData)) return errorData;
    if (typeof errorData === "object") return Object.values(errorData).flat();
    return ["Unexpected error occurred."];
  };

  const sendVerificationCode = () => {
    setLoading(true);
    const email = watch("value").toLowerCase();
    const oldEmail = value?.toLowerCase();

    if (!isValidEmail(email)) {
      setLoading(false);
      setError("value", {
        type: "manual",
        message: "Please enter a valid email address.",
      });
      return;
    }

    if (email === oldEmail) {
      setLoading(false);
      setError("value", {
        type: "manual",
        message: "New email address cannot be the same as the old email address.",
      });
      return;
    }

    editProfile({ user: { email } })
      .then((res) => {
        if (res.status !== 202 && res.status !== 200) {
          throw new Error(res.data);
        }
        clearErrors("value");
        setLoading(false);
        setResendTimer(60);
        setOpenModal(true);
        setNewEmail(email);
      })
      .catch((error) => {
        setLoading(false);
        let message = "Unexpected error occurred.";

        if (error.response) {
          const contentType = error.response.headers["content-type"];
          if (contentType && contentType.includes("application/json")) {
            message = getErrorsArray(error.response.data).join(", ");
          }
        } else {
          message = "Network error. Please try again later.";
        }

        setError("value", {
          type: "manual",
          message,
        });
      });
  };

  const onSubmit = () => {
    sendVerificationCode();
  };

  const resetForm = () => {
    setValue("value", savedValue, { shouldValidate: true });
    setDisabled(true);
  };


  const handleEndEditMode = (isVerified) => {
    setDisabled(true);
    if (typeof onEmailUpdated === "function") {
      onEmailUpdated(); 
    }
    setValue("value", originalEmail);
  };

  return (
    <>
      <Stack
        flexDirection={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "flex-start", lg: "flex-start" }}
        gap={{ xs: "10px", lg: 0 }}
      >
        <Typography
          width={{ xs: "auto", lg: "130px" }}
          mt={{ xs: 0, lg: "13px" }}
          variant="medium"
        >
          {label}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack flex={1} flexDirection="row" gap={pxToRem(20)}>
            <Box maxWidth="268px">
              <FormInputText
                name="value"
                control={control}
                setValue={setValue}
                rules={rules}
                muiProps={{
                  type,
                  disabled,
                  sx: inputStyles,
                }}
              />
            </Box>
            <Stack alignItems="flex-start" width="42px">
              {disabled ? (
                <Button
                  onClick={() => setDisabled(false)}
                  sx={{ fontSize: "12px", mt: "16px" }}
                >
                  Edit
                </Button>
              ) : (
                <Stack alignItems="flex-start" mt="10px">
                  <Button
                    onClick={resetForm}
                    sx={{ color: "#BFBEBB", fontSize: "12px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" sx={{ fontSize: "12px" }} disabled={loading}>
                    Change
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </form>
      </Stack>

      <ConfirmCodeModal
        open={openModal}
        setOpen={setOpenModal}
        oldEmail={value}
        email={newEmail}
        handleEndEditMode={handleEndEditMode}
      />
    </>
  );
};

export default EmailInputEdit;
