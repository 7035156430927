import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  FormHelperText,
  Slide,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { getGiftCheckoutLink } from "../../api/student";
import ModalHeader from "../../components/ModalHeader";
import FormInputText from "../../components/FormInputText";
import { useForm } from "react-hook-form";
import { isTodayBetweenDates } from "../../utils/format";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "30px",
    backgroundColor: "#EDECE8",
    height: "54px",
  },
  "& input": {
    borderRadius: "30px !important",
    padding: "15px 40px",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const GiftCourseModal = ({ open, handleClose, course }) => {
  const { control, setValue, handleSubmit, watch } = useForm();

  const email = watch("loginEmail");

  const [error, setError] = useState("");

  const handleGiftCourse = async (data, giftType) => {
    const email = data.loginEmail;
    const message = data.optionalMessage; 
    if (email) {
      try {
        const response = await getGiftCheckoutLink(
          course.id,
          email,
          giftType,
          message
        );
        const checkoutUrl = response.data.checkout_url;
        window.location.href = checkoutUrl;
      } catch (error) {
        if (error.response && error.response.data.non_field_errors) {
          setError(error.response.data.non_field_errors[0]);
        } else {
          console.error("Error generating checkout link:", error);
        }
      }
    }
  };

  const handleDialogClose = () => {

    handleClose();
    setError("");
  };

  //is on sale
  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  useEffect(() => {
    if (email) {
      setError(""); 
    }
  }, [email]);
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      keepMounted
      scroll={mobile_ssm ? "paper": "body"}
      TransitionComponent={mobile_ssm ? Transition : undefined}
      sx={{
        "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
        "& .MuiDialog-paper": {
          borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          maxWidth: { xs: "100%", ssm: "500px" },
          width: { xs: "100%", ssm: "500px" },
          maxHeight: {xs: "85vh", ssm: "none"},
        },
      }}
    >
      <ModalHeader
        title="Gift This Course"
        inModal={true}
        onClose={() => handleDialogClose()}
      />
      <DialogContent
        sx={{
          minHeight: "240px",
          backgroundColor: "#f7f6f2",
          p: { xs: pxToRem(30), ssm: pxToRem(50) },
        }}
      >
        <form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <FormInputText
                name="loginEmail"
                control={control}
                setValue={setValue}
                placeholder="Enter Recipient's Email"
                sx={{
                  flexGrow: "1",
                  width: { xs: "100%", ssm: "auto" },
                }}
                rules={{
                  required: "Field can't be empty",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                muiProps={{
                  type: "email",
                }}
              />
               {error && (
                <FormHelperText error sx={{fontSize:"14px",marginLeft:"14px"}}>{error}</FormHelperText> 
              )}
            </Box>

            <Box>
              <FormInputText
                name="optionalMessage"
                control={control}
                setValue={setValue}
                placeholder="Message to Recipient (Optional)"
                sx={{
                  flexGrow: "1",
                  width: { xs: "100%", ssm: "auto" },
                  "& .MuiInputBase-root": {
                    height: "auto",
                    borderRadius: pxToRem(10),
                    padding: 0,
                  },

                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input:focus": {
                    outline: "none",
                  },
                }}
                muiProps={{
                  type: "text",
                  multiline: true,
                  rows: 7,
                  variant: "none",
                }}
              />
            </Box>

            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", ssm: "row" },
                justifyContent: "space-between",
                gap: pxToRem(16),
              }}
            >
              <Button
                onClick={handleSubmit((data) =>
                  handleGiftCourse(data, "Lifetime")
                )}
                variant="outlined"
                sx={{
                  width:{
                    xs:"100%",
                    ssm:"50%"
                  },
                  height: pxToRem(54),
                  borderWidth: "2px",
                  "&:hover": {
                    border: "2px solid #026670",
                  },
                }}
              >
                Lifetime {`$${course.lifetime_price}`}
              </Button>
              <Button
                onClick={handleSubmit((data) =>
                  handleGiftCourse(data, "Normal")
                )}
                variant="yellow"
                sx={{
                  height: pxToRem(54),
                  width:{
                    xs:"100%",
                    ssm:"50%"
                  }
                }}
              >
                {isOnSale ? (
                  <>
                    <Typography component="span" fontWeight={600}>
                      {`Subscription: $${course.sale_price}`}
                    </Typography>
                    <Typography
                      fontSize="13px"
                      fontWeight="600"
                      color="#4a4a4a"
                      sx={{
                        textDecoration: "line-through",
                        marginLeft: 1,
                      }}
                    >
                      {course.subscription_price > 0 &&
                        `$${course.subscription_price}`}
                    </Typography>
                  </>
                ) : (
                  <Typography component="span" fontWeight={600}>
                    {`Subscription: $${course.subscription_price}`}
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default GiftCourseModal;
