import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Link,
  Stack,
  Typography,
  Skeleton,
  Rating,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
import BlockBordered from "../../components/BlockBordered";
import LikeBtn from "../../components/courses/LikeBtn";
import { isTodayBetweenDates } from "../../utils/format";
import { StarIcon, StarIconContainer } from "./StarIcon";
import shouldNavigateToPresubscribe from "../../utils/subscription";
import CourseImg1 from "../../images/course-img1.png";

import { getLanguageClass } from "../../utils/languageClass";

const Duration = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #026670;
  border-radius: 0 5px 0 0;
  padding: 3px 11px;
  transition: 0.2s ease;
  opacity: 0;

  @media (max-width: 899px) {
    opacity: 1;
  }
`;

const Label = styled(Typography)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#f8eab9",
    border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 18px",

    [theme.breakpoints.up("sssm")]: {
      padding: "0 25px",
    },
  };
});

const ClampedText = styled(Typography)(({ showBlur }) => ({
  position: "relative",
  maxHeight: "3.6em",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: "1.8em",
}));

const CourseSubtitle = ({ subtitle, isAlignedRight }) => {
  const textRef = useRef(null);

  return (
    <Box position="relative" overflow="hidden">
      <ClampedText
        ref={textRef}
        variant="medium"
        component="p"
        color="#333333"
        className={getLanguageClass(subtitle)}
      >
        {subtitle}
      </ClampedText>
    </Box>
  );
};

const Course = ({
  isAuthenticated,
  course,
  onFavoriteClick,
  loading,
  passRef,
  isHomePage,
  ...props
}) => {
  const [isHebrew, setIsHebrew] = useState(false);

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  useEffect(() => {
    setIsHebrew(getLanguageClass(props?.subtitle) === "is-hebrew-text");
  }, [props?.subtitle]);

  const RenderPrice = () => (
    <Stack>
      {isOnSale ? (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography
            fontSize="25px"
            lineHeight="25px"
            fontWeight="700"
            color="#026670"
          >
            {course.sale_price <= 0 ? "Free" : `$${course.sale_price}`}
          </Typography>
          <Typography
            fontSize={{ xs: "16px", sm: "16px" }}
            fontWeight="600"
            color="#BFBEBB"
            sx={{
              textDecoration: "line-through",
              transform: "translateY(2px)",
            }}
          >
            {course.subscription_price > 0 && `${course.subscription_price}`}
          </Typography>
        </Box>
      ) : (
        <Typography
          fontSize={{ xs: "25px", sm: "25px" }}
          lineHeight="25px"
          fontWeight="700"
          color="#026670"
        >
          {course.subscription_price <= 0
            ? "Free"
            : `$${course.subscription_price}`}
        </Typography>
      )}
    </Stack>
  );

  const RenderStatus = () => (
    <Typography
      component="div"
      fontSize="14px"
      fontWeight={600}
      color="#fff"
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "#026670",
        borderRadius: "14px",
        padding: "0px 10px 0px 5px",
        whiteSpace: "nowrap",
        gap: 1,
      }}
    >
      {props?.subscriptionStatus === "Expired" ? (
        <>
          <svg
            width="17px"
            height="17px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              color: "#fff",
              marginRight: "-2px",
              marginLeft: "-2px",
            }}
          >
            <path
              d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
              fill="currentColor"
            />
          </svg>
          <span>Expired</span>
        </>
      ) : (
        !shouldNavigateToPresubscribe(props?.subscriptionStatus) && (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              style={{
                marginRight: "-2px",
                marginLeft: "-2px",
              }}
            >
              <circle cx="7" cy="7" r="7" fill="#fff" />
              <path
                d="M4.5 7l2 2 4-4"
                fill="none"
                stroke="#026670"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>{course?.progress === 100 ? "Completed" : "Enrolled"}</span>
          </>
        )
      )}
    </Typography>
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: "420px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        ref={passRef}
        position="relative"
        bgcolor="#fff"
        borderRadius={pxToRem(20)}
        sx={{
          // overflow: "visible",
          transition: "0.2s ease",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: `calc(100% - ${pxToRem(40)})`,
            height: "100%",
            backgroundColor: "#FCE181",
            borderRadius: pxToRem(20),
            transform: "translate(-50%,-50%)",
            zIndex: -1,
            transition: "0.2s ease",
          },
          "& .MuiButton-root": {
            position: "static",
            fontSize: "16px",
            height: { xs: "44px", ssm: pxToRem(54) },
            opacity: 0,
            transition: "0.2s ease",
            zIndex: 1,
          },
          "&:hover": {
            boxShadow: {
              xs: "none",
              sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
            },
            "&::before": {
              height: `calc(100% + ${pxToRem(40)})`,
            },
            "& .slideTitle": {
              color: "primary.main",
            },
            "& .MuiButton-root, .duration": {
              opacity: 1,
            },
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              "& > *": { gridRow: "1 / -1", gridColumn: "1 / -1" },
            }}
          >
            <Button
              to={props.linkUrl}
              component={NavLink}
              variant="yellow"
              sx={{ padding: "0 40px", minWidth: "150px" }}
            >
              {isHomePage
                ? "Watch"
                : shouldNavigateToPresubscribe(props.subscriptionStatus)
                ? "View"
                : props.subscriptionStatus === "Expired"
                ? "Resubscribe"
                : props.subscriptionStatus === "Active" &&
                  course?.progress === 100
                ? "Watch Again"
                : "Continue Watching"}
            </Button>

            <Box
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
              height="200px"
              width="100%"
              overflow="hidden"
            >
              {isAuthenticated ? (
                <LikeBtn
                  courseId={course.id}
                  isFavorite={course.is_favorite}
                  onFavoriteClick={onFavoriteClick}
                />
              ) : (
                <></>
              )}
              <Duration className="duration">{props?.duration}</Duration>

              <img
                src={course?.thumb_nail}
                alt={props.title}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Box>
            <Stack
              width={{
                xs: "calc(100% - 40px)",
                ssm: "90%",
                sm: "calc(100% - 60px)",
                md: "90%",
                lg: "calc(100% - 60px)",
              }}
              flexDirection="row"
              alignContent="center"
              justifyContent="flex-end"
              overflow="hidden"
              flexWrap="nowrap"
              gap="8px"
              position="absolute"
              top="190px"
            >
              {props?.labels?.slice(0, 2).map((label, labelIndex) => (
                <Label
                  key={labelIndex}
                  className={getLanguageClass(label, true)}
                >
                  {label}
                </Label>
              ))}
            </Stack>
          </Box>

          <Box
            p={{ xs: pxToRem(25), sm: "26px 36px" }}
            bgcolor="#fff"
            borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
            overflow="hidden"
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="space-between"
          >
            <Box>
              <Box sx={{ margin: "15px 0" }}>
                <Typography
                  className={`slideTitle ${getLanguageClass(
                    props?.title,
                    false,
                    true
                  )}`}
                  fontSize={pxToRem(26)}
                  fontWeight={700}
                  lineHeight={pxToRem(30)}
                  color="secondary.main"
                  mb={pxToRem(12)}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    fontFamily: "PloniBold",
                    wordBreak: "break-all",
                  }}
                >
                  {props?.title}
                </Typography>
                <BlockBordered
                  isAlignedRight={
                    getLanguageClass(props?.subtitle) === "is-hebrew-text"
                  }
                  styleForBoxParent={{ wordBreak: "break-all" }}
                >
                  <CourseSubtitle subtitle={props?.subtitle} />
                </BlockBordered>
              </Box>
              {!isHomePage && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isHebrew ? "flex-end" : "flex-start",
                    gap: 2,
                    marginBottom: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {isHebrew ? (
                    <>
                      <RenderStatus />
                      <RenderPrice />
                    </>
                  ) : (
                    <>
                      <RenderPrice />
                      <RenderStatus />
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: isHomePage?"nowrap":"wrap",
                gap: pxToRem(10),
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",

                  flexGrow: 1,
                  marginTop: "2px",
                }}
              >
                <Typography
                  component="p"
                  fontSize="12px"
                  fontWeight={600}
                  color="#BFBEBB"
                  whiteSpace="nowrap"
                >
                  Posted {new Date(props.date).toLocaleDateString()}&nbsp; •
                  &nbsp;
                  {props.subscribers}{" "}
                  {props.subscribers === 1 ? "Subscriber" : "Subscribers"}
                </Typography>
              </Box>

              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="p"
                  fontSize="14px"
                  fontWeight={600}
                  color="#BFBEBB"
                  whiteSpace="nowrap"
                  marginRight="2px"
                >
                  {Number(course.rating).toFixed(1)}
                </Typography>

                <Rating
                  name="read-only"
                  value={course.rating}
                  readOnly
                  size="small"
                  icon={<StarIcon color="#fce181" size={13} />}
                  emptyIcon={<StarIcon color="#dfdedd" size={13} />}
                  sx={{ marginLeft: "2px", marginTop: "-2px" }}
                  IconContainerComponent={StarIconContainer}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Course;
