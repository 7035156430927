import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { rtlTheme } from "../../theme";
import { ThemeProvider } from "@mui/material/styles";

import { pxToRem } from "px2rem2px";
import {
  getCategories,
  getSubgroupVideos,
  postVideoFavorite,
  postVideoLike,
} from "../../api/parsha";
import confetti from "canvas-confetti";
import { useAuth } from "../../context/AuthContext";
import { formatDuration } from "../../utils/format";
import ParshaCard from "../../components/parsha/ParshaCard";
import { SwiperSlide } from "swiper/react";
import Toast from "../../components/Toast";
import { useToastNotification } from "../../context/ToastNotificationContext";
import useLikeHandler from "../../hooks/useLikeHandler";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  gap: "2px",
  marginBottom: pxToRem(34),
  padding: "0 13px",
  [theme.breakpoints.down("ssm")]: {
    margin: "0 -25px",
  },
  "&::before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    borderRadius: "calc(infinity * 1px)",
    left: 0,
    bottom: -2,
    backgroundColor: "#026670",
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.07)",
    backdropFilter: "blur(5px)",
    zIndex: 1,
  },
  "& button": {
    maxWidth: "220px",
    height: pxToRem(64),
    fontFamily: "Noto Sans Hebrew",
    width: "100%",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600,
    color: "#333",
    backgroundColor: "#E6E5E1",
    border: "none !important",
    borderRadius: "10px 10px 0 0 !important",
    textTransform: "capitalize",
    margin: "0 !important",
    [theme.breakpoints.down("ssm")]: {
      height: "50px",
      maxWidth: "150px",
    },
    [theme.breakpoints.down("sssm")]: {
      maxWidth: "115px",
    },
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
  "& .Mui-selected": {
    color: "#FCE181 !important",
    fontSize: "16px",
    backgroundColor: "#026670 !important",
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
}));

const ParshaSection = () => {
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const [categoryTab, setCategoryTab] = useState(3);
  const [groupTab, setGroupTab] = useState(0);
  const [selectedChip, setSelectedChip] = useState(0);

  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(false);
  const [showTopGradient, setShowTopGradient] = useState(false);
  const [showBottomGradient, setShowBottomGradient] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [videos, setVideos] = useState([]);

  //
  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    toastNotificationMessage,
    setToastWithActionOpen,
    toastWithActionOpen,
    resetSettings,
  } = useToastNotification();

  //
  const scrollToElement = (elementRef, offset = isMobile ? 0 : 80) => {
    if (elementRef.current) {
      setTimeout(() => {
        const elementTop =
          elementRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementTop - offset,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const [isLoading, setIsLoading] = useState({ videos: false });

  const fetchVideos = () => {
    setIsLoading({ ...isLoading, videos: true });
    getSubgroupVideos(selectedChip)
      .then((res) => {
        let videosData = res.data.results;

        if (!isAuthenticated) {
          const likedVideos =
            JSON.parse(localStorage.getItem("likedVideos")) || {};

          videosData = videosData.map((video) => {
            if (likedVideos.hasOwnProperty(video.id)) {
              return {
                ...video,
                is_liked: likedVideos[video.id],

                likes:
                  likedVideos[video.id] && !video.is_liked
                    ? video.likes + 1
                    : video.likes,
              };
            }
            return video;
          });
        }

        setVideos(videosData);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading({ ...isLoading, videos: false });
      });
  };

  useEffect(() => {
    fetchVideos();
  }, [selectedChip]);

  useEffect(() => {
    setSelectedChip(0);
  }, [groupTab, categoryTab]);

  const fetchCategories = () => {
    getCategories()
      .then((res) => setCategories(res.results))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredGroup = categories.filter(
        (category) => category.id === categoryTab
      );
      setGroups(filteredGroup[0]?.children);
      setGroupTab(filteredGroup[0]?.children[0]?.id);
    } else {
      setGroups([]);
    }
  }, [categoryTab, categories]);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const filteredSubgroup = groups.filter(
        (subgroup) => subgroup.id === groupTab
      );
      setSubgroups(filteredSubgroup[0]?.video_groups);
    } else {
      setSubgroups([]);
    }
  }, [categoryTab, groupTab, categories, groups]);

  useEffect(() => {
    console.log("subgroupshere", subgroups);
  }, [subgroups]);

  const tabsRef = useRef(null);
  const stackRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleCategoryTabChange = (event, newTab) => {
    if (newTab !== null) {
      setCategoryTab(newTab);
      scrollToElement(parentRef);
    }
  };
  const handleGroupTabChange = (event, newValue) => {
    setGroupTab(newValue);
    scrollToElement(parentRef);
  };
  const handleScroll = useCallback(() => {
    const scrollContainer = tabsRef.current?.querySelector(".MuiTabs-scroller");
    if (!scrollContainer) return;
    const { scrollWidth, clientWidth, scrollLeft } = scrollContainer;
    const currentScroll = Math.abs(scrollLeft);
    setShowRightGradient(currentScroll > 1);

    // Only show left gradient if there's more than one tab AND there's content to scroll to
    const hasMultipleTabs = groups && groups.length > 1;
    setShowLeftGradient(
      hasMultipleTabs && Math.round(currentScroll + clientWidth) < scrollWidth
    );
  }, [groups]);
  useEffect(() => {
    const scrollContainer = tabsRef.current?.querySelector(".MuiTabs-scroller");
    if (!scrollContainer) return;
    const resizeObserver = new ResizeObserver(handleScroll);
    resizeObserver.observe(scrollContainer);
    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      resizeObserver.disconnect();
    };
  }, [handleScroll, groups]);

  const handleVerticalScroll = useCallback(() => {
    const container = stackRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    setShowTopGradient(scrollTop > 0);
    setShowBottomGradient(Math.round(scrollTop + clientHeight) < scrollHeight);
  }, []);

  useEffect(() => {
    const scrollContainer = stackRef.current;
    if (!scrollContainer) return;

    const resizeObserver = new ResizeObserver(handleVerticalScroll);
    resizeObserver.observe(scrollContainer);

    scrollContainer.addEventListener("scroll", handleVerticalScroll);
    handleVerticalScroll();

    return () => {
      scrollContainer.removeEventListener("scroll", handleVerticalScroll);
      resizeObserver.disconnect();
    };
  }, [handleVerticalScroll, subgroups]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const slider = tabsRef.current.querySelector(".MuiTabs-scroller");
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();

    const slider = tabsRef.current.querySelector(".MuiTabs-scroller");
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, []);

  const [chipRows, setChipRows] = useState([]);

  // Add this function to detect rows
  const detectRows = useCallback(() => {
    const container = stackRef.current;
    if (!container) return;

    // Get all chips
    const chips = container.querySelectorAll(".MuiChip-root");
    if (!chips.length) return;

    // Initialize rows array
    const rows = [];
    let currentRow = [];
    let currentTop = chips[0].offsetTop;

    // Group chips into rows based on their vertical position
    chips.forEach((chip) => {
      if (chip.offsetTop !== currentTop) {
        rows.push(currentRow);
        currentRow = [];
        currentTop = chip.offsetTop;
      }
      currentRow.push(chip);
    });

    // Add the last row
    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    setChipRows(rows.map((row) => row.length));
  }, []);

  // Add ResizeObserver to detect layout changes
  useEffect(() => {
    const container = stackRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      detectRows();
    });

    resizeObserver.observe(container);

    // Initial detection
    detectRows();

    return () => {
      resizeObserver.disconnect();
    };
  }, [detectRows, subgroups]);

  // Update the getChipStyle function
  const getChipStyle = useCallback(
    (index) => {
      if (chipRows.length === 0) return 1;

      // Find which row this chip belongs to
      let currentIndex = 0;
      let rowIndex = 0;

      for (let i = 0; i < chipRows.length; i++) {
        if (currentIndex + chipRows[i] > index) {
          rowIndex = i;
          break;
        }
        currentIndex += chipRows[i];
      }

      // If it's the last row and not full compared to the longest row
      const maxItemsInRow = Math.max(...chipRows);
      if (
        rowIndex === chipRows.length - 1 &&
        chipRows[rowIndex] < maxItemsInRow
      ) {
        return 0;
      }

      return 1;
    },
    [chipRows]
  );

  // like / dislike video

  // const handleLikeClick = async (videoId, event) => {
  //   const video = videos.find((v) => v.id === videoId);
  //   if (!video) return;

  //   const newLikedStatus = !video.is_liked;
  //   const likeDelta = newLikedStatus ? 1 : -1;

  //   let origin = { x: 0.5, y: 0.6 };
  //   if (event && event.currentTarget) {
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     origin = {
  //       x: (rect.left + rect.width / 2) / window.innerWidth,
  //       y: (rect.top + rect.height / 2) / window.innerHeight,
  //     };
  //   }

  //   if (!isAuthenticated) {
  //     const likedVideos = JSON.parse(localStorage.getItem("likedVideos")) || {};

  //     likedVideos[videoId] = newLikedStatus;
  //     localStorage.setItem("likedVideos", JSON.stringify(likedVideos));

  //     setVideos((prevVideos) =>
  //       prevVideos.map((v) =>
  //         v.id === videoId
  //           ? { ...v, likes: v.likes + likeDelta, is_liked: newLikedStatus }
  //           : v
  //       )
  //     );

  //     if (newLikedStatus) {
  //       confetti({
  //         particleCount: 50,
  //         spread: 10,
  //         origin,
  //         zIndex: 9999,
  //       });
  //     }
  //     return;
  //   }

  //   try {
  //     await postVideoLike(videoId);
  //     setVideos((prevVideos) =>
  //       prevVideos.map((v) =>
  //         v.id === videoId
  //           ? { ...v, likes: v.likes + likeDelta, is_liked: newLikedStatus }
  //           : v
  //       )
  //     );
  //     if (newLikedStatus) {
  //       confetti({
  //         particleCount: 50,
  //         spread: 10,
  //         origin,
  //         zIndex: 9999,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error updating like status", error);
  //   }
  // };
  const { handleLikeClick } = useLikeHandler({
    videos,
    setVideos,
    isAuthenticated,
    postVideoLike,
  });

  // add / remove favorite video
  const handleFavoriteTogle = async (videoId) => {
    const video = videos.find((v) => v.id === videoId);
    if (!video) return;

    const newFavoriteStatus = !video.is_favorite;

    try {
      await postVideoFavorite(videoId);

      setVideos((prevVideos) =>
        prevVideos.map((v) =>
          v.id === videoId ? { ...v, is_favorite: newFavoriteStatus } : v
        )
      );
    } catch (error) {
      console.error("Error toggling favorite status", error);
    }
  };

  const parshaRef = useRef(null);
  const parentRef = useRef(null);
  const [parshaPosition, setParshaPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const offset = isMobile ? 0 : 64;

  const hasFiredDownRef = useRef(false);
  const hasFiredUpRef = useRef(false);
  const isIntersecting = useRef(false);
  const pageHeightRef = useRef(document.documentElement.scrollHeight);

  // 🔥 Detect when the component enters the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        isIntersecting.current = entry.isIntersecting;
      },
      { threshold: 0.001 }
    );

    if (parshaRef.current) observer.observe(parshaRef.current);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const debounce = (fn, delay) => {
      let timer;
      return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
      };
    };

    const updatePosition = () => {
      if (parshaRef.current && parentRef.current) {
        const rect = parshaRef.current.getBoundingClientRect();
        const newPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: parentRef.current.getBoundingClientRect().width,
        };

        setParshaPosition((prev) => {
          if (
            prev.top === newPosition.top &&
            prev.left === newPosition.left &&
            prev.width === newPosition.width
          ) {
            return prev;
          }
          return newPosition;
        });
      }
      pageHeightRef.current = document.documentElement.scrollHeight;
    };

    const debouncedUpdatePosition = debounce(updatePosition, 100);

    updatePosition();

    window.addEventListener("resize", debouncedUpdatePosition);
    const observer = new MutationObserver(debouncedUpdatePosition);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener("resize", debouncedUpdatePosition);
      observer.disconnect();
    };
  }, []);

  // 🔥 Optimized Scroll Handling with Dynamic Bottom Stop
  useEffect(() => {
    const handleScroll = () => {
      if (!isIntersecting.current) return;

      const scrollY = window.scrollY;
      const triggerPoint = parshaPosition.top - offset;
      const stopDistance = isMobile ? 400 : 50; // 🔥 Dynamic boundary based on isMobile
      const bottomBoundary =
        pageHeightRef.current - window.innerHeight - stopDistance;

      if (
        scrollY >= triggerPoint &&
        scrollY < bottomBoundary &&
        !hasFiredDownRef.current
      ) {
        console.log("🔥 Sticky Activated (Scrolling Down)");
        hasFiredDownRef.current = true;
        hasFiredUpRef.current = false;
      } else if (
        (scrollY < triggerPoint || scrollY >= bottomBoundary) &&
        !hasFiredUpRef.current
      ) {
        console.log("🔥 Sticky Deactivated (Scrolling Up or Near Bottom)");
        hasFiredUpRef.current = true;
        hasFiredDownRef.current = false;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [parshaPosition.top, isMobile]);

  return (
    <Box ref={parentRef}>
      {/* <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="15px"
        mb="25px"
      >
        <Typography
          variant="sectionTitle"
          component="h2"
          fontSize={pxToRem(35)}
          mb={0}
        >
          <span className="highlighted">Parsha</span>
        </Typography>
        {videos && (
          <Typography fontSize="12px" fontWeight={600} color="#BFBEBB">
            {videos.length} Parshas
          </Typography>
        )}
      </Stack> */}

      <StyledToggleButtonGroup
        value={categoryTab}
        exclusive
        onChange={handleCategoryTabChange}
      >
        {categories &&
          categories.length > 0 &&
          categories.map((category) => {
            return (
              <ToggleButton value={category.id}>{category.name}</ToggleButton>
            );
          })}
        {/*<ToggleButton value="parsha">Parsha</ToggleButton>
        <ToggleButton value="holidays">Holidays</ToggleButton>
        <ToggleButton value="seasonal">Seasonal</ToggleButton> */}
      </StyledToggleButtonGroup>
      <Box
        ref={parshaRef}
        sx={{
          position: "sticky",
          top: { xs: "0px", md: "66px" },
          zIndex: 2,
          height: "auto",
          backgroundColor: "#f7f6f2",
        }}
      >
        {groups && groups.length > 0 && (
          <ThemeProvider theme={rtlTheme}>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: { xs: pxToRem(46), ssm: pxToRem(49) },
                border: { ssm: "1px solid #BEBEBE" },
                backgroundColor: "#E6E5E1",
                mx: { xs: "-25px", ssm: "0" },
                borderRadius: { xs: "0", ssm: "100px" },
              }}
            >
              <Box position="relative" maxWidth="100%">
                {showLeftGradient && !isMobile && (
                  <Box
                    sx={{
                      position: "absolute",
                      left: { xs: "-25px", ssm: "1px" },
                      top: { xs: "2px", ssm: "1px" },
                      width: { xs: "50px", ssm: "55px", md: "78px" },
                      height: { xs: pxToRem(44), ssm: pxToRem(49) },
                      borderRadius: "100px 0px 0px 100px",
                      zIndex: 2,
                      background:
                        "linear-gradient(90deg, #E6E5E1 41.18%, rgba(230, 229, 225, 0.00) 91.18%)",
                    }}
                  />
                )}

                <Tabs
                  ref={tabsRef}
                  value={groupTab}
                  position="relative"
                  onChange={handleGroupTabChange}
                  variant="scrollable"
                  allowScrollButtonsMobile={isMobile}
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseUp}
                  sx={{
                    direction: "rtl",
                    // textAlign: "right",
                    "& .MuiTabs-flexContainer": {
                      height: "100%",
                    },
                    "& .MuiTabs-indicator": { backgroundColor: "#026670" },
                    "& .MuiTabScrollButton-root": {
                      zIndex: 3,
                      width: "28px",
                      "& .MuiSvgIcon-root": {
                        color: "#026670",
                        width: "28px",
                        height: "39px",
                      },
                    },
                  }}
                >
                  {groups.map((group) => (
                    <Tab
                      sx={{
                        py: pxToRem(10),
                        px: pxToRem(24),
                        fontSize: pxToRem(16),
                        minHeight: { xs: pxToRem(46), ssm: pxToRem(49) },
                        color: "#333333",
                        fontWeight: 600,
                        lineHeight: pxToRem(26),
                        fontFamily: "Noto Sans Hebrew",
                        "&.Mui-selected": { color: "#026670" },
                      }}
                      label={group?.name}
                      value={group.id}
                    />
                  ))}
                </Tabs>

                {showRightGradient && !isMobile && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: { xs: "-25px", ssm: "1px" },
                      top: { xs: "2px", ssm: "1px" },
                      width: { xs: "50px", ssm: "55px", md: "78px" },
                      height: { xs: pxToRem(44), ssm: pxToRem(49) },
                      borderRadius: "0px 100px 100px 0px",
                      zIndex: 2,
                      background:
                        "linear-gradient(270deg, #E6E5E1 41.18%, rgba(230, 229, 225, 0.00) 91.18%)",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </ThemeProvider>
        )}

        {subgroups && subgroups.length > 0 && (
          <Box
            position="relative"
            mx={{ xs: "-25px", ssm: "-10px" }}
            // px={{ sm: pxToRem(30), md: pxToRem(35), lg: pxToRem(45) }}
            padding="0 13px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: isMobile ? "#f7f6f2 !important" : "transparent",
            }}
          >
            {showTopGradient && (
              <Box
                position="absolute"
                width="100%"
                height="24px"
                top={pxToRem(20)}
                sx={{
                  background:
                    "linear-gradient(180deg, #F7F6F2 0%, rgba(247, 246, 242, 0.00) 100%)",
                }}
                zIndex={1}
              />
            )}

            <Stack
              ref={stackRef}
              direction="row-reverse"
              columnGap={{ xs: "8px", ssm: "10px" }}
              rowGap={{ xs: "8px", ssm: "10px" }}
              my={pxToRem(20)}
              py={pxToRem(12)}
              flexWrap="wrap"
              justifyContent={{ xs: "space-between", ssm: "center" }}
              px="10px"
              alignItems="flex-start"
              mx={{ xs: "-10px", ssm: "auto" }}
              maxWidth={{ xs: "369px", sssm: "474px", ssm: "684px" }}
              sx={{
                height: "auto",
                maxHeight: { xs: "140px", sm: "none" },
                overflow: "auto",
              }}
            >
              {subgroups.map((subgroup, index) => (
                <Chip
                  className={selectedChip === subgroup.id ? "pulse" : ""}
                  onClick={() => {
                    setSelectedChip(subgroup.id);
                    scrollToElement(parentRef);
                  }}
                  sx={{
                    fontWeight: 600,
                    px: { xs: pxToRem(16), ssm: pxToRem(30) },
                    height: { xs: pxToRem(34), ssm: "24px" },
                    backgroundColor:
                      selectedChip === subgroup.id ? "#026670" : "#E6E5E1",
                    color: selectedChip === subgroup.id ? "#FCE181" : "",
                    flexGrow: {
                      xs: 1,
                      ssm: getChipStyle(index, subgroups.length),
                    },
                    cursor: "pointer",
                    fontFamily: "Noto Sans Hebrew",
                    "&:hover": { backgroundColor: "#026670", color: "#FCE181" },
                  }}
                  label={subgroup.name}
                />
              ))}
            </Stack>

            {showBottomGradient && (
              <Box
                position="absolute"
                width="100%"
                height={{ xs: pxToRem(30), ssm: "18px" }}
                bottom={pxToRem(20)}
                sx={{
                  background:
                    "linear-gradient(0deg, #F7F6F2 0%, rgba(247, 246, 242, 0.00) 100%)",
                }}
                zIndex={1}
              />
            )}
          </Box>
        )}
      </Box>
      {isLoading.videos ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(50vh - 100px)",
          }}
        >
          <CircularProgress size={80} thickness={4} sx={{ color: "#036670" }} />
        </Box>
      ) : videos && videos.length > 0 ? (
        <Stack
          gap="40px"
          sx={{
            mt: "21px",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", ssm: "1fr 1fr" },
              gap: "40px 20px",
            }}
          >
            {videos?.map((video) => (
              <Box
                key={video.id}
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <ParshaCard
                  video={video}
                  text={video.text}
                  subscribers={video.subscribers}
                  duration={formatDuration(video.duration)}
                  labels={video.labels}
                  isPlaying={currentlyPlayingId === video.id}
                  setIsPlaying={setIsPlaying}
                  currentlyPlayingId={currentlyPlayingId}
                  setCurrentlyPlayingId={setCurrentlyPlayingId}
                  isVideoLibrary={true}
                  videoLink={{
                    category: categoryTab,
                    group: groupTab,
                    subgroup: selectedChip,
                  }}
                  onLikeClick={handleLikeClick}
                  onFavoritelick={handleFavoriteTogle}
                />
              </Box>
            ))}
          </Box>
        </Stack>
      ) : null}

      <Toast
        open={toastNotificationOpen}
        onClose={() => setToastNotificationOpen(false)}
        message={toastNotificationMessage}
      />
    </Box>
  );
};

export default ParshaSection;
