import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../IconBtnCircular";
import { ReactComponent as Bookmark } from "../../images/bookmark.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Download } from "../../images/download.svg";
import { ReactComponent as DownloadNotAllowed } from "../../images/download-not-allowed.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";
import { useVideoPlayer } from "../../context/VideoPlayerContext";
import {
  createParshaBookmark,
  downloadAudio,
  downloadVideo,
} from "../../api/parsha";
import usePlaybackSettings from "../../hooks/usePlaybackSettings";
import { formatFileSize } from "../../utils/format";
import { url } from "../../api/config";
import { useToastNotification } from "../../context/ToastNotificationContext";
import { useAuth } from "../../context/AuthContext";

const TopPanel = ({
  selectedLesson,

  fullscreen,
  isVideoLibrary = false,
  videoLink,

  setOpenDownloadFeedback,
  setDownloadFeedbackMsg,
  setOpenSavedModeFeedback,
  isSingleVideo,
  isHomePage,
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("sm"));
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const { settings, updatePlaybackSettings } = usePlaybackSettings();

  const {
    currentTime,
    setReservedTime,
    setListenWatchToggle,
    // setNoteToastOpen,
    setBookmarkToastOpen,
    // setBookmarkBgColor,
    setBookmarkMessage,
    handleListenClicked,
    handleWatchClicked,
    // showButtons,
    isListening,
    // firstTimeLoaded,
    // setIsListening,

    // setBookmarkListChanged,
    // setNoteListChanged,

    // setToastOpen,
    // setToastMessage,
  } = useVideoPlayer();
  const {
    // toastNotificationOpen,
    setToastNotificationOpen,
    setToastNotificationMessage,
    // toastNotificationMessage,
    setToastWithActionOpen,
    setToastNoClose,
    setBlockAutoHide,

    // toastWithActionOpen,
    // resetSettings,
  } = useToastNotification();

  const location = useLocation();

  const [disableBookmark, setDisableBookmark] = useState(false);
  //download
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [downloadPreference, setDownloadPreference] = useState("video");
  const [rememberChoice, setRememberChoice] = useState(false);

  // switch between listening and watching
  const handleToggleListening = () => {
    setListenWatchToggle(true);
    setReservedTime(currentTime?.current);
    console.log("Reserved time: ", currentTime);
    if (!isListening) {
      handleListenClicked();
    } else {
      handleWatchClicked();
    }
  };

  // share the current page for nonw
  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;


    if (isHomePage){
      navigator.clipboard
        .writeText(`${window.location.href}video/${selectedLesson.id}`)
        .then(() => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("Link copied to clipboard!");
        })
        .catch((err) => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("could not copy link!");
          console.error("Failed to copy:", err);
        });
      return;

    }

    // If it's a single video, copy the full URL immediately
    if (isSingleVideo) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("Link copied to clipboard!");
        })
        .catch((err) => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("could not copy link!");
          console.error("Failed to copy:", err);
        });
      return;
    }

    // For video libraries, construct a custom URL with query params
    if (isVideoLibrary) {
      navigator.clipboard
        .writeText(
          `${window.location.origin}/video/${selectedLesson.id}?category=${videoLink.category}&group=${videoLink.group}&subgroup=${videoLink.subgroup}`
        )
        .then(() => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("Link copied to clipboard!");
        })
        .catch((err) => {
          setToastNotificationOpen(true);
          setToastNotificationMessage("could not copy link!");
          console.error("Could not copy text: ", err);
        });
      return;
    }

    // Default: copy the full URL
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastNotificationOpen(true);
        setToastNotificationMessage("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  // add bookmark
  const handleAddBookmark = async () => {
    if (disableBookmark) return;
    setDisableBookmark(true);
    try {
      const bookmarkTime = Math.floor(currentTime?.current || 0);

      const response = await createParshaBookmark(
        selectedLesson.id,
        bookmarkTime,
        videoLink?.category || "",
        videoLink?.group || "",
        videoLink?.subgroup || ""
      );

      setToastNotificationMessage("Bookmark added successfully!");
      setToastNotificationOpen(true);
    } catch (error) {
      console.error("Error adding bookmark:", error);
    } finally {
      setDisableBookmark(false);
    }
  };

  // download media
  const [disableDownload, setDisableDownload] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    console.log("testt Downloading.", downloadProgress);
  }, [downloadProgress]);

  const downloadFile = async (format, delay) => {
    let endpoint = "";
    if (format === "mp3") {
      endpoint = `${url}/api/parshas/${selectedLesson?.id}/download_audio/`;
    } else if (format === "mp4") {
      endpoint = `${url}/api/parshas/${selectedLesson?.id}/download_video/`;
    }

    setDownloadProgress(0);

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        const errorData = await response.json();
        setToastNotificationMessage(
          errorData.detail || "Error downloading file"
        );
        setToastNotificationOpen(true);
        setIsDownloading(false);
        return;
      }

      // Get the total size (if provided by the server)
      const contentLength = response.headers.get("content-length");
      const total = contentLength ? parseInt(contentLength, 10) : null;
      let loaded = 0;
      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        loaded += value.length;

        // Update progress if total size is known
        if (total) {
          const progress = Math.round((loaded / total) * 100);
          setDownloadProgress(progress);
          if (progress > 0 && progress < 100) {
            setBlockAutoHide(true);
            setToastNotificationMessage(`Downloading ${progress}%...`);
            setToastNotificationOpen(true);
          }
        } else {
          setBlockAutoHide(true);
          setToastNotificationMessage("Downloading...");
          setToastNotificationOpen(true);
        }
      }

      // Combine the chunks into a Blob.
      const blob = new Blob(chunks);
      const urlBlob = window.URL.createObjectURL(blob);

      // Once the download is complete, update the toast message.
      setToastNotificationMessage("Download complete");
      setToastNotificationOpen(true);
      setToastNoClose(true);
      setBlockAutoHide(false);

      // Apply the dynamic delay if provided.
      if (delay) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }

      // Open the file in a new tab.
      window.open(urlBlob, "_blank");

      // Cleanup the blob URL.
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.log("Download error:", error);
      setToastNotificationMessage(
        "An error occurred while downloading the file"
      );
      setToastNotificationOpen(true);
    }
  };

  // download menu
  const handleDownloadClick = async (event) => {
    if (!selectedLesson?.is_download_allowed) {
      setToastNotificationOpen(true);
      setToastNotificationMessage("Download restricted by owner");
    } else {
      let mode;
      if (!isAuthenticated) {
        mode = localStorage.getItem("download_mode");
      } else {
        mode = settings?.download_mode;
      }

      if (mode === "mp3" || mode === "mp4") {
        showDownloadFeedback(mode);
        const success = await downloadFile(mode, 6000);
      } else {
        setDownloadAnchorEl(event.currentTarget);
      }
    }
  };

  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
    setRememberChoice(false);
  };

  // download btn

  const handleDownload = () => {
    handleDownloadClose();
    if (rememberChoice) {
      const mode = downloadPreference === "audio" ? "mp3" : "mp4";
      if (isAuthenticated) {
        updatePlaybackSettings({ download_mode: mode });
      } else {
        localStorage.setItem("download_mode", mode);
      }
    }
    if (downloadPreference === "video") {
      downloadFile("mp4");
    } else if (downloadPreference === "audio") {
      downloadFile("mp3");
    }
    showFeedback();
  };

  //  download feedback
  const showFeedback = () => {
    setDisableDownload(true);
    setToastNotificationOpen(true);
    setToastNotificationMessage(
      `your download will start soon. Please wait...`
    );
  };

  const showDownloadFeedback = (format) => {
    const mode = format === "mp3" ? "audio" : "video";

    setToastNotificationMessage(`You have a saved ${mode} download mode.`);
    setToastWithActionOpen(true);
  };

  return (
    <>
      <Stack
        flexDirection="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          zIndex: 9001,
          width: "100%",
          top: fullscreen
            ? pxToRem(100)
            : { xs: pxToRem(-20), ssm: pxToRem(-24) },
        }}
      >
        <Tooltip
          title={isListening ? "Switch to Video" : "Switch to Audio"}
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
        >
          <Box>
            <IconBtnCircular
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                backgroundColor: "white",
              }}
              className="hover-green"
            >
              <Box
                sx={{
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleToggleListening}
              >
                {isListening ? (
                  <CamcorderIcon color="#026670" />
                ) : (
                  <Volume color="#026670" />
                )}
              </Box>
            </IconBtnCircular>
          </Box>
        </Tooltip>
        <Tooltip
          title="Add Bookmark"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              whiteSpace: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
        >
          <Box onClick={handleAddBookmark}>
            <IconBtnCircular
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                borderRadius: "50%",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
              className="hover-green"
            >
              <Box sx={{ flexShrink: 0, display: "flex" }}>
                <Bookmark color="#026670" />
              </Box>
            </IconBtnCircular>
          </Box>
        </Tooltip>
        {/* {selectedLesson?.is_download_allowed && ( */}
        <Tooltip
          title={
            selectedLesson?.is_download_allowed
              ? "Download"
              : "Download Restricted"
          }
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: selectedLesson?.is_download_allowed
                  ? "#026670 !important"
                  : "#00383E!important",
                color: selectedLesson?.is_download_allowed
                  ? "#fff"
                  : "#fff !important", // disabled download
              },
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
            },
          }}
        >
          <Box>
            <IconBtnCircular
              onClick={handleDownloadClick}
              className={
                selectedLesson?.is_download_allowed
                  ? "hover-green"
                  : "hover-disabled-green" // disabled download
              }
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                borderRadius: "50%",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Download
                color={
                  // selectedLesson?.is_download_allowed ? "#026670" : "#BFBEBB"
                  "#026670"
                }
                style={{ marginRight: pxToRem(1.5) }}
              />

              {/* disabled download use different icon */}

              {/* {selectedLesson?.is_download_allowed ? (
                <Download
                  color="#026670"
                  style={{ marginRight: pxToRem(1.5) }}
                />
              ) : (
                <Download
                  color="#026670"
                  style={{ marginRight: pxToRem(1.5) }}
                />
                // <DownloadNotAllowed
                //   color="#026670"
                //   style={{ marginRight: pxToRem(1.5) }}
                // />
              )} */}
            </IconBtnCircular>
          </Box>
        </Tooltip>
        {/* // )} */}
        <Tooltip
          title="Share Now"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement="top"
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
            },
          }}
        >
          <Box onClick={handleShareClick}>
            <IconBtnCircular
              className="hover-green"
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                borderRadius: "50%",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Share color="#026670" style={{ marginRight: pxToRem(1.5) }} />
            </IconBtnCircular>
          </Box>
        </Tooltip>
      </Stack>

      <Menu
        anchorEl={downloadAnchorEl}
        open={Boolean(downloadAnchorEl)}
        onClose={handleDownloadClose}
        sx={{
          "& .MuiPaper-root": {
            mt: 1,
            padding: 2,
            borderRadius: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            minWidth: "280px",
          },
          "& .MuiList-root": {
            padding: 0,
          },
        }}
      >
        <Stack spacing={2.5}>
          <Typography
            variant="h6"
            sx={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              color: "#026670",
              mb: 1,
            }}
          >
            Download Options
          </Typography>

          <FormControl sx={{ mt: "10px !important" }}>
            <RadioGroup
              value={downloadPreference}
              onChange={(e) => setDownloadPreference(e.target.value)}
            >
              <FormControlLabel
                sx={{
                  py: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: pxToRem(15),
                    fontWeight: 500,
                    color: "#333333",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(2, 102, 112, 0.04)",
                    borderRadius: "8px",
                  },
                }}
                value="video"
                control={
                  <Radio
                    sx={{
                      color: "#026670",
                      p: 1,
                      "&.Mui-checked": { color: "#026670" },
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  />
                }
                label={
                  <Stack>
                    <Typography sx={{ fontWeight: 500 }}>
                      Download Video
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#666" }}>
                      {formatFileSize(selectedLesson?.video_size)} • MP4 Format
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                sx={{
                  py: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: pxToRem(15),
                    fontWeight: 500,
                    color: "#333333",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(2, 102, 112, 0.04)",
                    borderRadius: "8px",
                  },
                }}
                value="audio"
                control={
                  <Radio
                    sx={{
                      color: "#026670",
                      p: 1,
                      "&.Mui-checked": { color: "#026670" },
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  />
                }
                label={
                  <Stack>
                    <Typography sx={{ fontWeight: 500 }}>
                      Download Audio
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#666" }}>
                      {formatFileSize(selectedLesson?.audio_size)} • MP3 Format
                    </Typography>
                  </Stack>
                }
              />
            </RadioGroup>
          </FormControl>

          <FormControlLabel
            sx={{
              borderTop: "1px solid rgba(2, 102, 112, 0.12)",
              pt: "10px !important",
              mt: "10px !important",
              "& .MuiFormControlLabel-label": {
                fontSize: pxToRem(14),
                fontWeight: 500,
                color: "#333333",
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#026670",
                  "&.Mui-checked": {
                    color: "#026670",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
                checked={rememberChoice}
                onChange={(e) => setRememberChoice(e.target.checked)}
              />
            }
            label="Remember my choice"
          />

          <Stack
            direction="row"
            spacing={2}
            sx={{ pt: "16px !important", mt: "0 !important" }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ px: `${pxToRem(28)} !important`, flexGrow: 1 }}
              onClick={handleDownloadClose}
            >
              Cancel
            </Button>
            <Button
              variant="green"
              size="small"
              sx={{ px: `${pxToRem(28)} !important`, flexGrow: 1 }}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default TopPanel;
