import React, { useState } from "react";
import {
  Box,
  Collapse,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import DeleteBtn from "./DeleteBtn";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { useTheme } from "@emotion/react";
import { getLanguageClass } from "../../utils/languageClass";

const Note = ({
  note,
  lessons,
  longestTimestamp,
  onDelete,
  onToggle,
  expanded, // controlled from parent on small screens
  onTransitionEnd, // callback for when the Collapse animation finishes
  children,
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("sm"));

  // Use parent's expanded state if provided; otherwise use internal state.
  const [internalOpen, setInternalOpen] = useState(false);
  const isOpen = expanded !== undefined ? expanded : internalOpen;

  const formatTimestamp = (timestamp) => {
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor((timestamp % 3600) / 60);
    const seconds = timestamp % 60;
    return hours > 0
      ? `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`
      : `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClick = () => {
    // If parent's expanded state is provided, call onToggle; otherwise toggle internal state.
    if (expanded !== undefined) {
      onToggle();
    } else {
      setInternalOpen((prev) => !prev);
      if (onToggle) onToggle();
    }
  };

  const approximateWidth = `${longestTimestamp.length / 1.2}ch`;

  return (
    <Box borderTop="1px solid rgba(191,190,187,0.5)">
      <ListItemButton
        onClick={handleClick}
        sx={{
          alignItems: "flex-start",
          p: `${pxToRem(10)} 0`,
          "&:hover": {
            backgroundColor: "transparent",
            "& .deleteBtn": {
              display: "flex",
            },
          },
          // On mobile, if the note is expanded, show the delete button.
          ...(mobile_ssm &&
            isOpen && {
              "& .deleteBtn": {
                display: "flex",
              },
            }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="10px"
          flex={1}
        >
          <Box
            sx={{
              display: "grid",
              alignItems: "left",
              overflow: "hidden",
            }}
          >
            <Typography
              component="span"
              display="block"
              textAlign="left"
              fontSize={pxToRem(16)}
              fontWeight={600}
              color="secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className={getLanguageClass(note.title)}
            >
              {note.title}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={!mobile_ssm ? note.lesson?.name : ""}
              enterDelay={0}
              enterTouchDelay={0}
              placement="top"
              sx={{
                mt: "8px",
                transform: "translateY(8px)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    marginRight: "5px",
                    // On mobile, force hide the delete button if the note is not expanded.
                    ...(mobile_ssm && !isOpen && { display: "none" }),
                  }}
                >
                  <DeleteBtn onDelete={onDelete} isNote />
                </Box>
                <Typography
                  component="span"
                  fontSize={pxToRem(16)}
                  fontWeight={600}
                  color="#333333"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box sx={{ width: "65px" }}>
                    {(() => {
                      const lessonIndex = lessons?.findIndex(
                        (lesson) => lesson.id === note.lesson?.id
                      );
                      return lessonIndex !== -1
                        ? `Lecture ${lessonIndex + 1}`
                        : "Unknown Lecture";
                    })()}
                  </Box>
                  <Box>
                    <span>&nbsp;•&nbsp;</span>
                  </Box>
                  <Box
                    sx={{ textAlign: "left", marginLeft: "2px", width: approximateWidth }}
                  >
                    <span>{formatTimestamp(note.timestamp)}</span>
                  </Box>
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Stack>
        <Box
          sx={{
            "& svg": {
              transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)",
              mr: pxToRem(2),
              ml: pxToRem(20),
              transition: "0.1s ease",
            },
          }}
        >
          <Chevron color={isOpen ? "#333" : "#BFBEBB"} />
        </Box>
      </ListItemButton>

      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        onEntered={() => {
          console.log("[Collapse] Animation finished for note", note.id);
          // Small delay to ensure layout settles before notifying parent.
          setTimeout(() => {
            if (onTransitionEnd) onTransitionEnd();
          }, 50);
        }}
      >
        {children}
      </Collapse>
    </Box>
  );
};

export default Note;
