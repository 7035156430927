import React, { useEffect } from "react";
import { Gradient } from "../utils/gradient-animation";

const GradientAnimation = ({ topOnly = false, customClass = "", id="gradient-canvas" }) => {
  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient(`#${id}`);
  }, [id]);

  return (
    <canvas
      id={id}
      data-js-darken-top
      data-transition-in
      className={`gradient-canvas ${topOnly ? "gradient-canvas--top" : ""} ${customClass}`}
      style={{
        "--gradient-color-1": "#d9da96",
        "--gradient-color-2": "#f7f0bd",
        "--gradient-color-3": "#86c7a2",
        "--gradient-color-4": "#f9dd7d",
      }}
    ></canvas>
  );
};

export default GradientAnimation;
