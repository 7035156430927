import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Box,
  styled,
  Table,
  TableContainer,
  TableCell,
  TableRow,
} from "@mui/material";

import { pxToRem } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import { Close } from "@mui/icons-material";
import BlockBordered from "./BlockBordered";
import dayjs from "dayjs";
import {
  getCoursePaymentHistory,
  getSubscriptionDetails,
} from "../api/payment";
import { useEffect, useState } from "react";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(18),
  fontWeight: 600,
  lineHeight: pxToRem(30),
  "& span": {
    display: "inline-block",
    [theme.breakpoints.up("xs")]: {
      width: pxToRem(110),
    },

    [theme.breakpoints.up("sssm")]: {
      width: pxToRem(190),
    },
    color: "#BFBEBB",
    marginRight: pxToRem(20),
  },
}));

const formatDate = (dateString) => {
  if (!dateString) return "Never";
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const firstRowStyles = {
  fontSize: "16px",
  fontWeight: 700,
  color: "#2b646e",
};
const rowStyles = {
  fontSize: "14px",
  fontWeight: 500,
};

export default function CoursePayments({
  details,
  open,
  setOpen,
  isCourse = false,
}) {
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const fetchSubscriptionDetails = () => {
    getSubscriptionDetails(details?.id)
      .then((res) => {
        setSubscriptionHistory(res?.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);
  console.log("subscriptionHistory", subscriptionHistory);
  console.log("details", details);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={false}
        maxWidth="ssm"
        keepMounted
        //scroll={mobile_ssm ? "paper" : "body"}
        // TransitionComponent={mobile_ssm ? Transition : undefined}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: {
              xs: "100% !important",
              ssm: "calc(100% - 64px) !important",
              md: `${pxToRem(920)} !important`,
            },
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            height: "auto",
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            backgroundColor: "#F7F6F2",
            maxHeight: { xs: "85vh", ssm: "none" },
          },
          zIndex: 1500,
        }}
      >
        {/* Header Section */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="#EDECE8"
          p={{
            xs: `${pxToRem(18)}`,
            ssm: `${pxToRem(25)}`,
            md: `${pxToRem(40)} ${pxToRem(60)}`,
          }}
        >
          <Typography
            fontSize={{ xs: pxToRem(23), ssm: pxToRem(35) }}
            fontWeight={700}
          >
            {details?.course}
          </Typography>
          <IconBtnCircular
            onClick={handleClose}
            sx={{
              width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
              height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
            }}
          >
            <Close />
          </IconBtnCircular>
        </Stack>

        {/* Body Section */}
        <DialogContent sx={{ p: 0 }}>
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize={pxToRem(26)}
            pt={6}
            pl={6}
            mb={-3}
          >
            Enrollment Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ width: { lg: "50%", padding: 50 } }}>
              <BlockBordered sx={{ display: "none" }}>
                <TypographyStyled>
                  <span>Amount:</span>
                  {parseInt(details?.price) ? "$" + details?.price : "Free"}
                </TypographyStyled>
                <TypographyStyled>
                  <span>Subscription Date:</span>
                  {details?.start_date}
                </TypographyStyled>
                <TypographyStyled>
                  <span>Expiration Date</span>
                  {details?.end__date ? details?.end__date : "Never"}
                </TypographyStyled>
              </BlockBordered>
            </Box>
          </Box>
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize={pxToRem(26)}
            pl={6}
          >
            Transaction History
          </Typography>
          {subscriptionHistory.length > 0 ? (
            <TableContainer sx={{ my: "20px", px: 5 }}>
              <Table>
                <TableRow
                  sx={{
                    borderTop: "1px solid #E0E0E0",
                    borderBottom: "1px solid #E0E0E0",
                  }}
                >
                  {/*  <TableCell sx={firstRowStyles}>Title</TableCell> */}
                  <TableCell sx={firstRowStyles}>Date</TableCell>
                  <TableCell sx={firstRowStyles}>Amount Paid</TableCell>
                  <TableCell sx={firstRowStyles}>Amount Refunded</TableCell>
                  {/*  <TableCell sx={firstRowStyles}>Discount</TableCell> */}
                  <TableCell sx={firstRowStyles}>Method</TableCell>
                  <TableCell sx={firstRowStyles}>Status</TableCell>
                  <TableCell sx={firstRowStyles}></TableCell>
                </TableRow>
                {subscriptionHistory.map((row) => (
                  <TableRow key={row.id}>
                    {/*   <TableCell sx={rowStyles}>{row?.course}</TableCell> */}
                    <TableCell sx={rowStyles}>
                      {dayjs(row?.created_at).format("MM-DD-YYYY")}
                    </TableCell>
                    <TableCell sx={rowStyles}>
                      ${row?.amount_captured}
                    </TableCell>
                    <TableCell sx={rowStyles}>
                      {row?.refunded ? `$${row.refunded}` : "-"}
                    </TableCell>
                    {/*  <TableCell sx={rowStyles}>${row?.discount}</TableCell> */}
                    <TableCell sx={rowStyles}>
                      {row?.last4_digits ? `****${row?.last4_digits}` : ""}
                    </TableCell>
                    <TableCell sx={rowStyles}>{row?.payment_status}</TableCell>
                    <TableCell
                      sx={{
                        fontStyle: "italic",
                        color: "#2b646e",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(row?.receipt_url, "_blank");
                      }}
                    >
                      View Receipt
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 500,
                padding: 3,
              }}
            >
              No transactions were recorded
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
