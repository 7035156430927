import { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
const AuthRedirect = ({ props }) => {
  const { oauth } = useAuth();
  const [params, setParams] = useSearchParams();
  console.log(params);
  useEffect(() => {
    const state = params.get("state");
    const code = params.get("code");

    console.log("state", state, "code", code);
    oauth(state, code);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: "40px",
        color: "#026670",
      }}
    >
      <Typography variant="h4">Redirecting...</Typography>
      <CircularProgress sx={{ color: "#026670" }} />
    </Box>
  );
};

export default AuthRedirect;
