import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { pxToRem } from "px2rem2px";
import TextLink from "./TextLink";
import { useEffect, useState } from "react";
import { listCourses } from "../api/course";
import { useNavigate } from "react-router-dom";
import VideoSlider from "./VideoSlider";

const VideosSection = ({ courseId, categoryId }) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  useEffect(() => {
    const fetchCourses = async () => {
      if (!categoryId) {
        return;
      }
      try {
        const res = await listCourses(5, 1, "", categoryId, false);
        const filteredCourses = res.data.results.filter(
          (course) => course.id !== courseId
        );
        setCourses(filteredCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [courseId]);

  if (courses.length === 0) {
    return null;
  }

  const handleRedirect = (slug) => {
    navigate(`/course/${slug}`);
  };

  return (
    <Box
      display={{ xs: "none", ssm: "block" }}
      pb={{ xs: pxToRem(90), md: pxToRem(110) }}
    >
      <Container disableGutters>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={{ xs: pxToRem(10), md: pxToRem(40) }}
        >
          <Typography variant="sectionTitle" component="h2">
            You <span className="highlighted">may</span> also{" "}
            <span className="highlighted">like</span>
          </Typography>
          <Box
            onClick={() => {
              navigate("/courses");
            }}
          >
            <TextLink>View All Videos</TextLink>
          </Box>
        </Stack>
      </Container>

      <VideoSlider
        videos={courses}
        loading={loading}
        handleRedirect={handleRedirect}
        mobile={mobile}
        mobile__ssm={mobile__ssm}
        sliderSettings={{
          slidesPerView: 3,
        }}
      />
    </Box>
  );
};

export default VideosSection;
