import { useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import restrictToWindowEdgesMargin from "../../utils/restrictToWindowEdges";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import VideoPlayerDraggable from "./VideoPlayerDraggable";

const DEFAULT_COORDINATES = { x: -25, y: -25 };

const VideoPlayer = (props) => {
  const {
    useParshaPlayer,
    selectedLesson,
    isVideoLibrary,
    preview,
    openResourcesTab,
    setVideoEnded,
    videoEnded,
    noListen,
    lastLesson,
    id,
    bookmarkStartTime,
    handleNextLectureClick,
    lessons,
    mediaControlsHeight,
    modalScreen,
    setBlockAutoLectureId,
    setBookmarkStartTime,
    drivingMode,
    lessonLockedAndSelected,
    handlePlayPreviousLecture,
    useCustomPlayer,
    videoUrlCustom,
    thumbnailCustom,
    customPosition,
    startsPlaying,
    pausedOrExpired,
    isPreview,
    progressRestriction,
    videoLink,
    exitFullScreen = false,
    setExitFullScreen,
    isPresubsribed,
    sx,
    isBlog,
    isFeaturedCourse,
    isHomePage,
    isSingleVideo,
  } = props;

  const [{ x, y }, setCoordinates] = useState(DEFAULT_COORDINATES);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 1 },
  });
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
    pointerSensor
  );

  const startTime = useParshaPlayer
    ? selectedLesson?.latest_watch_time === selectedLesson?.duration
      ? 0
      : selectedLesson.latest_watch_time ?? 0
    : selectedLesson?.last_tracked === selectedLesson?.length
    ? 0
    : selectedLesson?.last_tracked ?? 0;

  const handleDragEnd = ({ delta }) => {
    setCoordinates(({ x, y }) => ({
      x: x + delta.x,
      y: y + delta.y,
    }));
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdgesMargin]}
    >
      <VideoPlayerDraggable
        isVideoLibrary={isVideoLibrary}
        x={x}
        y={y}
        resetPosition={() => setCoordinates(DEFAULT_COORDINATES)}
        selectedLesson={selectedLesson}
        preview={preview}
        openResourcesTab={openResourcesTab}
        startTime={startTime}
        setVideoEnded={setVideoEnded}
        videoEnded={videoEnded}
        noListen={noListen}
        lastLesson={lastLesson}
        id={id}
        bookmarkStartTime={bookmarkStartTime}
        handleNextLectureClick={handleNextLectureClick}
        lessons={lessons}
        mediaControlsHeight={mediaControlsHeight}
        modalScreen={modalScreen}
        setBlockAutoLectureId={setBlockAutoLectureId}
        setBookmarkStartTime={setBookmarkStartTime}
        drivingMode={drivingMode}
        lessonLockedAndSelected={lessonLockedAndSelected}
        handlePlayPreviousLecture={handlePlayPreviousLecture}
        useCustomPlayer={useCustomPlayer}
        videoUrlCustom={videoUrlCustom}
        thumbnailCustom={thumbnailCustom}
        customPosition={customPosition}
        startsPlaying={startsPlaying}
        useParshaPlayer={useParshaPlayer}
        pausedOrExpired={pausedOrExpired}
        isPreview={isPreview}
        progressRestriction={progressRestriction}
        videoLink={videoLink}
        exitFullScreen={exitFullScreen}
        setExitFullScreen={setExitFullScreen}
        isPresubsribed={isPresubsribed}
        sx={sx}
        isBlog={isBlog}
        isHomePage={isHomePage}
        isSingleVideo={isSingleVideo}
      />
    </DndContext>
  );
};

export default VideoPlayer;
