import React, { useEffect } from "react";
import { MediaTimeSlider, MediaSliderValue } from "@vidstack/react";

export default function CustomTimeSlider({ maxWatchedTime, duration }) {
  const fractionWatched = duration > 0 ? maxWatchedTime / duration : 0;

  const watchedFraction = Math.min(Math.max(fractionWatched, 0), 1);



  return (
    <div style={{ position: "relative", display: "flex" }}>
      <MediaTimeSlider style={{ width: "100%" }}>
        <div slot="preview">
          <MediaSliderValue type="pointer" format="time" />
        </div>
      </MediaTimeSlider>

      {watchedFraction < 1 && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,

            left: `${watchedFraction * 100}%`,
            right: 0,
            pointerEvents: "auto",
            cursor: "not-allowed",

            background: 'transparent',
            // background: "rgba(0, 0, 0, 0.5)",
          }}
        />
      )}
    </div>
  );
}
