import { useEffect, useState } from "react";
import api from "../../api/api";
import { Typography, useMediaQuery, useTheme, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { pxToRem } from "px2rem2px";

import { Stack } from "@mui/system";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import VideoSlider from "../VideoSlider";
import { getRecentlyWatched } from "../../api/course";
const CoursesSlider = ({ type }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const navigate = useNavigate();

  const fetchLatestWatchedCourses = () => {
    setLoading(true);
    getRecentlyWatched(type)
      .then((res) => {
        setVideos(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLatestWatchedCourses();
  }, []);

  const handleRedirect = (id) => {
    navigate(type === "video" ? `/video/${id}` : `/course/${id}`);
  };

  return (
    <>
      {(loading || videos.length > 0) && (
        <>
          <Stack
            flexDirection={{ ssm: "row" }}
            margin={{ xs: "0 -25px", ssm: "0 0 25px 0" }}
            padding={{ xs: `${pxToRem(49)} 25px 0 25px`, ssm: "0px" }}
            alignItems={{ ssm: "center" }}
            backgroundColor={{ xs: "#fff", ssm: "transparent" }}
            justifyContent={{ ssm: "space-between" }}
            gap={pxToRem(14)}
          >
            <Typography
              variant="sectionTitle"
              component="h2"
              fontSize={pxToRem(35)}
            >
              <span className="highlighted">Continue</span> Watching
            </Typography>
            {tablet && (
              <Button
                onClick={() => navigate("/user/courses")}
                endIcon={<Chevron />}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#026670",
                  fontSize: "16px",
                  "& .MuiButton-endIcon": { ml: pxToRem(12) },
                }}
              >
                My Courses
              </Button>
            )}
          </Stack>
          <VideoSlider
            videos={videos}
            loading={videos.length === 0}
            handleRedirect={handleRedirect}
            mobile={mobile}
            mobile__ssm={mobile__ssm}
            sliderSettings={{
              slidesPerView: 3,
            }}
            isMyCourses
            isContinueWatching
            type={type}
          />
        </>
      )}
    </>
  );
};

export default CoursesSlider;
