import axios from "axios";
import api from "./api";
import { url } from "./config";
import { checkAuth } from "../utils/checkAuth";

export const validatePromoCode = async (payload) => {
  const res = await api.post(`api/subscription/validate_promo_code/`, payload);
  return res;
};

export const createCheckoutSession = async (
  courseId,
  priceType,
  priceClass,
  prolongBy,
  promoCode = ""
) => {
  try {
    const response = await api.get(
      `/api/subscription/get-checkout-link/?course_id=${courseId}&price_type=${priceType}&price_class=${priceClass}&prolonged_by=${prolongBy}&promo_code=${promoCode}`
    );
    return response;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export const resolveCheckoutSession = async (subscriptionId) => {
  try {
    const response = await api.get(
      `/api/subscription/get_resolve_failure_checkout?subscription_id=${subscriptionId}`
    );
    return response;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export const createAssociateCheckoutSession = async (courseId, email) => {
  try {
    const response = await api.get(
      `/api/subscription/get-associate-checkout-link/?course_id=${courseId}&price_type=Normal&price_class=Associate&associate_email=${email}`
    );
    return response;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export const createDonationCheckoutSession = async (
  amount,
  sustain_option,
  recurrence_rate,
  notification,
  email,
  full_name,
  phone_number,
  comment
) => {
  try {
    const response = await axios.get(
      `${url}/api/subscription/get_donation_link/?amount=${amount}&sustain_option=${sustain_option}&recurrence_rate=${recurrence_rate}&recieve_notifications=${notification}&email=${email}&full_name=${full_name}&phone_number=${phone_number}&comment=${comment}`
    );
    return response;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export const getSubscriptionsHistory = async () => {
  if (checkAuth()) {
    const res = api.get(`/api/subscription/my_subscriptions`);
    return res;
  }
};

export const getSubscriptionDetails = async (subscriptionId) => {
  if (checkAuth()) {
    const res = api.get(`/api/subscription/${subscriptionId}/my_payments`);
    return res;
  }
};

export const getCoursePaymentHistory = async (courseId) => {
  if (checkAuth()) {
    const res = api.get(
      `/api/subscription/my_subscriptions?course=${courseId}`
    );
    return res;
  }
};
