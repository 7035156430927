import { useState, useEffect } from "react";
import {
  Dialog,
  Stack,
  Typography,
  IconButton,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import { Close, ExpandMore, ExpandLess } from "@mui/icons-material";
import { getCoursesNotes } from "../../../../api/student";
import { pxToRem } from "px2rem2px";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import { getLanguageClass } from "../../../../utils/languageClass";

dayjs.extend(duration);

// Styled Typography Component
const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(18),
  fontWeight: 600,
  lineHeight: pxToRem(30),
  display: "flex",
  alignItems: "center", // Align text and icon
  "& span": {
    display: "inline-block",
    [theme.breakpoints.up("xs")]: {
      width: pxToRem(110),
    },
    [theme.breakpoints.up("sssm")]: {
      width: pxToRem(190),
    },
    color: "#333",
    marginRight: pxToRem(20),
  },
}));

export default function Notes({ open, setOpen }) {
  const [notes, setNotes] = useState([]);
  const [expandedCourse, setExpandedCourse] = useState(null);
  const [expandedLecture, setExpandedLecture] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    setOpen(false);
  };

  const handleCourseChange = (courseId) => (event, isExpanded) => {
    setExpandedCourse(isExpanded ? courseId : null);
    setExpandedLecture(null); // Collapse lectures when switching courses
  };

  const handleLectureChange = (lectureId) => (event, isExpanded) => {
    setExpandedLecture(isExpanded ? lectureId : null);
  };

  const fetchNotes = () => {
    getCoursesNotes()
      .then((res) => {
        setNotes(res.data.results);
        console.log("Fetched notes:", res.data.results);
      })
      .catch((err) => {
        console.error("Error fetching notes:", err);
      });
  };

  useEffect(() => {
    if (open) fetchNotes(); // Fetch only when the dialog is opened
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={false}
      maxWidth="ssm"
      keepMounted
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-container": {
          alignItems: { xs: "flex-end", ssm: "center" },
        },
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: {
            xs: "100% !important",
            ssm: "calc(100% - 64px) !important",
            md: `${pxToRem(920)} !important`,
          },
          borderRadius: {
            xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
            ssm: pxToRem(20),
          },
          height: "auto",
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          backgroundColor: "#F7F6F2",
          maxHeight: { xs: "85vh", ssm: "none" },
        },
        zIndex: 1500,
      }}
    >
      {/* Header Section */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#EDECE8"
        p={{
          xs: pxToRem(18),
          ssm: pxToRem(25),
          md: `${pxToRem(40)} ${pxToRem(60)}`,
        }}
      >
        <Typography
          fontSize={{ xs: pxToRem(23), ssm: pxToRem(35) }}
          fontWeight={700}
        >
          Notes
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
            height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      {/* Notes Content */}
      <Box
        sx={{ paddingRight: isMobile ? "" : "90px !important" }}
        p={{ xs: pxToRem(18), ssm: pxToRem(25), md: pxToRem(40) }}
      >
        {notes.length === 0 ? (
          <TypographyStyled>No notes available.</TypographyStyled>
        ) : (
          notes.map((course) => (
            <Accordion
              key={course.id}
              className={getLanguageClass(course.name)}
              expanded={expandedCourse === course.id}
              onChange={handleCourseChange(course.id)}
              sx={{
                ml: pxToRem(10),
                "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root": {
                  py: pxToRem(10),
                  pr: isMobile ? "" : "90px",
                },
              }}
            >
              <AccordionSummary>
                {/* Custom Expand Icon on Left Side */}
                {expandedCourse === course.id ? <ExpandLess /> : <ExpandMore />}
                <TypographyStyled
                  sx={{ fontSize: pxToRem(20), ml: pxToRem(10) }}
                >
                  {course.name}{" "}
                </TypographyStyled>
                <Tooltip title="View Course" placement="top">
                  <Link
                    to={`/course/${course.id}`}
                    state={{ showNotes: true }}
                    underline="none"
                  >
                    <LinkIcon
                      style={{
                        marginLeft: "10px",
                        marginTop: "7px",
                        color: "#045e69",
                        fontSize: "20px",
                      }}
                      color="primary"
                    />
                  </Link>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                {course.lectures.length > 0 ? (
                  course.lectures.map((lecture) => (
                    <Accordion
                      key={lecture.id}
                      expanded={expandedLecture === lecture.id}
                      onChange={handleLectureChange(lecture.id)}
                      sx={{
                        ml: pxToRem(10),
                        "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root":
                          {
                            py: pxToRem(10),
                            backgroundColor: "#edece8",
                            pr: isMobile ? "" : "20px",
                            borderRadius: "8px",
                          },
                      }}
                    >
                      <AccordionSummary>
                        {/* Custom Expand Icon on Left Side */}
                        {expandedLecture === lecture.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                        <TypographyStyled
                          sx={{ fontSize: pxToRem(18), ml: pxToRem(10) }}
                        >
                          {lecture.name}
                        </TypographyStyled>
                      </AccordionSummary>
                      <AccordionDetails>
                        {lecture.notes.length > 0 ? (
                          lecture.notes.map((note) => (
                            <Box
                              key={note.id}
                              borderTop="1px solid rgba(191,190,187,0.5)"
                              p={`${pxToRem(20)} 0 ${pxToRem(10)}`}
                              sx={{ "& p": { margin: 0 } }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <TypographyStyled>
                                  {note.title}
                                </TypographyStyled>
                                <span
                                  style={{ fontSize: "12px", color: "#888" }}
                                >
                                  @{" "}
                                  {dayjs
                                    .duration(note.timestamp, "seconds")
                                    .format("mm:ss")}
                                </span>
                              </Box>
                              <Typography sx={{ fontWeight: 400 }}>
                                {note.content}
                              </Typography>
                            </Box>
                          ))
                        ) : (
                          <TypographyStyled>
                            No notes available for this lecture.
                          </TypographyStyled>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <TypographyStyled>No lectures available.</TypographyStyled>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
    </Dialog>
  );
}
