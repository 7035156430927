import React, { useState, useEffect, useRef } from "react";
import {
  createBookmark,
  createNote,
  deleteBookmark,
  deleteNote,
  getNotes,
  listBookmarks,
  updateNote,
} from "../../api/course";
import {
  Badge,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import BlockBordered from "../../components/BlockBordered";
import Note from "./Note";
import IconBtn from "./IconBtn";
import DeleteBtn from "./DeleteBtn";
import { ReactComponent as Download } from "../../images/writing.svg";
import { ReactComponent as Print } from "../../images/printer.svg";
import { ReactComponent as Plus } from "../../images/plus.svg";
import Chevron from "../../images/chevron.svg";
import { useTheme } from "@emotion/react";
import Toast from "../../components/Toast";
import AddNoteDialog from "../../components/AddNoteDialog";

import { ReactComponent as Info } from "../../images/info.svg";
import { getLanguageClass } from "../../utils/languageClass";
import { useVideoPlayer } from "../../context/VideoPlayerContext";
import zIndex from "@mui/material/styles/zIndex";

const Block = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: pxToRem(25),
  [theme.breakpoints.up("ssm")]: {
    padding: `${pxToRem(30)} ${pxToRem(40)} ${pxToRem(40)} ${pxToRem(40)}`,
    borderRadius: pxToRem(20),
  },
}));

const ProgressButtons = ({ onProgressClick, alignment, margin = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipButtonRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleTooltipClick = (e) => {
    e.stopPropagation();
    if (isMobile) {
      setTooltipOpen((prev) => !prev);
    }
  };

  const handleClickOutside = (e) => {
    if (
      tooltipButtonRef.current &&
      !tooltipButtonRef.current.contains(e.target)
    ) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isMobile]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: alignment,
        alignItems: "center",
        width: "100%",
        marginTop: margin ? "20px" : "0px",
        gap: "10px",
      }}
    >
      <Button
        variant="yellow"
        sx={{
          height: `${pxToRem(40)} !important`,
          fontSize: pxToRem(14),
          padding: {
            xs: `${pxToRem(20)}`,
            ssm: `${pxToRem(20)} ${pxToRem(30)}`,
          },
          boxShadow: "none",
        }}
        onClick={onProgressClick}
      >
        Return to Latest Progress
      </Button>
      <Tooltip
        title="Return to your latest completed point and continue the course."
        enterDelay={0}
        enterTouchDelay={0}
        placement="top"
        open={isMobile ? tooltipOpen : undefined}
        disableHoverListener={isMobile}
      >
        <Button
          ref={tooltipButtonRef}
          startIcon={<Info />}
          sx={{
            fontSize: pxToRem(12),
            fontWeight: 600,
            color: "#BFBEBB",
            padding: 0,
            minHeight: 0,
          }}
          onClick={handleTooltipClick}
        />
      </Tooltip>
    </Box>
  );
};

const NotesBookmarks = ({
  course,
  bookmarks,
  setBookmarks,
  // notes,
  jumpToLatestProgress,
  jumpToBookMark,
  isCourseCompleted,

  selectedLesson,
  // currentTime,
  subscriptionStatus,
  lessons,
  hideJumpToLatestProgress,
}) => {
  //
  const {
    currentTime,
    bookmarkListChanged,
    setBookmarkListChanged,
    noteListChanged,
    setNoteListChanged,
  } = useVideoPlayer();

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const bookmarksRef = useRef(null);
  const [bookmarksAccordianIsOpen, setBookmarksAccordianIsOpen] =
    useState(false);

  // notes
  const [notes, setNotes] = useState([]);
  const [noteTimestamp, setNoteTimestamp] = useState(0);
  const [longestTimestamp, setLongestTimestamp] = useState("");
  const [noteToastOpen, setNoteToastOpen] = useState(false);
  const [NoteToastMessage, setNoteToastMessage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingNotes, setEditingNotes] = useState({});
  const [noteContents, setNoteContents] = useState({});

  const [courseNotesAccordianIsOpen, setCourseNotesAccordianIsOpen] =
    useState(false);
  const [parentHeight, setParentHeight] = useState(0);
  const [notesToggleCount, setNotesToggleCount] = useState(0);
  const [expandedNoteId, setExpandedNoteId] = useState(null);

  const courseNotesRef = useRef(null);

  useEffect(() => {
    if (courseNotesAccordianIsOpen && courseNotesRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.target.scrollHeight;
          console.log("[ResizeObserver] New height:", newHeight);
          setParentHeight(newHeight);
        }
      });
      // console.log("[ResizeObserver] Observing:", courseNotesRef.current);
      resizeObserver.observe(courseNotesRef.current);
      return () => {
        // console.log("[ResizeObserver] Disconnecting observer");
        resizeObserver.disconnect();
      };
    }
  }, [courseNotesAccordianIsOpen, notes, notesToggleCount]);

  const updateParentHeight = () => {
    if (courseNotesRef.current) {
      const newHeight = courseNotesRef.current.scrollHeight;
      // console.log("[updateParentHeight] New height:", newHeight);
      setParentHeight(newHeight);
    }
  };

  const handleNoteToggleGeneral = () => {
    setNotesToggleCount((prev) => prev + 1);
  };

  const handleNoteToggle = (noteId) => {
    if (mobile_ssm) {
      setExpandedNoteId((prev) => (prev === noteId ? null : noteId));
    }
    handleNoteToggleGeneral();
  };

  const handleCourseNotesClick = () => {
    setCourseNotesAccordianIsOpen((prev) => !prev);
  };
  //

  const [disableBookmark, setDisableBookmark] = useState(null);
  const [bookmarkMessage, setBookmarkMessage] = useState(null);
  const [bookmarkToastOpen, setBookmarkToastOpen] = useState(false);

  // edit note

  const [noteAction, setNoteAction] = useState("Add");
  const [selectedNote, setSelectedNote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditNote = (note) => {
    setNoteAction("Edit");
    setSelectedNote(note);
    handleOpenDialog();
  };

  const getAllNotes = async () => {
    try {
      const response = await getNotes(course?.id);
      setNotes(response.data.results);
    } catch (error) {
      console.error("Error getting notes:", error);
    }
  };

  useEffect(() => {
    getAllNotes();
  }, [course?.id, noteListChanged]);

  useEffect(() => {
    if (notes?.length > 0) {
      const timestamps = notes.map((note) => formatTimestamp(note.timestamp));
      const longestTimestamp = timestamps.reduce(
        (prev, current) => (current.length > prev.length ? current : prev),
        ""
      );
      setLongestTimestamp(longestTimestamp);
    }
  }, [notes]);

  const handleAddNote = () => {
    setNoteAction("Add");
    handleOpenDialog();
  };

  const handleUpdateNote = async (title, content) => {
    setIsLoading(true);
    setNoteListChanged(true);
    const payload = {
      lesson: selectedNote?.lesson?.id,
      title: title,
      content: content,
    };
    try {
      const response = await updateNote(selectedNote?.id, payload);
      console.log("updateNote response", response);
      setNoteToastOpen(true);
      setNoteToastMessage("Note saved successfully!");
    } catch (error) {
      console.error("Error updating note:", error);
    } finally {
      handleCloseDialog();
      setSelectedNote(null);
      setNoteListChanged(false);
      setIsLoading(false);
    }
  };

  // delete note
  const handleDeleteNote = async (noteId) => {
    try {
      await deleteNote(noteId);
      setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleBookmarksClick = () => {
    if (bookmarks?.length === 0) return;
    setBookmarksAccordianIsOpen(!bookmarksAccordianIsOpen);
  };

  const handleOpenDialog = () => {
    if (noteAction === "Add") {
      if (subscriptionStatus === "Expired" || subscriptionStatus === "Paused") {
        setNoteToastOpen(true);
        setNoteToastMessage(
          `Your subscription is ${subscriptionStatus.toLowerCase()}. You can't add notes.`
        );
        return;
      }
      setNoteTimestamp(Math.floor(currentTime.current));
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setNoteTimestamp(0);
    setIsDialogOpen(false);
    setSelectedNote(null);
  };

  const handleSaveNote = async (noteTitle, noteContent) => {
    setIsLoading(true);
    console.log("Note saved:", { noteTitle, noteContent });
    setNoteListChanged(true);
    const payload = {
      lesson: selectedLesson?.id,
      title: noteTitle,
      content: noteContent,
      timestamp: noteTimestamp,
    };

    try {
      const response = await createNote(selectedLesson?.id, payload);
      console.log(response);
      setNoteToastOpen(true);
      setNoteToastMessage("Note added successfully!");
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      handleCloseDialog();
      setNoteListChanged(false);
      setIsLoading(false);
    }
  };

  // bookmarks

  useEffect(() => {
    if (!bookmarkListChanged) {
      const getBookmarks = async () => {
        try {
          const response = await listBookmarks(course?.id);
          console.log("bookmarksresponse", response);
          setBookmarks(response.data.results);
        } catch (error) {
          console.log(error);
        }
      };
      getBookmarks();
    }
  }, [bookmarkListChanged]);

  const formatTimestamp = (timestamp) => {
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      await deleteBookmark(bookmarkId);
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark?.id !== bookmarkId)
      );
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  // notes

  const downloadNotes = (notes) => {
    const notesContent = `Course: ${course?.name}\n\n${notes
      ?.map(
        (note) => `Lecture: ${note?.lesson?.name} (timestamp ${formatTimestamp(
          note?.timestamp
        )})
  
  Title: ${note?.title}
  
  Content:
  ${note?.content.replace(/(?:\r\n|\r|\n)/g, "\n")}
  
  --------------------------------------------
  
  `
      )
      ?.join("\n\n")}`;

    const element = document.createElement("a");
    const file = new Blob([notesContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${course?.name}_notes.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  //

  const printNotes = (notes) => {
    const notesContent = notes
      .map(
        (note) => `
          <div class="note-container">
            <div class="lesson-info">
            <div class="note-title">
              
                <span class="lesson-name">${note?.title}</span> 
              </div>
              <h3 class="highlighted-title">${note?.lesson?.name}</h3>
             
            </div>
            <div class="note-content">
              
              <div class="note-text">
               <span class="timestamp"> ${formatTimestamp(
                 note?.timestamp
               )}  </span> ${note?.content.replace(/(?:\r\n|\r|\n)/g, "<br>")}
              </div>
            </div>
          </div>
        `
      )
      .join("");

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${course?.name}</title>
          <!-- Google Fonts -->
          <link href="https://fonts.googleapis.com/css?family=Merriweather|Roboto&display=swap" rel="stylesheet">
          <style>
            * {
              padding: 0;
              margin: 0;
              box-sizing: border-box;
            }
            body {
              font-family: 'Roboto', sans-serif;
              color: #333;
              background-color: #fff;
            }
            .main-container {
              width: 90%;
              margin: 30px auto;
            }
            .note-container {
              background-color: #f9f9f9;
              margin: 20px 0;
              padding: 25px;
              border-radius: 12px;
              border-left: 6px solid #016670;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            }
            .lesson-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-weight: bold;
              padding-bottom: 10px;
              border-bottom: 2px solid #ddd;
              color: #016670;
            }
            .lesson-name {
              font-size: 18px;
            }
            .timestamp {
              font-size: 12px;
              color: #666;
              margin-right:8px
            }
            .note-content {
              padding-top: 15px;
            }
            .note-title {
              
              display:flex;
              align-items: center;
              justify-content: start;
              gap:10px;
            }
            .highlighted-title {
              font-family: 'Merriweather', serif;
              font-size: 18px;
              font-weight: bold;
              color: #016670;
              background-color: #FCE181;
              padding: 3px 6px;
              border-radius: 5px;
              display: inline-block;
              margin-bottom: 5px;
            }
            .note-text {
              word-wrap: break-word;
              word-break: break-word;
              line-height: 1.6;
              font-size: 16px;
              color: #444;
              font-weight:bolder;
            }
            .header {
              text-align: center;
              padding-bottom: 20px;
              border-bottom: 3px solid #BFBEBB;
            }
            .header h1 {
              color: #016670;
              font-size: 26px;
              font-weight: bold;
            }
            @media print {
              body {
                background-color: white !important;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              .note-container {
                background-color: #f9f9f9 !important;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
                page-break-inside: avoid;
              }
              .highlighted-title {
                background-color: #FCE181 !important;
                color: #016670 !important;
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              .header h1 {
                color: #016670 !important;
                font-weight: bold !important;
              }
              @page {
                size: auto;
                margin: 15mm;
              }
            }
          </style>
        </head>
        <body>
          <div class="main-container">
            <div class="header">
              <h1>${course?.name}</h1>
            </div>
            ${notesContent}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  console.log("disable bookmark", disableBookmark);
  const handleAddBookmark = async () => {
    if (disableBookmark) return;
    if (subscriptionStatus === "Expired" || subscriptionStatus === "Paused") {
      setNoteToastOpen(true);
      setNoteToastMessage(
        `Your subscription is ${subscriptionStatus.toLowerCase()}. You can't add bookmarks.`
      );
      return;
    }
    console.log("Adding bookmark: active enrollment:", {
      selectedLesson,
      currentTime,
    });
    setDisableBookmark(true);
    setBookmarkListChanged(true);
    console.log("Adding bookmark:", { selectedLesson, currentTime });
    try {
      const response = await createBookmark(
        selectedLesson.id,
        currentTime?.current
      );
      setBookmarks((prevBookmarks) => [...prevBookmarks, response.data]);

      setBookmarkMessage("Bookmark added successfully!");
      setBookmarkToastOpen(true);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      if (
        error?.response?.data?.error ===
        "Maximum bookmarks limit of 10 lectures per course reached."
      ) {
        setBookmarkMessage(
          "You have reached the maximum number of bookmarks for this course."
        );
        setBookmarkToastOpen(true);
      } else if (
        error?.response?.data?.timestamp[0] === "This field may not be null."
      ) {
        setBookmarkMessage(
          "Failed to capture time. Start the lecture and try again."
        );
        setBookmarkToastOpen(true);
      }
    } finally {
      setDisableBookmark(false);
      setBookmarkListChanged(false);
    }
  };

  return (
    <>
      <Toast
        open={bookmarkToastOpen}
        onClose={() => {
          setBookmarkToastOpen(false);
          setBookmarkMessage("");
        }}
        message={bookmarkMessage}
      />

      <Toast
        open={noteToastOpen}
        onClose={() => {
          setNoteToastOpen(false);
          setNoteToastMessage("");
        }}
        message={NoteToastMessage}
      />
      <Box>
        <Block>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleBookmarksClick}
          >
            <Stack
              width="100%"
              dispay="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack flexDirection="row">
                <Badge
                  badgeContent={
                    <Typography
                      fontSize={pxToRem(12)}
                      fontWeight={700}
                      color="#BFBEBB !important"
                    >
                      {bookmarks?.length} / 10
                    </Typography>
                  }
                  sx={{
                    mr: { ssm: pxToRem(90) },
                    "& .MuiBadge-badge": {
                      right: pxToRem(-33),
                      height: pxToRem(27),
                      borderRadius: pxToRem(20),
                      backgroundColor: "#EDECE8",
                      p: `${pxToRem(5)} ${pxToRem(10)}`,
                    },
                  }}
                >
                  <Typography
                    position="relative"
                    fontSize={pxToRem(20)}
                    fontWeight={700}
                  >
                    Bookmarks
                  </Typography>
                </Badge>
              </Stack>
              <Stack flexDirection="row" columnGap={{ xs: "12px", ssm: 0 }}>
                {!hideJumpToLatestProgress &&
                  !isCourseCompleted &&
                  subscriptionStatus !== "Expired" &&
                  subscriptionStatus !== "Paused" &&
                  !mobile_ssm && (
                    <ProgressButtons
                      onProgressClick={jumpToLatestProgress}
                      alignment="flex-end"
                    />
                  )}
                {bookmarks?.length > 0 && (
                  <Box
                    sx={{
                      display: { xs: "flex", ssm: "none" },
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: pxToRem(4),
                    }}
                  >
                    <img
                      src={Chevron}
                      style={{
                        transform: bookmarksAccordianIsOpen
                          ? "rotate(-90deg)"
                          : "rotate(90deg)",
                        transition: "all 0.3s ease-in-out",
                        height: pxToRem(15),
                      }}
                      alt="Chevron"
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
            {!isCourseCompleted &&
              subscriptionStatus !== "Expired" &&
              subscriptionStatus !== "Paused" &&
              mobile_ssm && (
                <Box>
                  <ProgressButtons
                    onProgressClick={jumpToLatestProgress}
                    alignment="center"
                    margin
                  />
                </Box>
              )}
          </Stack>

          <Box
            ref={bookmarksRef}
            sx={{
              overflow: "hidden",
              maxHeight: {
                xs: bookmarksAccordianIsOpen
                  ? `${bookmarksRef.current.scrollHeight}px`
                  : "0px",
                ssm: "100%",
              },
            }}
            style={{ transition: "max-height 0.3s ease-in-out" }}
          >
            <Box
              sx={{
                display: "grid",
                mt: pxToRem(24),
                gridTemplateColumns: {
                  ssm: bookmarks.length > 4 ? "1fr 1fr" : "1fr",
                },
                gap: `0 ${pxToRem(40)}`,
              }}
            >
              {bookmarks?.map((bookmark) => (
                <Stack
                  key={bookmark.id}
                  // to="/"
                  // component={Link}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  py={pxToRem(10)}
                  sx={{
                    // "&:first-child": {mt: pxToRem(24)},
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100vw",
                      height: "1px",
                      backgroundColor: "#DFDEDD",
                    },
                    "&:hover": {
                      "& .text": {
                        color: "#333",
                      },
                      "& .bookmark-number": {
                        backgroundColor: "#FCE181",
                        span: {
                          color: "#026670",
                        },
                      },
                      "& .deleteBtn": {
                        display: "flex",
                      },
                    },
                  }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    columnGap={pxToRem(15)}
                    onClick={() =>
                      jumpToBookMark(bookmark?.timestamp, bookmark?.lesson?.id)
                    }
                    sx={{
                      cursor: "pointer",
                      // backgroundColor: "green",
                      width: "calc(100% - 80px)",
                    }}
                  >
                    <Box
                      className="bookmark-number"
                      width={pxToRem(27)}
                      height={pxToRem(27)}
                      borderRadius="100%"
                      bgcolor="#EDECE8"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexShrink={0}
                    >
                      <Typography
                        component="span"
                        fontSize={pxToRem(12)}
                        fontWeight={700}
                        color="#BFBEBB"
                      >
                        {bookmarks?.indexOf(bookmark) + 1}
                      </Typography>
                    </Box>

                    <Typography
                      // className="text"
                      style={
                        bookmarks.length <= 4
                          ? {
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }
                          : !isTablet
                          ? {
                              // length >  4  isTablet  false
                            }
                          : {
                              // length >  4  isTablet  true
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }
                      }
                      display="block"
                      fontSize={pxToRem(16)}
                      lineHeight={pxToRem(20)}
                      fontWeight={600}
                      color="secondary"
                      className={`text 
                        ${getLanguageClass(bookmark?.lesson?.name)}
                        
                        `}
                    >
                      {bookmarks.length > 4 &&
                      !isTablet &&
                      bookmark?.lesson?.name.length > 27
                        ? `${bookmark?.lesson?.name.slice(0, 25)}...`
                        : bookmark?.lesson?.name}
                    </Typography>
                  </Stack>

                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    sx={{ "& svg": { mb: "1px" } }}
                  >
                    <DeleteBtn
                      onDelete={() =>
                        handleRemoveBookmark(bookmark?.id, bookmark?.timestamp)
                      }
                    />
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={600}
                      color="primary"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        minWidth: "40px",
                      }}
                    >
                      {formatTimestamp(bookmark?.timestamp)}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </Box>
        </Block>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            marginTop: pxToRem(20),
            marginRight: { xs: pxToRem(25), ssm: 0 },
          }}
        >
          <Tooltip
            title={
              subscriptionStatus === "Expired"
                ? "Your subscription has expired."
                : subscriptionStatus === "Paused"
                ? "Your subscription is paused."
                : !currentTime
                ? "Play a lecture to add a bookmark."
                : ""
            }
            enterDelay={0}
            enterTouchDelay={0}
            placement="top"
            zIndex={2}
          >
            <Box
              onClick={handleAddBookmark}
              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <TextLink
                sx={{ padding: "0px !important", zIndex: 0 }}
                withIcon={false}
                disabled={
                  subscriptionStatus === "Expired" ||
                  subscriptionStatus === "Paused" ||
                  !currentTime
                }
                startIcon={<Plus height={15} width={15} color="#036670" />}
              >
                Add Bookmark
              </TextLink>
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Box mt={{ xs: pxToRem(30), ssm: pxToRem(60) }}>
        {/* here */}
        <Block>
          {/* Accordion Header */}
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleCourseNotesClick}
            marginBottom="20px"
          >
            <Stack flexDirection="row">
              <Badge
                badgeContent={
                  notes.length > 0 && (
                    <Typography
                      fontSize={pxToRem(12)}
                      fontWeight={700}
                      color="#BFBEBB !important"
                    >
                      {notes?.length}
                    </Typography>
                  )
                }
                sx={{
                  mr: { ssm: pxToRem(90) },
                  "& .MuiBadge-badge": {
                    right: notes?.length > 9 ? pxToRem(-23) : pxToRem(-21),
                    height: pxToRem(27),
                    borderRadius: pxToRem(20),
                    backgroundColor: notes.length > 0 && "#EDECE8",
                    p: `${pxToRem(5)} ${pxToRem(10)}`,
                  },
                }}
              >
                <Typography
                  position="relative"
                  fontSize={pxToRem(20)}
                  fontWeight={700}
                >
                  Course Notes
                </Typography>
              </Badge>
            </Stack>
            <Stack flexDirection="row" gap="10px">
              {notes?.length > 0 && (
                <Stack direction="row" gap="10px">
                  <Tooltip title="Download notes to text file">
                    <Box>
                      <IconBtn
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadNotes(notes);
                        }}
                      >
                        <Download color="#026670" />
                      </IconBtn>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Print Notes">
                    <Box>
                      <IconBtn
                        onClick={(e) => {
                          e.stopPropagation();
                          printNotes(notes);
                        }}
                      >
                        <Print color="#026670" />
                      </IconBtn>
                    </Box>
                  </Tooltip>
                </Stack>
              )}
              {notes?.length > 0 && (
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "4px",
                  }}
                >
                  <img
                    src={Chevron}
                    style={{
                      transform: courseNotesAccordianIsOpen
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                      transition: "all 0.3s ease-in-out",
                      height: "15px",
                    }}
                    alt="Chevron"
                  />
                </Box>
              )}
            </Stack>
          </Stack>

          {/* Accordion Content */}
          <Box
            ref={courseNotesRef}
            sx={{
              overflow: "hidden",

              maxHeight: mobile_ssm
                ? courseNotesAccordianIsOpen
                  ? `${parentHeight}px`
                  : "0px"
                : "100%",
            }}
            style={{ transition: "max-height 0.3s ease-in-out" }}
          >
            {notes?.map((note) => {
              const isEditing = editingNotes[note.id] || false;
              const content = noteContents[note.id] || note.content;
              return (
                <Stack key={note.id}>
                  <Note
                    note={note}
                    onDelete={() => handleDeleteNote(note.id)}
                    lessons={lessons}
                    longestTimestamp={longestTimestamp}
                    expanded={
                      mobile_ssm ? expandedNoteId === note.id : undefined
                    }
                    onToggle={() => handleNoteToggle(note.id)}
                    onTransitionEnd={updateParentHeight}
                  >
                    <BlockBordered
                      mb="20px"
                      isAlignedRight={
                        getLanguageClass(note.content) === "is-hebrew-text"
                      }
                    >
                      <Box
                        className="note-edited"
                        sx={{
                          display: "flex",
                          justifyContent:
                            getLanguageClass(note.content) === "is-hebrew-text"
                              ? "end"
                              : "start",
                        }}
                      >
                        <Typography
                          variant="medium"
                          lineHeight="22px"
                          className={getLanguageClass(note.content)}
                        >
                          {note.content}
                        </Typography>
                      </Box>
                    </BlockBordered>
                    <Box
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditNote(note);
                      }}
                    >
                      <TextLink
                        href=""
                        sx={{
                          marginBottom: "10px",
                        }}
                      >
                        Edit Note
                      </TextLink>
                    </Box>
                  </Note>
                </Stack>
              );
            })}
          </Box>
        </Block>

        {/* here */}

        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            marginTop: pxToRem(20),
            marginRight: { xs: pxToRem(25), ssm: 0 },
          }}
        >
          <Tooltip
            title={
              subscriptionStatus === "Expired"
                ? "Your subscription has expired."
                : subscriptionStatus === "Paused"
                ? "Your subscription is paused."
                : ""
            }
            enterDelay={0}
            enterTouchDelay={0}
            placement="top"
            zIndex={1000}
          >
            <span style={{ display: "inline-block" }}>
              <Box onClick={handleAddNote}>
                <TextLink
                  withIcon={false}
                  sx={{
                    color:
                      subscriptionStatus === "Expired" ||
                      subscriptionStatus === "Paused"
                        ? "#BFBEBB"
                        : "#026670",
                  }}
                  disabled={
                    subscriptionStatus === "Expired" ||
                    subscriptionStatus === "Paused"
                  }
                  startIcon={<Plus height={15} width={15} color="#036670" />}
                >
                  Add Note
                </TextLink>
              </Box>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <AddNoteDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={noteAction === "Edit" ? handleUpdateNote : handleSaveNote}
        initialTitle={noteAction === "Edit" ? selectedNote?.title : ""}
        initialContent={noteAction === "Edit" ? selectedNote?.content : ""}
        action={noteAction}
        onAction={isLoading}
      />
    </>
  );
};

export default NotesBookmarks;
