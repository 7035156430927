import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import FormInputText from "../../../components/FormInputText";
import { useForm, Controller } from "react-hook-form";

import FormInputPhone from "../../../components/FormInputPhone";
import { editProfile } from "../../../api/student";
import { saveStudentLocal } from "../../../context/AuthContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpIcon from "@mui/icons-material/Help";
import { useTheme } from "@emotion/react";

const InputAccountEdit = (props) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      outline: "2px solid #333",
      outlineOffset: "-2px",
      backgroundColor: "#fff",
      width: props.phone ? "100%" : "268px",
      "&.Mui-disabled": {
        backgroundColor: "#EDECE8",
        outline: "none",
      },
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
    },
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [savedValue, setSavedValue] = useState(props.value || "");
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipButtonRef = useRef(null);

  const [temPhoneNumber, setTempPhoneNumber] = useState(
    props?.phoneNumber || ""
  );

  useEffect(() => {
    setTempPhoneNumber(props?.phoneNumber);
  }, [props?.phoneNumber]);

  useEffect(() => {
    setSavedValue(props.value);
  }, [props.value]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      value: props.value || "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    let payload = {};
    if (props.editLabel === "nickname") {
      payload[props.editLabel] = data.value.toLowerCase();
    } else if (props.phone) {
      props?.setPhoneNumber(temPhoneNumber);
      payload = { phone_number: temPhoneNumber || "" };
    } else {
      payload = { user: {} };
      payload.user[props.editLabel] = data.value;
    }

    editProfile(payload)
      .then((res) => {
        setIsSubmitting(false);
        setSavedValue(data.value);
        setDisabled(true);
        saveStudentLocal().then((res) => {
          if (
            props.editLabel === "first_name" ||
            props.editLabel === "last_name"
          ) {
            window.location.href = "/user/account-settings";
          }
        });
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err.response && err.response.data) {
          if (err.response.data.phone_number) {
            setError("phoneNumber", {
              type: "manual",
              message: err.response.data.phone_number[0],
            });
          } else if (err.response.data.nickname) {
            setError("value", {
              type: "manual",
              message: err.response.data.nickname[0],
            });
          }
        } else {
          console.log("Error encountered:", err.message);
        }
      });
  };

  const resetForm = () => {
    setValue("value", savedValue, { shouldValidate: true });
    setDisabled(true);
    clearErrors();
    setTempPhoneNumber(props?.phoneNumber);
  };

  // open and close tooltip

  const handleTooltipClick = () => (e) => {
    e.stopPropagation();
    if (isTablet) {
      setTooltipOpen((prev) => !prev);
    }
  };

  const handleClickOutside = (e) => {
    if (
      tooltipButtonRef.current &&
      !tooltipButtonRef.current.contains(e.target)
    ) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Stack
      flexDirection={{ xs: "column", lg: "row" }}
      alignItems={{ xs: "flex-start", lg: "flex-start" }}
      gap={{ xs: "10px", lg: 0 }}
    >
      <Box
        display="flex"
        alignItems="center"
        width={{ xs: "auto", lg: "130px" }}
        mt={{ xs: 0, lg: "13px" }}
        gap={1}
      >
        <Typography variant="medium">{props.label}</Typography>

        {props?.label === "Nickname" && (
          <Tooltip
            open={isTablet ? tooltipOpen : undefined}
            title="Suggest a Nickname for public display when submitting comments, etc., if you prefer to remain anonymous."
            enterDelay={0}
            enterTouchDelay={0}
            PopperProps={{ disablePortal: true }}
            placement="top"
            sx={{
              "& .MuiTooltip-tooltip": {
                mt: pxToRem(4),
                py: pxToRem(4),
              },
            }}
          >
            <Box
              flexShrink={0}
              textAlign="center"
              display="flex"
              alignItems="center"
            >
              <Button
                ref={tooltipButtonRef}
                startIcon={
                  <HelpOutlineIcon sx={{ fontSize: " 16px !important" }} />
                }
                sx={{
                  color: "#BFBEBB",
                  textAlign: "center",
                }}
                onClick={handleTooltipClick()}
              ></Button>
            </Box>
          </Tooltip>
        )}
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack flex={1} flexDirection="row" gap={pxToRem(20)}>
          <Box maxWidth="268px">
            {props?.phone ? (
              <FormInputPhone
                placeholder="Phone"
                rules={{
                  required: "Field can't be empty",
                }}
                disabled={disabled}
                value={temPhoneNumber}
                onChange={(data) => {
                  setTempPhoneNumber(data.phone);
                  clearErrors("phoneNumber");
                }}
                muiProps={{
                  disabled: disabled,
                  sx: inputStyles,
                }}
                error={!!errors?.phoneNumber}
                helperText={
                  errors?.phoneNumber ? errors?.phoneNumber?.message : ""
                }
                userSettings
              />
            ) : (
              <FormInputText
                name="value"
                control={control}
                setValue={setValue}
                rules={props.rules}
                muiProps={{
                  type: props.type,
                  disabled: disabled,
                  sx: inputStyles,
                }}
                error={!!errors?.value}
                helperText={errors?.value ? errors?.value?.message : ""}
              />
            )}
          </Box>

          <Stack alignItems="flex-start" width="42px">
            {disabled ? (
              <Button
                onClick={() => setDisabled(false)}
                sx={{ fontSize: "12px", mt: "16px" }}
              >
                Edit
              </Button>
            ) : (
              <Stack alignItems="flex-start" mt="10px">
                <Button
                  onClick={resetForm}
                  sx={{ color: "#BFBEBB", fontSize: "12px" }}
                >
                  Cancel
                </Button>
                <Button type="submit" sx={{ fontSize: "12px" }}>
                  Save
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default InputAccountEdit;
