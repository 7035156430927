import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getFooterSettings,
  getNavigationMenu,
  getGeneralSettings,
} from "../api/cmsApi";
import { url } from "../api/config";

const CMSDataContext = createContext({});

export const CMSDataProvider = ({ children }) => {
  const [navigationMenu, setNavigationMenu] = useState([]);
  const [logo, setLogo] = useState(null);
  const [footerSettings, setFooterSettings] = useState({
    email: "",
    phone: "",
    links: [],
    description: "",
  });

  useEffect(() => {
    // Fetch Logo
    getGeneralSettings()
      .then((res) => {
        const logoUrl = `${url}${res.logo}`;
        const img = new Image();
        img.src = logoUrl;
        img.onload = () => {
          setLogo(logoUrl);
        };
      })
      .catch((err) => console.error("General settings error:", err));

    // Fetch Navigation Menu
    getNavigationMenu()
      .then((res) => setNavigationMenu(res))
      .catch((err) => console.error("Navigation menu error:", err));

    // Fetch Footer Settings
    getFooterSettings()
      .then((res) => setFooterSettings(res.data))
      .catch((err) => console.error("Footer settings error:", err));
  }, []);

  return (
    <CMSDataContext.Provider value={{ navigationMenu, logo, footerSettings }}>
      {children}
    </CMSDataContext.Provider>
  );
};

export const useCMSData = () => useContext(CMSDataContext);
