import {
  Box,
  Container,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";

import Header from "../../components/Header";
import { useEffect, useState } from "react";

import LinkWithIcon from "../../components/LinkWithIcon";
import { Stack, styled } from "@mui/system";
import GradientAnimation from "../../components/GradientAnimation";
import TreeImg from "../../images/footer-tree.svg";
import ReusableHeaderBox from "../../components/ReausibleHeaderBox";
import { useLocation, useParams } from "react-router-dom";
import { getVideo } from "../../api/parsha";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import { VideoPlayerProvider } from "../../context/VideoPlayerContext";

import { getLanguageClass } from "../../utils/languageClass";
import BlockBordered from "../../components/BlockBordered";
import YouMayAlsoLike from "./YouMayAlsoLike";
import { formatDate, formatDuration } from "../../utils/format";

import { ReactComponent as Eye } from "../../images/eye.svg";
import Likes from "../../components/Likes";

import useLikeHandler from "../../hooks/useLikeHandler";
import { checkAuth } from "../../utils/checkAuth";

import { postVideoLike } from "../../api/parsha";
import Toast from "../../components/Toast";

import { useToastNotification } from "../../context/ToastNotificationContext";
import { postVideoInteraction } from "../../api/parsha";
const TypographyStyled = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: pxToRem(2),
  fontSize: pxToRem(14),
  fontWeight: 600,
  lineHeight: pxToRem(18),
  color: "#BFBEBB",
  "& span": {
    color: "#333",
  },
});

const SingleVideo = () => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const [imageLoaded, setImageLoaded] = useState(false);

  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const { id } = useParams();
  const location = useLocation();

  //
  const isAuthenticated = checkAuth();

  //

  const [toastOpen, setToastOpen] = useState(false);

  // const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  //top panel feedback
  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    setToastNotificationMessage,
    toastNotificationMessage,
    setToastWithActionOpen,
    toastWithActionOpen,
    resetSettings,
    toastNoClose,
    blockAutoHide,
  } = useToastNotification();
  const [video, setVideo] = useState({
    title: "",
    thumb_nail: "",
    video_url: "",
  });

  const { handleLikeClick } = useLikeHandler({
    video,
    setVideo,
    isAuthenticated,
    postVideoLike,
    isSingleVideo: true,
  });

  const [isLiked, setIsLiked] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const categoryId = Number(params.get("category"));
  const groupId = Number(params.get("group"));
  const subgroupId = Number(params.get("subgroup"));

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const handlePlayVideo = () => {
    if (video?.video_url) {
      // setIsPlaying(true);
      setCurrentlyPlayingId(video?.id);
    }
  };

  //

  // Extract the "t" query parameter from the URL.
  const queryParams = new URLSearchParams(location.search);
  const startTimeParam = queryParams.get("t");

  // Automatically play if the "t" parameter exists.
  useEffect(() => {
    if (startTimeParam) {
      setIsPlaying(true);
    }
  }, [startTimeParam]);

  //
  useEffect(() => {
    document.title = `${video?.title} | Reb Yakov Moshe` || "Reb Yakov Moshe";
  }, [video]);

  useEffect(() => {
    if (!isAuthenticated && video?.id) {
      const storedLikes = localStorage.getItem("likedVideos");
      const likedVideos = storedLikes ? JSON.parse(storedLikes) : {};
      setIsLiked(likedVideos[video.id] === true);
    } else if (isAuthenticated) {
      setIsLiked(video?.is_liked);
    }
  }, [isAuthenticated, video]);

  const [loading, setLoading] = useState(false);
  const fetchVideo = async (id) => {
    setLoading(true);
    try {
      const res = await getVideo(id);
      setVideo(res);
      console.log("res", res);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error(`Video with ID ${id} not found.`);
        window.location.href = "/videos";
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchVideo(id);
  }, [id]);

  useEffect(() => {
    console.log(
      "currentlyPlayingIdequalityceck",
      Number(currentlyPlayingId),
      id,
      currentlyPlayingId === id
    );
    if (currentlyPlayingId === Number(id)) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [currentlyPlayingId]);

  useEffect(() => {
    postVideoInteraction(id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, [video]);
  return (
    <>
      <Box position="relative" overflow="hidden" mb={"50px"}>
        <ReusableHeaderBox
          treeImg={TreeImg}
          gradientComponent={GradientAnimation}
          headerComponent={() => (
            <Header menuVisible hideUserProfile isSingleVideo />
          )}
        />
        <Box
          // maxWidth="md"
          sx={{
            py: { xs: pxToRem(33), ssm: pxToRem(77), md: pxToRem(80) },
          }}
          // disableGutters
        >
          <Container
            disableGutters
            // sx={{ display: { xs: "none", ssm: "block" } }}
            // sx={{backgroundColor:'pink'}}
          >
            <Stack
              className="rtl-section"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              pb={{ xs: 0, ssm: pxToRem(36) }}
              // columnGap={pxToRem(20)}
            >
              <Box
                sx={{
                  width: "100%",
                  // backgroundColor: { xs: "#F7F6F2", md: "unset" },
                  // pb: { xs: pxToRem(10), ssm: 0 },
                }}
              >
                {!loading && video && video?.title ? (
                  <Typography
                    fontSize={{
                      xs: pxToRem(30),
                      ssm: pxToRem(35),
                      md: pxToRem(40),
                    }}
                    lineHeight={pxToRem(40)}
                    fontFamily="PloniBold"
                    textAlign="right"
                    className={getLanguageClass(video?.title, false, true)}
                    mb="10px"
                  >
                    {video?.title}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="40%" height={pxToRem(30)} />
                )}
              </Box>
              <LinkWithIcon
                to="/videos"
                className="rtl-section"
                style={{ display: tablet ? "none" : "", marginBottom: 60 }}
              >
                Back
              </LinkWithIcon>
            </Stack>
          </Container>

          <Container
            sx={{
              px: { xs: 0, md: pxToRem(50) },
              pt: { xs: pxToRem(50), md: pxToRem(30) },
            }}
            disableGutters
          >
            <Box
              position="relative"
              width="100%"
              display="flex"
              borderRadius={{ xs: "0", md: pxToRem(20) }}
              flexShrink={0}
              // before
              sx={{
                "&::before": {
                  content: { xs: "none", md: '""' },
                  position: "absolute",
                  bottom: pxToRem(-40),
                  right: pxToRem(-40),
                  width: "46.6%",
                  height: "59.7%",
                  bgcolor: "#FCE181",
                  borderRadius: pxToRem(20),
                  zIndex: "0",
                },
              }}
            >
              {isPlaying ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "16/9",
                    backgroundColor: "black",
                  }}
                  borderRadius={{ xs: "0", md: pxToRem(20) }}
                >
                  <VideoPlayerProvider key={id}>
                    <VideoPlayer
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      selectedLesson={video}
                      thumbnailCustom={video?.thumb_nail}
                      currentTime={currentTime}
                      setCurrentTime={setCurrentTime}
                      useCustomPlayer
                      useParshaPlayer
                      isVideoLibrary={true}
                      startsPlaying
                      bookmarkStartTime={
                        startTimeParam ? Number(startTimeParam) : 0
                      }
                      isSingleVideo
                    />
                  </VideoPlayerProvider>
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "16/9",

                    overflow: "hidden",
                  }}
                  borderRadius={{ xs: "0", md: pxToRem(20) }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      borderRadius={{ xs: "0", md: pxToRem(20) }}
                    />
                  ) : (
                    <img
                      src={video?.thumb_nail}
                      alt={video?.title}
                      onLoad={() => setImageLoaded(true)}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 2,
                    }}
                  >
                    <PlayBtn
                      width={{ xs: "64px", md: pxToRem(96) }}
                      height={{ xs: "64px", md: pxToRem(96) }}
                      sx={{ backgroundColor: "#fff" }}
                      onClick={handlePlayVideo}
                    >
                      <Play
                        color="#026670"
                        width={pxToRem(20)}
                        height={pxToRem(24)}
                        style={{ marginLeft: "6%", pointerEvents: "none" }}
                      />
                    </PlayBtn>
                  </Box>
                </Box>
              )}
            </Box>
          </Container>

          <Container
            sx={{
              padding: { xs: "0px 30px 30px 30px", md: "0px 45px 30px 45px" },
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              columnGap={{ xs: pxToRem(30), md: pxToRem(20) }}
              rowGap={pxToRem(10)}
              mt={{ xs: pxToRem(20), md: pxToRem(10) }}
              order="1"
              // display={{ xs: "grid", ssm: "flex" }}
              // gridTemplateColumns={{
              //   xs: "auto auto",
              //   sssm: "1fr 1fr",
              //   ssm: "unset",
              // }}
              // justifyContent={{ xs: "space-between", ssm: "unset" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  alignItems: "center",
                  // backgroundColor: "green",
                }}
                marginTop={{ xs: "16px", ssm: "0px" }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    columnGap: pxToRem(8),
                  }}
                >
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                    whiteSpace="nowrap"
                  >
                    {video?.views}
                  </Typography>
                  <Box sx={{ mt: "3px" }}>
                    <Eye
                      width={mobile__ssm ? 18 : 15}
                      height={mobile__ssm ? 18 : 15}
                    />
                  </Box>
                </Box>

                <Box>
                  <Likes
                    iconHeight={mobile__ssm ? 16 : 13}
                    number={video.likes}
                    isLiked={isLiked}
                    onClick={(e) => handleLikeClick(video.id, e)}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection={{ xs: "column", ssm: "row" }}
                gap={{ xs: 0, ssm: 1 }}
                alignItems="flex-start"
                // sx={{backgroundColor:"green"}}
              >
                <TypographyStyled>
                  Length:{" "}
                  <span style={{ marginLeft: "4px" }}>
                    {video?.duration ? (
                      formatDuration(video?.duration)
                    ) : (
                      <Skeleton width={50} />
                    )}
                  </span>
                </TypographyStyled>
                <TypographyStyled>
                  Posted:{" "}
                  <span style={{ marginLeft: "4px" }}>
                    {video?.uploaded_at ? (
                      formatDate(video?.uploaded_at)
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </span>
                </TypographyStyled>
              </Box>
            </Stack>
            <Divider
              sx={{
                backgroundColor: "#BFBEBB",
                // mb: { xs: pxToRem(38), ssm: pxToRem(60) },
                mt: { xs: pxToRem(0), md: pxToRem(10) },
                order: "2",
              }}
            />
          </Container>
        </Box>

        <YouMayAlsoLike
          videoId={id}
          categoryId={categoryId}
          groupId={groupId}
          subgroupId={subgroupId}
          toastOpen={toastOpen}
          setToastOpen={setToastOpen}
          setCurrentlyPlayingId={setCurrentlyPlayingId}
          currentlyPlayingId={currentlyPlayingId}
        />
      </Box>

      <Toast
        open={toastNotificationOpen}
        onClose={() => setToastNotificationOpen(false)}
        message={toastNotificationMessage}
        noClose={toastNoClose}
        blockAutoHide={blockAutoHide}
      />
      <Toast
        open={toastWithActionOpen}
        onClose={() => setToastWithActionOpen(false)}
        message={toastNotificationMessage}
        button={"Change Download Option"}
        onUndo={resetSettings}
      />
    </>
  );
};
export default SingleVideo;
