import { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import {
  Box,
  Button,
  Container,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import Slider from "../../components/Slider";
import TextLink from "../../components/TextLink";
import Slide from "../../components/Slide";
import { subscribeToNewsletter } from "../../api/student";
import Toast from "../../components/Toast";
import { getBlogs } from "../../api/blogs";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../context/AuthContext";
import { useNotificationSettings } from "../../hooks/useNotificationSettings";

const BlogNews = ({ student }) => {
  const theme = useTheme();
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const { notifications: fetchedNotifications, updateNotifications } =
    useNotificationSettings();

  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const [subscribedSuccessfully, setSubscribedSuccessfully] = useState(false);

  const [anonymousAlreadySubscribed, setAnonymousAlreadySubscribed] =
    useState(false);

  const [notifications, setNotifications] = useState({});
  const [isUpdatesAndNewsActive, setIsUpdatesAndNewsActive] = useState(false);

  useEffect(() => {
    if (fetchedNotifications && Object.keys(fetchedNotifications).length > 0) {
      setNotifications(fetchedNotifications);
      setIsUpdatesAndNewsActive(fetchedNotifications.updates_and_news);
    }
  }, [fetchedNotifications]);

  const fetchBlogs = () => {
    getBlogs(7, 1)
      .then((res) => {
        setBlogs(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const isSubscribed = localStorage.getItem("newsletterSubscribed");
    if (isSubscribed === "true" && isAuthenticated === false) {
      setAnonymousAlreadySubscribed(true);
    }
  }, []);

  const triggerConfetti = () => {
    if (isConfettiActive) return;

    setIsConfettiActive(true);

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"],
    });

    setTimeout(() => {
      setIsConfettiActive(false);
    }, 5000);
  };

  const handleSubscribe = async () => {
    setLoading(true);

    if (isAuthenticated) {
      try {
        const updatedNotifications = {
          ...notifications,
          updates_and_news: true, // turn it on
        };
        // Save via React Query mutation
        const res = await updateNotifications({
          preferences: updatedNotifications,
        });

        // If success, trigger confetti
        triggerConfetti();
        setSubscribedSuccessfully(true);
      } catch (error) {
        console.log("Subscription failed:", error);
      }
    } else {
      // Unauthenticated: do email-based subscription
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailAddress)) {
        setEmailError(true);
      } else {
        setEmailError(false);
        try {
          const response = await subscribeToNewsletter(emailAddress);
          if (response) {
            triggerConfetti();
            setSubscribedSuccessfully(true);
            localStorage.setItem("newsletterSubscribed", "true");
          }
        } catch (error) {
          console.log("Subscription failed:", error);
          if (
            error.response?.data?.email &&
            error.response?.data?.email[0] ===
              "news letter subscribers with this email already exists."
          ) {
            setToastOpen(true);
            setToastMessage(
              "This Email is alraedy subscribed to our newsletter"
            );
          }
        }
      }
    }

    setLoading(false);
  };

  const showSubscriptionBox =
    subscribedSuccessfully ||
    (!isAuthenticated ? !anonymousAlreadySubscribed : !isUpdatesAndNewsActive);

  return (
    <>
      <Toast
        open={toastOpen}
        message={toastMessage}
        onClose={() => {
          setToastOpen(false);
        }}
      />

      <Box mb={{ xs: pxToRem(70), ssm: "86px", sm: "100px", md: pxToRem(150) }}>
        <Container disableGutters>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="space-between"
            gap="15px"
            mb={{ xs: "36px", sm: pxToRem(57.79) }}
          >
            <Typography variant="sectionTitle" component="h2">
              <span className="highlighted">Blogs</span> &{" "}
              <span className="highlighted">News</span>
            </Typography>
            <TextLink href="/blogs">Visit Blog & News</TextLink>
          </Stack>
        </Container>
        <Box
          sx={{
            height: "530px",
            mb: "150px",
            // backgroundColor:"green"
          }}
        >
          <Slider
            slidesPerView={1}
            spaceBetween={20}
            arrows
            breakpoints={{
              600: {
                slidesPerView: 2,
              },
              900: {
                slidesPerView: 3,
              },
            }}
            style={{
              padding: `${pxToRem(30)} ${pxToRem(20)}`,
              margin: `0 ${pxToRem(-20)}`,
              height: "100%",
            }}
          >
            {blogs &&
              blogs.length > 0 &&
              blogs.slice(0, 7).map((blog, index) => (
                <SwiperSlide key={index} style={{ height: "auto" }}>
                  <Slide
                    image={blog?.image ? blog?.image : blog?.thumbnail}
                    title={blog?.title}
                    text={blog?.content}
                    blogLink={`/blog/${blog.id}`}
                    date={
                      blog?.published_at
                        ? new Date(blog.published_at).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"
                    }
                    author={
                      blog?.author
                        ? `${blog.author.first_name} ${blog.author.last_name}`
                        : "Unknown Author"
                    }
                    label="New Post"
                    isBlog
                    style={{ flexGrow: 1 }}
                  />
                </SwiperSlide>
              ))}
          </Slider>
        </Box>

        {showSubscriptionBox && (
          <Container
            disableGutters
            sx={{ position: "relative", px: { xs: 0, md: "30px" } }}
          >
            <Box
              bgcolor="#026670"
              borderRadius={{ xs: 0, md: pxToRem(30) }}
              textAlign="center"
              py={{ xs: pxToRem(70), ssm: pxToRem(60) }}
              px={{ xs: "25px", sm: pxToRem(30) }}
              mt={pxToRem(40)}
              sx={{
                "@media (max-width: 1330px)": { mt: "120px" },
                "@media (max-width: 767px)": { mt: "80px" },
                "@media (max-width: 576px)": { mt: pxToRem(70) },
                height: "290px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box position="relative" zIndex={1}>
                <Typography
                  component="h3"
                  fontSize={subscribedSuccessfully ? pxToRem(28) : pxToRem(22)}
                  fontWeight={700}
                  color="#fff"
                  mb={subscribedSuccessfully ? 0 : pxToRem(20)}
                >
                  {subscribedSuccessfully
                    ? "You're in! Stay tuned for exciting updates!"
                    : "Subscribe to our newsletter:"}
                </Typography>

                {!subscribedSuccessfully && !isAuthenticated && (
                  <Box maxWidth={pxToRem(380)} m="auto">
                    <OutlinedInput
                      fullWidth
                      placeholder="Your Email Address"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      onFocus={() => setEmailError(false)}
                      sx={{
                        height: pxToRem(54),
                        border: emailError
                          ? "2px solid red"
                          : "2px solid #BFBEBB",
                        borderRadius: pxToRem(32),
                        outline: 0,
                        backgroundColor: "#167078",
                        mb: pxToRem(20),
                        "& input": {
                          textAlign: "center",
                          color: "#fff",
                          outline: 0,
                          "&::placeholder": {
                            fontSize: pxToRem(16),
                            color: "#BFBEBB",
                            fontWeight: 500,
                            opacity: 1,
                          },
                        },
                      }}
                    />
                    {emailError && (
                      <Typography
                        color="red"
                        fontSize={pxToRem(12)}
                        mb={pxToRem(5)}
                        textAlign="center"
                      >
                        Please enter a valid email address.
                      </Typography>
                    )}
                  </Box>
                )}

                {!subscribedSuccessfully && (
                  <Button
                    variant="yellow"
                    onClick={handleSubscribe}
                    sx={{
                      height: pxToRem(54),
                      position: "relative",
                      zIndex: 1,
                    }}
                    disabled={loading}
                  >
                    Subscribe
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
        )}
      </Box>
    </>
  );
};

export default BlogNews;
