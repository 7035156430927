import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link as NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import GradientAnimation from "../../components/GradientAnimation";
import { getFeaturedCourse } from "../../api/cmsApi";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import videoThumbnail from "../../images/video-thumbnail-1.jpg";
import { useState, useEffect, useRef } from "react";
import { getLanguageClass } from "../../utils/languageClass";
import { VideoPlayerProvider } from "../../context/VideoPlayerContext";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
const FeaturedCourse = ({ currentlyPlayingId, setCurrentlyPlayingId }) => {
  const customPosition = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [featuredCourse, setFeaturedCourse] = useState({
    course: {},
    description: "",
    button_label: "",
    button_link: "",
    target_new_tab: false,
  });
  const [isPlaying, setIsPlaying] = useState(false);

  const fetchFeaturedCourse = () => {
    getFeaturedCourse()
      .then((res) => {
        setFeaturedCourse(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchFeaturedCourse();
  }, []);

  const handlePlayClick = () => {
    setCurrentlyPlayingId((prevId) =>
      prevId === featuredCourse?.course?.id ? null : featuredCourse?.course?.id
    );
  };

  useEffect(() => {
    if (currentlyPlayingId === featuredCourse?.course?.id) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [currentlyPlayingId]);

  return (
    <Box
      position="relative"
      sx={{
        "@media(max-width: 767px)": {
          "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            background:
              "linear-gradient(to bottom, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%)",
            zIndex: -1,
          },
        },
        mx: { xs: "-30px", md: 0 },
      }}
    >
      <Container disableGutters>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize={pxToRem(45)}
            lineHeight="35px"
            mb={{
              xs: "36px",
              sm: pxToRem(57.79),
              md: pxToRem(87.79),
              lg: pxToRem(110.79),
            }}
            noWrap
            pl={{ xs: pxToRem(30), md: 0 }}
          >
            <span className="highlighted">Featured</span> Course
          </Typography>
          <Box position="relative">
            <GradientAnimation id="gradient-canvas-featured-courses" />
            <Grid
              container
              columnSpacing={{ lg: pxToRem(80), xs: pxToRem(40) }}
              sx={{ zIndex: 2, position: "relative" }}
            >
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <Box px={{ xs: "22px", md: 0 }}>
                  {isPlaying ? (
                    <Box
                      // className="featured-course-video-player"
                      sx={{
                        position: "relative",
                        backgroundColor: "black",
                        borderRadius: pxToRem(40),
                        maxWidth: pxToRem(550),
                        height: "100%",
                        // height: { xs: "216px", sssm: "330px", md: pxToRem(376) },
                        aspectRatio: "16/9",
                        overflow: "hidden",
                        border: {
                          xs: `8px solid #f7f6f2`,
                          ssm: `10px solid #f7f6f2`,
                        },
                      }}
                      m={{
                        xs: `auto auto ${pxToRem(-162)} auto`,
                        ssm: `auto auto ${pxToRem(-240)} auto`,
                        md: pxToRem(-30),
                        lg: `${pxToRem(-53)} 0 0 ${pxToRem(-48)}`,
                      }}
                    >
                      <VideoPlayerProvider>
                        <VideoPlayer
                          videoUrlCustom={
                            featuredCourse?.course?.video_preview_url
                          }
                          thumbnailCustom={featuredCourse?.course?.thumb_nail}
                          useCustomPlayer
                          customPosition={customPosition}
                          startsPlaying
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          isHomePage
                        />
                      </VideoPlayerProvider>
                    </Box>
                  ) : (
                    <Box
                      position="relative"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      maxWidth={pxToRem(550)}
                      height="100%"
                      // height={{ xs: "216px", sssm: "330px", md: pxToRem(376) }}
                      aspectRatio="16/9"
                      borderRadius={pxToRem(40)}
                      overflow="hidden"
                      border={{
                        xs: `8px solid #f7f6f2`,
                        ssm: `10px solid #f7f6f2`,
                      }}
                      m={{
                        xs: `auto auto ${pxToRem(-162)} auto`,
                        ssm: `auto auto ${pxToRem(-240)} auto`,
                        md: pxToRem(-30),
                        lg: `${pxToRem(-53)} 0 0 ${pxToRem(-48)}`,
                      }}
                    >
                      <NavLink
                        to="#"
                        style={{ position: "absolute", zIndex: 1 }}
                        onClick={handlePlayClick}
                      >
                        <PlayBtn
                          width={{ xs: "60px", sm: pxToRem(96) }}
                          height={{ xs: "60px", sm: pxToRem(96) }}
                          onClick={handlePlayClick}
                        >
                          <Play
                            color="#026670"
                            width={pxToRem(20)}
                            height={pxToRem(24)}
                            style={{ marginLeft: "6%" }}
                            onClick={handlePlayClick}
                          />
                        </PlayBtn>
                      </NavLink>
                      <Box position="absolute" width="0.1px">
                        <PlayBtn onClick={handlePlayClick} />
                      </Box>
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: pxToRem(30),
                          aspectRatio: "16/9",
                          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                        }}
                        src={
                          featuredCourse.course?.thumb_nail || videoThumbnail
                        }
                        alt="Course Thumbnail"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                className="rtl-direction"
                xs={12}
                md={6}
                order={{ xs: 1, md: 2 }}
              >
                <Box
                  sx={{
                    padding: {
                      xs: `${pxToRem(47)} 25px`,
                      sssm: `${pxToRem(47)} 30px`,
                      ssm: `${pxToRem(47)} 40px`,
                      md: `${pxToRem(47)} ${pxToRem(60)} ${pxToRem(90)} 0`,
                      lg: `${pxToRem(47)} ${pxToRem(124)} ${pxToRem(67.2)} 0`,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(42),
                      color: "#333333",
                      fontFamily: "PloniBold",
                      fontWeight: 700,
                      lineHeight: pxToRem(55),
                      marginBottom: pxToRem(6),
                    }}
                    component="h2"
                  >
                    {featuredCourse.course?.name}
                  </Typography>
                  <Typography variant="medium" component="p" />
                  <Typography
                    sx={{
                      fontSize: pxToRem(23),
                      color: "#333333",
                      fontFamily: "PloniBold",
                      fontWeight: 700,
                      lineHeight: pxToRem(30),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: featuredCourse.description,
                    }}
                    className={getLanguageClass(
                      featuredCourse.description,
                      true
                    )}
                  />

                  <Box sx={{ textAlign: "right", mt: pxToRem(54) }}>
                    <Button
                      variant="green"
                      onClick={() => {
                        window.open(
                          featuredCourse.button_link,
                          featuredCourse.target_new_tab ? "_blank" : "_self"
                        );
                      }}
                    >
                      {featuredCourse.button_label}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FeaturedCourse;
