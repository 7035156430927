import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Button,
  useMediaQuery,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useState, useEffect, forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import ModalHeader from "../../../components/ModalHeader";
import Toast from "../../../components/Toast";
import { editProfilePicture, getUserInfo } from "../../../api/student";
import { saveStudentLocal } from "../../../context/AuthContext";

import defaultAvatar from "../../../images/avatar.png";
import { useTheme } from "@emotion/react";
import { pxToRem } from "px2rem2px";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditProfilePicture({ open, setOpen, update }) {
  const userInfo = getUserInfo();

  const [isDefault, setIsDefault] = useState(false);
  const [preview, setPreview] = useState(userInfo?.avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) return;
    const file = acceptedFiles[0];
    setSelectedFile(file);

    setPreview(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  const handleClearImage = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    setPreview(defaultAvatar);
  };

  const handleCancel = () => {
    setOpen(false);
    setIsDefault(false);
    setPreview(userInfo?.avatar);
  };

  function handleSubmit() {
    setLoading(true);
    const formData = new FormData();
    if (selectedFile) {
      formData.append("avatar", selectedFile);
    } else if (isDefault) {
      formData.append("avatar", "");
    } else if (typeof preview === "string") {
      handleCancel();
      return;
    }

    editProfilePicture(formData)
      .then((res) => {
        setOpen(false);
        setOpenSuccess(true);
        setLoading(false);
        update();
      })
      .catch((err) => {
        console.log("err is", err);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (preview === "/static/media/avatar.898a860ff1d3c5692368.png") {
      setIsDefault(true);
    }
  }, [preview]);

  const applyChanges = () => {
    setOpenSuccess(false);

    // setTimeout(() => {
    saveStudentLocal().then((res) => {
      window.location.href = "/user/account-settings";
    });
    // }, 1000);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <>
      <Toast
        open={openSuccess}
        onClose={applyChanges}
        message="Your profile picture has been successfully updated!"
      />

      <Dialog
        fullWidth
        open={open}
        maxWidth="ssm"
        keepMounted
        scroll={mobile_ssm ? "paper" : "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiPaper-root": {
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            maxWidth: { xs: "100%", ssm: "500px", sm: "600px" },
            width: { xs: "100%", ssm: "500px", sm: "600px" },
            maxHeight: { xs: "85vh", ssm: "none" },
          },
        }}
      >
        <ModalHeader
          title="Change Profile Picture"
          inModal
          onClose={handleCancel}
        />

        <DialogContent sx={{ backgroundColor: "#f7f6f2" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: 200,
                height: 200,
              }}
            >
              <Box
                {...getRootProps()}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  overflow: "hidden",
                  cursor: "pointer",
                  backgroundImage: preview ? `url(${preview})` : "none",
                  backgroundColor: preview ? "transparent" : "#ddd",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",

                  "&:hover .hover-overlay": {
                    opacity: 1,
                  },

                  ...(isTablet && {
                    "& .hover-overlay": {
                      opacity: 1,
                    },
                  }),
                }}
              >
                <input {...getInputProps()} />

                <Box
                  className="hover-overlay"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "50%",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    opacity: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    textAlign: "center",
                    transition: "opacity 0.3s ease",
                  }}
                >
                  <Typography variant="body1">
                    {isDragActive
                      ? "Drop the image here ..."
                      : isTablet
                      ? "click to upload a new image"
                      : "Drag and drop or click to upload an image"}
                  </Typography>
                </Box>
              </Box>

              {preview && !isDefault && (
                <IconButton
                  onClick={(e) => {
                    handleClearImage(e);
                  }}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                    zIndex: 2,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
                padding: { xs: "30px 0", ssm: "30px" },
                backgroundColor: "#f7f6f2",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  mt: pxToRem(15),
                  width: isMobile ? pxToRem(150) : pxToRem(120),
                  fontWeight: 500,
                }}
                size="small"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="green"
                sx={{
                  mt: pxToRem(15),
                  width: isMobile ? pxToRem(150) : pxToRem(120),
                  fontWeight: 500,
                }}
                disabled={loading}
                size="small"
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
