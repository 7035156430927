import { useState, useEffect } from "react";
export default function useDebounce(delay = 500) {
  const [value, setValue] = useState("");
  const [valueQuery, setValueQuery] = useState("");

  useEffect(() => {
    const delayFn = setTimeout(() => setValue(valueQuery), delay);
    return () => clearTimeout(delayFn);
  }, [valueQuery, delay]);

  return [value, setValueQuery];
}
