import { Box, Stack, styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import React, { useEffect, useRef, useState } from "react";
import LikeBtn from "../../components/courses/LikeBtn";
import { useAuth } from "../../context/AuthContext";

import { IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Toast from "../../components/Toast";
import Likes from "../../components/Likes";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Eye } from "../../images/eye.svg";
import IconBtnCircular from "../../components/IconBtnCircular";
import { ReactComponent as Share } from "../../images/share.svg";

import VideoPlayer from "../videoPlayer/VideoPlayer";

import { VideoPlayerProvider } from "../../context/VideoPlayerContext";
import { getLanguageClass } from "../../utils/languageClass";
import { useToastNotification } from "../../context/ToastNotificationContext";
const Duration = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #026670;
  border-radius: 0 5px 0 0;
  padding: 3px 11px;
  transition: 0.2s ease;
  opacity: 0;
  @media (max-width: 899px) {
    opacity: 1;
  }
`;

export default function ParshaCard({
  videoLink,
  duration,
  isPlaying,
  setIsPlaying,
  video,
  setCurrentlyPlayingId,
  onLikeClick,
  onFavoritelick,
  isHomePage,
  setOpenDownloadFeedback,
  setDownloadFeedbackMsg,
  setOpenSavedModeFeedback,
}) {
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const location = useLocation();
  const customPosition = useRef(null);

  const handlePlayClick = () => {
    setCurrentlyPlayingId((prevId) => (prevId === video.id ? null : video.id));
  };

  const { setToastNotificationOpen, setToastNotificationMessage } =
    useToastNotification();

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;

    navigator.clipboard
      .writeText(
        `${window.location.origin}/video/${video.id}?category=${videoLink.category}&group=${videoLink.group}&subgroup=${videoLink.subgroup}`
      )
      .then(() => {
        setToastNotificationOpen(true);
        setToastNotificationMessage("Link copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        setToastNotificationOpen(true);
        setToastNotificationMessage("Failed to copy link to clipboard");
      });
    return;
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          position="relative"
          bgcolor="#fff"
          borderRadius={pxToRem(20)}
          sx={{
            transition: "0.2s ease",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            "&::before": {
              content: "''",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: `calc(100% - ${pxToRem(40)})`,
              height: "100%",
              backgroundColor: "#FCE181",
              borderRadius: pxToRem(20),
              transform: "translate(-50%,-50%)",
              zIndex: -1,
              transition: "0.2s ease",
            },
            "& .play-btn, .share-btn": {
              position: "static",
              opacity: 0,
              transition: "0.2s ease",
              zIndex: 1,
            },
            "&:hover": {
              boxShadow: {
                xs: "none",
                sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
              },
              "&::before": {
                height: `calc(100% + ${pxToRem(40)})`,
              },
              "& .slideTitle": {
                color: "primary.main",
              },
              "& .play-btn, .duration, .share-btn": {
                opacity: 1,
              },
            },
          }}
        >
          <Box
            ref={customPosition}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {isPlaying ? (
              <Box
                className="parsha-player-video"
                sx={{
                  position: "relative",
                  width: "100%",
                  aspectRatio: "16/9",
                  backgroundColor: "black",
                  borderRadius: "1.25rem 1.25rem 0 0",
                }}
              >
                <VideoPlayerProvider key={video?.id}>
                  <VideoPlayer
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    selectedLesson={video}
                    thumbnailCustom={video?.thumb_nail}
                    customPosition={customPosition}
                    isVideoLibrary={true}
                    videoLink={videoLink}
                    setOpenDownloadFeedback={setOpenDownloadFeedback}
                    setDownloadFeedbackMsg={setDownloadFeedbackMsg}
                    setOpenSavedModeFeedback={setOpenSavedModeFeedback}
                    useCustomPlayer
                    useParshaPlayer
                    startsPlaying
                    isHomePage
                  />
                </VideoPlayerProvider>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  "& > *": { gridRow: "1 / -1", gridColumn: "1 / -1" },
                  position: "relative",
                }}
              >
                <PlayBtn
                  width={pxToRem(96)}
                  height={pxToRem(96)}
                  sx={{ backgroundColor: "#fff" }}
                  onClick={handlePlayClick}
                >
                  <Play
                    color="#026670"
                    width={pxToRem(20)}
                    height={pxToRem(24)}
                    style={{ marginLeft: "6%", pointerEvents: "none" }}
                  />
                </PlayBtn>
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
                  width="100%"
                  sx={{ aspectRatio: "16/9", overflow: "hidden" }}
                >
                  {!isHomePage ? (
                    <Stack position="absolute" top="20px" gap="5px" left="20px">
                      <LikeBtn
                        courseId={video?.id}
                        isFavorite={video?.is_favorite}
                        onFavoriteClick={() => onFavoritelick(video?.id)}
                        sx={{
                          position: "static",
                          width: pxToRem(48),
                          height: pxToRem(48),
                        }}
                      />
                      <Box onClick={handleShareClick}>
                        <IconBtnCircular
                          className="hover-green share-btn"
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            borderRadius: "50%",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#026670",
                          }}
                        >
                          <Share
                            color="#FCE181"
                            style={{ marginRight: pxToRem(1.5) }}
                          />
                        </IconBtnCircular>
                      </Box>
                    </Stack>
                  ) : (
                    <></>
                  )}

                  <Duration className="duration">{duration}</Duration>
                  <img
                    src={video?.thumb_nail}
                    alt={video?.title}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box
              p={{ xs: pxToRem(25), sm: "26px 36px" }}
              bgcolor="#fff"
              borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
              overflow="hidden"
              display="flex"
              flexDirection="column"
              flexGrow={1}
              justifyContent="space-between"

            >
              <Typography
                className={`slideTitle ${getLanguageClass(
                  video?.title,
                  false,
                  true
                )}`}
                fontSize={pxToRem(26)}
                fontWeight={700}
                lineHeight={pxToRem(30)}
                color="secondary.main"
                mb={pxToRem(12)}

                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontFamily: "PloniBold",
                  wordBreak: "normal", 
                  overflowWrap: "break-word",
                  minHeight: pxToRem(60), 
                }}
              >
                {video?.title}
              </Typography>

              {/* Bottom Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: pxToRem(24),
                  // Removed height:"100%" and mt:"auto"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    mt: "2px",
                  }}
                >
                  <Stack flexDirection="row" flexWrap="wrap">
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                      noWrap
                    >
                      Posted {new Date(video?.uploaded_at).toLocaleDateString()}
                    </Typography>
                  </Stack>
                </Box>

                <Stack flexDirection="row" alignItems="center" gap="16px">
                  {/*  */}
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    columnGap={pxToRem(8)}
                  >
                    <Typography
                      fontSize={pxToRem(14)}
                      fontWeight={600}
                      color={"#BFBEBB"}
                      mt={pxToRem(1)}
                    >
                      {video?.views}
                    </Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      marginBottom={pxToRem(3)}
                    >
                      <Eye width={pxToRem(17)} height={pxToRem(17)} />
                    </Stack>
                  </Stack>


                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end", // aligns items by their bottom edge
                      columnGap: pxToRem(8),
                      backgroundColor: "green",
                      mb: "4px",
                    }}
                  >
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                      whiteSpace="nowrap"
                    >
                      {video?.views}
                    </Typography>
                    <Eye width={pxToRem(20)} height={pxToRem(20)} />
                  </Box> */}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                    }}
                  >
                    <Box sx={{ marginLeft: "-3px" }}>
                      <Likes
                        number={video?.likes}
                        isLiked={video?.is_liked}
                        onClick={(e) => onLikeClick(video?.id, e)}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
