import { Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import { ReactComponent as Close } from "../images/close.svg";
import { getLanguageClass } from "../utils/languageClass";

const ModalHeader = ({
  isFeedBack,
  title,
  inModal,
  onClose,
  children,
  classes,
  isCheckout,
}) => (
  <Stack
    position="relative"
    bgcolor="#EDECE8"
    p={
      isFeedBack
        ? {
            xs: `${pxToRem(30)} ${pxToRem(25)}`,
            ssm: `${pxToRem(40)} ${pxToRem(40)}`,
            sm: `${pxToRem(40)} ${pxToRem(60)}`,
          }
        : {
            xs: `${pxToRem(30)}`,
            ssm: `${pxToRem(40)} ${pxToRem(50)} ${pxToRem(40)}`,
          }
    }
    sx={{ "& button": { boxShadow: "none" }, ...classes?.headerContainer }}
  >
    <Stack
      direction="row"
      columnGap={pxToRem(20)}
      alignItems="center"
      justifyContent={isCheckout ? "flex-end" : "space-between"}
    >
      <Typography
        fontSize={{ xs: pxToRem(25), sm: pxToRem(30) }}
        fontWeight="bold"
        sx={{ wordBreak: "break-word", ...classes?.title }}
        // sx={{ marginLeft: isFeedBack ? "22%" : "inherit" }}
        className={getLanguageClass(title, false, true)}
        fontFamily="PloniBold"
      >
        {title}
      </Typography>
      {inModal && (
        <IconBtnCircular
          onClick={onClose}
          sx={{
            width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
            height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
          }}
        >
          <Close color="#026670" />
        </IconBtnCircular>
      )}
    </Stack>
    {children}
  </Stack>
);

export default ModalHeader;
