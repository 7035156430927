import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


import {
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Typography,
  ListItemText,
  ListItem,
  Divider,
  Button,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { Box } from "@mui/system";

import { formatTimeAgo } from "../utils/format";
import { getLanguageClass } from "../utils/languageClass";

// Mock implementation of clearAllNotifications
const clearAllNotifications = async () => {
  console.log("All notifications cleared");
};

const getNotificationLabel = (notification) => {
  if (notification.notification_class === "invitation") {
    return "Invitation";
  } else {
    return "Course";
  }
};

const NotificationItem = ({
  notification,
  onClick,
  isLast,
  isProfileMenu,
  label,
}) => (
  <Box
    onClick={onClick}
    sx={{
      borderBottomLeftRadius: isProfileMenu ? (isLast ? "8px" : 0) : 0,
      borderBottomRightRadius: isProfileMenu ? (isLast ? "8px" : 0) : 0,

      width: isProfileMenu ? "220px" : "320px",

      padding: isProfileMenu ? "0px 20px" : "0px 20px 0 40px",
      "&:hover": {
        backgroundColor: "#f5f5f5",
        cursor: "pointer",
        ".delete-icon": {
          visibility: "visible",
          opacity: 1,
        },
        "& .notification-change": {
          color: "#026670",
        },
      },
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderTop: "1px solid #f5f5f5",
        padding: "5px 0px",
      }}
    >
      <Box
        sx={{
          width: "70%",
        }}
      >
        <Typography
          className="notification-change"
          sx={{
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 600,
            color: "#333333",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            lineClamp: 1,
          }}
        >
          {notification.title}
        </Typography>

        <Typography
          className={`notification-change ${getLanguageClass(
            notification.message,
            true
          )}`}
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            lineClamp: 1,
            fontSize: "0.875rem",

            color: "#333333",
            fontWeight: 500,
          }}
        >
          {notification.message}
        </Typography>

        <Typography
          sx={{ whiteSpace: "nowrap" }}
          variant="caption"
          color="textSecondary"
        >
          {label}
          <Box component="span" sx={{ mx: 0.5 }}>
            •
          </Box>
          {formatTimeAgo(notification.created_at)}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "28%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            padding: isProfileMenu ? "3px 10px" : "7px 20px",
            border: "2px solid",
            borderRadius: "30px",
            backgroundColor: "transparent",
            fontSize: isProfileMenu ? "10px" : "12px",
            "&:hover": {
              border: "2px solid",
            },
            width: "100%",
          }}
        >
          View
        </Button>
      </Box>
    </Box>
  </Box>
);

const NotificationsDropdown = ({
  insideUserProfile,
  handleCloseParentMenu,
  insideDropDown,
  notifications,
  setNotifications,
  nextPage,
  setNextPage,
  hasMore,
  count,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedNotification, setExpandedNotification] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewMore = () => {
    if (hasMore) {
      setNextPage(nextPage + 1);
    }
  };

  const handleNotificationClick = async () => {
    try {
      await clearAllNotifications();
      setNotifications([]);
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
    navigate("/user/dashboard");
    if (handleCloseParentMenu) {
      handleCloseParentMenu(true);
    }
  };

  return (
    <>
      {insideUserProfile ? (
        <>
          {!insideDropDown ? (
            <Badge onClick={handleClick} badgeContent={count} color="secondary">
              Notifications
            </Badge>
          ) : (
            <>
              {notifications?.length === 0 ? (
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "center", padding: "10px" }}
                  >
                    No notifications available
                  </Typography>
                </Box>
              ) : (
                notifications?.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    onClick={handleNotificationClick}
                    isLast={index === notifications?.length - 1}
                    isProfileMenu
                  />
                ))
              )}
              {hasMore && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 0 3px 0",
                  }}
                >
                  <Button
                    sx={{
                      padding: "7px 20px",
                      border: "2px solid #e9e9e9",
                      borderRadius: "30px",
                      backgroundColor: "#e9e9e9",
                      color: "#333333",
                      fontSize: "12px",
                      "&:hover": {
                        border: "2px solid ",
                        color: "#333333",
                      },
                    }}
                    onClick={handleNotificationClick}
                  >
                    View All
                  </Button>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <IconButton color="inherit" onClick={handleClick}>
            <Badge badgeContent={count} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            disableScrollLock
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "220px",
                overflow: "visible",
                filter: "drop-shadow(0px 10px 20px rgba(0,0,0,0.16))",
                mt: 1.375,
                borderRadius: "10px",
                ".MuiList-root": {
                  li: {
                    p: 0,
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  },
                  hr: {
                    my: 1.875,
                  },
                },
              },
            }}
          >
            {notifications?.length === 0 ? (
              <Box sx={{ minWidth: "280px" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "center", padding: "10px" }}
                >
                  No notifications available
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    fontWeight: 700,
                    // pl: 5,
                    // pt:2,
                    padding: "15px 0 5px 0px",
                    color: "#333333",
                    marginLeft: "40px",
                  }}
                >
                  Notifications
                </Typography>
                {notifications?.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    onClick={handleNotificationClick}
                    isLast={index === notifications?.length - 1}
                    label={getNotificationLabel(notification)}
                  />
                ))}
              </Box>
            )}
            {hasMore && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 0 3px 0",
                }}
              >
                <Button
                  sx={{
                    padding: "7px 20px",
                    border: "2px solid #e9e9e9",
                    borderRadius: "30px",
                    backgroundColor: "#e9e9e9",
                    color: "#333333",
                    fontSize: "12px",
                    "&:hover": {
                      border: "2px solid ",
                      color: "#333333",
                    },
                  }}
                  onClick={handleNotificationClick}
                >
                  View All
                </Button>
              </Box>
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default NotificationsDropdown;
