import { Button, IconButton, Snackbar, useMediaQuery } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as Close } from "../images/close.svg";
import { useTheme } from "@emotion/react";

const Toast = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const getBackgroundColor = (color) => {
    switch (color) {
      case "red":
        return "#ff8f8f";
      case "green":
        return "#026670";
      case "yellow":
        return "#FCE181";
      default:
        return "#333333";
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={props.open}
      onClose={props.onClose}
      // autoHideDuration={props.button ? 4000 : 5000}
      autoHideDuration={
        !props.blockAutoHide ? (props.button ? 4000 : 5000) : undefined
      }
      message={props.message}
      ContentProps={{
        sx: {
          flexWrap: "nowrap",
        },
      }}
      sx={{
        zIndex: 9999,
        "& .MuiPaper-root": {
          maxWidth: mobile_ssm
            ? pxToRem(300)
            : tablet
            ? pxToRem(400)
            : undefined,
          minWidth: pxToRem(200),
          borderRadius: pxToRem(10),
          padding: `${pxToRem(8)} ${pxToRem(30)}`,
          transform: "scale(1.15) !important",
          backgroundColor: `${getBackgroundColor(
            props?.backgroundColor
          )} !important`,
        },
        "& .MuiSnackbarContent-message": {
          fontSize: pxToRem(12),
          fontWeight: 700,
          color: "#fff",
        },
        "& .MuiSnackbarContent-action": {
          gap: pxToRem(20),
        },
      }}
      action={
        <>
          {props.button ? (
            <Button
              onClick={props.onUndo}
              sx={{
                color: "#fff",
                fontSize: pxToRem(12),
                fontWeight: 500,
                "&:hover": { color: "#FCE181" },
              }}
            >
              {props.button}
            </Button>
          ) : null}

          {!props?.noClose && (
            <IconButton
              onClick={props.onClose}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": { backgroundColor: "rgba(255,255,255,0.3)" },
              }}
            >
              <Close color="#fff" width={pxToRem(14)} height={pxToRem(14)} />
            </IconButton>
          )}
        </>
      }
    />
  );
};

Toast.defaultProps = {
  backgroundColor: "default",
};

export default Toast;
