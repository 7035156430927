import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Row from "./Row";

const PurchaseTable = ({ subscriptionsHistory }) => {
  return (
    <TableContainer
      sx={{
        maxHeight: 440,
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#ccc",
        },
      }}
    >
      <Table stickyHeader>
        <TableHead sx={{ display: { xs: "none", sm: "table-header-group" } }}>
          <TableRow
            sx={{
              "& th": {
                color: "#026670",
                fontSize: "16px",
                fontWeight: 700,
                pt: 0,
              },
            }}
          >
            <TableCell align="left" sx={{ minWidth: "160px", pl: 0 }}>
              Course
            </TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Discount</TableCell>
            <TableCell align="center">Expiration</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& tr:first-of-type td": { pt: { xs: 0, sm: "16px" } },
            "& tr:last-of-type td": { borderBottom: "unset" },
          }}
        >
          {subscriptionsHistory.length > 0 &&
            subscriptionsHistory.map((row, idx) => (
              <Row key={row.id} details={row} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseTable;
