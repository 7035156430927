import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { pxToRem } from "px2rem2px";
import { getLanguageClass } from "../utils/languageClass";
import { useNavigate } from "react-router-dom";

const ContactCard = ({ cardsDetails, gridSm, gridLg, scrollToForm }) => {
  const navigate = useNavigate();
  return (
    <>
      {cardsDetails.map((cardDetails, index) => (
        <Grid key={index} item sm={gridSm} lg={gridLg}>
          <Stack
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: pxToRem(20),
              padding: {
                xs: `${pxToRem(40)} ${pxToRem(30)}`,
                ssm: `${pxToRem(50)} ${pxToRem(45)}`,
                md: pxToRem(50),
              },
              textAlign: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  width: pxToRem(72),
                  height: pxToRem(72),
                  borderRadius: cardDetails.rounded ? pxToRem(100) : "none",
                  overflow: "hidden",
                  m: "auto",
                }}
              >
                <img src={cardDetails.icon} alt={cardDetails.title} />
              </Box>
              <Typography
                variant="blockTitle"
                component="h3"
                fontSize={{ sm: pxToRem(22), md: pxToRem(25) }}
                py={pxToRem(17)}
              >
                {cardDetails.title}
              </Typography>
              <Typography
                variant="medium"
                component="p"
                dangerouslySetInnerHTML={{ __html: cardDetails.description }}
                className={getLanguageClass(cardDetails.description, true)}
              />
            </Box>
            <Box>
              <Button
                variant="outlined"
                size="small"
                sx={{ mt: { xs: pxToRem(20), ssm: pxToRem(30) } }}
                onClick={() => {
                  if (cardDetails.link) {
                    navigate(cardDetails.link);
                  } else if (cardDetails.scrollToForm) {
                    scrollToForm();
                  }
                }}
              >
                {cardDetails.btnName}
              </Button>
            </Box>
          </Stack>
        </Grid>
      ))}
    </>
  );
};

export default ContactCard;
