import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Fab,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";

import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import CoursesSection from "./CoursesSection";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import { useCallback, useEffect, useRef, useState } from "react";
import { pxToRem } from "px2rem2px";
import { listCourses, listCategories } from "../../api/course";
import { addFavorite, removeFavorite } from "../../api/course";

import { useAuth } from "../../context/AuthContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { useCourses } from "../../context/CoursesContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getLanguageClass } from "../../utils/languageClass";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReactComponent as Bookmarks } from "../../images/bookmarks.svg";
import BookmarksModal from "./BookmarksModal";
import { useModal } from "mui-modal-provider";
import LoginSubscribeModal from "../preSubscribe/LoginSubscribeModal";
import LoginModal from "../auth/LoginModal";
import Toast from "../../components/Toast";
import { useNotificationSettings } from "../../hooks/useNotificationSettings";

const SidebarCourses = ({
  categories,
  subscribedCourses,
  latestCourses,
  handleCategoryClick,
  isSmallScreen,
  isMediumScreen,
  selectedCategory,
  categoriesLoading,
  latestCoursesLoading,
  setIsExpanded,
  handleOpen,
  setTurnOnNotificationFeedback,
}) => {
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const { showModal, hideModal } = useModal();

  const { notifications: fetchedNotifications, updateNotifications } =
    useNotificationSettings();

  const [notifications, setNotifications] = useState({});
  const [isNotificationOn, setIsNotificationOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turnedOnSuccessfully, setTurnedOnSuccessfully] = useState(false);

  useEffect(() => {
    if (fetchedNotifications && Object.keys(fetchedNotifications).length > 0) {
      console.log("fetchedNotifications", fetchedNotifications);
      setNotifications(fetchedNotifications);
      setIsNotificationOn(fetchedNotifications.new_course);
    }
  }, [fetchedNotifications]);

  const handleGetNotified = async () => {
    setLoading(true);
    if (isAuthenticated) {
      try {
        const updatedPreferences = {
          ...notifications,
          new_course: true,
        };
        await updateNotifications({ preferences: updatedPreferences });
        setTurnOnNotificationFeedback(true);
        setTurnedOnSuccessfully(true);
      } catch (error) {
        console.log("Subscription failed:", error);
      }
      setLoading(false);
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal, {
            onRedirectActivateNotification: "new_course",
          });
        },
        loginMessage:
          "You need to log in first to enable notifications for new courses.",
        hideModal: () => hideModal(modal.id),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const activateAfterLogin = localStorage.getItem(
      "onRedirectActivateNotification"
    );
    if (activateAfterLogin && isAuthenticated) {
      localStorage.removeItem("onRedirectActivateNotification");
      handleGetNotified();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (turnedOnSuccessfully) {
      const timeout = setTimeout(() => {
        setIsNotificationOn(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [turnedOnSuccessfully]);

  return (
    <Box
      sx={{
        display: "flex",
        width: { xs: "100%", md: "180px" },
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "row"
          : "column",
        px: { xs: pxToRem(20), md: 0 },
        mt: { xs: pxToRem(20), lg: 0 },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <NavGroup
          title="Categories"
          itemsLength={categories.length}
          selectedCategory={selectedCategory}
          handleCategoryClick={handleCategoryClick}
          setIsExpanded={setIsExpanded}
        >
          {categoriesLoading
            ? Array.from(new Array(1)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: pxToRem(15),
                    fontWeight: 600,
                    color: "#333",
                    width: "100px",
                  }}
                />
              ))
            : categories.map((category) => (
                <MenuDropdownLink
                  to="#"
                  className={`overflow-ellipsis ${getLanguageClass(
                    category?.name,
                    true
                  )}`}
                  key={category?.id}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category?.name}
                </MenuDropdownLink>
              ))}
        </NavGroup>

        {isAuthenticated && subscribedCourses.length > 0 && (
          <NavGroup
            title="My Courses"
            itemsLength={subscribedCourses?.length}
            isSubscribedCourses
          >
            {subscribedCourses.map((course) => (
              <MenuDropdownLink
                key={course.slug}
                to={`/course/${course.slug}`}
                className={`overflow-ellipsis ${getLanguageClass(
                  course?.name,
                  true
                )}`}
              >
                {course.name}
              </MenuDropdownLink>
            ))}
          </NavGroup>
        )}

        {(latestCoursesLoading || latestCourses?.length > 0) && (
          <NavGroup title="Recently Added" itemsLength={latestCourses.length}>
            {latestCoursesLoading
              ? Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="text"
                    animation="wave"
                    height={20}
                  />
                ))
              : latestCourses.map((course) => (
                  <MenuDropdownLink
                    key={course.slug}
                    to={`/course/${course.slug}`}
                    className={`overflow-ellipsis ${getLanguageClass(
                      course?.name,
                      true
                    )}`}
                  >
                    {course.name}
                  </MenuDropdownLink>
                ))}
          </NavGroup>
        )}
      </Box>

      <Box display={{ xs: "none", md: "block" }}>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={600}
          mb="20px"
        >
          Your Bookmarks
        </Typography>
        <Button
          fullWidth
          variant="green"
          size="small"
          sx={{
            backgroundColor: "#026670",
            color: "#FCE181",
            fontSize: "14px !important",
          }}
          onClick={handleOpen}
        >
          <Bookmarks style={{ marginRight: "8px" }} />
          Bookmarks
        </Button>
      </Box>

      <Divider
        sx={{
          my: { xs: "20px", md: pxToRem(30) },
          display: { xs: "none", md: "block" },
        }}
      />

      {!isMediumScreen && !isNotificationOn && (
        <>
          <Box>
            <Typography
              fontSize="14px"
              lineHeight="18px"
              fontWeight={600}
              mb="20px"
            >
              Receive notifications for new courses!
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                fontSize: "14px !important",
                "&:hover .MuiSvgIcon-root": {
                  color: "#FCE181",
                  transition: "all 200ms ease-out",
                },
              }}
              onClick={turnedOnSuccessfully ? () => {} : handleGetNotified}
              disabled={loading}
            >
              <NotificationsIcon
                sx={{
                  color: "#026670",
                  mr: "8px",
                  width: "26px",
                  height: "30px",
                }}
              />
              {turnedOnSuccessfully ? "Notification On" : "Get Notified"}
            </Button>
          </Box>
          <Divider sx={{ my: { xs: "20px", md: pxToRem(30) } }} />
        </>
      )}

      {!isMediumScreen && (
        <Box
          sx={{
            marginLeft: isSmallScreen ? "0px" : isMediumScreen ? "30px" : "0",
          }}
        >
          <Stack
            gap="15px"
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "row" : "column",
              justifyContent: "center",
              "& a": { fontSize: "14px" },
            }}
          >
            <MenuDropdownLink to="/">Account</MenuDropdownLink>
            <MenuDropdownLink to="/">FAQ</MenuDropdownLink>
            <MenuDropdownLink to="/">Help</MenuDropdownLink>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

const Courses = () => {
  useEffect(() => {
    document.title = "Explore Courses - Reb Yakov Moshe";
  }, []);
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width:1227px)");
  const isMediumScreen = useMediaQuery("(max-width:899px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const {
    courses,
    setCourses,
    activeCourses,
    setActiveCourses,
    page,
    setPage,
    selectedCategory,
    setSelectedCategory,
  } = useCourses();

  const [isLoading, setIsLoading] = useState(false);
  const [subscribedCourses, setSubscribedCourses] = useState([]);
  const [latestCourses, setLatestCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [courseCount, setCoursesCount] = useState("");
  const [isOnSearchMode, setIsOnSearchMode] = useState(false);
  const [activeCoursesPage, setActiveCoursesPage] = useState(1);
  const [hasMoreActive, setHasMoreActive] = useState(true);
  const [filterMode, setFilterMode] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [subscribedCoursesPageSize, setSubscribedCoursesPageSize] =
    useState(100);
  const [countLoading, setCountLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // sidebar
  const [latestCoursesLoading, setLatestCoursesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [turnOnNotificationFeedback, setTurnOnNotificationFeedback] =
    useState(false);

  // courses
  const observer = useRef();
  const observerActive = useRef();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [activeCoursesErrorOccurred, setActiveCoursesErrorOccurred] =
    useState(false);

  const lastCourseElementRef = useCallback(
    (node) => {
      if (isLoading || errorOccurred) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("Last course is visible, loading more courses...");
            setPage((prevPage) => prevPage + 1);
            setIsLoading(true);
          }
        },
        {
          threshold: 0.5,
        }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, errorOccurred, hasMore]
  );

  // active courses

  useEffect(() => {
    if (!isAuthenticated) {
      setHasMoreActive(false);
    }
  }, [isAuthenticated]);

  const lastActiveCourseElementRef = useCallback(
    (node) => {
      if (isLoading || activeCoursesErrorOccurred) return;
      if (observerActive.current) observerActive.current.disconnect();
      observerActive.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMoreActive) {
            console.log(
              "Last active course is visible, loading more active courses..."
            );
            setActiveCoursesPage((prevPage) => prevPage + 1);
            setIsLoading(true);
          }
        },
        {
          threshold: 0.5,
        }
      );
      if (node) observerActive.current.observe(node);
    },
    [isLoading, activeCoursesErrorOccurred, hasMoreActive]
  );

  const fetchCourses = () => {
    const searchParam = isOnSearchMode ? searchQuery : "";
    setIsLoading(true);
    listCourses(
      4,
      page,
      searchParam,
      selectedCategory,
      selectedCategory || isOnSearchMode ? "" : false,
      "",
      ""
    )
      .then((res) => {
        if (res?.data?.results) {
          setHasMore(!!res.data.next);
          if (selectedCategory || searchParam) {
            setCoursesCount(res.data.count);
          }

          if (hasMoreActive) return;

          setCourses((prevCourses) => {
            const uniqueCourses = [
              ...new Map(
                [...prevCourses, ...res?.data?.results].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            return uniqueCourses;
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setErrorOccurred(true);
      });
  };

  useEffect(() => {
    if (isOnSearchMode) {
      setCourses([]);
      setPage(1);
      setSelectedCategory("");

      setActiveCourses([]);
      setHasMoreActive(false);
    }
    if (hasMoreActive) {
      return;
    }
    console.log(
      "Fetching courses for page:",
      page,
      "search:",
      searchQuery,
      "category:",
      selectedCategory
    );

    fetchCourses();
  }, [page, searchQuery, selectedCategory, isOnSearchMode, hasMoreActive]);

  useEffect(() => {
    loadCourses(100);
  }, [subscribedCoursesPageSize]);

  const loadCourses = async (page) => {
    if (isFetching) {
      console.log("loadCourses called while fetching, returning early");
      return;
    }

    console.log("loadCourses called with page:", page);
    setIsFetching(true);
    setIsLoading(true);

    try {
      const res = await listCourses(
        subscribedCoursesPageSize,
        1,
        "",
        "",
        "true"
      );
      console.log("API response received:", res);

      if (res?.data?.results) {
        console.log("API response data:", res.data.results);

        const sortedCourses = res.data.results.sort((a, b) => {
          return (
            new Date(b.user_subscription.start_date) -
            new Date(a.user_subscription.start_date)
          );
        });

        console.log("listcoursesdjnddjn unsortedcourses", res.data.results);
        console.log("listcoursesdjnddjn sortedCourses", sortedCourses);

        setSubscribedCourses(sortedCourses);
        setTotalCourses(res?.data?.count || 0);
      }
    } catch (err) {
      console.error("API error:", err);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  // fetch categories

  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesLoading(true);

      try {
        const res = await listCategories();

        if (res?.data?.results) {
          setCategories(res?.data?.results);
          console.log("categories", res.data.results);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // fetch latest courses
  useEffect(() => {
    const fetchLatestCourses = async () => {
      setLatestCoursesLoading(true);

      const currentDate = new Date();
      const startDate = new Date();
      startDate.setDate(currentDate.getDate() - 60);

      const formattedStartDate = startDate.toISOString().slice(0, 10);
      const formattedEndDate = currentDate.toISOString().slice(0, 10);

      try {
        const res = await listCourses(
          4,
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          formattedStartDate,
          formattedEndDate
        );

        if (res?.data?.results) {
          setLatestCourses(res?.data?.results);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLatestCoursesLoading(false);
      }
    };

    fetchLatestCourses();
  }, []);

  // start: active courses

  useEffect(() => {
    if (filterMode || isOnSearchMode || !isAuthenticated) {
      setHasMoreActive(false);
      return;
    } else {
      setCourses([]);
    }

    setIsLoading(true);

    listCourses(4, activeCoursesPage, "", "", true, "", "")
      .then((res) => {
        if (res?.data?.results) {
          setActiveCourses((prevCourses) => {
            const uniqueCourses = [
              ...new Map(
                [...prevCourses, ...res?.data?.results].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            return uniqueCourses;
          });
          setHasMoreActive(!!res.data.next);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("activeCoerrursesPage", err);
        setIsLoading(false);
        setActiveCoursesErrorOccurred(true);
      });
  }, [activeCoursesPage, filterMode, isOnSearchMode]);

  // end: active courses

  // get count

  useEffect(() => {
    setCountLoading(true);

    listCourses(1, 1)
      .then((res) => {
        if (res?.data?.results && !filterMode && !isOnSearchMode) {
          setCoursesCount(res.data.count);
        }
        setCountLoading(false);
      })
      .catch((err) => {
        console.log("activeCoerrursesPage", err);
        setCountLoading(false);
      });
  }, [filterMode, isOnSearchMode]);

  useEffect(() => {
    if (!isOnSearchMode && isAuthenticated) {
      setHasMoreActive(true);
      setCourses([]);
    }
  }, [isOnSearchMode]);

  // get count end

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryClick = (category) => {
    setCoursesCount("");
    setPage(1);
    setCourses([]);
    setActiveCourses([]);
    setFilterMode(true);
    setHasMoreActive(false);
    setSelectedCategory(parseInt(category, 10));

    if (isMediumScreen) {
      const coursesTitle = document.getElementById("courses-title");
      if (coursesTitle) {
        setTimeout(() => {
          coursesTitle.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  };

  const handleFavoriteClick = (courseId) => {
    let course = courses.find((c) => c.id === courseId);
    let whichList = "courses";

    if (!course) {
      course = activeCourses.find((c) => c.id === courseId);
      whichList = "activeCourses";
    }

    if (!course) {
      console.error(
        "Course not found in either `courses` or `activeCourses`:",
        courseId
      );
      return;
    }

    console.log(
      "handleFavoriteClick -> found course in:",
      whichList,
      "with ID:",
      courseId
    );

    if (course.is_favorite) {
      removeFavorite(courseId)
        .then(() => {
          console.log("Favorite bookmark removed successfully");
          if (whichList === "courses") {
            setCourses((prevCourses) =>
              prevCourses.map((c) =>
                c.id === courseId ? { ...c, is_favorite: false } : c
              )
            );
          } else {
            setActiveCourses((prevActiveCourses) =>
              prevActiveCourses.map((ac) =>
                ac.id === courseId ? { ...ac, is_favorite: false } : ac
              )
            );
          }
        })
        .catch((err) => {
          console.error("Error removing favorite:", err);
        });
    } else {
      addFavorite(courseId)
        .then(() => {
          console.log("Favorite bookmark added successfully");
          if (whichList === "courses") {
            setCourses((prevCourses) =>
              prevCourses.map((c) =>
                c.id === courseId ? { ...c, is_favorite: true } : c
              )
            );
          } else {
            setActiveCourses((prevActiveCourses) =>
              prevActiveCourses.map((ac) =>
                ac.id === courseId ? { ...ac, is_favorite: true } : ac
              )
            );
          }
        })
        .catch((err) => {
          console.error("Error adding favorite:", err);
        });
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [openBookmarks, setOpenBookmarks] = useState(false);
  const { showModal, hideModal } = useModal();

  const handleOpen = () => {
    if (isAuthenticated) {
      setOpenBookmarks(true);
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
        },
        loginMessage: "You need to log in first to see your bookmarks.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  const handleClose = () => {
    setOpenBookmarks(false);
  };

  return (
    <>
      <Toast
        open={turnOnNotificationFeedback}
        onClose={() => {
          setTurnOnNotificationFeedback(false);
        }}
        message={"Notification for new courses turned on successfully!"}
      />
      <Box position="relative">
        {mobile_ssm && (
          <Box>
            <Fab
              size="small"
              aria-label="scroll back to top"
              onClick={scrollToTop}
              sx={{
                position: "fixed",
                bottom: 17,
                right: 17,
                zIndex: 50,
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                boxShadow: "0px 10px 20px #00000012",
                backgroundColor: "#FCE181",
                color: "#026670",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#026670",
                  color: "#FCE181",
                },
              }}
            >
              <KeyboardArrowUpIcon style={{ width: "28px", height: "28px" }} />
            </Fab>
          </Box>
        )}
        <Hero
          title="Courses"
          onSearch={handleSearch}
          courses={courses}
          setCourses={setCourses}
          setIsOnSearchMode={setIsOnSearchMode}
          isOnSearchMode={isOnSearchMode}
          setPage={setPage}
          setHasMoreActive={setHasMoreActive}
          setFilterMode={setFilterMode}
          sx={{
            marginBottom: { xs: pxToRem(63), md: pxToRem(100) },
            // overflow: isLargeScreen ? "visible" : "hidden",
          }}
        />

        <Box position="relative">
          <Box
            sx={{
              width: { xs: "100%", md: "90%" },
              margin: isMediumScreen ? "auto" : undefined,
            }}
          >
            <Stack
              flexDirection={isMediumScreen ? "column" : "row"}
              justifyContent="space-between"
              gap="5%"
              mb="100px"
              width="100%"
              maxWidth="1367px"
              position="relative"
            >
              {mobile_ssm && <Box sx={{ height: "70px" }}></Box>}
              <Sidebar
                display="flex"
                sx={{
                  gap: "0",
                  padding: isMediumScreen ? "20px 25px" : pxToRem(50),
                  position: { xs: "absolute", ssm: "static" },
                  zIndex: { xs: 3, ssm: 0 },
                  mx: { ssm: "25px", md: 0 },
                }}
              >
                {isMediumScreen ? (
                  <>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: "pointer" }}
                      onClick={handleToggle}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <FilterAltIcon
                          sx={{ width: pxToRem(33), height: pxToRem(33) }}
                        />
                        <Typography sx={{ fontWeight: 600, fontSize: "17px" }}>
                          Filters
                        </Typography>
                      </Stack>
                      <KeyboardArrowDownIcon
                        sx={{
                          width: pxToRem(31),
                          height: pxToRem(31),
                          transition: "all 300ms ease-in-out",
                          transform: isExpanded
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                        }}
                      />
                    </Stack>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <SidebarCourses
                        categories={categories}
                        subscribedCourses={subscribedCourses}
                        latestCourses={latestCourses}
                        handleCategoryClick={handleCategoryClick}
                        isAuthenticated={isAuthenticated}
                        isSmallScreen={isSmallScreen}
                        isMediumScreen={isMediumScreen}
                        selectedCategory={selectedCategory}
                        categoriesLoading={categoriesLoading}
                        latestCoursesLoading={latestCoursesLoading}
                        setIsExpanded={setIsExpanded}
                        handleOpen={handleOpen}
                        setTurnOnNotificationFeedback={
                          setTurnOnNotificationFeedback
                        }
                      />
                    </Collapse>
                  </>
                ) : (
                  <SidebarCourses
                    categories={categories}
                    subscribedCourses={subscribedCourses}
                    latestCourses={latestCourses}
                    handleCategoryClick={handleCategoryClick}
                    isAuthenticated={isAuthenticated}
                    isSmallScreen={isSmallScreen}
                    isMediumScreen={isMediumScreen}
                    selectedCategory={selectedCategory}
                    categoriesLoading={categoriesLoading}
                    latestCoursesLoading={latestCoursesLoading}
                    handleOpen={handleOpen}
                    setTurnOnNotificationFeedback={
                      setTurnOnNotificationFeedback
                    }
                  />
                )}
              </Sidebar>
              <Box
                mx={{ xs: "25px", md: 0 }}
                mt={{ xs: "20px", md: 0 }}
                marginBottom={{ xs: pxToRem(63), ssm: pxToRem(94), md: 0 }}
                display={{ md: "none" }}
              >
                <Button
                  fullWidth
                  variant={isMediumScreen ? "outlined" : "green"}
                  size="small"
                  sx={{
                    // backgroundColor: "#026670",
                    // color: "#FCE181",
                    borderWidth: `${pxToRem(3)} !important`,
                    fontSize: "14px !important",
                    transition: "all",
                    "&:hover svg": {
                      color: "#FCE181 !important",
                    },
                  }}
                  onClick={handleOpen}
                >
                  <Bookmarks
                    style={{
                      marginRight: "8px",
                      color: isMediumScreen ? "#026670" : "#FCE181",
                    }}
                  />
                  Bookmarks
                </Button>
              </Box>
              <Box
                padding={{ xs: "0 25px", md: "0" }}
                flex={1}
                width={isMediumScreen ? "100%" : "65%"}
              >
                {isAuthenticated ? <CoursesSlider type="course" /> : <></>}
                <CoursesSection
                  activeCourses={activeCourses}
                  courses={courses}
                  onFavoriteClick={handleFavoriteClick}
                  isAuthenticated={isAuthenticated}
                  lastCourseElementRef={lastCourseElementRef}
                  lastActiveCourseElementRef={lastActiveCourseElementRef}
                  loading={isLoading}
                  courseCount={courseCount}
                  isOnSearchMode={isOnSearchMode}
                  categoriesList={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setCourses={setCourses}
                  setPage={setPage}
                  setHasMoreActive={setHasMoreActive}
                  setFilterMode={setFilterMode}
                  setActiveCoursesPage={setActiveCoursesPage}
                />
              </Box>
            </Stack>
          </Box>
        </Box>

        <Container disableGutters>
          <ContentSlider page={"Courses"} />
        </Container>
      </Box>

      <BookmarksModal
        open={openBookmarks}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </>
  );
};

export default Courses;
