import { Box, Button, Stack, Skeleton, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Course from "../../../components/user/Course";
import { Link } from "react-router-dom";

import { ReactComponent as Chevron } from "../../../images/chevron.svg";
import { ReactComponent as FilterMenu } from "../../../images/filter-menu.svg";
import { ReactComponent as Arrows } from "../../../images/down-arrow.svg";
import { useEffect, useState } from "react";
import { listCourses } from "../../../api/course";



const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false); 
 
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const res = await listCourses("", page, "", "", true, "", "");
        if (res?.data?.results) {
          setCourses(res?.data?.results);
        }
      } catch (err) {
        console.log("activeCoursesPage error:", err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCourses();
  }, [page]);




  const renderSkeletons = (count = 3) => {
    return Array.from(new Array(count)).map((_, index) => (
      <Box key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={240}
          sx={{ borderRadius: "16px", marginBottom: pxToRem(10) }}
        />
      </Box>
    ));
  };

  return (
    <>
      <BlockHeading>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", ssm: "flex-end" }}
          gap={{ xs: "15px", ssm: "30px" }}
        >
          <Button
            startIcon={<FilterMenu />}
            endIcon={<Chevron />}
            sx={{
              color: "#333",
              fontSize: "14px",
              "& .MuiButton-endIcon": {
                ml: pxToRem(12),
                "& svg": { transform: "rotate(90deg)" },
              },
            }}
          >
            Active
          </Button>

          <Button
            startIcon={<Arrows />}
            endIcon={<Chevron />}
            sx={{
              color: "#333",
              fontSize: "14px",
              "& .MuiButton-endIcon": {
                ml: pxToRem(12),
                "& svg": { transform: "rotate(90deg)" },
              },
            }}
          >
            Date Subscribed
          </Button>
        </Stack>
      </BlockHeading>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, minmax(0, 1fr))",
            ssm: "repeat(3, minmax(0, 1fr))",
            lg: "repeat(3, minmax(0, 1fr))",
          },
          gap: "30px 20px",
          "@media(max-width: 575px)": {
            gap: "20px 11px",
          },
        }}
      >
        {loading ? (
          renderSkeletons()
        ) : courses?.length > 0 ? (
          courses.map((course) => (
            <Course
              key={course?.id}
              image={course?.thumb_nail}
              videoLink={`${window.location.origin}/course/${course?.slug}`}
              title={course.name}
              subscription={course?.user_subscription}
              subscribed={false}
              progress={course?.progress}
              isMyCourses
            />
          ))
        ) : (
          <Box 
            sx={{ 
              gridColumn: "1 / -1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "40px 0"
            }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 600, 
                marginBottom: "16px" 
              }}
            >
              Explore Your Courses
            </Typography>
            <Typography 
              variant="body1"
              sx={{ 
                maxWidth: "600px", 
                marginBottom: "32px",
                color: "#666" 
              }}
            >
              You're not subscribed to any courses yet. Discover and subscribe to courses that interest you, and start learning immediately!
            </Typography>
            <Button 
              component={Link} 
              to="/courses" 
              variant="green"
              size="small"
            >
              Explore Courses
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Courses;
