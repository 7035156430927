// import { useEffect, useState } from "react";
// import { Button, Divider, FormControl, Stack, Typography } from "@mui/material";
// import { pxToRem } from "px2rem2px";
// import BlockHeading from "../../../components/BlockHeading";
// import Switcher from "../../../components/Switcher";
// import {
//   getNotificationsSettings,
//   updateNotificationSettings,
// } from "../../../api/student";
// import Toast from "../../../components/Toast";

// const Notifications = () => {
//   const [notifications, setNotifications] = useState([]);
//   const [notificationsToggle, setNotificationsToggle] = useState(false); // Track all on/off

//   const [toastOpen, setToastOpen] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");

//   const fetchNotificationSettings = async () => {
//     try {
//       const res = await getNotificationsSettings();
//       setNotifications(res.data.preferences);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchNotificationSettings();
//   }, []);

//   const handleChange = async (event) => {
//     const { name, value, checked, type } = event.target;
//     const updatedNotifications = {
//       ...notifications,
//       [name]: type === "checkbox" ? checked : value,
//     };

//     setNotifications(updatedNotifications);

//     const updatedSettings = {
//       preferences: updatedNotifications,
//     };

//     try {
//       await updateNotificationSettings(updatedSettings);
//       setToastOpen(true);
//       setToastMessage("Changes saved successfully.");
//     } catch (error) {
//       console.log("Error updating notification settings:", error);
//       setToastOpen(true);
//       setToastMessage("Failed to save changes.");
//     }
//   };

//   const turnOffNotifications = async () => {
//     const updatedNotifications = {
//       updates_and_news: !notificationsToggle,
//       new_course: !notificationsToggle,
//       new_parsha_lecture: !notificationsToggle,
//       invitation_to_survey: !notificationsToggle,
//       rating_request: !notificationsToggle,
//       course_reminder: !notificationsToggle,
//       likes: !notificationsToggle,
//       trending: !notificationsToggle,
//     };

//     const updatedSettings = {
//       preferences: updatedNotifications,
//     };

//     setNotifications(updatedNotifications);

//     setNotificationsToggle(!notificationsToggle);

//     try {
//       await updateNotificationSettings(updatedSettings);
//       console.log("All notifications updated successfully");
//       setToastOpen(true);
//       setToastMessage(
//         `All notifications turned ${notificationsToggle ? "off" : "on"}`
//       );
//     } catch (error) {
//       console.log("Error updating notification settings:", error);
//     }
//   };

//   useEffect(() => {
//     const relevantKeys = [
//       "updates_and_news",
//       "new_course",
//       "new_parsha_lecture",
//       "invitation_to_survey",
//       "rating_request",
//       "course_reminder",
//       "likes",
//       "trending",
//     ];

//     const allOn = relevantKeys.every((key) => notifications[key]);
//     setNotificationsToggle(allOn);
//   }, [notifications]);

//   return (
//     <>
//       <BlockHeading>
//         <Typography component="h3" variant="blockTitle" mb={pxToRem(5)}>
//           General Notifications
//         </Typography>
//         <Typography component="p" variant="medium">
//           Sed ut perspiciatis unde omnis iste natus accusantium.
//         </Typography>
//       </BlockHeading>

//       <Stack
//         gap={pxToRem(20)}
//         sx={{
//           "& label": {
//             alignItems: "flex-start",
//             "& .MuiTypography-root": {
//               fontSize: pxToRem(18),
//               fontWeight: 600,
//               color: "#333",
//               ml: "20px",
//             },
//           },
//           "& .MuiSwitch-root": {
//             mt: "4px !important",
//           },
//         }}
//       >
//         <FormControl>
//           <Switcher
//             checked={notifications?.updates_and_news}
//             onChange={handleChange}
//             label="Updates & News"
//             name="updates_and_news"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.new_course}
//             onChange={handleChange}
//             label="New course"
//             name="new_course"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.new_parsha_lecture}
//             onChange={handleChange}
//             label="New Parsha Lecture"
//             name="new_parsha_lecture"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.invitation_to_survey}
//             onChange={handleChange}
//             label="Invitation to take customer survey"
//             name="invitation_to_survey"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.rating_request}
//             onChange={handleChange}
//             label="Request to rate and review courses you have taken"
//             name="rating_request"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.course_reminder}
//             onChange={handleChange}
//             label="Reminders about your in-progress courses"
//             name="course_reminder"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.likes}
//             onChange={handleChange}
//             label="Someone liked your comment, rating or comment"
//             name="likes"
//           />
//         </FormControl>
//         <FormControl>
//           <Switcher
//             checked={notifications?.trending}
//             onChange={handleChange}
//             label="Top trending courses and Parsha lectures"
//             name="trending"
//           />
//         </FormControl>
//       </Stack>

//       <Divider
//         sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
//       />

//       <Button variant="yellow" size="small" onClick={turnOffNotifications}>
//         Turn All {notificationsToggle ? "Off" : "On"}
//       </Button>

//       <Toast
//         open={toastOpen}
//         onClose={() => setToastOpen(false)}
//         message={toastMessage}
//       />
//     </>
//   );
// };

// export default Notifications;
import { useEffect, useState } from "react";
import { Button, Divider, FormControl, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Switcher from "../../../components/Switcher";
import Toast from "../../../components/Toast";
// <-- Use the new custom hook
import { useNotificationSettings } from "../../../hooks/useNotificationSettings";

const Notifications = () => {
  // 1) Grab data and methods from the hook
  const {
    notifications: fetchedNotifications,
    updateNotifications,
    isNotificationLoading,
    isNotificationError,
  } = useNotificationSettings();

  // 2) Local UI states
  const [notifications, setNotifications] = useState({});
  const [notificationsToggle, setNotificationsToggle] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // 3) Sync local state with fetched data so switches respond immediately
  useEffect(() => {
    if (fetchedNotifications) {
      setNotifications(fetchedNotifications);
    }
  }, [fetchedNotifications]);

  // 4) If your notifications have these relevant keys,
  //    figure out the "all on" or "all off" toggle
  useEffect(() => {
    const relevantKeys = [
      "updates_and_news",
      "new_course",
      "new_parsha_lecture",
      "invitation_to_survey",
      "rating_request",
      "course_reminder",
      "likes",
      "trending",
    ];
    const allOn = relevantKeys.every((key) => notifications[key] === true);
    setNotificationsToggle(allOn);
  }, [notifications]);

  // 5) Handler for individual switch toggles
  const handleChange = async (event) => {
    const { name, value, checked, type } = event.target;
    const updatedNotifications = {
      ...notifications,
      [name]: type === "checkbox" ? checked : value,
    };
    setNotifications(updatedNotifications);

    try {
      await updateNotifications({ preferences: updatedNotifications });
      setToastOpen(true);
      setToastMessage("Changes saved successfully");
    } catch (error) {
      console.log("Error updating notification settings:", error);
      setToastOpen(true);
      setToastMessage("Failed to save changes");
    }
  };

  // 6) Handler for turning all notifications On or Off at once
  const turnOffNotifications = async () => {
    // Flip the boolean for each relevant key
    const updatedNotifications = {
      updates_and_news: !notificationsToggle,
      new_course: !notificationsToggle,
      new_parsha_lecture: !notificationsToggle,
      invitation_to_survey: !notificationsToggle,
      rating_request: !notificationsToggle,
      course_reminder: !notificationsToggle,
      likes: !notificationsToggle,
      trending: !notificationsToggle,
    };
    setNotifications(updatedNotifications);
    setNotificationsToggle(!notificationsToggle);

    try {
      await updateNotifications({ preferences: updatedNotifications });
      setToastOpen(true);
      setToastMessage(
        `All notifications turned ${notificationsToggle ? "off" : "on"}`
      );
    } catch (error) {
      console.log("Error updating notification settings:", error);
      setToastOpen(true);
      setToastMessage("Failed to save changes");
    }
  };

  // 7) Handle loading / error states
  if (isNotificationLoading) {
    return <div>Loading notification settings...</div>;
  }
  if (isNotificationError) {
    return <div>Error loading notification settings.</div>;
  }

  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle" mb={pxToRem(5)}>
        Manage Your Notifications
        </Typography>
        <Typography component="p" variant="medium">
        Choose which notifications you would like to receive to stay informed and updated
        </Typography>
      </BlockHeading>

      <Stack
        gap={pxToRem(20)}
        sx={{
          "& label": {
            alignItems: "flex-start",
            "& .MuiTypography-root": {
              fontSize: pxToRem(18),
              fontWeight: 600,
              color: "#333",
              ml: "20px",
            },
          },
          "& .MuiSwitch-root": {
            mt: "4px !important",
          },
        }}
      >
        <FormControl>
          <Switcher
            checked={notifications?.updates_and_news || false}
            onChange={handleChange}
            label="Updates & News"
            name="updates_and_news"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.new_course || false}
            onChange={handleChange}
            label="New course"
            name="new_course"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.new_parsha_lecture || false}
            onChange={handleChange}
            label="New Parsha Lecture"
            name="new_parsha_lecture"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.invitation_to_survey || false}
            onChange={handleChange}
            label="Invitation to take customer survey"
            name="invitation_to_survey"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.rating_request || false}
            onChange={handleChange}
            label="Request to rate and review courses you have taken"
            name="rating_request"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.course_reminder || false}
            onChange={handleChange}
            label="Reminders about your in-progress courses"
            name="course_reminder"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.likes || false}
            onChange={handleChange}
            label="Someone liked your comment, rating or comment"
            name="likes"
          />
        </FormControl>
        <FormControl>
          <Switcher
            checked={notifications?.trending || false}
            onChange={handleChange}
            label="Top trending courses and Parsha lectures"
            name="trending"
          />
        </FormControl>
      </Stack>

      <Divider sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }} />

      <Button variant="yellow" size="small" onClick={turnOffNotifications}>
        Turn All {notificationsToggle ? "Off" : "On"}
      </Button>

      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default Notifications;
