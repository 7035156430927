import { Avatar, Button, Divider, Typography } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import { Link, NavLink } from "react-router-dom";
import AvatarImg from "../images/avatar.png";
import IconBtnCircular from "./IconBtnCircular";
import { ReactComponent as Close } from "../images/close.svg";
import React from "react";
import LinkBtn from "./LinkBtn";
import NestedMenu from "./NestedMenu";
import MenuDropdownLink from "./menuDropdown/MenuDropdownLink";
import NotificationsDropdown from "./NotificationsDropdown";
import { useMediaQuery, useTheme } from "@mui/material";
const Hr = styled(Divider)({
  width: "100%",
  borderColor: "rgba(191,190,187,0.5)",
});
const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

const MenuInner = ({
  isAuthenticated,
  userInfo,
  openPopup,
  toggleDrawer,
  handleClose,
  handleCloseDrawer,
  navigationMenu,
  pathname,
  notifications,
  setNotifications,
  setNextPage,
  hasMore,
  setHasMore,
  loading,
  notificationsCount,
  logout,
  nickName,
  firstName,
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        "&::-webkit-scrollbar": { width: pxToRem(6) },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#D9D9D9",
          borderRadius: pxToRem(100),
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#B6B6B6",
          borderRadius: pxToRem(100),
        },
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#EDECE8"
        p={`${pxToRem(40)} ${pxToRem(80)}`}
        sx={{
          "& button": { boxShadow: "none" },
          "@media(max-width: 576px)": {
            paddingX: "40px",
          },
          "@media(max-width: 400px)": {
            paddingX: "30px",
          },

          overflow: "auto",
          "&::-webkit-scrollbar": { width: pxToRem(6) },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#D9D9D9",
            borderRadius: pxToRem(100),
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#B6B6B6",
            borderRadius: pxToRem(100),
          },
        }}
      >
        {isAuthenticated ? (
          <Box>
            <Link
              to="/user/dashboard"
              state={isMobile ? { showMenu: true } : undefined}
              component={NavLink}
              underline="none"
            >
              <Avatar
                sx={{
                  width: pxToRem(48),
                  height: pxToRem(48),
                  backgroundColor: "#fff",
                }}
              >
                <img src={userInfo?.avatar || AvatarImg} alt="User avatar" />
              </Avatar>{" "}
              {userInfo && userInfo.user && (
                <Typography
                  fontSize="15px"
                  fontWeight={700}
                  mt="10px"
                  whiteSpace="no-wrap"
                  sx={{ color: "#333333" }}
                >
                  Hi,{" "}
                  {(nickName || firstName)?.length > 15
                    ? `${(nickName || firstName)?.substring(0, 15)}...`
                    : nickName || firstName}
                </Typography>
              )}
            </Link>
          </Box>
        ) : (
          <Button
            onClick={openPopup}
            variant="yellow"
            sx={{
              height: `${pxToRem(40)} !important`,
              fontSize: pxToRem(14),
              p: `${pxToRem(20)} ${pxToRem(47)} !important`,
            }}
          >
            Sign In
          </Button>
        )}
        <IconBtnCircular onClick={toggleDrawer(false)}>
          <Close color="#026670" />
        </IconBtnCircular>
      </Stack>
      <Stack
        alignItems="flex-start"
        gap={pxToRem(15)}
        p={`${pxToRem(30)} ${pxToRem(80)} ${pxToRem(30)}`}
        sx={{
          "& > a, & > .MuiBox-root > a": {
            fontSize: pxToRem(25),
            fontWeight: 700,
          },
          "@media(max-width: 576px)": {
            paddingX: "40px",
          },
          "@media(max-width: 400px)": {
            paddingX: "30px",
          },
        }}
      >
        {navigationMenu &&
          navigationMenu.map((item, index) => {
            if (!item.submenus || item.submenus.length === 0) {
              return (
                <React.Fragment key={index}>
                  <LinkBtn
                    url={item.url}
                    title={item.label}
                    useFirstStyle={true}
                    newTab={item.target_new_tab}
                    isSideBar
                    closeDrawer={handleCloseDrawer}
                  />
                  <Hr key={`hr-${index}`} />
                </React.Fragment>
              );
            } else if (item.submenus && item.submenus.length > 0) {
              return (
                <React.Fragment key={index}>
                  <NestedMenu
                    title={item.label}
                    url={item.url}
                    newTab={item.target_new_tab}
                    isSideBar
                    closeDrawer={handleCloseDrawer}
                  >
                    {item.submenus.map((subitem, subIndex) => (
                      <MenuDropdownLink
                        to={subitem.url}
                        key={`submenu-${subIndex}`}
                        newTab={subitem.target_new_tab}
                        isSideBar
                        closeDrawer={handleCloseDrawer}
                      >
                        {subitem.label}
                      </MenuDropdownLink>
                    ))}
                  </NestedMenu>
                  <Hr key={`hr-${index}`} />
                </React.Fragment>
              );
            }
            return null;
          })}
        <Stack
          width="100%"
          py={pxToRem(30)}
          gap={pxToRem(20)}
          borderBottom={
            isAuthenticated ? "1px solid rgba(191,190,187,0.5)" : "none"
          }
          sx={{ "& a": { fontSize: pxToRem(16) } }}
        >
          <MenuDropdownLink
            to="/user/dashboard"
            state={{ referer: pathname, showMenu: true }}
          >
            Account
          </MenuDropdownLink>

          {notificationsCount > 0 && (
            <MenuDropdownLink to="/user/dashboard" noOverflow>
              <NotificationsDropdown
                insideUserProfile
                handleCloseParentMenu={handleClose}
                notifications={notifications}
                setNotifications={setNotifications}
                nextPage={setNextPage}
                hasMore={hasMore}
                setHasMore={setHasMore}
                loading={loading}
                count={notificationsCount}
              />
            </MenuDropdownLink>
          )}

          <MenuDropdownLink
            to="/user/courses"
            isSideBar
            closeDrawer={handleCloseDrawer}
          >
            My Courses
          </MenuDropdownLink>
          <MenuDropdownLink
            to="/user/favorites"
            isSideBar
            closeDrawer={handleCloseDrawer}
          >
            My Favorites
          </MenuDropdownLink>
          <MenuDropdownLink
            to="/contact"
            isSideBar
            closeDrawer={handleCloseDrawer}
          >
            Help & Support
          </MenuDropdownLink>
        </Stack>

        {isAuthenticated && (
          <Button
            variant="outlined"
            onClick={() => logout()}
            sx={{
              height: `${pxToRem(40)} !important`,
              fontSize: pxToRem(14),
              p: `${pxToRem(18)} ${pxToRem(40)} !important`,
              borderWidth: pxToRem(2),
              mt: pxToRem(25),
              "&:hover": {
                border: "2px solid #026670",
              },
            }}
          >
            Sign Out
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default MenuInner;
