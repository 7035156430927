import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Container, useMediaQuery } from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import PresentationImg from "../../images/presentation-img1.jpg";
import { getPresentationSection } from "../../api/cmsApi";
import highlightHTML from "../../utils/highlightHTML";
import { getLanguageClass } from "../../utils/languageClass";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import { useTheme } from "@emotion/react";
import SimpleVidstackPlayer from "./SimpleVidstackPlayer";
import { VideoPlayerProvider } from "../../context/VideoPlayerContext";
const Presentation = ({

  currentlyPlayingId,
  setCurrentlyPlayingId,
}) => {
  const theme = useTheme();
  const tablet = useMediaQuery("(max-width: 879px)");
  const [presentationSection, setPresentationSection] = useState({
    title: "",
    subtitle: "",
    button_link: "",
    button_text: "",
    target_new_tab: false,
  });

  const [videoUrl, setVideoUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const fetchPresentation = () => {
    getPresentationSection()
      .then((res) => {
        setPresentationSection(res.data);
        setVideoUrl(`${res.data.video_upload}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPresentation();
  }, []);

  const customPosition = useRef(null);

  const handlePlayClick = () => {
    setCurrentlyPlayingId((prevId) => (prevId === videoUrl ? null : videoUrl));
    // setIsPlaying(true);
  };

  useEffect(() => {
    if (currentlyPlayingId === videoUrl) {
      setIsPlaying(true);
    }else{
      setIsPlaying(false);
    }
  }, [currentlyPlayingId]);


  console.log("currentlyPlayingId",currentlyPlayingId)

  const renderButton = () => {
    if (!presentationSection?.button_text) return null;
    return (
      <Box>
        <Button
          variant="yellow"
          sx={{ position: "relative", zIndex: 1 }}
          onClick={() => {
            if (presentationSection?.button_link) {
              window.open(
                presentationSection?.button_link,
                presentationSection?.target_new_tab ? "_blank" : "_self"
              );
            }
          }}
        >
          {presentationSection.button_text}
        </Button>
      </Box>
    );
  };
  return (
    <Container
      disableGutters
      sx={{
        pb: { xs: pxToRem(70), ssm: "86px", sm: "100px", md: pxToRem(150) },
      }}
    >
      <Box>
        {presentationSection?.title && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              maxWidth={pxToRem(600)}
              sx={{
                fontSize: { xs: "1.4rem", sm: "2.5rem" },
                fontWeight: 500,
                lineHeight: { xs: "40px", sm: pxToRem(60) },
                color: "inherit",
                marginBottom: { xs: "0px", sm: "24px" },
                maxWidth: pxToRem(600),
              }}
              fontFamily="PloniBold"
              dangerouslySetInnerHTML={{
                __html: highlightHTML(presentationSection?.title),
              }}
              className={getLanguageClass(
                presentationSection?.title,
                false,
                true
              )}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: pxToRem(10),
          }}
        >
          <BlockBordered
            mb={{ xs: "20px", sm: pxToRem(50) }}
            isAlignedRight={true}
          >
            {presentationSection?.subtitle && (
              <Box
                maxWidth={pxToRem(600)}
                sx={{
                  fontSize: { xs: pxToRem(15), sm: pxToRem(22) },
                  fontWeight: "500",
                  // marginBottom: { xs: "12px", sm: "16px" },
                  "& p": {
                    my: 0,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: presentationSection?.subtitle,
                }}
                className={getLanguageClass(presentationSection?.subtitle)}
              />
            )}
          </BlockBordered>
        </Box>
      </Box>

      <Box ref={customPosition}>
        <Box
          sx={{
            display: "flex",
            flexDirection: tablet ? "column" : "row",
            alignItems: tablet ? "flex-end" : "flex-start",
          }}
        >
          {tablet && renderButton()}
          {isPlaying ? (
            <Box
              sx={{
                backgroundColor: "black",
                aspectRatio: "16/9",
                borderRadius: "1.25rem",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "800px",
                },
                mr: "auto",
                mt: "30px",
                mb: "0px",
              }}
            >
              <VideoPlayerProvider>
                <VideoPlayer
                  videoUrlCustom={videoUrl}
                  thumbnailCustom={PresentationImg}
                  customPosition={customPosition}
                  useCustomPlayer
                  startsPlaying
                  isHomePage
                />
              </VideoPlayerProvider>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "black",
                aspectRatio: "16/9",
                borderRadius: "1.25rem",
                overflow: "hidden",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "800px",
                },
                mr: "auto",
                mt: "30px",
                mb: "0px",
              }}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={`${pxToRem(20)}`}
              width="100%"
            >
              <img
                src={PresentationImg}
                alt={presentationSection?.title}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <PlayBtn
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                width={{ xs: "60px", sm: pxToRem(120) }}
                height={{ xs: "60px", sm: pxToRem(120) }}
                onClick={handlePlayClick}
              >
                <Play
                  color="#026670"
                  width={pxToRem(20)}
                  height={pxToRem(24)}
                  style={{ marginLeft: "6%" }}
                />
              </PlayBtn>
            </Box>
          )}
          {!tablet && renderButton()}
        </Box>
      </Box>

      {/* <Box
        position="relative"
        maxWidth={pxToRem(960)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        // onClick={() => {
        //   setIsPlaying(true)
        // }}
        borderRadius={pxToRem(20)}
        boxShadow={
          isPlaying
            ? ""
            : `${pxToRem(-30)} ${pxToRem(30)} ${pxToRem(50)} rgba(0,0,0,0.2)`
        }
        ml="auto"
        mt={{ xs: "20px", sm: "50px" }}
        mr={{ xs: 0, sm: "30px", md: 0 }}
        sx={{
          "&::before": {
            content: "''",
            position: "absolute",
            top: { xs: "-30px", md: pxToRem(-40) },
            right: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
            width: { xs: "55%", sm: pxToRem(550) },
            height: { xs: "75%", sm: pxToRem(400) },

            borderRadius: pxToRem(20),
            zIndex: -1,
          },
        }}
      >
      </Box> */}
    </Container>
  );
};

export default Presentation;
