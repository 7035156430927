import React, { createContext, useState, useRef, useContext } from "react";

const VideoPlayerContext = createContext();

export const VideoPlayerProvider = ({ children }) => {
  const player = useRef(null);
  const currentTime = useRef(null);

  const [playIcon, setPlayIcon] = useState(false);
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const [isWatching, setIsWatching] = useState(false);
  const [listenWatchToggle, setListenWatchToggle] = useState(false);
  const [reservedTime, setReservedTime] = useState(null);
  const [playTriggered, setPlayTriggered] = useState(false);
  const [isMediaReady, setIsMediaReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  /// course related
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [bookmarkToastOpen, setBookmarkToastOpen] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");
  const [bookmarkBgColor, setBookmarkBgColor] = useState(null);
  const [noteToastOpen, setNoteToastOpen] = useState(false);
  const [bookmarkListChanged, setBookmarkListChanged] = useState(false);
  const [noteListChanged, setNoteListChanged] = useState(false);

  // listen
  const handleListenClicked = () => {
    setFirstTimeLoaded(false);
    setIsListening(true);
    setIsWatching(false);
    setPlayTriggered(true);
    setShowButtons(false);

    if (player.current) {
      if (isMediaReady) {
        player.current.play();

        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  // watch
  const handleWatchClicked = () => {
    setFirstTimeLoaded(false);
    setIsWatching(true);
    setIsListening(false);
    setPlayTriggered(true);
    setShowButtons(false);

    if (player.current) {
      if (isMediaReady) {
        player.current.play();
        if (setPlayIcon) setPlayIcon(true);
      } else {
        player.current.addEventListener(
          "canplay",
          () => player.current.play(),
          { once: true }
        );
      }
    }
  };

  return (
    <VideoPlayerContext.Provider
      value={{
        currentTime,

        firstTimeLoaded,
        setFirstTimeLoaded,
        showButtons,
        setShowButtons,
        isListening,
        setIsListening,
        isWatching,
        setIsWatching,
        listenWatchToggle,
        setListenWatchToggle,
        reservedTime,
        setReservedTime,
        playTriggered,
        setPlayTriggered,
        isMediaReady,
        setIsMediaReady,
        player,
        handleListenClicked,
        handleWatchClicked,

        //
        toastOpen,
        setToastOpen,
        setToastMessage,
        toastMessage,

        bookmarkToastOpen,
        setBookmarkToastOpen,
        bookmarkMessage,
        setBookmarkMessage,
        bookmarkBgColor,

        setBookmarkBgColor,
        noteToastOpen,
        setNoteToastOpen,

        bookmarkListChanged,
        setBookmarkListChanged,
        noteListChanged,
        setNoteListChanged,
        playIcon,
        setPlayIcon,
        isPlaying,
        setIsPlaying,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayer = () => {
  const context = useContext(VideoPlayerContext);
  if (!context) {
    throw new Error("useVideoPlayer must be used within a VideoPlayerProvider");
  }
  return context;
};
