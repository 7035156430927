import React, { useEffect, useRef, useState, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCoursePreview,
  getLessonsPresubscribed,
  getRatings,
  subscribeToFreeCourse,
} from "../../api/course";
import {
  Box,
  Button,
  Container,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { useModal } from "mui-modal-provider";
import { useAuth } from "../../context/AuthContext";
import Header from "../../components/Header";
import Sidebar from "../course/components/Sidebar";
import BlockBordered from "../../components/BlockBordered";
import Rating from "../../components/Rating";
import IconBtnCircular from "../../components/IconBtnCircular";
import PlayBtn from "../../components/PlayBtn";
import VideosSection from "../../components/VideosSection";
import PreviewModal from "./PreviewModal";
import LoginSubscribeModal from "./LoginSubscribeModal";
import PurchaseModal from "./PurchaseModal";
import LoginModal from "../auth/LoginModal";
import ThankModal from "./ThankModal";
import ErrorModal from "./ErrorModal";
import {
  formatDate,
  formatDuration,
  isTodayBetweenDates,
} from "../../utils/format";
import InfoBlock from "../../components/InfoBlock";

// import PresentationImg from "../../images/pre-subscribed-banner.jpg";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Star } from "../../images/star.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Info } from "../../images/info.svg";
import { ReactComponent as Gift } from "../../images/gift.svg";

import GiftCourseModal from "./GiftCourseModal";
import Toast from "../../components/Toast";
import SubscriptionFeedbackModal from "../course/components/SubscriptionFeedbackModal";
import {
  acceptInvitation,
  getStudent,
  prolongSubscription,
  rejectInvitation,
  renewSubscription,
  unsubscribeFromEmail,
} from "../../api/student";
import {
  createAssociateCheckoutSession,
  resolveCheckoutSession,
} from "../../api/payment";
import shouldNavigateToPresubscribe from "../../utils/subscription";

import CourseRatingsComponent from "../../components/CourseRatingsComponent";
import PreSubscribeDescription from "./components/PresubscribeDescription";

import { getLanguageClass } from "../../utils/languageClass";
import LinkWithIcon from "../../components/LinkWithIcon";
import PresubscribeSkeleton from "./components/PresubscribeSkeleton";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import { removeParamFromURL } from "../../utils/params";
import { VideoPlayerProvider } from "../../context/VideoPlayerContext";
export const PreSubscribeContext = createContext();
const PreSubscribe = () => {
  const { showModal, hideModal } = useModal();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [course, setCourse] = useState({ ratings: [] });
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [success, setSuccess] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [redirectToSubscribed, setRedirectToSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [feedbackAssociateOpen, setFeedbackAssociateOpen] = useState(false);
  const [giftFeedbackOpen, setGiftFeedbackOpen] = useState(false);
  const [showReviewsModal, setShowReviewsModal] = useState(false);

  // reviews

  const [reviews, setReviews] = useState([]);
  const [isReviewedByMe, setIsReviewedByMe] = useState(false);
  const [myReviewId, setMyReviewId] = useState(null);
  const [currentReview, setCurrentReview] = useState({});

  const fetchRatings = () => {
    getRatings(id)
      .then((res) => {
        setReviews(res.data.results);
        res.data.results.forEach((review) => {
          if (review.is_mine === true) {
            setIsReviewedByMe(true);
            setMyReviewId(review.id);
            setCurrentReview(review);
          }
        });
      })
      .catch((err) => {
        console.log("err");
      });
  };

  //reviews
  const handleCloseToast = () => {
    setToastOpen(false);
    setToastMessage("");
  };

  const handleShareClick = () => {
    let path = location.pathname;

    if (path.endsWith("/presubscribe")) {
      path = path.slice(0, -"/presubscribe".length);
    }

    const fullUrl = `${window.location.origin}${path}`;

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
        setToastMessage("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  const handleInvitationAcceptance = async (invitationId) => {
    if (isAuthenticated) {
      try {
        const response = await acceptInvitation(invitationId);
        const email = response.email;
        const checkoutSessionResponse = await createAssociateCheckoutSession(
          id,
          email
        );
        console.log("checkoutSessionResponse", checkoutSessionResponse);
        if (
          checkoutSessionResponse &&
          checkoutSessionResponse.data &&
          checkoutSessionResponse.data.checkout_url
        ) {
          window.location.href = checkoutSessionResponse.data.checkout_url;
        } else {
          console.error("Checkout URL not found in the response.");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setModalTitle("Invitation Error");
          setErrorMessage(error?.response?.data?.detail);
          setFeedbackAssociateOpen(true);
        } else {
          console.error(
            "Error handling invitation acceptance and checkout session:",
            error
          );
        }
      }
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
        },
        loginMessage: "You need to log in first to accept the invitation.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  const handleCloseAssociateFeedback = () => {
    setFeedbackAssociateOpen(false);
    if (!redirectToSubscribed) return;
    navigate(`/course/${id}/`);
    setRedirectToSubscribed(false);
  };

  const handleReject = async (invitationId) => {
    if (isAuthenticated) {
      try {
        const response = await rejectInvitation(invitationId);
        setModalTitle("Invitation Feedback");
        setErrorMessage(response?.data?.detail);
        setFeedbackAssociateOpen(true);
        setSuccess(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setModalTitle("Invitation Error");
          setErrorMessage(error?.response?.data?.detail);
          setFeedbackAssociateOpen(true);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.includes(
            "You are already subscribed to this course."
          )
        ) {
          setRedirectToSubscribed(true);
          setModalTitle("Partner Discount");
          setErrorMessage("You are already subscribed to this course.");
          setFeedbackAssociateOpen(true);
        } else {
          console.error(
            "Error handling invitation acceptance and checkout session:",
            error
          );
        }
      }
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
        },
        loginMessage: "You need to log in first to reject the invitation.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  const handleCloseGiftFeedback = () => {
    setGiftFeedbackOpen(false);
    clearParams();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    if (email) {
      if (!isAuthenticated) {
        const modal = showModal(LoginSubscribeModal, {
          openLoginModal: () => {
            hideModal(modal.id);
            showModal(LoginModal);
          },
          loginMessage:
            "Sign in or create an account to access the gifted course.",
          hideModal: () => hideModal(modal.id),
        });
      } else {
        const fetchStudent = async () => {
          try {
            const response = await getStudent();
            if (response.data.user.email !== email) {
              setModalTitle("Gifted Course");
              setErrorMessage(
                "It appears you're logged into an account with a different email address. Please log in with the correct email address, or contact customer support."
              );
              setGiftFeedbackOpen(true);
            } else {
              navigate(`/course/${id}/`);
            }
          } catch (error) {
            console.error("Error fetching student data:", error);
          }
        };
        fetchStudent();
      }
    }

    const invitationId = params.get("invitation_id");
    const acceptInvitation = params.get("accept");
    if (invitationId && acceptInvitation === "false") {
      handleReject(invitationId);
    } else if (invitationId && acceptInvitation === "true" && id) {
      handleInvitationAcceptance(invitationId);
    }
    const isAssociate = params.get("is_associate");
    const isNormalSubscription = params.get("is_normal_subscription");
    const isGifted = params.get("is_gifted");
    const successParam = params.get("success");
    const cancelParam = params.get("cancel");
    setSuccess(successParam);
    setCancel(cancelParam);

    if (successParam === "yes" || cancelParam === "yes") {
      if (isAssociate === "yes") {
        setModalTitle("Add Partner");
      } else if (isNormalSubscription === "yes") {
        setModalTitle("Course Subscription");
      } else if (isGifted === "yes") {
        setModalTitle("Gift Course");
      }
      setFeedbackOpen(true);
    }
  }, [location]);

  const clearParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  const handleCloseFeedback = () => {
    clearParams();
    setFeedbackOpen(false);
  };

  const handleOpenGiftModal = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          // showModal(LoginModal);
          showModal(LoginModal, { onRedirectOpenModal: "giftModal" });
        },
        loginMessage:
          "You need to log in first to gift this course to someone.",
        hideModal: () => hideModal(modal.id),
      });
      // localStorage.setItem('openModal', 'giftModal');
    } else {
      setOpenGiftModal(true);
    }
  };

  const handleCloseGiftModal = () => {
    setOpenGiftModal(false);
  };

  const showSubscriptionModal = () => {
    // console.log("debugonredirect course", course);

    const previewModal = showModal(PreviewModal, {
      course: course,
      openSubscriptionModal: (price, type) => {
        hideModal(previewModal.id);

        const purchaseModal = showModal(PurchaseModal, {
          course: course,
          type: type,
          price: price,
          openThankModal: () => {
            hideModal(purchaseModal.id);
            showModal(ThankModal);
          },
          openErrorModal: () => {
            hideModal(purchaseModal.id);
            const errorModal = showModal(ErrorModal, {
              openPurchaseModal: () => {
                hideModal(errorModal.id);
                showModal(PurchaseModal, { course: course });
              },
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    const redirectModal = localStorage.getItem("onRedirectOpenModal");

    if (
      !loading &&
      course?.id &&
      !course?.is_subscribed &&
      redirectModal === "previewModal"
    ) {
      localStorage.setItem("onRedirectOpenModal", "");
      if (!isMobile) {
        showSubscriptionModal();
      }
    } else if (
      !loading &&
      course &&
      !course?.is_subscribed &&
      redirectModal === "giftModal"
    ) {
      localStorage.setItem("onRedirectOpenModal", "");
      handleOpenGiftModal();
    }
  }, [course?.id]);

  const [lessonsCount, setLessonsCount] = useState(0);
  const fetchCourse = async () => {
    setLoading(true);
    try {
      const response = await getCoursePreview(id);
      setCourse(response.data);
      setLessonsCount(response.data?.lessons_count);
    } catch (error) {
      console.error("Error fetching the course:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourse();
    fetchRatings();
  }, [id]);

  useEffect(() => {
    const fetchLessons = async () => {
      setLoading(true);
      try {
        const lessonsResponse = await getLessonsPresubscribed(id);

        setLessons(lessonsResponse.data?.lessons);
      } catch (error) {
        console.error("Error fetching the course:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [id]);

  const [shouldRenderContent, setShouldRenderContent] = useState(false);

  useEffect(() => {
    setShouldRenderContent(!!(!loading && course?.id));
  }, [loading, course]);

  // console.log("shouldRenderContentjdnjndjnd", shouldRenderContent);

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  const filteredWhatsIncluded = course?.whats_included_preview?.filter(
    (item) => item.trim() !== ""
  );
  const filteredWhatsLearned = course?.whats_learned_preview?.filter(
    (item) => item.trim() !== ""
  );

  const handleResolvePurchase = async (subscriptionId) => {
    try {
      const response = await resolveCheckoutSession(subscriptionId);
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while trying to resolve the issue."
      );
      setErrorToastOpen(true);
    }
  };

  useEffect(() => {
    // allow presubscribed view for testing
    // if (id && course && course.subscription_status) {
    //   if (!shouldNavigateToPresubscribe(course.subscription_status)) {
    //     navigate(`/course/${id}`);
    //   }
    // }
  }, [id, course?.subscription_status]);

  // show 50% of name and subtitle
  const nameRef = useRef(null);
  const containerRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTextWidth = () => {
      console.log("containerRef.current", containerRef.current);
      if (nameRef.current && containerRef.current) {
        const nameWidth = nameRef.current.offsetWidth;
        const containerWidth = containerRef.current.offsetWidth;

        // console.log('nameWidth',nameWidth,"containerWidth",containerWidth)
        console.log("containerWidth", containerWidth / 2);

        // Check if the name width exceeds 50% of the container width
        if (nameWidth > containerWidth / 2) {
          setIsTruncated(true);
        } else {
          setIsTruncated(false);
        }
      }
    };

    checkTextWidth();
    window.addEventListener("resize", checkTextWidth);

    // Clean up the resize event listener
    return () => window.removeEventListener("resize", checkTextWidth);
  }, [course?.name]);

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const isMobile = useMediaQuery("(max-width:767px)");

  const unsubscribeHelperFun = async (token) => {
    try {
      const res = await unsubscribeFromEmail(token);
      console.log("jdnjdnjndjndjjndjd", res);
    } catch (error) {
      const errorMsg = error?.response?.data?.detail;
      if (errorMsg === "Given token not valid for any token type") {
        const modal = showModal(LoginSubscribeModal, {
          openLoginModal: () => {
            hideModal(modal.id);
            // showModal(LoginModal);
            showModal(LoginModal, { onRedirectOpenModal: "giftModal" });
          },
          loginMessage:
            "You need to log in first to gift this course to someone.",
          hideModal: () => hideModal(modal.id),
        });
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const unsubscribe = params.get("unsubscribe");
    if (unsubscribe) {
      unsubscribeHelperFun(unsubscribe);
    }
  }, [location]);

  //end: unsubscribe from notifications

  // mobile
  const handleSubscribe = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal, { onRedirectOpenModal: "previewModal" });
        },
        loginMessage: "You need to log in first to subscribe to this course.",
        hideModal: () => hideModal(modal.id),
      });
    } else {
      if (course.is_free) {
        subscribeToFreeCourse(course.id);
        navigate(`/course/${course.slug}`);
      } else {
        const purchaseModal = showModal(PurchaseModal, {
          course: course,
          type: "normal",
          price: course?.subscription_price,
          openThankModal: () => {
            hideModal(purchaseModal.id);
            showModal(ThankModal);
          },
          openErrorModal: () => {
            hideModal(purchaseModal.id);
            const errorModal = showModal(ErrorModal, {
              openPurchaseModal: () => {
                hideModal(errorModal.id);
                showModal(PurchaseModal, { course: course });
              },
            });
          },
        });
      }
    }
  };

  //start: renew prolong on redirect from course page

  const [openSubscriptionFeedback, setOpenSubscriptionFeedback] =
    useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleCloseModal = () => {
    setOpenSubscriptionFeedback(false);
    removeParamFromURL("subscription");
    removeParamFromURL("prolong");
  };

  const renewProlongEmail = async (token, action) => {
    try {
      let response;

      if (action === "renew") {
        response = await renewSubscription(token);
        console.log("Renew response: ", response);
      } else if (action === "prolong") {
        response = await prolongSubscription(token);
        console.log("Prolong response: ", response);
      } else {
        throw new Error("Invalid action provided.");
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.detail;

      if (errorMsg === "Given token not valid for any token type") {
        const modal = showModal(LoginSubscribeModal, {
          openLoginModal: () => {
            hideModal(modal.id);
            localStorage.setItem(
              action === "renew" ? "renewToken" : "prolongToken",
              token
            );
            removeParamFromURL(action === "renew" ? "subscription" : "prolong");
            showModal(LoginModal);
          },
          loginMessage:
            action === "renew"
              ? "You need to log in first to renew your subscription."
              : "You need to log in first to prolong your subscription.",
          hideModal: () => {
            hideModal(modal.id);
            removeParamFromURL(action === "renew" ? "subscription" : "prolong");
          },
        });
      } else if (
        errorMsg === "Invalid renew link." ||
        errorMsg === "Invalid prolong link."
      ) {
        setModalTitle(
          action === "renew" ? "Renew Subscription" : "Prolong Subscription"
        );
        setModalMessage(
          action === "renew"
            ? "Oops! It looks like you're logged in with the wrong account. to resubscribe to this course."
            : "Oops! It looks like you're logged in with the wrong account. to prolong the subscription for this course."
        );
        setOpenSubscriptionFeedback(true);
        setSuccess(false);
      } else {
        setModalTitle("Error");
        setModalMessage(
          "An unexpected error occurred. Please try again later."
        );
        setOpenSubscriptionFeedback(true);
        setSuccess(false);
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const renewTokenFromURL = params.get("subscription");
    const prolongTokenFromURL = params.get("prolong");
    if (course?.id) {
      if (renewTokenFromURL) {
        renewProlongEmail(renewTokenFromURL, "renew");
      } else if (prolongTokenFromURL) {
        renewProlongEmail(prolongTokenFromURL, "prolong");
      }
    }
  }, [course?.id]);

  //end: renew prolong on redirect from course page

  return (
    <PreSubscribeContext.Provider
      value={{
        course,
      }}
    >
      <VideoPlayerProvider key={id}>
        <Box position="relative" zIndex={2}>
          <Header menuVisible nonSticky />
          <Box className="header-padding" mt={{ md: pxToRem(40) }}>
            <Container disableGutters sx={{ px: { xs: 0, md: "30px" } }}>
              <Box
                position="relative"
                pb={!shouldRenderContent ? "0" : { sm: "56.3%" }}
                sx={
                  shouldRenderContent
                    ? {
                        "&::before": {
                          content: { xs: "none", md: "''" },
                          position: "absolute",
                          bottom: pxToRem(-40),
                          right: pxToRem(-40),
                          width: "46.6%",
                          height: "59.7%",
                          bgcolor: "#FCE181",
                          borderRadius: pxToRem(20),
                          zIndex: -1,
                          // zIndex: isMobile ? 1 : -1,
                        },
                      }
                    : {}
                }
              >
                {!shouldRenderContent ? (
                  <PresubscribeSkeleton />
                ) : (
                  <>
                    {isMobile ? (
                      <>
                        <VideoPlayer
                          preview={course}
                          noListen={true}
                          id={id}
                          mediaControlsHeight={52}
                          modalScreen={true}
                          isPresubsribed
                        />
                      </>
                    ) : (
                      <Box
                        display="flex"
                        position={{ xs: "relative", sm: "absolute" }}
                        bgcolor="#fff"
                        borderRadius={{ md: pxToRem(20) }}
                        height="100%"
                        width="100%"
                        overflow="hidden"
                      >
                        <img
                          src={course?.thumb_nail}
                          alt=""
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <Box
                          position="absolute"
                          display={{ sm: "none" }}
                          top="50%"
                          left="50%"
                          sx={{
                            zIndex: 10,
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            "& .MuiStack-root": {
                              margin: `0 auto ${pxToRem(10)}`,
                            },
                          }}
                          onClick={showSubscriptionModal}
                        >
                          <Button
                            variant="yellow"
                            sx={{
                              height: pxToRem(32),
                              p: `${pxToRem(5)} ${pxToRem(40)}`,
                              cursor: "pointer",
                            }}
                          >
                            <Play
                              color="#026670"
                              width={pxToRem(20)}
                              height={pxToRem(24)}
                              style={{ marginRight: "10px" }}
                            />
                            Preview
                          </Button>
                        </Box>
                      </Box>
                    )}

                    <Box
                      position={{ sm: "absolute" }}
                      height="100%"
                      width="100%"
                      borderRadius={{ md: pxToRem(20) }}
                      sx={{
                        background: {
                          sm: "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%)",
                        },
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        height="100%"
                        textAlign="right"
                        p={{
                          xs: `${pxToRem(22)} 25px 0`,
                          ssm: `${pxToRem(25)} 25px 0`,
                          sm: `${pxToRem(50)} ${pxToRem(30)} ${pxToRem(30)}`,
                          md: `${pxToRem(63)} ${pxToRem(60)} ${pxToRem(60)}`,
                        }}
                      >
                        <Stack height="100%">
                          <Box>
                            <Box
                              mb={{ xs: pxToRem(24), ssm: pxToRem(29) }}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <Box>
                                {!isMobile && (
                                  <Box
                                    sx={{
                                      width: pxToRem(590),
                                      height: pxToRem(336),
                                      borderTopLeftRadius: "20px",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      opacity: "30%",
                                      background:
                                        "linear-gradient(151deg, rgb(0 0 0 / 30%) 26.73%, rgb(0 0 0 / 0%) 57.21%)",
                                    }}
                                  ></Box>
                                )}
                                <LinkWithIcon
                                  to="/courses"
                                  className="rtl-section back-arrow-hover"
                                  sx={{ marginTop: pxToRem(14) }}
                                  style={{
                                    display: tablet ? "none" : "",
                                  }}
                                  styles={{
                                    buttonBase: {
                                      "&:hover .MuiButtonBase-root": {
                                        backgroundColor: "#ebcf6a !important",
                                      },
                                      "&:hover .MuiTypography-root": {
                                        color: "#ebcf6a",
                                      },
                                    },
                                    iconButton: {
                                      backgroundColor: "#FCE181",
                                      "& svg": { color: "#026670" },
                                    },
                                    textStyle: { color: "#FCE181" },
                                  }}
                                >
                                  Back
                                </LinkWithIcon>
                              </Box>

                              <Stack alignItems="end" flexGrow={1}>
                                <Typography
                                  variant="sectionTitleHebrew"
                                  fontSize={{
                                    xs: pxToRem(30),
                                    ssm: pxToRem(36),
                                    md: pxToRem(40),
                                  }}
                                  sx={{
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                    width: { sm: "50%" },
                                    overflow: "hidden",
                                    textAlign: "right",
                                    fontFamily: "PloniBold",
                                    lineHeight: mobile_ssm ? "2rem" : "3rem",
                                    marginBottom: "1rem",
                                  }}
                                  className={getLanguageClass(
                                    course?.name,
                                    false,
                                    true
                                  )}
                                >
                                  {course?.name}
                                </Typography>

                                {isMobile ? (
                                  <BlockBordered
                                    isAlignedRight={getLanguageClass(
                                      course?.subtitle === "is-hebrew-text"
                                    )}
                                  >
                                    <Typography
                                      variant="medium"
                                      color="#333333"
                                      fontSize={{
                                        xs: pxToRem(20),
                                        ssm: pxToRem(22),
                                        sm: pxToRem(18),
                                        md: pxToRem(22),
                                      }}
                                      width={{ sm: "50%" }}
                                      lineHeight={1.2}
                                      sx={{
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        textAlign: "right",
                                      }}
                                      className={getLanguageClass(
                                        course?.subtitle
                                      )}
                                    >
                                      {course?.subtitle}
                                    </Typography>
                                  </BlockBordered>
                                ) : (
                                  <Typography
                                    variant="medium"
                                    color="#333333"
                                    fontSize={{
                                      xs: pxToRem(20),
                                      ssm: pxToRem(22),
                                      sm: pxToRem(18),
                                      md: pxToRem(22),
                                    }}
                                    width={{ sm: "50%" }}
                                    lineHeight={1.2}
                                    sx={{
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                      textAlign: "right",
                                    }}
                                    className={getLanguageClass(
                                      course?.subtitle
                                    )}
                                  >
                                    {course?.subtitle}
                                  </Typography>
                                )}

                                {/* <BlockBordered
                                  isAlignedRight={getLanguageClass(
                                    course?.subtitle === "is-hebrew-text"
                                  )}
                                >
                                  <Typography
                                    variant="medium"
                                    color="#333333"
                                    fontSize={{
                                      xs: pxToRem(20),
                                      ssm: pxToRem(22),
                                      sm: pxToRem(18),
                                      md: pxToRem(22),
                                    }}
                                    width={{ sm: "50%" }}
                                    lineHeight={1.2}
                                    sx={{
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                      textAlign: "right",
                                    }}
                                    className={getLanguageClass(
                                      course?.subtitle
                                    )}
                                  >
                                    {course?.subtitle}
                                  </Typography>
                                </BlockBordered> */}
                              </Stack>
                            </Box>

                            <Typography
                              component="p"
                              fontSize={pxToRem(14)}
                              fontWeight={600}
                              mb={pxToRem(10)}
                            >
                              {formatDuration(course?.length)} &nbsp;•&nbsp;
                              Released {formatDate(course?.release_date)}
                            </Typography>

                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              gap={pxToRem(10)}
                              mb={{
                                xs: pxToRem(30),
                                ssm: pxToRem(40),
                                sm: pxToRem(50),
                              }}
                            >
                              {course?.rating ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography fontWeight={700}>
                                    {course?.rating}
                                  </Typography>
                                  <Rating
                                    value={course?.rating}
                                    precision={0.1}
                                    readOnly
                                  />
                                </Box>
                              ) : (
                                <Typography fontWeight={700}>
                                  {"No Reviews"}
                                </Typography>
                              )}
                              <Typography fontWeight={700}>
                                •&nbsp;&nbsp; {course?.subscriber_count}{" "}
                                {course?.subscriber_count === 1
                                  ? "Subscriber"
                                  : "Subscribers"}
                              </Typography>
                            </Stack>
                          </Box>
                          <Stack
                            flexGrow="1"
                            flexDirection={{
                              xs: isOnSale ? "column" : "row",
                              sm: "column",
                            }}
                            gap={{
                              xs: pxToRem(20),
                              sssm: pxToRem(37),
                              sm: pxToRem(20),
                            }}
                            justifyContent={{
                              xs: "space-between",
                              sssm: "flex-end",
                              sm: "space-between",
                            }}
                            alignItems={{
                              xs: isOnSale ? "flex-end" : "center",
                              sm: "flex-end",
                            }}
                          >
                            {course?.user_subscription?.is_failed ? (
                              <Button
                                variant="yellow"
                                sx={{
                                  height: pxToRem(54),
                                  width: pxToRem(300),
                                }}
                                onClick={() =>
                                  handleResolvePurchase(
                                    course?.user_subscription?.id
                                  )
                                }
                              >
                                Resolve Issue
                              </Button>
                            ) : (
                              <Button
                                onClick={
                                  isMobile
                                    ? handleSubscribe
                                    : showSubscriptionModal
                                }
                                variant="green"
                                sx={{
                                  height: pxToRem(54),
                                  borderWidth: pxToRem(2),
                                  pl: pxToRem(40),
                                  pr: pxToRem(40),
                                  order: { xs: isOnSale ? 2 : 1, sm: 1 },
                                }}
                              >
                                <Typography
                                  component="span"
                                  fontWeight={600}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space_between",
                                  }}
                                >
                                  {isOnSale ? (
                                    <>
                                      <Typography
                                        component="span"
                                        fontWeight={600}
                                      >
                                        {course?.sale_price <= 0
                                          ? "Subscribe for Free"
                                          : `Subscribe: $${course?.sale_price}`}
                                      </Typography>

                                      <Typography
                                        fontSize="13px"
                                        fontWeight="600"
                                        color="#BFBEBB"
                                        sx={{
                                          textDecoration: "line-through",
                                          marginLeft: 1,
                                          transform: "translateY(0.9px)",
                                        }}
                                      >
                                        {course?.subscription_price > 0 &&
                                          `$${course?.subscription_price}`}
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography
                                      component="span"
                                      fontWeight={600}
                                    >
                                      {course?.subscription_price <= 0
                                        ? "Subscribe for Free"
                                        : `Subscribe: $${course?.subscription_price}`}
                                    </Typography>
                                  )}
                                </Typography>
                              </Button>
                            )}
                            <Stack
                              position="relative"
                              alignItems="flex-end"
                              gap={pxToRem(10)}
                              flexDirection={{ xs: "row", sm: "column" }}
                              sx={{
                                order: { xs: isOnSale ? 1 : 2, sm: 2 },
                              }}
                            >
                              {!course?.is_free && (
                                <Tooltip
                                  title="Gift Course"
                                  placement={mobile_ssm ? "bottom" : "left"}
                                  PopperProps={{
                                    disablePortal: true,
                                    sx: {
                                      textWrap: "nowrap",
                                      "& .MuiTooltip-tooltipPlacementBottom": {
                                        mt: {
                                          xs: "8px !important",
                                          sssm: "12px !important",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      "& + .MuiTooltip-popper .MuiTooltip-tooltip":
                                        {
                                          backgroundColor: "#fce181",
                                          color: "#026670",
                                        },
                                      "& svg": { transition: "0.2s ease" },
                                      "&:hover svg": { color: "#026670" },
                                    }}
                                    onClick={handleOpenGiftModal}
                                  >
                                    <IconBtnCircular className="yellow-ic">
                                      <Gift color="#fff" />
                                    </IconBtnCircular>
                                  </Box>
                                </Tooltip>
                              )}

                              <Tooltip
                                title="Share Now"
                                placement={mobile_ssm ? "bottom" : "left"}
                                PopperProps={{
                                  disablePortal: true,
                                  sx: {
                                    textWrap: "nowrap",
                                    "& .MuiTooltip-tooltipPlacementBottom": {
                                      mt: {
                                        xs: "8px !important",
                                        sssm: "12px !important",
                                      },
                                    },
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    "& button:hover": {
                                      backgroundColor: "#00545d !important",
                                    },
                                  }}
                                  onClick={handleShareClick}
                                >
                                  <IconBtnCircular className="hover-green active">
                                    <Share
                                      color="#026670"
                                      style={{ marginRight: pxToRem(1.5) }}
                                    />
                                  </IconBtnCircular>
                                </Box>
                              </Tooltip>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                    <Box
                      onClick={showSubscriptionModal}
                      position="absolute"
                      display={{ xs: "none", sm: "block" }}
                      top="50%"
                      left="27.2%"
                      sx={{
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        "& .MuiStack-root": { margin: `0 auto ${pxToRem(10)}` },
                      }}
                    >
                      <PlayBtn width={pxToRem(96)} height={pxToRem(96)}>
                        <Play
                          color="#026670"
                          width={pxToRem(20)}
                          height={pxToRem(24)}
                          style={{ marginLeft: "6%" }}
                        />
                      </PlayBtn>
                      <Button
                        variant="yellow"
                        sx={{
                          height: pxToRem(32),
                          p: `${pxToRem(5)} ${pxToRem(40)}`,
                        }}
                      >
                        Preview
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Container>
          </Box>
        </Box>
        <Box
          position="relative"
          pt={{ xs: pxToRem(41), ssm: pxToRem(51), md: pxToRem(100) }}
          mt={{ md: pxToRem(-30) }}
        >
          <Box
            position="relative"
            pt={{ md: pxToRem(150) }}
            mt={{ md: pxToRem(-110) }}
            mb={{ xs: pxToRem(80), ssm: pxToRem(100), md: pxToRem(150) }}
          >
            <Container disableGutters>
              <Stack
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                gap={{ xs: pxToRem(24), md: "6.5%" }}
              >
                <Sidebar
                  lessons={lessons}
                  isSubscribed={course?.is_subscribed}
                  subscriptionStatus={course?.user_subscription?.status}
                  isFailed={course?.user_subscription?.is_failed}
                  lessonsCount={lessonsCount}
                  isPresubscribed

                  // sx={{ order: { xs: 1, md: 2 } }}
                />
                <Box
                  width="100%"
                  sx={{
                    order: {
                      xs: 2,
                      md: 1,
                      "& .rich-text-display p": { fontSize: pxToRem(16) },
                    },
                  }}
                >
                  <PreSubscribeDescription />

                  {!shouldRenderContent ? (
                    <></>
                  ) : (
                    <>
                      {filteredWhatsIncluded?.length > 0 && (
                        <InfoBlock title="Whats Included?">
                          <ul
                            className={`list-items ${getLanguageClass(
                              filteredWhatsIncluded[0],
                              false,
                              true
                            )}`}
                          >
                            {filteredWhatsIncluded?.map((item, index) => (
                              <li
                                style={{ fontFamily: "Noto Sans Hebrew" }}
                                key={index}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </InfoBlock>
                      )}

                      {filteredWhatsLearned?.length > 0 && (
                        <InfoBlock title="What You’ll Learn?">
                          <ul
                            className={`list-items ${getLanguageClass(
                              filteredWhatsLearned[0],
                              false,
                              true
                            )}`}
                          >
                            {filteredWhatsLearned?.map((item, index) => (
                              <li
                                style={{ fontFamily: "Noto Sans Hebrew" }}
                                key={index}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </InfoBlock>
                      )}
                    </>
                  )}
                  <CourseRatingsComponent
                    course={course}
                    subscribed={false}
                    reviews={reviews}
                    isReviewedByMe={isReviewedByMe}
                    currentReview={currentReview}
                    fetchRatings={fetchRatings}
                  />
                </Box>
              </Stack>
            </Container>
          </Box>
          <VideosSection
            courseId={course?.id}
            categoryId={course?.category?.id}
          />
        </Box>

        {course && (
          <>
            <GiftCourseModal
              open={openGiftModal}
              handleClose={handleCloseGiftModal}
              course={course}
            />
            <Toast
              open={toastOpen}
              onClose={handleCloseToast}
              message={toastMessage}
            />

            <SubscriptionFeedbackModal
              open={feedbackOpen}
              handleClose={handleCloseFeedback}
              modalTitle={modalTitle}
              success={success}
              cancel={cancel}
              // errorMessage={errorMessage}
              showSubscriptionModal={showSubscriptionModal}
            />

            <SubscriptionFeedbackModal
              open={feedbackAssociateOpen}
              handleClose={handleCloseAssociateFeedback}
              modalTitle={modalTitle}
              success={success}
              cancel={cancel}
              errorMessage={errorMessage}
            />

            <SubscriptionFeedbackModal
              open={giftFeedbackOpen}
              handleClose={handleCloseGiftFeedback}
              modalTitle={modalTitle}
              success={success}
              cancel={cancel}
              errorMessage={errorMessage}
            />

            {/* renew prolong feedback modal */}
            <SubscriptionFeedbackModal
              open={openSubscriptionFeedback}
              handleClose={handleCloseModal}
              modalTitle={modalTitle}
              errorMessage={modalMessage}
              success={success}
              unsubscribe // fix this
            />

            <Toast
              open={errorToastOpen}
              onClose={() => setErrorToastOpen(false)}
              message={errorMessage}
            />
          </>
        )}
      </VideoPlayerProvider>
    </PreSubscribeContext.Provider>
  );
};

export default PreSubscribe;
