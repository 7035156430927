import {
  Box,
  Typography,
  Container,
  Grid,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import GradientAnimation from "../../components/GradientAnimation";
import { useEffect, useState } from "react";
import Slider from "../../components/Slider";
import { SwiperSlide } from "swiper/react";
import { getAllBlogs } from "../../api/blogs";
import TopPost from "./components/TopPost";
import FrontPost from "./components/FrontPost";
import { getLanguageClass } from "../../utils/languageClass";
import BlogCard from "./components/BlogCard";
import BlogsSkeleton from "./components/BlogsSkeleton";
import ContentSlider from "../../components/courses/ContentSlider";
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [frontPost, setFrontPost] = useState(null);
  const [blogsDisplayed, setBlogsDisplayed] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const fetchAllBlogs = async () => {
    setIsLoading(true);

    try {
      const res = await getAllBlogs();

      const publishedBlogs = res.data.results.filter((blog) => {
        return new Date(blog.published_at) < new Date();
      });

      const featuredIndex = publishedBlogs.findIndex(
        (blog) => blog.is_featured
      );

      let frontPost = null;

      if (featuredIndex !== -1) {
        frontPost = publishedBlogs[featuredIndex];
        publishedBlogs.splice(featuredIndex, 1);
      }

      setBlogs(publishedBlogs);
      setFrontPost(frontPost);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Blogs and News - Reb Yakov Moshe";
  }, []);

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  const displayMoreBlogs = () => {
    setBlogsDisplayed(blogsDisplayed + 6);
  };

  return (
    <Box position="relative" overflow="hidden">
      <Box
        position="relative"
        pt={{ xs: pxToRem(20), sm: pxToRem(30) }}
        pb={pxToRem(40)}
        minHeight={pxToRem(350)}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: { xs: 0, md: pxToRem(40) },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowX: "clip",
            "&::before": {
              content: "''",
              position: "absolute",
              bottom: pxToRem(30),
              left: "50%",
              transform: "translateX(-125%)",
              background: `url(${TreeImg})`,
              zIndex: 1,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              width: pxToRem(520),
              height: pxToRem(702),
              opacity: 0.4,
              "@media(max-width: 1300px)": {
                left: "30px",
                transform: "none",
              },
              "@media(max-width: 899px)": {
                left: "50%",
                transform: "translateX(-50%)",
              },
            },
          }}
        ></Box>
        <GradientAnimation />
        <Header menuVisible />

        <Box
          className="header-padding"
          sx={{
            flexGrow: 1,
            zIndex: 2,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            fontFamily="PloniBold"
            fontWeight="700"
            textAlign="center"
            px={pxToRem(30)}
            fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
            lineHeight={{ xs: "40px", sm: pxToRem(60) }}
            mb="20px"
          >
            Blogs & News
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: { xs: pxToRem(20), ssm: pxToRem(25) },
              letterSpacing: "-0.5px",
              lineHeight: { xs: "", sm: pxToRem(60) },
              fontWeight: 700,
              opacity: "75%",
              px: pxToRem(20),
              textAlign: "center",
              // display: { xs: "none", ssm: "block" },
            }}
          >
            Read the Latest Updates, Announcements, and Reflections from Reb
            Yakov Moshe
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <BlogsSkeleton />
      ) : blogs.length === 0 && frontPost === null ? (
        <Box
          sx={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "16px", 
                sm: "18px", 
                md: "22px", 
                lg: "30px", 
              },
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            No blogs available yet, stay tuned for exciting new posts, coming
            soon!
          </Typography>
        </Box>
      ) : (
        <Container
          sx={{ px: pxToRem(30), py: { xs: pxToRem(77), md: pxToRem(120) } }}
          disableGutters
        >
          <Grid
            container
            spacing={{ xs: pxToRem(60), ssm: pxToRem(80) }}
            columns={{ xs: 12, lg: 11 }}
          >
            {frontPost && <FrontPost post={frontPost} />}
            <Grid item xs={12} lg={4}>
              {blogs.length > 0 && blogs.some((b) => b.is_featured) && (
                <Typography
                  sx={{
                    marginBottom: pxToRem(20),
                    color: "#333333",
                    fontWeight: 700,
                    fontSize: { xs: pxToRem(22), ssm: pxToRem(25) },
                    lineHeight: pxToRem(32),
                  }}
                >
                  Top Posts
                </Typography>
              )}
              <Grid
                container
                columnSpacing={pxToRem(20)}
                rowSpacing={pxToRem(12)}
                display={{ xs: "none", ssm: "flex" }}
              >
                {blogs &&
                  blogs.length > 0 &&
                  blogs
                    .filter((blog) => blog.is_featured)
                    .slice(0, isTablet ? 4 : 5)
                    .map((blog, index) => <TopPost key={index} post={blog} />)}
              </Grid>
              <Box
                sx={{
                  "& .MuiContainer-root": {
                    p: 0,
                  },
                }}
                mx={{ xs: "-25px", ssm: 0 }}
                display={{ xs: "block", ssm: "none" }}
              >
                <Slider
                  slidesPerView={1.2}
                  spaceBetween={10}
                  arrows
                  slidesOffsetBefore={25}
                  slidesOffsetAfter={25}
                  style={{ marginBottom: "20px" }}
                >
                  {blogs &&
                    blogs.length > 0 &&
                    blogs
                      .filter((blog) => blog.is_featured)
                      .slice(0, 4)
                      .map((blog, index) => {
                        return (
                          <>
                            <SwiperSlide>
                              <Stack
                                flexDirection="row"
                                sx={{
                                  gap: pxToRem(15),
                                  borderRadius: pxToRem(20),
                                  backgroundColor: "#EDECE8",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                  height: "100px",
                                }}
                                onClick={() =>
                                  (window.location.href = `/blog/${blog.id}`)
                                }
                              >
                                <Box
                                  sx={{
                                    padding: {
                                      xs: `${pxToRem(20)}`,
                                      ssm: `${pxToRem(14)} ${pxToRem(
                                        36
                                      )} ${pxToRem(14)} ${pxToRem(15)}`,
                                    },

                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      lineHeight: pxToRem(20),
                                      color: "#333333",
                                      fontWeight: "600",
                                      marginBottom: pxToRem(10),
                                      textAlign: "left",
                                    }}
                                    className={`${getLanguageClass(
                                      blog?.title,
                                      false,
                                      true
                                    )}`}
                                  >
                                    {blog.title}
                                  </Typography>

                                  <Typography
                                    component="p"
                                    fontSize={pxToRem(14)}
                                    fontWeight={600}
                                    color="#BFBEBB"
                                  >
                                    {blog?.author
                                      ? `${blog.author.first_name} ${blog.author.last_name}`
                                      : "Unknown Author"}{" "}
                                    •{" "}
                                    {blog?.published_at
                                      ? new Date(
                                          blog.published_at
                                        ).toLocaleDateString("en-US", {
                                          month: "2-digit",
                                          day: "2-digit",
                                          year: "numeric",
                                        })
                                      : "N/A"}
                                  </Typography>
                                </Box>
                              </Stack>
                            </SwiperSlide>
                          </>
                        );
                      })}
                </Slider>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={pxToRem(20)}
            rowSpacing={pxToRem(40)}
            sx={{
              marginTop: {
                xs: pxToRem(20),
                ssm: pxToRem(60),
                lg: pxToRem(100),
              },
            }}
          >
            {blogs &&
              blogs.length > 0 &&
              blogs
                .slice(0, blogsDisplayed)
                .map((blog, index) => <BlogCard key={index} blog={blog} />)}
            {blogsDisplayed <= blogs.length && (
              <Grid item xs={12} alignItems="center">
                <Stack alignItems="center" justifyContent="center">
                  <Button variant="green" onClick={displayMoreBlogs}>
                    Load More Posts
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
      <Container disableGutters>
        <ContentSlider page={"Blogs"} />
      </Container>
    </Box>
  );
};
export default Blogs;
