import { useEffect, useState } from "react";
import Courses from "./Courses";
import Questions from "./Questions";
import { Skeleton, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as HeartEmpty } from "../../../images/heart-empty.svg";
import { Box, Stack } from "@mui/system";

const Favorites = () => {
  const [noFavorites, setNoFavorites] = useState(false);
  const [noFavCourses, setNoFavCourses] = useState(false);
  const [noFavQuestion, setNoFavQuestion] = useState(false);
  const [favCoursesLoaded, setFavCoursesLoaded] = useState(false);

  useEffect(() => {
    if (noFavQuestion && noFavCourses) {
      setNoFavorites(true);
    } else {
      setNoFavorites(false);
    }
  }, [noFavQuestion, noFavCourses]);
  const renderSkeletons = (count = 3) => {
    return Array.from(new Array(count)).map((_, index) => (
      <Box key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={240}
          sx={{ borderRadius: "16px", marginBottom: pxToRem(10) }}
        />
      </Box>
    ));
  };

  return (
    <>
      {noFavorites &&
        (favCoursesLoaded ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography component="h3" variant="blockTitle" textAlign="center">
              Your Favorites Await!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 0.5,
                marginTop: "10px",
              }}
            >
              <Typography
                component="time"
                fontSize="14px"
                fontWeight={600}
                color="#BFBEBB"
                textAlign="center"
              >
                You haven’t saved any favorites yet. Add content to your personalized list by clicking the
                <Box
                  component="span"
                  sx={{ margin: "0 5px", verticalAlign: "middle" }}
                >
                  <HeartEmpty style={{ width: pxToRem(20) }} />
                </Box>
                icon and creating your collection!
              </Typography>
            </Box>
          </Box>
        ) : null)}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, minmax(0, 1fr))",
            ssm: "repeat(3, minmax(0, 1fr))",
            lg: "repeat(3, minmax(0, 1fr))",
          },
          gap: "30px 20px",
          "@media(max-width: 575px)": {
            gap: "20px 11px",
          },
        }}
      >
        {!favCoursesLoaded && !noFavCourses && renderSkeletons(3)}
      </Box>
      <Courses
        setNoFavCourses={setNoFavCourses}
        setFavCoursesLoaded={setFavCoursesLoaded}
        favCoursesLoaded={favCoursesLoaded}
      />
      <Questions setNoFavQuestion={setNoFavQuestion} />
    </>
  );
};

export default Favorites;
