import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Pagination,
  DialogActions,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Menu,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import ModalHeader from "../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { forwardRef, useEffect, useState } from "react";

// import BookmarkItem from "./BookmarkItem";

import { motion, AnimatePresence } from "framer-motion";

import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { ReactComponent as FilterMenu } from "../../images/filter-menu.svg";
import { ReactComponent as Arrows } from "../../images/down-arrow.svg";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import defaultImage from "../../images/video-slide3.jpg";
import {
  deleteBookmark,
  listAllBookmarkedCourses,
  listAllLecturesBookmarks,
} from "../../api/course";
import BookmarkItemSkeleton from "../../components/bookmarksModal/BookmarkItemSkeleton";
import BookmarkItem from "../../components/bookmarksModal/BookmarkItem";
import BookmarksPagination from "../../components/bookmarksModal/BookmarksPagination";
import { useToastNotification } from "../../context/ToastNotificationContext";
import Toast from "../../components/Toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dummyVideos = [
  {
    id: "vid1",
    thumbnail: defaultImage,
    title: "Video Title 1",
    category: "Category A",
    group: "Group 1",
    subGroup: "SubGroup 1",
  },
  {
    id: "vid2",
    thumbnail: defaultImage,
    title: "Video Title 2",
    category: "Category B",
    group: "Group 2",
    subGroup: "SubGroup 2",
  },
  {
    id: "vid3",
    thumbnail: defaultImage,
    title:
      "long video title test bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
    category: "Category C",
    group: "Group 3",
    subGroup: "SubGroup 3",
  },
];

// reausible move it

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#d6d6d6",
  color: "#333333",
  fontSize: "12px",
  padding: "4px 8px",
  "&:hover": {
    backgroundColor: "#c4c4c4",
  },
  borderRadius: "10px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "200px",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  width: "18px",
  height: "18px",
  pading: "15px 8px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#f3f3f3",
    color: "#333333",
    borderRadius: "50%",
    padding: "2px",
    fontSize: "16px",
  },
  "&:hover .MuiSvgIcon-root": {
    backgroundColor: "#e0e0e0",
  },
}));

const BookmarksModal = ({ open, onClose }) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const isMobile = useMediaQuery("(max-width:470px)");

  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    setToastNotificationMessage,
    toastNotificationMessage,
    handleCloseToast,
  } = useToastNotification();
  const [bookmarksCache, setBookmarksCache] = useState({}); //fix
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarksCount, setBookmarksCount] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [isLoading, setIsLoading] = useState(false);

  /// sort by created at
  const [sortOption, setSortOption] = useState("-id");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  // filter by video
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [videoAnchorEl, setVideoAnchorEl] = useState(null);
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(null);

  // bookmarked Videos

  const [bookmarkedVideos, setBookmarkedVideos] = useState([]);
  const [bookmarkedVideosCount, setBookmarkedVideosCount] = useState(0);
  const [searchVideos, setSearchVideos] = useState("");

  // bookmarks
  useEffect(() => {
    if (!open) return;

    // Create a composite cache key based on page, sortOption, and selectedVideoId
    const cacheKey = `${page}-${sortOption}-${selectedVideoId}`;

    // Check if we already have the results cached for these filters
    if (bookmarksCache[cacheKey]) {
      setBookmarks(bookmarksCache[cacheKey].results);
      setBookmarksCount(bookmarksCache[cacheKey].count);

      return;
    }

    const getBookmarks = async () => {
      setIsLoading(true);
      try {
        const response = await listAllLecturesBookmarks(
          page,
          pageSize,
          selectedVideoId,
          sortOption
        );
        setBookmarks(response.data.results);
        setBookmarksCount(response.data.count);

        // Save results to cache using the composite key
        setBookmarksCache((prev) => ({
          ...prev,
          [cacheKey]: {
            results: response.data.results,
            count: response.data.count,
          },
        }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getBookmarks();
  }, [open, page, sortOption, selectedVideoId]);

  // bookmarked videos
  useEffect(() => {
    if (!open) return;
    const getBookmarkedVideos = async () => {
      // setIsBookmarkedVideosLoading(true);
      try {
        const response = await listAllBookmarkedCourses(searchVideos);
        setBookmarkedVideos(response.data.results);
        setBookmarkedVideosCount(response.data.count);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getBookmarkedVideos();
  }, [searchVideos, open]);

  useEffect(() => {
    console.log("bookmarkedVideos", bookmarkedVideos);
  }, [bookmarkedVideos]);

  //
  const deleteLectureBookmark = async (bookmarkId) => {
    try {
      await deleteBookmark(bookmarkId);
      setTimeout(() => {
        const updatedBookmarks = bookmarks.filter(
          (bookmark) => bookmark.id !== bookmarkId
        );
        const newCount = bookmarksCount - 1;
        setBookmarksCount(newCount);
        const totalPages = Math.ceil(newCount / pageSize);
        if (updatedBookmarks.length === 0 && page > 1) {
          setPage(totalPages);
        }
        setBookmarksCache({});
        setBookmarks(updatedBookmarks);
      }, 350);

      setToastNotificationOpen(true);
      setToastNotificationMessage("Bookmark deleted successfully");
    } catch (error) {
      console.error("Failed to delete bookmark:", error);
      setToastNotificationOpen(true);
      setToastNotificationMessage("Failed to delete bookmark!");
      throw error;
    }
  };

  useEffect(() => {
    if (!open) {
      setBookmarksCache({});
    }
  }, [open]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    handleFilterClose();
  };

  useEffect(() => {
    console.log("sortOption", sortOption);
  }, [sortOption]);

  const handleVideoAnchorClick = (e) => {
    setVideoAnchorEl(e.currentTarget);
  };

  const handleVideoAnchorClose = () => {
    setVideoAnchorEl(null);
  };

  const handleVideoSelect = (videoId, videoName) => {
    setPage(1);
    setSelectedVideoId(videoId);
    setSelectedVideoTitle(videoName);
    handleVideoAnchorClose();

    console.log("Selected video id:", videoId);
  };

  const handleClearFilter = () => {
    setPage(1);
    setSelectedVideoId("");
    setSelectedVideoTitle("");
  };

  return (
    <>
      <Toast
        open={toastNotificationOpen}
        onClose={handleCloseToast}
        message={toastNotificationMessage}
      />

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="645px"
        keepMounted
        scroll="paper"
        TransitionComponent={mobile_ssm ? Transition : undefined}
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiDialog-paper": {
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            maxWidth: { xs: "100%", ssm: "515px", sm: pxToRem(645) },
            width: { xs: "100%", ssm: "515px", sm: pxToRem(645) },
            maxHeight: { xs: "85vh", ssm: "80vh" },
          },

          ...(bookmarksCount < 1 &&
            !isLoading && {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
        }}
      >
        <ModalHeader
          title="Bookmarks"
          classes={{
            title: { fontSize: { xs: pxToRem(25), ssm: pxToRem(32) } },
          }}
          inModal={true}
          onClose={onClose}
        />
        <DialogContent
          sx={{
            minHeight: "240px",
            backgroundColor: "#f7f6f2",
            p: {
              xs: `${pxToRem(30)}`,
              ssm: `${pxToRem(40)} ${pxToRem(50)} ${pxToRem(40)}`,
            },
            ...(bookmarksCount < 1 &&
              !isLoading && {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }),

            overflow: "auto",
            "&::-webkit-scrollbar": { width: pxToRem(6) },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#D9D9D9",
              borderRadius: pxToRem(100),
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#B6B6B6",
              borderRadius: pxToRem(100),
            },
          }}
        >
          {isLoading ? (
            <Box mt={mobile_ssm ? 4 : 3}>
              <BookmarkItemSkeleton coursesBookmarks />
            </Box>
          ) : bookmarksCount < 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize={pxToRem(16)}
                fontWeight={700}
                color="#424242"
                textAlign="center"
              >
                No bookmarks yet. <br />
                Add one while playing to quickly return to any spot!
              </Typography>
            </Box>
          ) : (
            <>
              <Stack spacing={pxToRem(24)} zIndex={0} position="relative">
                {isMobile && (
                  <Typography
                    fontSize={pxToRem(16)}
                    fontWeight={700}
                    color="#424242"
                  >
                    {bookmarksCount}{" "}
                    {bookmarksCount === 1 ? "bookmark" : "bookmarks"}{" "}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-end" : "space-between",
                    alignItems: "center",
                    // backgroundColor:"green"
                  }}
                >
                  {!isMobile && (
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={700}
                      color="#424242"
                    >
                      {bookmarksCount}{" "}
                      {bookmarksCount === 1 ? "bookmark" : "bookmarks"}{" "}
                    </Typography>
                  )}

                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={{ xs: "space-between", ssm: "flex-end" }}
                    gap={{ xs: "15px", ssm: "30px" }}
                  >
                    <Button
                      startIcon={<FilterMenu />}
                      endIcon={
                        <Chevron
                          style={{
                            transition: "transform 0.3s ease",
                            transform: videoAnchorEl
                              ? "rotate(-90deg)"
                              : "rotate(90deg)",
                          }}
                        />
                      }
                      onClick={handleVideoAnchorClick}
                      sx={{
                        color: "#333",
                        fontSize: "14px",
                        "& .MuiButton-endIcon": {
                          ml: pxToRem(12),
                          "& svg": { transform: "rotate(90deg)" },
                        },
                      }}
                    >
                      Filter by Course
                    </Button>

                    <Button
                      startIcon={<Arrows />}
                      endIcon={
                        <Chevron
                          style={{
                            transition: "transform 0.3s ease",
                            transform: filterAnchorEl
                              ? "rotate(-90deg)"
                              : "rotate(90deg)",
                          }}
                        />
                      }
                      onClick={handleFilterClick}
                      sx={{
                        color: "#333",
                        fontSize: "14px",
                        "& .MuiButton-endIcon": {
                          ml: pxToRem(12),
                          "& svg": { transform: "rotate(90deg)" },
                        },
                      }}
                    >
                      {sortOption === "-id" ? "Newest first" : "Oldest first"}
                    </Button>
                  </Stack>
                </Box>

                {selectedVideoId && (
                  <Box
                    sx={{
                      minHeight: pxToRem(40),
                      mt: pxToRem(8),
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {" "}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FilterAltIcon
                        sx={{
                          color: "#bfbebb",
                          fontSize: "20px",
                          width: pxToRem(30),
                          height: pxToRem(30),
                        }}
                      />{" "}
                      <Typography
                        sx={{
                          color: "#bfbebb",
                          fontSize: pxToRem(17),
                          fontWeight: 600,
                        }}
                      >
                        Filter:
                      </Typography>
                    </Box>
                    <StyledButton
                      endIcon={
                        <CustomIconButton
                          size="small"
                          onClick={handleClearFilter}
                        >
                          <CloseIcon />
                        </CustomIconButton>
                      }
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "100%",
                        }}
                      >
                        {selectedVideoTitle}
                      </Box>
                    </StyledButton>
                  </Box>
                )}
                <Stack spacing={pxToRem(24)} zIndex={0} position="relative">
                  <AnimatePresence>
                    {bookmarks?.map((bookmark) => (
                      <motion.div
                        key={bookmark.id}
                        layout
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                      >
                        <BookmarkItem
                          bookmark={bookmark}
                          onDelete={deleteLectureBookmark}
                          coursesBookmarks
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </Stack>
              </Stack>
            </>
          )}
        </DialogContent>
        {bookmarksCount > pageSize && (
          <DialogActions
            sx={{
              backgroundColor: "#f7f6f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: mobile_ssm ? "80px" : "70px",
            }}
          >
            <BookmarksPagination
              count={bookmarksCount / pageSize}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </DialogActions>
        )}

        {/* newest oldest */}
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
          sx={{
            "& .MuiPaper-root": {
              mt: 1,
              ml: "-55px",
              padding: 2,
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              minWidth: "180px",
            },
            "& .MuiList-root": {
              padding: 0,
            },
          }}
        >
          <Stack spacing={2.5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: pxToRem(16),
                fontWeight: 600,
                color: "#026670",
                mb: 1,
              }}
            >
              Sort Bookmarks
            </Typography>
            <FormControl sx={{ mt: "10px !important" }}>
              <RadioGroup value={sortOption} onChange={handleSortChange}>
                <FormControlLabel
                  sx={{
                    py: 1,
                    "& .MuiFormControlLabel-label": {
                      fontSize: pxToRem(15),
                      fontWeight: 500,
                      color: "#333333",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(2, 102, 112, 0.04)",
                      borderRadius: "8px",
                    },
                  }}
                  value="-id"
                  control={
                    <Radio
                      sx={{
                        color: "#026670",
                        p: 1,
                        "&.Mui-checked": { color: "#026670" },
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                    />
                  }
                  label="Newest first"
                />
                <FormControlLabel
                  sx={{
                    py: 1,
                    "& .MuiFormControlLabel-label": {
                      fontSize: pxToRem(15),
                      fontWeight: 500,
                      color: "#333333",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(2, 102, 112, 0.04)",
                      borderRadius: "8px",
                    },
                  }}
                  value="id"
                  control={
                    <Radio
                      sx={{
                        color: "#026670",
                        p: 1,
                        "&.Mui-checked": { color: "#026670" },
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                    />
                  }
                  label="Oldest first"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Menu>
        {/* course filters */}
        <Menu
          anchorEl={videoAnchorEl}
          open={Boolean(videoAnchorEl)}
          onClose={handleVideoAnchorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              mt: 1,
              ml: "-71px",
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              width: "320px",
              maxHeight: "300px",
              overflow: "auto",
            },
          }}
          sx={{
            "& .MuiList-root": {
              padding: 0,
            },
          }}
        >
          {bookmarkedVideos && (
            <Box sx={{ p: pxToRem(16) }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: pxToRem(16),
                  fontWeight: 600,
                  color: "#026670",
                  mb: 1,
                }}
              >
                {bookmarkedVideosCount} bookmarked course
                {bookmarkedVideosCount === 1 ? "" : "s"}
              </Typography>
              <List>
                {bookmarkedVideos?.map((video, index) => (
                  <ListItemButton
                    key={video?.id}
                    onClick={() => handleVideoSelect(video?.id, video?.name)}
                    sx={{
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "rgba(2, 102, 112, 0.04)",
                      },
                      backgroundColor:
                        selectedVideoId === video.id
                          ? "rgba(2, 102, 112, 0.04)"
                          : "inherit",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: index !== bookmarkedVideosCount ? "10px" : 0,
                    }}
                  >
                    <ListItemText
                      sx={{ flex: 1, pr: 2, textAlign: "right" }}
                      primary={
                        <Typography
                          sx={{
                            fontWeight: 600,
                            textAlign: "right",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {video.name}
                        </Typography>
                      }
                    />
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={video.thumb_nail}
                        sx={{ width: pxToRem(90), height: pxToRem(50) }}
                      />
                    </ListItemAvatar>
                  </ListItemButton>
                ))}
              </List>
            </Box>
          )}
        </Menu>
      </Dialog>
    </>
  );
};

export default BookmarksModal;
