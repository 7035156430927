import {
  Box,
  Container,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import LinkWithIcon from "../../components/LinkWithIcon";
import { Stack, styled } from "@mui/system";
import GradientAnimation from "../../components/GradientAnimation";
import TreeImg from "../../images/footer-tree.svg";
import ReusableHeaderBox from "../../components/ReausibleHeaderBox";
import { useLocation, useParams } from "react-router-dom";
import { getBlog } from "../../api/blogs";
import { getLanguageClass } from "../../utils/languageClass";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "../../context/VideoPlayerContext";
import PlayBtn from "../../components/PlayBtn";
import { ReactComponent as Play } from "../../images/play.svg";
import BlogSkeleton from "./components/BlogSkeleton";
import { ReactComponent as Share } from "../../images/share.svg";
import Toast from "../../components/Toast";
import IconBtnCircular from "../../components/IconBtnCircular";
const SingleBlog = () => {
  const location = useLocation();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const mobile_sssm = useMediaQuery(theme.breakpoints.down("sssm"));
  const Label = styled(Typography)(({ theme }) => {
    return {
      display: "flex",
      alignItems: "center",
      height: "20px",
      fontSize: "12px",
      fontWeight: 600,
      color: "#026670",
      backgroundColor: "#FFFFFF",
      // border: "2px solid #f5e093",
      borderRadius: "calc(infinity * 1px)",
      padding: "0 15px",
    };
  });

  const { id } = useParams();
  const [post, setPost] = useState({
    title: "",
    content: "",
    image: "",
    video: "",
  });
  const [toastOpen, setToastOpen] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handlePlayVideo = () => {
    if (post?.video) {
      setIsPlaying(true);
    }
  };

  const fetchBlog = () => {
    setIsLoading(true);
    getBlog(id)
      .then((res) => {
        setPost(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchBlog();
  }, [id]);

  useEffect(() => {
    document.title = post?.title
      ? `${post?.title} - Reb Yakov Moshe`
      : "RebYakovMoshe";
  }, [post]);
  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
        setToastMessage("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  return (
    <Box position="relative" overflow="hidden">
      <ReusableHeaderBox
        treeImg={TreeImg}
        gradientComponent={GradientAnimation}
        headerComponent={() => <Header menuVisible hideUserProfile />}
      />

      <Container
        maxWidth="md"
        sx={{
          px: { xs: "25px", md: "0px" },
          py: { xs: pxToRem(30), ssm: pxToRem(77), md: pxToRem(80) },
        }}
        disableGutters
      >
        <LinkWithIcon
          to="/blogs"
          className="rtl-section"
          sx={{ marginTop: pxToRem(14) }}
          style={{ display: tablet ? "none" : "" }}
        >
          All Blogs
        </LinkWithIcon>
        {isLoading ? (
          <BlogSkeleton />
        ) : (
          <Box
            sx={{
              paddingRight: { xs: "0", sm: "30px", md: pxToRem(40) },
            }}
          >
            <Box
              sx={{
                marginTop: pxToRem(40),
                marginBottom: pxToRem(20),
                textAlign:
                  getLanguageClass(post?.title) === "is-hebrew-text"
                    ? "right"
                    : "left",
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <Label
                sx={{
                  height: "24px",
                  border: "2px solid #f5e093",
                  backgroundColor: "#F9EBB9",
                  display: "inline-block",
                  minWidth: pxToRem(122),
                  textAlign: "center",
                  fontSize: pxToRem(14),
                }}
              >
                Blog
              </Label>
            </Box>

            <Box>
              <Typography
                component="h2"
                sx={{
                  fontSize: pxToRem(30),
                  fontWeight: 700,
                  lineHeight: pxToRem(40),
                  marginBottom: pxToRem(20),
                }}
                className={`${getLanguageClass(post?.title, false, true)}`}
                fontFamily="PloniBold"
              >
                {post.title}
              </Typography>

              <Typography
                component="p"
                fontSize={pxToRem(16)}
                lineHeight={pxToRem(25)}
                fontWeight={600}
                color="#BFBEBB"
                marginBottom={pxToRem(90)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  textAlign:
                    getLanguageClass(post?.title) === "is-hebrew-text"
                      ? "right"
                      : "left",
                }}
              >
                <>
                  {post?.author
                    ? `${post.author.first_name} ${post.author.last_name}`
                    : "Unknown Author"}{" "}
                  •{" "}
                </>
                <>
                  {" "}
                  {post?.published_at
                    ? new Date(post.published_at).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    : "N/A"}
                </>
                <Tooltip title="Share Now" placement="top">
                  <Box
                    sx={{
                      "& button:hover": {
                        backgroundColor: "#00545d !important",
                      },
                    }}
                    onClick={handleShareClick}
                  >
                    <IconBtnCircular
                      className="hover-green active"
                      sx={{
                        width: "36px",
                        height: "36px",
                        p: "10px",
                        mb: "4px",
                        ml: "9px",
                      }}
                    >
                      <Share
                        color="#026670"
                        style={{ marginRight: pxToRem(1.5), height: "24px" }}
                      />
                    </IconBtnCircular>
                  </Box>
                </Tooltip>
              </Typography>
            </Box>
            <Box
              paddingLeft={{
                xs: "20px",
                ssm: "25px",
                sm: "30px",
                md: pxToRem(40),
              }}
              paddingRight={{ xs: "0", sm: "30px", md: pxToRem(40) }}
              position="relative"
            >
              <Box
                position="relative"
                // maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
                width="100%"
                // height={{ xs: "310px", md: "auto" }}
                display="flex"
                borderRadius={pxToRem(20)}
                // boxShadow={`${pxToRem(30)} ${pxToRem(30)} ${pxToRem(
                //   50
                // )} rgba(0,0,0,0.2)`}
                flexShrink={0}
                sx={{
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    left: { xs: "-20px", ssm: "-30px", md: pxToRem(-40) },
                    top: { xs: "-20px", ssm: "-30px", md: pxToRem(-40) },
                    width: { xs: "85%", md: "88%" },
                    height: { xs: "90%", md: "74.1%" },
                    bgcolor: "#FCE181",
                    borderRadius: pxToRem(20),
                    zIndex: -1,
                  },
                }}
              >
                {isPlaying && post?.video ? (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      aspectRatio: "16/9",
                      backgroundColor: "black",
                      borderRadius: "1.25rem",
                    }}
                  >
                    <VideoPlayerProvider key={id}>
                      <VideoPlayer
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        videoUrlCustom={post?.video}
                        useCustomPlayer
                        startsPlaying
                        selectedLesson={{
                          video_url: post?.video,
                        }}
                        thumbnailCustom={post?.thumbnail}
                        isBlog
                      />
                    </VideoPlayerProvider>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%", // Maintain image aspect ratio
                      borderRadius: pxToRem(20),
                      overflow: "hidden", // Ensures no overflow
                      maxHeight: (() => {
                        if (mobile_sssm) return "200px";
                        if (mobile_ssm) return "250px";
                        return "400px";
                      })(),
                    }}
                  >
                    <img
                      src={post?.image ? post.image : post?.thumbnail}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: pxToRem(20),
                        cursor: post?.video ? "pointer" : "default",
                      }}
                    />
                    {post?.video && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)", // Ensures perfect centering
                          zIndex: 1,
                        }}
                      >
                        <PlayBtn
                          width={{ xs: "60px", sm: pxToRem(64) }}
                          height={{ xs: "60px", sm: pxToRem(64) }}
                          onClick={handlePlayVideo}
                        >
                          <Play
                            color="#026670"
                            width={pxToRem(20)}
                            height={pxToRem(24)}
                            onClick={handlePlayVideo}
                          />
                        </PlayBtn>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <Stack
              gap={pxToRem(30)}
              sx={{
                marginTop: pxToRem(60),
                paddingRight: { xs: "0", sm: "30px", md: pxToRem(40) },
              }}
            >
              <Box
                className={`slideTitle ${getLanguageClass(post?.content)}`}
                sx={{
                  fontSize: pxToRem(18),
                  fontWeight: 500,
                  lineHeight: pxToRem(30),
                }}
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              />
            </Stack>
          </Box>
        )}
      </Container>
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </Box>
  );
};
export default SingleBlog;
