import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Hero from "./Hero";
import TagsCloud from "../../components/TagsCloud";
import Presentation from "./Presentation";
import Philosophy from "./Philosophy";
import BlogNews from "./BlogNews";
import FAQ from "./FAQ";
import Parsa from "./Parsa";
import Courses from "./Courses";
import Promotion from "./Promotion";
import VideoSlider from "./VideoSlider";
import { unsubscribeFromEmail } from "../../api/student";
import { useAuth } from "../../context/AuthContext";
import { useModal } from "mui-modal-provider";
import { useLocation } from "react-router-dom";
import { getPromotionSection } from "../../api/cmsApi";
import { pxToRem } from "px2rem2px";
import FeaturedCourse from "./FeaturedCourse";
import capitalizeFirstLetter from "../../utils/capitalize";
import UnsubscribeFromNotifications from "../../components/UnsubscribeFromNotifications";
import { useStudentProfile } from "../../hooks/useStudentProfile";

const Home = () => {
  const [promotionSectionOrder, setPromotionSectionOrder] = useState(4);
  const [promotionSectionDates, setPromotionSectionDates] = useState({
    start_date: "",
    end_date: "",
  });
  const fetchPromotionSectionOrder = () => {
    getPromotionSection()
      .then((res) => {
        console.log(res.data);
        setPromotionSectionOrder(res.data.position);
        setPromotionSectionDates({
          start_date: res.data.start_date,
          end_date: res.data.end_date,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    document.title = "Learn and Grow with Reb Yakov Moshe";
    fetchPromotionSectionOrder();
  }, []);
  console.log("promotionSectionOrder", promotionSectionOrder);
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();

  const { showModal, hideModal } = useModal();
  const location = useLocation();

  console.log("isAuthenticated", isAuthenticated);

  const { student } = useStudentProfile();

  const nickName = student?.nickname
    ? capitalizeFirstLetter(student.nickname)
    : "";
  const firstName = student?.user?.first_name
    ? capitalizeFirstLetter(student.user.first_name)
    : "";

  //start: unsubscribe from notifications
  const [openUnsubscribeFeedback, setOpenUnsubscribeFeedback] = useState(false);
  const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);
  const [notificationType, setNotificationType] = useState(false);
  const [unsubscribeToken, setUnsubscribeToken] = useState(null);
  const [successfullySubsucribed, setSuccessfullySubsucribed] = useState(false);

  const removeUnsubscribeParamFromURL = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("unsubscribe");
    window.history.replaceState({}, document.title, url);
  };

  const unsubscribeHelperFun = async (token, resubscribe) => {
    try {
      const res = await unsubscribeFromEmail(token, resubscribe);

      console.log("unsubscribe from email response", res);
      setIsAlreadyUnsubscribed(
        res?.is_already_unsubscribed && !res?.has_resubscribed
      );
      setNotificationType(res?.notification_type);
      setSuccessfullySubsucribed(res?.has_resubscribed);
      setOpenUnsubscribeFeedback(true);
    } catch (error) {
      console.log("unsubscribed error", error);
    }
  };

  const handleCloseModal = () => {
    setOpenUnsubscribeFeedback(false);
    setIsAlreadyUnsubscribed(false);
    setNotificationType(false);
    setUnsubscribeToken(null);
    setSuccessfullySubsucribed(false);
    removeUnsubscribeParamFromURL();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const unsubscribe = params.get("unsubscribe");
    if (unsubscribe) {
      unsubscribeHelperFun(unsubscribe);
      setUnsubscribeToken(unsubscribe);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribeToken = localStorage.getItem("unsubscribeToken");
    if (unsubscribeToken) {
      unsubscribeHelperFun(unsubscribeToken);
      localStorage.removeItem("unsubscribeToken");
    }
  }, []);

  //end: unsubscribe from notifications

  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const sections = [
    { id: 1, component: <Hero /> },
    { id: 2, component: <TagsCloud /> },
    {
      id: 3,
      component: isAuthenticated ? (
        <VideoSlider name={nickName || firstName} />
      ) : (
        <Box height={{ xs: pxToRem(70), sm: 120 }} />
      ),
    },
    {
      id: 4,
      component: (
        <Presentation
          currentlyPlayingId={currentlyPlayingId}
          setCurrentlyPlayingId={setCurrentlyPlayingId}
        />
      ),
    },
    {
      id: 5,
      component: (
        <FeaturedCourse
          currentlyPlayingId={currentlyPlayingId}
          setCurrentlyPlayingId={setCurrentlyPlayingId}
        />
      ),
    },
    { id: 6, component: <Philosophy /> },
    { id: 7, component: <Courses /> },
    {
      id: 8,
      component: (
        <Parsa
          currentlyPlayingId={currentlyPlayingId}
          setCurrentlyPlayingId={setCurrentlyPlayingId}
        />
      ),
    },
    { id: 9, component: <BlogNews student={student} /> },
    { id: 10, component: <FAQ /> },
    // { id: 10, component: <Parsa /> },
  ];
  if (
    promotionSectionOrder > 0 &&
    promotionSectionOrder <= sections.length + 1 &&
    new Date() >= new Date(promotionSectionDates.start_date) &&
    (!promotionSectionDates.end_date ||
      new Date() <= new Date(promotionSectionDates.end_date))
  ) {
    sections.splice(promotionSectionOrder - 1, 0, {
      id: 11,
      component: <Promotion />,
    });
  }
  return (
    <Box position="relative" overflow="hidden">
      {sections.map((section, index) => (
        <Box key={index}>{section.component}</Box>
      ))}

      <UnsubscribeFromNotifications
        open={openUnsubscribeFeedback}
        handleClose={handleCloseModal}
        notificationType={notificationType}
        isAlreadyUnsubscribed={isAlreadyUnsubscribed}
        unsubscribeHelperFun={unsubscribeHelperFun}
        token={unsubscribeToken}
        successfullySubsucribed={successfullySubsucribed}
      />
    </Box>
  );
};

export default Home;
