import { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Drawer,
  Stack,
  useTheme,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { useModal } from "mui-modal-provider";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import LinkBtn from "./LinkBtn";

import MenuDropdownLink from "./menuDropdown/MenuDropdownLink";
import MenuDropdown from "./menuDropdown/MenuDropdown";
import Toast from "./Toast";
import ToastCookie from "./ToastCookie";
import ProfileMenu from "./ProfileMenu";
import { saveStudentLocal, useAuth } from "../context/AuthContext";
import { getNotifications, getUserInfo } from "../api/student";
import { ReactComponent as Menu } from "../images/menu.svg";
import { ReactComponent as Search } from "../images/search.svg";
import { ReactComponent as User } from "../images/user.svg";

import { ReactComponent as Car } from "../images/car.svg";

import LoginModal from "../pages/auth/LoginModal";
import NotificationsDropdown from "./NotificationsDropdown";
import LinkWithIcon from "./LinkWithIcon";
import TestimonialMenu from "./TestimonialMenu";

import { getNavigationMenu, getGeneralSettings } from "../api/cmsApi";
import { url } from "../api/config";
import MenuInner from "./MenuInner";
import Playback from "../pages/user/playBack";
import capitalizeFirstLetter from "../utils/capitalize";
import { useCMSData } from "../context/CMSDataContext";
import Logo from "./Logo";
import HeaderSearchComponent from "./HeaderSearchComponent";
const Header = (props) => {
  const { navigationMenu, logo } = useCMSData();
  const { showModal } = useModal();

  const [isPlaybackOpen, setIsPlaybackOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [toast1, setToast1] = useState(false);
  const [toast2, setToast2] = useState(false);
  const [toast3, setToast3] = useState(false);
  const header = useRef(null);
  const { pathname } = useLocation();
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const userInfo = getUserInfo();

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const location = useLocation();

  const presubscribeAndSubscribed = location.pathname.startsWith("/course/");

  const presubscribeOnly =
    location.pathname.startsWith("/course/") &&
    location.pathname.includes("presubscribe");

  const subscribedOnly =
    location.pathname.startsWith("/course/") &&
    !location.pathname.includes("presubscribe");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast1(false);
    setToast2(false);
    setToast3(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const openPopup = () => {
    toggleDrawer(false)({ type: "click" });
    showModal(LoginModal);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sticky = header.current.offsetTop;

      if (window.scrollY > sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // notifications start

  const [nextPage, setNextPage] = useState(1);

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState([]);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchNotifications = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await getNotifications({ page });
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...response?.data?.results?.filter(
          (newNotification) =>
            !prevNotifications.some(
              (prevNotification) => prevNotification.id === newNotification.id
            )
        ),
      ]);
      setHasMore(response.data.next !== null);
      setNotificationsCount(response?.data?.count);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotifications(nextPage);
    }
  }, [nextPage, fetchNotifications]);

  // notifications end

  // full name

  const nickName = userInfo?.nickname
    ? capitalizeFirstLetter(userInfo.nickname)
    : "";
  const firstName = userInfo?.user?.first_name
    ? capitalizeFirstLetter(userInfo.user.first_name)
    : "";

  useEffect(() => {
    if (userInfo && userInfo.timestamp) {
      const currentTime = Date.now();
      const diff = currentTime - userInfo.timestamp;
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      const fiftyMinutesInMs = 50 * 60 * 1000;

      if (diff < fiftyMinutesInMs) {
        console.log("User data is fresh");
      } else {
        saveStudentLocal()
          .then(() => {
            console.log("User data is fresh Saved student data locally");
          })
          .catch((error) => {
            console.error(
              "User data is fresh Failed to save student data locally:",
              error
            );
          });
      }
    } else {
    }
  }, [userInfo]);

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  const [showSearchTabs, setShowSearchTabs] = useState(false);

  const toggleSearchTabs = () => {
    setShowSearchTabs((prev) => !prev);
  };

  useEffect(() => {
    if (!mobile_ssm) {
      return;
    }
    if (showSearchTabs) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      if (showSearchTabs) {
        document.body.style.overflow = "auto";
      }
    };
  }, [mobile_ssm, showSearchTabs]);

  const toggleDrivingMode = () => {
    props?.setDrivingMode(!props?.drivingMode);
  };

  return (
    <>
      <Box
        ref={header}
        className={!isSticky ? "non-sticky" : tablet ? "non-sticky" : "sticky"}
        width="100%"
        zIndex={100}
      >
        <Stack
          maxWidth={pxToRem(1380)}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pt={{ xs: pxToRem(20), ssm: pxToRem(30), md: "10px" }}
          pb={{ xs: pxToRem(20), ssm: pxToRem(30), md: "10px" }}
          m="auto"
        >
          <Stack
            flex={1}
            flexDirection="row"
            alignItems="center"
            columnGap={{ xs: 0, lg: pxToRem(15) }}
          >
            {!props.menuVisible || tablet ? (
              <Box
                mr={`${
                  props?.isSingleVideo
                    ? 0
                    : (presubscribeAndSubscribed ? 0 : 20) +
                      (subscribedOnly && !mobile_ssm ? 20 : 0)
                }px`}
              >
                <IconBtnCircular onClick={toggleDrawer(true)}>
                  <Menu />
                </IconBtnCircular>
              </Box>
            ) : null}
            {props?.isSingleVideo && tablet && (
              <LinkWithIcon to="/videos" sx={{ marginTop: pxToRem(14) }} />
            )}
            {subscribedOnly && mobile_ssm && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }} />
            )}
            {presubscribeOnly && tablet && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }} />
            )}

            {((subscribedOnly && !mobile_ssm) || !subscribedOnly) && (
              <Box
                position="relative"
                sx={{ zIndex: 1, width: "100px", height: "100%" }}
              >
                <Logo
                  logo={logo}
                  style={{
                    width: mobile_ssm ? "85px" : "100px",
                    height: "auto",
                  }}
                />
              </Box>
            )}

            {props.menuVisible && !tablet ? (
              <Stack
                flex={1}
                flexDirection="row"
                alignItems="center"
                justifyContent={{ xs: "space-between", lg: "flex-start" }}
                columnGap={{ xs: pxToRem(15), lg: pxToRem(56) }}
                mx={pxToRem(40)}
              >
                {navigationMenu &&
                  navigationMenu.map((item, index) => {
                    if (!item.submenus.length) {
                      return (
                        <LinkBtn
                          url={item.url}
                          title={item.label}
                          key={index}
                          newTab={item.target_new_tab}
                        />
                      );
                    } else if (item.submenus.length > 0) {
                      return (
                        <MenuDropdown
                          title={item.label}
                          key={index}
                          submenus={item.submenus}
                          url={item.url}
                          newTab={item.target_new_tab}
                        >
                          {item.submenus.map((subitem, subIndex) => (
                            <MenuDropdownLink
                              to={subitem.url}
                              key={subIndex}
                              newTab={subitem.target_new_tab}
                            >
                              {subitem.label}
                            </MenuDropdownLink>
                          ))}
                        </MenuDropdown>
                      );
                    }
                    return null;
                  })}
              </Stack>
            ) : null}
          </Stack>

          <Stack flexDirection="row" alignItems="center" gap="10px">
            {subscribedOnly && tablet && (
              <>
                {!props?.pausedOrExpired && (
                  <IconBtnCircular onClick={toggleDrivingMode}>
                    <Car />
                  </IconBtnCircular>
                )}

                {mobile_ssm && (
                  <TestimonialMenu sx={{ mt: "0" }}>
                    <MenuItem onClick={() => setIsPlaybackOpen(true)}>
                      Media Settings
                    </MenuItem>
                  </TestimonialMenu>
                )}
              </>
            )}
            {(!subscribedOnly || (subscribedOnly && !mobile_ssm)) && (
              <Box sx={{ position: { ssm: "relative" } }}>
                <IconBtnCircular
                  className="search-icon"
                  onClick={toggleSearchTabs}
                >
                  <Search color="#026670" />
                </IconBtnCircular>
                {showSearchTabs && (
                  <Box sx={{ position: "absolute", zIndex: 3, width: "100%" }}>
                    <HeaderSearchComponent
                      toggleSearchTabs={toggleSearchTabs}
                    />
                  </Box>
                )}
              </Box>
            )}

            {!mobile_ssm &&
              (isAuthenticated ? (
                <>
                  {mobile ? null : notifications.length > 0 ? (
                    <IconBtnCircular>
                      <NotificationsDropdown
                        notifications={notifications}
                        setNotifications={setNotifications}
                        nextPage={setNextPage}
                        hasMore={hasMore}
                        setHasMore={setHasMore}
                        loading={loading}
                        count={notificationsCount}
                      />
                    </IconBtnCircular>
                  ) : null}

                  <ProfileMenu
                    notifications={notifications}
                    setNotifications={setNotifications}
                    nextPage={setNextPage}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    loading={loading}
                    count={notificationsCount}
                  />
                </>
              ) : (
                <Box onClick={() => openPopup()}>
                  <IconBtnCircular>
                    <User height="19.99" width="19.99" />
                  </IconBtnCircular>
                </Box>
              ))}
          </Stack>
          <Drawer
            anchor="right" // Change anchor to "right" to open from the right
            open={isPlaybackOpen}
            onClose={() => setIsPlaybackOpen(false)}
            sx={{
              zIndex: 1500,
              "& .MuiPaper-root": {
                padding: 5,
                maxWidth: pxToRem(420),
                width: "100%",
                bgcolor: "#F7F6F2",
                borderRadius: `${pxToRem(40)} 0 0 ${pxToRem(40)}`, // Adjust border radius for right side
              },
            }}
          >
            <Playback
              isHeader={true}
              closeDrawer={() => {
                setIsPlaybackOpen(false);
              }}
            />
          </Drawer>
          <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleDrawer(false)}
            sx={{
              zIndex: 1500,
              "& .MuiPaper-root": {
                maxWidth: pxToRem(420),
                width: "100%",
                bgcolor: "#F7F6F2",
                borderRadius: `0 ${pxToRem(40)} ${pxToRem(40)} 0`,
                overflowY: "initial",
                overflow: "hidden",
                "@media(max-width: 400px)": {
                  borderRadius: 0,
                },
              },
            }}
          >
            <Box
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <MenuInner
                isAuthenticated={isAuthenticated}
                userInfo={userInfo}
                openPopup={openPopup}
                toggleDrawer={toggleDrawer}
                handleClose={handleClose}
                handleCloseDrawer={handleCloseDrawer}
                navigationMenu={navigationMenu}
                pathname={pathname}
                notifications={notifications}
                setNotifications={setNotifications}
                setNextPage={setNextPage}
                hasMore={hasMore}
                setHasMore={setHasMore}
                loading={loading}
                notificationsCount={notificationsCount}
                logout={logout}
                nickName={nickName}
                firstName={firstName}
              />
            </Box>
          </Drawer>
        </Stack>
      </Box>
      <Toast
        open={toast1}
        onClose={handleClose}
        message="Toast notification text here"
        button
      />
      <Toast
        open={toast2}
        onClose={handleClose}
        message="Toast notification text here"
      />
      <ToastCookie
        open={toast3}
        onClose={handleClose}
        message="Nemo enim ipsam voluptatem
        quia voluptas sit aspernatur"
      />
    </>
  );
};

export default Header;
