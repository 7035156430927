import { forwardRef } from "react";
import ModalHeader from "../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComingSoonDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="ssm"
      keepMounted
      scroll={mobile_ssm ? "paper" : "body"}
      TransitionComponent={mobile_ssm ? Transition : undefined}
      sx={{
        "& .MuiDialog-container": {
          alignItems: { xs: "flex-end", ssm: "center" },
        },
        "& .MuiDialog-paper": {
          borderRadius: {
            xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
            ssm: pxToRem(20),
          },
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          maxWidth: { xs: "100%", ssm: "500px" },
          width: { xs: "100%", ssm: "500px" },
          maxHeight: { xs: "85vh", ssm: "none" },
        },
      }}
    >
      <ModalHeader title="Coming Soon!" inModal={true} onClose={onClose} />
      <DialogContent
        sx={{
          p: {
            xs: `${pxToRem(30)}`,
            ssm: `${pxToRem(40)} ${pxToRem(50)} ${pxToRem(40)}`,
          },
        }}
      >
        <Typography
          fontSize={pxToRem(16)}
          fontWeight={500}
          lineHeight={pxToRem(26)}
        >
          This feature is not yet available. If you are subscribed to our
          newsletter, we will notify you as soon as it’s ready. Stay tuned!
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ComingSoonDialog;
