import { useEffect, useState } from "react";
import { Box, Button, Typography, Container, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { pxToRem } from "px2rem2px";

import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import Philosophy from "../../images/philosophy-img.png";
import CustomerService from "../../images/customer-service.svg";
import GradientAnimation from "../../components/GradientAnimation";
import { getLanguageClass } from "../../utils/languageClass";
import { getAboutUs } from "../../api/cmsApi";
import { url } from "../../api/config";
import highlightHTML from "../../utils/highlightHTML";
const aboutUsCardsDetails = [
  {
    icon: Philosophy,
    title: "Need Personal Guidance?",
    description:
      "Schedule an appointment now to get personalized guidance and support. <span style='font-weight: 700;'> Reb Yakov Moshe </span> is here to answer your questions and provide the help.",
    btnName: "Schedule Appointment",
    rounded: true,
    link: "/booking",
  },
  {
    icon: CustomerService,
    title: "Technical Support",
    description:
      "For  <span style='font-weight: 700;'> website or account support, </span> contact our technical support team for quick and efficient assistance with any issues you encounter.",
    btnName: "Contact Support",
    link: "/contact",
  },
];

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Learn More About Reb Yakov Moshe";
  }, []);

  const [aboutUs, setAboutUs] = useState({ title: "", body: "", image: null });
  const fetchAboutUs = () => {
    getAboutUs()
      .then((res) => setAboutUs(res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAboutUs();
  }, []);
  return (
    <>
      <Box position="relative" overflow="hidden">
        <Box
          position="relative"
          pt={{ xs: pxToRem(20), sm: pxToRem(30) }}
          pb={pxToRem(40)}
          minHeight={pxToRem(350)}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: { xs: 0, md: pxToRem(40) },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              overflowX: "clip",
              "&::before": {
                content: "''",
                position: "absolute",
                bottom: pxToRem(30),
                left: "50%",
                transform: "translateX(-125%)",
                background: `url(${TreeImg})`,
                zIndex: 1,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                width: pxToRem(520),
                height: pxToRem(702),
                opacity: 0.4,
                "@media(max-width: 1300px)": {
                  left: "30px",
                  transform: "none",
                },
                "@media(max-width: 899px)": {
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              },
            }}
          ></Box>
          <GradientAnimation />
          <Header menuVisible />

          <Box
            className="header-padding"
            sx={{
              flexGrow: 1,
              zIndex: 2,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "-40px",
            }}
          >
            {aboutUs.title && (
              <>
                <Box
                  maxWidth={500}
                  textAlign={"center"}
                  dangerouslySetInnerHTML={{
                    __html: highlightHTML(aboutUs.title),
                  }}
                  className={getLanguageClass(aboutUs?.title) + " page-titles"}
                />
              </>
            )}
          </Box>
        </Box>
        <Container
          sx={{ px: pxToRem(30), py: { xs: pxToRem(77), md: pxToRem(120) } }}
          disableGutters
        >
          <Box container spacing={11} sx={{ alignItems: "center" }}>
            <Box>
              <Box
                position="relative"
                maxWidth={{
                  xs: "300px",
                  ssm: "270px",
                  sm: "310px",
                  md: "400px",
                  lg: pxToRem(500),
                }}
                width="100%"
                height="auto"
                display="flex"
                borderRadius={pxToRem(20)}
                flexShrink={0}
                sx={{
                  marginLeft: {
                    xs: "auto",
                    ssm: pxToRem(38),
                    sm: pxToRem(50),
                    lg: pxToRem(89),
                  },
                  marginBottom: {
                    xs: pxToRem(51),
                    ssm: pxToRem(40),
                    sm: pxToRem(50),
                  },
                  marginRight: { xs: "auto", ssm: pxToRem(24), lg: 0 },
                  float: { ssm: "right" },
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    right: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                    bottom: { xs: "-30px", md: pxToRem(-40) },
                    width: { xs: "93%", md: "88%" },
                    height: { xs: "95%", md: "74.1%" },
                    maxWidth: "100%",
                    bgcolor: "#FCE181",
                    borderRadius: pxToRem(20),
                    zIndex: -1,
                  },
                }}
              >
                <img
                  src={url + aboutUs.image}
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: pxToRem(500),
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: pxToRem(20),
                    transform: "scale(-1, 1)",
                  }}
                />
              </Box>
              {aboutUs.body && (
                <Box
                  sx={{
                    fontSize: { xs: pxToRem(15), sm: pxToRem(22) },
                    fontWeight: "500",
                    marginBottom: pxToRem(80),
                  }}
                  dangerouslySetInnerHTML={{ __html: aboutUs.body }}
                  className={getLanguageClass(aboutUs?.body)}
                />
              )}
              {/*  <Typography
              sx={{
                marginBottom: { xs: pxToRem(18), md: pxToRem(33) },
                fontWeight: 700,
                pt: pxToRem(35),
                fontSize: { xs: pxToRem(36), sm: pxToRem(42), md: pxToRem(45) },
              }}
              className="rtl-section"
              variant="sectionTitle"
              component="h2"
            >
              וואס מיר טוען
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: pxToRem(22), sm: pxToRem(25), md: pxToRem(32) },
                lineHeight: { xs: pxToRem(37), sm: pxToRem(48) },
              }}
              className="rtl-section"
            >
              נוהגים להרבות בתעניתים בשני וחמישי משום דאמרין במדרש זמרע' ה' עלה
              בחמישי לקבל לוחות אחרונות וירד בשני. ואומרים והוא רחום. ויש במדרש
              זג' זקנים תקנוהו. שתפסם מלך אחד ונתנם בג' ספינות בלא מנהיג. ושלחם
              לים ונתפזרו למרחקים. ולמקום שבאו הציר להם מאד ועמדו בתפלה וכל אחד
              יסד מקצת. ובכל חלק ח״ח שמות
            </Typography> */}
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", width: "100%" }}>
          <Typography
            sx={{
              pt: { xs: pxToRem(62), md: pxToRem(70) },
              mb: pxToRem(80),
              fontWeight: 600,
              fontSize: { xs: pxToRem(20), sm: pxToRem(22), md: pxToRem(32) },
              lineHeight: { xs: pxToRem(37), sm: pxToRem(48) },
            }}
            className="rtl-section"
          >
            נוהגים להרבות בתעניתים בשני וחמישי משום דאמרין במדרש זמרע' ה' עלה
            בחמישי לקבל לוחות אחרונות וירד בשני. ואומרים והוא רחום. ויש במדרש
            זג' זקנים תקנוהו. שתפסם מלך אחד ונתנם בג' ספינות בלא מנהיג. ושלחם
            לים ונתפזרו למרחקים. ולמקום שבאו הציר להם מאד ועמדו בתפלה וכל אחד
            יסד מקצת. ובכל חלק ח״ח שמות כנגד תפלת י״ח. ויחת אותו מלך שהציר להם
            בתחלואים רעים. ושלחו תפלתם בכל תפוצות ישראל לאמרה בשני וחמישי נוהגים
            להרבות בתעניתים בשני וחמישי משום דאמרין במדרש זמרע' ה' עלה בחמישי
            לקבל לוחות אחרונות וירד בשני. ואומרים והוא רחום. ויש במדרש זג' זקנים
            תקנוהו. שתפסם מלך אחד ונתנם בג' ספינות בלא מנהיג. ושלחם לים ונתפזרו
            למרחקים. ולמקום שבאו הציר להם מאד ועמדו בתפלה וכל אחד יסד מקצת. ובכל
            חלק ח״ח שמות כנגד תפלת י״ח. ויחת אותו מלך שהציר להם בתחלואים רעים.
            ושלחו תפלתם בכל תפוצות ישראל לאמרה בשני וחמישי
          </Typography>
        </Box> */}
          <Grid container spacing={{ xs: 2.5, lg: 5.5 }}>
            {aboutUsCardsDetails.map((cardDetails, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Stack
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: pxToRem(20),
                    flexDirection: { md: "row" },
                    gap: pxToRem(26),
                    padding: {
                      xs: `${pxToRem(40)} ${pxToRem(30)}`,
                      ssm: `${pxToRem(50)} ${pxToRem(45)}`,
                      md: pxToRem(60),
                    },
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: pxToRem(72),
                      height: pxToRem(72),
                      borderRadius: cardDetails.rounded ? pxToRem(100) : "none",
                      overflow: "hidden",
                      flexShrink: "0",
                      mx: { xs: "auto", md: "0" },
                    }}
                  >
                    <img src={cardDetails.icon} alt={cardDetails.title} />
                  </Box>
                  <Stack sx={{ justifyContent: "space-between" }}>
                    <Box>
                      <Typography
                        variant="blockTitle"
                        component="h3"
                        fontSize={pxToRem(25)}
                        pb={pxToRem(15)}
                        textAlign={{ xs: "center", md: "start" }}
                      >
                        {cardDetails.title}
                      </Typography>
                      <Typography
                        variant="medium"
                        component="p"
                        fontSize={{ xs: pxToRem(16), md: pxToRem(18) }}
                        textAlign={{ xs: "center", md: "start" }}
                        dangerouslySetInnerHTML={{
                          __html: cardDetails.description,
                        }}
                      />
                    </Box>
                    <Box textAlign={{ xs: "center", md: "start" }}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ mt: { xs: pxToRem(20), ssm: pxToRem(30) } }}
                        onClick={() =>
                          (window.location.href = cardDetails.link)
                        }
                      >
                        {cardDetails.btnName}
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default AboutUs;
