import React from "react";
import { Avatar } from "@mui/material";
import { pxToRem } from "px2rem2px";
import AvatarImg from "../images/avatar.png";

const MemoizedAvatar = React.memo(({ src, alt, ...props }) => {
  return (
    <Avatar
      sx={{ width: pxToRem(48), height: pxToRem(48), backgroundColor: "white" }}
      {...props}
    >
      <img src={src || AvatarImg} alt={alt || "User avatar"} />
    </Avatar>
  );
});

export default MemoizedAvatar;
