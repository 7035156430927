import React from "react";
import { Grid } from "@mui/material";

import Slide from "../../../components/Slide";

export default function BlogCard({ blog }) {
  return (
    <Grid
      item
      xs={12}
      ssm={6}
      md={4}
      sx={{ cursor: "pointer" }}
      onClick={() => window.open(`/blog/${blog.id}`, "_self")}
    >
      <Slide
        image={blog?.image ? blog?.image : blog?.thumbnail}
        title={blog?.title}
        text={blog?.content}
        blogLink={`/blog/${blog.id}`}
        date={
          blog?.published_at
            ? new Date(blog.published_at).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "N/A"
        }
        label="New Post"
        author={
          blog?.author
            ? `${blog.author.first_name} ${blog.author.last_name}`
            : "Unknown Author"
        }
        isBlog
        style={{ flexGrow: 1 }}
      />
    </Grid>
  );
}
