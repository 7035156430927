import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import Slider from "../Slider";
import { pxToRem } from "px2rem2px";

const content = [
  {
    id: 0,
    title: "Courses",
    url: "/courses",
  },
  {
    id: 1,
    title: "Weekly Shiurim",
    url: "/videos",
  },
  {
    id: 2,
    title: "Blogs",
    url: "/blogs",
  },
  {
    id: 3,
    title: "Products",
    url: "https://a.co/d/eWA8joL",
  },
  {
    id: 4,
    title: "Donate",
    url: "/donate",
  },
  {
    id: 5,
    title: "About Us",
    url: "/about-us",
  },
];

const ContentSlider = ({ page }) => {
  return (
    <Box pb="100px">
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize={pxToRem(35)}
        mb="35px"
      >
        <span className="highlighted">Discover</span> More
      </Typography>

      <Box
        sx={{
          "& .MuiContainer-root": {
            p: { lg: "0 100px" },
          },
          "& .swiper-slide": { width: "auto" },
          "& .arrow-prev": { left: "-100px" },
          "& .arrow-next": { right: "-100px" },
        }}
      >
        <Slider
          slidesPerView="auto"
          spaceBetween={10}
          arrows
          containerPadding={{
            px: { xs: 0, ssm: "25px" },
            "& .swiper": {
              mb: "0",
              "@media (max-width: 1330px)": { mb: "20px" },
            },
          }}
     
        >
          {content
            .filter((v) => v.title !== page)
            .map((v) => (
              <SwiperSlide
                key={v.id}
                onClick={() => (window.location.href = v.url)}
              >
                <Button variant="outlined">{v.title}</Button>
              </SwiperSlide>
            ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ContentSlider;
