import React, { useState, useEffect, useRef, useCallback } from "react";
import { useModal } from "mui-modal-provider";
import {
  Box,
  Button,
  Stack,
  Typography,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import TextLink from "../../components/TextLink";
import {
  createCheckoutSession,
  resolveCheckoutSession,
} from "../../api/payment";
import GiftCourseModal from "../preSubscribe/GiftCourseModal";
import { ReactComponent as Gift } from "../../images/gift.svg";
import IconBtnCircular from "../../components/IconBtnCircular";
import Toast from "../../components/Toast";
import { Share } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Info } from "../../images/info.svg";
import InviteAssociateModal from "../user/dashboard/InviteAssociateModal";
import PurchaseModal from "../preSubscribe/PurchaseModal";
import ThankModal from "../preSubscribe/ThankModal";
import ErrorModal from "../preSubscribe/ErrorModal";
import ProlongModal from "./components/ProlongModal";
import { isTodayBetweenDates, isWithin21Days } from "../../utils/format";
import { useTheme } from "@emotion/react";
import CoursePayments from "../../components/CoursePayments";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(18),
  fontWeight: 600,
  lineHeight: pxToRem(30),
  "& span": {
    display: "inline-block",
    [theme.breakpoints.up("xs")]: {
      width: pxToRem(110),
    },
    [theme.breakpoints.up("sssm")]: {
      width: pxToRem(152),
    },
    color: "#BFBEBB",
    marginRight: pxToRem(20),
  },
}));

const formatDate = (dateString) => {
  if (!dateString) return "Never";
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const InfoTooltipButton = ({
  tooltipOpen,
  title,
  buttonRef,
  onClick,
  placement,
}) => (
  <Tooltip
    open={tooltipOpen}
    title={title}
    enterDelay={0}
    enterTouchDelay={0}
    PopperProps={{ disablePortal: true }}
    placement={placement}
    sx={{
      "& .MuiTooltip-tooltip": {
        mt: pxToRem(4),
        py: pxToRem(4),
      },
    }}
  >
    <Box flexShrink={0} textAlign="center" mt={pxToRem(5)}>
      <Button
        ref={buttonRef}
        startIcon={<Info />}
        sx={{
          fontSize: pxToRem(12),
          fontWeight: 600,
          color: "#BFBEBB",
          textAlign: "center",
        }}
        onClick={onClick}
      >
        How does it work?
      </Button>
    </Box>
  </Tooltip>
);

const SubscriptionOptions = ({
  course,
  containerSx,
  shouldShowExtendButton,
  isOnSale,
  openProlongModal,
  handleLifeLongPurchase,
  handleResolvePurchase,
  showSubscriptionModal,
  extendTooltipOpen,
  extendButtonRef,
  handleTooltipClick,
  isTablet,
}) => (
  <Box sx={containerSx}>
    {!course?.user_subscription?.is_failed && course?.lifetime_price > 0 && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="p"
          fontSize={pxToRem(15)}
          fontWeight={500}
          lineHeight={pxToRem(24)}
          textAlign="center"
          my={pxToRem(16)}
          sx={{ width: pxToRem(300) }}
        >
          Purchase a Lifetime Subscription with No Expiration for a Slightly
          Higher Price
        </Typography>
        <Button
          onClick={() => handleLifeLongPurchase("Lifetime", "Normal")}
          variant="outlined"
          sx={{
            width: pxToRem(300),
            height: pxToRem(54),
            borderWidth: "2px",
            "&:hover": {
              border: "2px solid #026670",
            },
            paddingLeft: { xs: pxToRem(40), ssm: pxToRem(20), sm: pxToRem(59) },
            paddingRight: {
              xs: pxToRem(40),
              ssm: pxToRem(20),
              sm: pxToRem(59),
            },
            mb: !shouldShowExtendButton ? pxToRem(32) : 0,
          }}
        >
          {`Lifetime Access for $${course?.lifetime_price}`}
        </Button>
      </Box>
    )}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", ssm: "auto" },
      }}
    >
      {shouldShowExtendButton && (
        <>
          <Button
            variant="yellow"
            sx={{
              height: pxToRem(54),
              width: pxToRem(300),
              mt: "10px",
            }}
            onClick={() => openProlongModal("Normal", "Prolong")}
          >
            <Typography
              component="span"
              fontWeight={600}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span>{`Extend Course for $${course.prolong_price}`}</span>
              <span
                style={{
                  fontSize: pxToRem(13),
                  fontWeight: 600,
                  color: "#333",
                  textDecoration: "line-through",
                  marginLeft: pxToRem(4),
                  transform: "translateY(0.9px)",
                }}
              >
                ${course?.subscription_price}
              </span>
            </Typography>
          </Button>
          <InfoTooltipButton
            tooltipOpen={isTablet ? extendTooltipOpen : undefined}
            title="Your course is about to expire — extend now to avoid interruptions and enjoy a discounted rate"
            buttonRef={extendButtonRef}
            onClick={handleTooltipClick("extend")}
            placement={isTablet ? "top" : "bottom"}
          />
        </>
      )}
      {course?.user_subscription?.status === "Expired" &&
        !course?.user_subscription?.is_failed &&
        course?.is_prolong_allowed && (
          <Button
            variant="yellow"
            sx={{
              height: pxToRem(54),
              width: pxToRem(300),
              mb: pxToRem(32),
            }}
            onClick={() =>
              showSubscriptionModal(course.prolong_price, "normal")
            }
          >
            <Typography
              component="span"
              fontWeight={600}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span>{`Resubscribe for $${
                isOnSale ? course?.sale_price : course.prolong_price
              }`}</span>
              <span
                style={{
                  fontSize: pxToRem(13),
                  fontWeight: 600,
                  color: "#333",
                  textDecoration: "line-through",
                  marginLeft: pxToRem(4),
                  transform: "translateY(0.9px)",
                }}
              >
                ${course?.subscription_price}
              </span>
            </Typography>
          </Button>
        )}
      {course?.user_subscription?.is_failed && (
        <>
          <Typography
            component="p"
            fontSize={pxToRem(16)}
            fontWeight={600}
            lineHeight={pxToRem(24)}
            textAlign="center"
            my={pxToRem(16)}
            sx={{ width: pxToRem(300) }}
          >
            Your payment method failed. Resolve the issue.
          </Typography>
          <Button
            variant="yellow"
            sx={{
              height: pxToRem(54),
              width: { xs: "100%", ssm: pxToRem(300) },
              mb: pxToRem(32),
            }}
            onClick={() => handleResolvePurchase(course?.user_subscription?.id)}
          >
            Resolve Issue
          </Button>
        </>
      )}
    </Box>
  </Box>
);

const SubscriptionTab = ({
  my_subscription,
  course,
  progress,
  setFetchCourseAgain,
}) => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [shareToastOpen, setShareToastOpen] = useState(false);
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { showModal, hideModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const large_mobile = useMediaQuery("(max-width: 612px)");

  const extendButtonRef = useRef(null);
  const associateButtonRef = useRef(null);

  const [prolongModal, setProlongModal] = useState(false);
  const [extendTooltipOpen, setExtendTooltipOpen] = useState(false);
  const [associateTooltipOpen, setAssociateTooltipOpen] = useState(false);
  const [isPurchaseHistoryOpen, setIsPurchaseHistoryOpen] = useState(false);
  const handleTooltipClick = (tooltipType) => (e) => {
    // console.log("tooltipType", tooltipType);
    e.stopPropagation();
    if (isTablet) {
      if (tooltipType === "extend") {
        setExtendTooltipOpen((prev) => !prev);
        setAssociateTooltipOpen(false);
      } else if (tooltipType === "associate") {
        setAssociateTooltipOpen((prev) => !prev);
        setExtendTooltipOpen(false);
      }
    }
  };

  const handleClickOutside = useCallback((e) => {
    if (
      extendButtonRef.current &&
      !extendButtonRef.current.contains(e.target) &&
      associateButtonRef.current &&
      !associateButtonRef.current.contains(e.target)
    ) {
      setExtendTooltipOpen(false);
      setAssociateTooltipOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleModalOpen = async () => {
    setInviteModalOpen(true);
    setFetchCourseAgain(true);
  };

  const handleLifeLongPurchase = async (
    priceType,
    priceClass,
    prolongBy = ""
  ) => {
    try {
      const response = await createCheckoutSession(
        course.id,
        priceType,
        priceClass,
        prolongBy
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.log("Error creating checkout session:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while creating the checkout session."
      );
      setErrorToastOpen(true);
    }
  };

  const handleResolvePurchase = async (subscriptionId) => {
    try {
      const response = await resolveCheckoutSession(subscriptionId);
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while trying to resolve the issue."
      );
      setErrorToastOpen(true);
    }
  };

  const handleOpenGiftModal = () => setOpenGiftModal(true);
  const handleCloseGiftModal = () => setOpenGiftModal(false);

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => setShareToastOpen(true))
      .catch((err) => console.error("Could not copy text: ", err));
  };

  // renew / resubscribe
  const showSubscriptionModal = (price, type) => {
    const purchaseModal = showModal(PurchaseModal, {
      course,
      type,
      price,
      openThankModal: () => {
        hideModal(purchaseModal.id);
        showModal(ThankModal);
      },
      openErrorModal: () => {
        hideModal(purchaseModal.id);
        const errorModal = showModal(ErrorModal, {
          openPurchaseModal: () => {
            hideModal(errorModal.id);
            showModal(PurchaseModal, { course });
          },
        });
      },
    });
  };

  const shouldShowExtendButton =
    course?.user_subscription?.status === "Active" &&
    !course?.user_subscription?.is_failed &&
    course?.is_prolong_allowed &&
    isWithin21Days(course?.user_subscription?.end_date);

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  // container style
  const containerSx =
    isTablet && !large_mobile
      ? { width: pxToRem(320) }
      : { width: { sm: pxToRem(320), md: "100%" } };

  const openProlongModal = () => {
    setProlongModal(true);
  };

  return (
    <Box>
      <Stack
        flexDirection={large_mobile ? "column" : { ssm: "row", md: "column" }}
        justifyContent="space-between"
        flexWrap="wrap"
        columnGap={{ xs: 0, sm: pxToRem(60) }}
        rowGap={{ xs: pxToRem(20), sm: 0 }}
      >
        <Box sx={{ width: { sm: "50%", md: "100%" } }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={pxToRem(15)}
            mb={pxToRem(17)}
          >
            <Typography
              variant="sectionTitle"
              component="h2"
              fontSize={{ xs: pxToRem(20), ssm: pxToRem(35) }}
            >
              Subscription
            </Typography>
            <Stack
              position="relative"
              direction="row"
              alignItems="flex-end"
              gap={pxToRem(10)}
              display={{ xs: "none", md: "flex" }}
            >
              <Tooltip title="Share Now" placement="top">
                <Box
                  sx={{
                    "& button:hover": {
                      backgroundColor: "#00545d !important",
                    },
                  }}
                  onClick={handleShareClick}
                >
                  <IconBtnCircular className="hover-green active">
                    <Share
                      color="#026670"
                      style={{ marginRight: pxToRem(1.5) }}
                    />
                  </IconBtnCircular>
                </Box>
              </Tooltip>
              {course.subscription_price > 0 && (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "nowrap", padding: "5px 0" }}>
                      Gift Course
                    </span>
                  }
                  placement="top"
                  PopperProps={{ disablePortal: true }}
                >
                  <Box
                    sx={{
                      "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
                        backgroundColor: "#fce181",
                        color: "#026670",
                      },
                      "& svg": { transition: "0.2s ease" },
                      "&:hover svg": { color: "#026670" },
                    }}
                    onClick={handleOpenGiftModal}
                  >
                    <IconBtnCircular className="yellow-ic">
                      <Gift color="#fff" />
                    </IconBtnCircular>
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={pxToRem(25)}
            mt={{ xs: "20px", md: "30px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ width: { lg: "50%" } }}>
                <BlockBordered sx={{ display: "none" }}>
                  <TypographyStyled>
                    <span>Enrolled on:</span>{" "}
                    {formatDate(my_subscription?.start_date)}
                  </TypographyStyled>
                  <TypographyStyled>
                    <span>
                      {course?.user_subscription?.status === "Expired"
                        ? "Expired on"
                        : "Expires on"}
                      :
                    </span>{" "}
                    {course.subscription_price > 0
                      ? !my_subscription?.end_date
                        ? "Lifetime Access"
                        : formatDate(my_subscription?.end_date)
                      : "Lifetime Access"}
                  </TypographyStyled>
                  <TypographyStyled>
                    <span>Progress:</span> {Math.floor(progress)}% Completed
                  </TypographyStyled>
                </BlockBordered>
              </Box>
            </Box>
          </Stack>
          <Box
            onClick={() => {
              setIsPurchaseHistoryOpen(true);
            }}
          >
            <TextLink sx={{ mt: "20px", display: "flex" }}>
              View Purchase Details
            </TextLink>
          </Box>
        </Box>
        <Box sx={{ width: { sm: pxToRem(320), md: "100%" } }}>
          {course.subscription_price > 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  mt: { md: "20px" },
                  columnGap: pxToRem(20),
                  flexWrap: { ssm: "wrap" },
                }}
              >
                {course?.is_associate_allowed && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: { xs: "center", md: "start" },
                      mt: "auto",
                      // order: { xs: 2, ssm: 1 },
                    }}
                  >
                    <Button
                      variant="yellow"
                      sx={{
                        height: pxToRem(54),
                        width: pxToRem(300),
                        mt: "10px",
                        mb: course?.associate ? "28px" : "0px",
                      }}
                      onClick={handleModalOpen}
                    >
                      {course?.associate
                        ? "View Partner"
                        : `Add Partner for $${course?.associate_price}`}
                    </Button>
                    {course?.is_associate_allowed && !course?.associate && (
                      <InfoTooltipButton
                        tooltipOpen={
                          isTablet ? associateTooltipOpen : undefined
                        }
                        title="Invite a partner to purchase the course at a discounted rate."
                        buttonRef={associateButtonRef}
                        onClick={handleTooltipClick("associate")}
                        placement={isTablet ? "top" : "bottom"}
                      />
                    )}
                  </Box>
                )}
                <Box>
                  {(!isTablet || large_mobile) &&
                    course?.user_subscription?.type !== "Lifetime" &&
                    course?.user_subscription?.type !== "Free" && (
                      <SubscriptionOptions
                        course={course}
                        containerSx={containerSx}
                        shouldShowExtendButton={shouldShowExtendButton}
                        isOnSale={isOnSale}
                        openProlongModal={openProlongModal}
                        handleLifeLongPurchase={handleLifeLongPurchase}
                        handleResolvePurchase={handleResolvePurchase}
                        showSubscriptionModal={showSubscriptionModal}
                        extendTooltipOpen={extendTooltipOpen}
                        extendButtonRef={extendButtonRef}
                        handleTooltipClick={handleTooltipClick}
                        isTablet={isTablet}
                      />
                    )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Stack>
      {isTablet &&
        !large_mobile &&
        course?.user_subscription?.type !== "Lifetime" &&
        course?.user_subscription?.type !== "Free" && (
          <SubscriptionOptions
            course={course}
            containerSx={containerSx}
            shouldShowExtendButton={shouldShowExtendButton}
            isOnSale={isOnSale}
            openProlongModal={openProlongModal}
            handleLifeLongPurchase={handleLifeLongPurchase}
            handleResolvePurchase={handleResolvePurchase}
            showSubscriptionModal={showSubscriptionModal}
            extendTooltipOpen={extendTooltipOpen}
            extendButtonRef={extendButtonRef}
            handleTooltipClick={handleTooltipClick}
            isTablet={isTablet}
          />
        )}
      {course?.is_prolong_allowed && (
        <ProlongModal
          open={prolongModal}
          setOpen={setProlongModal}
          endDate={my_subscription?.end_date}
          duration={course?.duration}
          prolongPrice={course?.prolong_price}
          subscriptionPrice={course?.subscription_price}
          handleLifeLongPurchase={handleLifeLongPurchase}
        />
      )}
      {course?.subscription_price > 0 && (
        <>
          <InviteAssociateModal
            courseId={course?.id}
            open={inviteModalOpen}
            setOpen={setInviteModalOpen}
            associate={course?.associate}
            setFetchCourseAgain={setFetchCourseAgain}
          />
          <Toast
            open={toastOpen}
            onClose={() => setToastOpen(false)}
            message="Checkout link sent successfully."
          />
        </>
      )}
      {openGiftModal && (
        <GiftCourseModal
          open={openGiftModal}
          course={course}
          handleClose={handleCloseGiftModal}
        />
      )}
      <Toast
        open={shareToastOpen}
        onClose={() => setShareToastOpen(false)}
        message="Link copied to clipboard!"
      />
      <Toast
        open={errorToastOpen}
        onClose={() => setErrorToastOpen(false)}
        message={errorMessage}
      />
      <CoursePayments
        details={course?.user_subscription}
        open={isPurchaseHistoryOpen}
        setOpen={setIsPurchaseHistoryOpen}
      />
    </Box>
  );
};

export default SubscriptionTab;
