import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";

import Slider from "../../../components/Slider";
import PlayBtn from "../../../components/PlayBtn";
import Alert from "./Alert";
import BlockHeading from "../../../components/BlockHeading";
import InvitationCard from "../../../components/InvitationCard";

import { ReactComponent as Play } from "../../../images/play.svg";

import {
  acceptInvitation,
  getDashboardNotifications,
  getInvitations,
  hideNotification,
  rejectInvitation,
} from "../../../api/student";
import { useEffect, useState } from "react";

import { getLanguageClass } from "../../../utils/languageClass";
import Toast from "../../../components/Toast";
import { createAssociateCheckoutSession } from "../../../api/payment";
import { getLatestInteractions } from "../../../api/course";
import { checkAuth } from "../../../utils/checkAuth";

const getSeverity = (notificationClass, notificationType) => {
  if (
    notificationClass === "reminder" &&
    notificationType === "course_reminder"
  ) {
    return "success";
  }
  if (notificationClass === "invitation") {
    switch (notificationType) {
      case "associate_invitation":
      case "associate_invitation_accepted":
        return "success";
      case "associate_invitation_rejected":
        return "error";
      default:
        return "warning";
    }
  }
  if (notificationClass === "standard") {
    switch (notificationType) {
      case "associate_invitation_rejected":
        return "error";
      case "new_blog":
        return "success";
      default:
        return "warning";
    }
  }

  return "warning";
};

const Dashboard = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthenticated = checkAuth();

  const [recentlyItems, setRecentlyItems] = useState([]);

  const fetchRecentlyItems = () => {
    getLatestInteractions()
      .then((res) => {
        setRecentlyItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchRecentlyItems();
  }, []);

  useEffect(() => {
    getInvitations().then((res) => console.log(res));
  }, []);

  // start: notifications
  const [notifications, setNotifications] = useState([]);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [hasMore, setHasMore] = useState(null);

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  useEffect(() => {
    const getNotifications = async () => {
      setIsLoadingNotifications(true);
      try {
        const response = await getDashboardNotifications(page, pageSize);
        console.log("notification and alerts response", response);
        setHasMore(response.data.next !== null);

        setNotifications((prevNotifications) => {
          const newNotifications = response?.data?.results || [];
          const existingIds = new Set(prevNotifications?.map((n) => n.id));

          const uniqueNewNotifications = newNotifications?.filter(
            (notification) => !existingIds.has(notification.id)
          );

          return [...prevNotifications, ...uniqueNewNotifications];
        });
      } catch (error) {
        console.log("notification and alerts error:", error);
      } finally {
        setIsLoadingNotifications(false);
      }
    };
    if (isAuthenticated) {
      getNotifications();
    }
  }, [page, isAuthenticated]);

  const handleHide = async (notificationId) => {
    try {
      await hideNotification(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId
        )
      );
    } catch (error) {
      console.error("Error hiding notification:", error);
    }
  };

  const handleAccept = async (invitationId) => {
    try {
      const response = await acceptInvitation(invitationId);
      const email = response.email;
      const id = response.course_id;

      const checkoutSessionResponse = await createAssociateCheckoutSession(
        id,
        email
      );

      if (
        checkoutSessionResponse &&
        checkoutSessionResponse.data &&
        checkoutSessionResponse.data.checkout_url
      ) {
        window.location.href = checkoutSessionResponse.data.checkout_url;
      } else {
        console.error("Checkout URL not found in the response.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
      } else {
        console.error(
          "Error handling invitation acceptance and checkout session:",
          error
        );
      }
    }
  };

  const handleReject = async (notificationId) => {
    try {
      await rejectInvitation(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId
        )
      );
    } catch (error) {
      console.error("Error hiding notification:", error);
    }
  };

  const handleViewMore = () => {
    if (hasMore) {
      const nextPageNumber = page + 1;
      setPage(nextPageNumber);
      // fetchNotifications(nextPageNumber);
    }
  };

  // end: notifications

  return (
    <>
      <Toast
        open={openToast}
        onClose={() => setOpenToast(false)}
        message={toastMessage}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle">
          Alerts & Notifications
        </Typography>
      </BlockHeading>

      <InvitationCard />

      <Stack gap={pxToRem(20)} mb={pxToRem(60)}>
        {notifications.length === 0 && (
          <Box sx={{ textAlign: "center", py: 2 }}>
            <Typography variant="h6" fontWeight={700} gutterBottom>
              All Caught Up!
            </Typography>
            <Typography fontWeight={500}>
              Your notifications will appear here. You're all set for now—check
              back regularly for any new alerts or updates.
            </Typography>
          </Box>
        )}
        {notifications.map((notification) => (
          <Alert
            severity={getSeverity(
              notification?.notification_class,
              notification?.notification_type
            )}
            title={notification?.title}
            handleHide={handleHide}
            id={notification?.id}
            notification={notification}
            isReminder={
              notification?.link &&
              notification?.notification_class === "reminder"
            }
            isInvitation={
              notification?.notification_class === "invitation" &&
              notification?.notification_type === "associate_invitation"
            }
            link={notification?.link}
            handleAccept={handleAccept}
            handleReject={handleReject}
            time={notification?.created_at}
            isBlog={notification?.notification_type === "new_blog"}
            // isCourse={notification?.notification_type===""}
          >
            {notification?.content}
          </Alert>
        ))}
        {hasMore && (
          <Button disabled={isLoadingNotifications} onClick={handleViewMore}>
            View More
          </Button>
        )}
      </Stack>

      {recentlyItems && recentlyItems.length > 0 && (
        <>
          <BlockHeading>
            <Typography component="h3" variant="blockTitle">
              Recently Viewed
            </Typography>
          </BlockHeading>{" "}
          <Box
            sx={{
              "& .MuiContainer-root": { p: 0 },
              "& .swiper": { pb: "35px" },
              "& .swiper-slide": { width: "auto" },
              "& .arrow-prev": { left: "-90px", top: "40%" },
              "& .arrow-next": { right: "-90px", top: "40%" },
            }}
          >
            <Slider
              slidesPerView={2}
              spaceBetween={20}
              arrows
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                576: {
                  slidesPerView: "auto",
                },
              }}
              style={{ marginBottom: "20px" }}
            >
              {recentlyItems.length > 0 &&
                recentlyItems.map((v) => (
                  <SwiperSlide key={v.id}>
                    <VideoSlide
                      image={v.thumb_nail}
                      videoLink={
                        v.type === "course"
                          ? `/course/${v.id}`
                          : `/video/${v.id}`
                      }
                      title={v.name}
                      isMobile={mobile}
                      type={v.type === "course" ? "Course" : "Video"}
                    />
                  </SwiperSlide>
                ))}
            </Slider>
          </Box>
        </>
      )}
    </>
  );
};

const VideoSlide = (props) => {
  return (
    <Link
      to={props.videoLink}
      component={NavLink}
      underline="none"
      sx={{
        width: { xs: "auto", ssm: "180px", sm: "auto" },
        "&:hover": {
          ".MuiTypography-root": { color: "#333" },
          ".play-btn": {
            backgroundColor: "#fff",
            "& svg": {
              color: "#333",
            },
          },
        },
      }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={{ xs: "auto", ssm: "180px", sm: "auto" }}
        height={{ xs: "auto", ssm: "180px", sm: pxToRem(190) }}
        borderRadius="19px"
        overflow="hidden"
        mb="-10px"
      >
        <Box sx={{ position: "absolute", zIndex: 1 }}>
          <PlayBtn
            width={{ xs: "60px", sm: pxToRem(64) }}
            height={{ xs: "60px", sm: pxToRem(64) }}
          >
            <Play
              color="#026670"
              width={pxToRem(20)}
              height={pxToRem(24)}
              style={{ marginLeft: "8%" }}
            />
          </PlayBtn>
        </Box>
        <img
          style={{
            objectFit: "cover",
            width: "160px",
            height: "120px",
            borderRadius: pxToRem(20),
          }}
          src={props.image}
          alt=""
        />
      </Box>

      <Typography
        maxWidth={{ xs: "180px", sm: pxToRem(190) }}
        fontSize={pxToRem(15)}
        color={"#333"}
        fontWeight={700}
        textAlign="center"
        sx={{ opacity: 0.5 }}
      >
        {props.type}
      </Typography>
      <Typography
        maxWidth={{ xs: "180px", sm: pxToRem(190) }}
        component="p"
        fontSize={pxToRem(16)}
        lineHeight={pxToRem(20)}
        fontWeight={600}
        textAlign="center"
        color="#026670"
        boxSizing="border-box"
        sx={{
          wordBreak: "break-word",
          overflow: "hidden", // Hide overflowed text
          textOverflow: "ellipsis", // Show three dots at the end
          whiteSpace: "nowrap", // Prevent text from wrapping to the next line
        }}
        className={getLanguageClass(props.title, true)}
      >
        {props.title}
      </Typography>
    </Link>
  );
};

export default Dashboard;
