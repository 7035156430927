import { useEffect, useState } from "react";
import { Avatar, Box, Button, Stack, Typography, Divider } from "@mui/material";
import { pxToRem } from "px2rem2px";


import { useStudentProfile } from "../../../hooks/useStudentProfile";

import BlockHeading from "../../../components/BlockHeading";
import InputAccountEdit from "./InputAccountEdit";
import EmailInputEdit from "./EmailInputEdit";
import EditProfilePicture from "./EditProfilePicture";
import ChangePasswordModal from "./ChangePasswordModal";

const AccountSettings = () => {
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);


  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCanceled, setPhoneCanceled] = useState(false);


  const [studentInfo, setStudentInfo] = useState({
    id: "",
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      is_active: true,
    },
    phone_number: "",
    spouse: null,
    avatar: "",
    nickname: "",
  });


  const {
    student,           
    isStudentLoading,  
    isStudentError,    
    refetch,           
  } = useStudentProfile();


  useEffect(() => {
    if (student) {
      setStudentInfo(student);
      setPhoneNumber(student.phone_number || "");
    }
  }, [student]);


  useEffect(() => {

    if (phoneCanceled || !openEmailModal) {
      refetch();
    }
  }, [phoneCanceled, openEmailModal, refetch]);


  if (isStudentLoading) {
    return <div>Loading your profile...</div>;
  }
  if (isStudentError) {
    return <div>Error loading profile. Please try again.</div>;
  }


  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle">
          Account settings
        </Typography>
      </BlockHeading>

      <Stack
        flexDirection={{ xs: "column-reverse", ssm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        gap={{ xs: "30px", ssm: "15px" }}
        mb={pxToRem(60)}
      >
        <Stack flex={1} gap={pxToRem(20)}>
          <InputAccountEdit
            label="First Name"
            editLabel="first_name"
            value={studentInfo?.user?.first_name}
            type="text"
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />
          <InputAccountEdit
            label="Last Name"
            editLabel="last_name"
            value={studentInfo?.user?.last_name}
            type="text"
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />
          <InputAccountEdit
            label="Nickname"
            editLabel="nickname"
            value={studentInfo?.nickname}
            type="text"
            rules={{
              minLength: { value: 3, message: "Minimum 3 characters" },
              maxLength: { value: 50, message: "Maximum 50 characters" },
            }}
          />
          <InputAccountEdit
            phone
            label="Phone"
            editLabel="phone_number"
            stipn={studentInfo?.phone_number}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setPhoneCanceled={setPhoneCanceled}
            rules={{ required: "Field can't be empty" }}
          />
          <EmailInputEdit
            label="Email Address"
            editLabel="email"
            value={studentInfo?.user?.email}
            type="email"
            rules={{
              required: "Field can't be empty",
              maxLength: { value: 100, message: "Maximum 100 characters" },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}

            originalEmail={studentInfo?.user?.email}
            setOpenEmailModal={setOpenEmailModal}

            onEmailUpdated={() => {
              // e.g. fetch fresh data
              refetch();
            }}
          />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{ width: pxToRem(150), height: pxToRem(150), backgroundColor: "#fff" }}
          >
            <img src={studentInfo?.avatar} alt="Avatar" />
          </Avatar>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: pxToRem(15), width: pxToRem(150), fontWeight: 500 }}
            onClick={() => setOpenProfilePictureModal(true)}
          >
            Update Picture
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: pxToRem(10), width: pxToRem(150), fontWeight: 500 }}
            onClick={() => setOpenPasswordModal(true)}
          >
            Change Password
          </Button>
        </Box>
      </Stack>

      <EditProfilePicture
        open={openProfilePictureModal}
        setOpen={setOpenProfilePictureModal}
        // No more `update={fetchStudent}` needed
        // because we can rely on `refetch()` or see new data from the mutation
      />

      <ChangePasswordModal
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
      />
    </>
  );
};

export default AccountSettings;
