import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificationsSettings,
  updateNotificationSettings,
} from "../api/student";
import { getUserInfo } from "../api/student";

export function useNotificationSettings() {
  const queryClient = useQueryClient();
  const userInfo = getUserInfo();

  const {
    data: notificationData,
    isLoading: isNotificationLoading,
    isError: isNotificationError,
  } = useQuery(
    ["notificationSettings", userInfo?.id],
    () => getNotificationsSettings(userInfo?.id),
    {
      enabled: !!userInfo?.id,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const { mutateAsync: updateNotifications } = useMutation(
    updateNotificationSettings,
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          ["notificationSettings", userInfo?.id],
          (oldData) => {
            const updatedData = {
              ...oldData,
              data: {
                ...oldData.data,
                preferences: {
                  ...oldData.data.preferences,
                  ...response.data.preferences,
                },
              },
            };

            return updatedData;
          }
        );
      },
      onError: (error) => {
        console.error("useNotificationSettings > onError:", error);
      },
    }
  );

  return {
    notifications: notificationData?.data?.preferences || {},
    isNotificationLoading,
    isNotificationError,
    updateNotifications,
  };
}
