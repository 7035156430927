import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import ModalProvider, { useModal } from "mui-modal-provider";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import Toast from "./Toast";

import { checkAuth } from "../utils/checkAuth";
const Layout = () => {
  useEffect(() => {
    let popupShown = localStorage.getItem("popupShown");
    if (popupShown !== "true" && popupShown !== "false") {
      localStorage.setItem("popupShown", "false");
      popupShown = "false";
    }

    if (!checkAuth() && popupShown === "false") {
      localStorage.setItem("popupShown", "false");
    } else {
      localStorage.setItem("popupShown", "true");
    }
  }, []);

  const theme = useTheme();
  const location = useLocation();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  // verify email
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  // hide footer
  const shouldHideFooter =
    mobile_ssm &&
    location.pathname.startsWith("/course/") &&
    !location.pathname.includes("presubscribe");

  // feedback
  useEffect(() => {
    // console.log(`Current path: ${location.pathname}`);
    window.scrollTo(0, 0);

    const message = localStorage.getItem("successMessage");
    if (message) {
      setSuccessMessage(message);
      setOpen(true);
      localStorage.removeItem("successMessage");
    }
  }, [location.pathname]);

  const handleClose = () => {
    setOpen(false);
  };
 

  return (
    <ModalProvider>
      <Box
        position="relative"
        maxWidth={pxToRem(1920)}
        m="0 auto"
        p={{ xs: 0, md: pxToRem(30) }}
        sx={{ overflowX: { ssm: "clip" } }}
      >
        <Outlet />
        <Toast open={open} message={successMessage} onClose={handleClose} />

        {!shouldHideFooter && <Footer />}
      </Box>
    </ModalProvider>
  );
};

export default Layout;
