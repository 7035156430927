import { Box, Grid, Skeleton } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import TagCloud from "react-tag-cloud";
import { pxToRem } from "px2rem2px";
import { getTagCloud } from "../api/cmsApi";
import tagCloudTemplate from "../utils/tagCloudTemplate";
import transformArrayToCloud from "../utils/transformArrayToCloud";
import { fontFamily } from "@mui/system";
const TagsCloud = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);
  const [tagCloud, setTagCloud] = useState([]);

  const fetchTagCloud = async () => {
    setLoading(true);
    try {
      const res = await getTagCloud();
      setTagCloud(transformArrayToCloud(res.data.keywords, tagCloudTemplate));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTagCloud();
  }, []);
  useEffect(() => {
    setInterval(() => {
      forceUpdate();
    }, 5000);
  }, [forceUpdate]);

  return (
    <Box
      height={pxToRem(700)}
      borderRadius={pxToRem(40)}
      sx={{
        backgroundImage: "linear-gradient(to bottom, transparent,#ffffff 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          px: { xs: `${pxToRem(70)} 0`, sm: "120px 0", lg: pxToRem(150) },
          py: { xs: pxToRem(70), ssm: "86px", sm: "100px", md: pxToRem(150) },
          width: "100%",
        }}
      >
        {loading ? (
          <>
          <Grid container rowSpacing={8} px="15px" columnSpacing={{xs: 2, ssm: 4}}>
            <Grid item xs={5}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={2}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={2}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={2}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
            <Grid item xs={7}>
              <Skeleton variant="rounded" sx={{fontSize: "30px", borderRadius: "8px"}} />
            </Grid>
          </Grid>
        </>
        ) : (
          <TagCloud
            className="tagCloud"
            style={{
              fontFamily: "Pioni",
              fontWeight: "bold",
              padding: 8,
              width: "100%",
              height: "100%",
            }}
          >
            {tagCloud &&
              tagCloud.map((element, index) => (
                <div
                  key={index}
                  text={element.text}
                  style={{ ...element.style, fontFamily: "PloniBold" }}
                  rotate={element.rotate}
                  className="hovered"
                >
                  {element.text}
                </div>
              ))}
          </TagCloud>
        )}
      </Box>
    </Box>
  );
};

export default TagsCloud;
