import { Box, Button, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import PresentationImg from "../../images/promotion-img1.jpg";
import { getPromotionSection } from "../../api/cmsApi";
import { useState, useEffect } from "react";
import { url } from "../../api/config";
import { getLanguageClass } from "../../utils/languageClass";
const Promotion = () => {
  const [promotionSection, setPromotionSection] = useState({
    title: "",
    subtitle: "",
    image: "",
    button_text: "",
    button_link: "",
    target_new_tab: false,
    is_active: true,
    start_date: "",
    end_date: "",
  });

  const fetchPromotionSection = () => {
    getPromotionSection()
      .then((res) => {
        console.log("Promotion Data", res.data);
        setPromotionSection(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPromotionSection();
  }, []);
  return (
    <>
      {promotionSection.is_active && (
        <Box pb={{ xs: pxToRem(70), ssm: "86px", sm: "100px", md: pxToRem(150) }} mt={"40px"}>
          <Box
            borderRadius={{ xs: 0, md: pxToRem(30) }}
            overflow="hidden"
            bgcolor="white"
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundImage: `url(${promotionSection?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                pt: "315px"
              }}
            >
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-start" }}
              bgcolor="#FCE181"
              pt={{ xs: pxToRem(50) , ssm: "40px", sm: "74px", md: pxToRem(86) }}
              pb={{ xs: pxToRem(70) , ssm: "40px", sm: "74px", md: pxToRem(86) }}
              px={{ xs: "25px", sm: "30px", md: pxToRem(80) }}
            >
              <Box
                maxWidth={pxToRem(510)}
                textAlign={{ xs: "center", sm: "left" }}
              >
                {promotionSection && promotionSection?.title && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: promotionSection?.title,
                    }} // Render raw HTML
                    fontFamily="PloniBold"
                    sx={{
                      "& h1": { fontSize: pxToRem(45), mt: 0, mb: pxToRem(10) },
                    }}
                    className={getLanguageClass(
                      promotionSection?.title,
                      false,
                      true
                    )}
                  />
                )}

                {promotionSection && promotionSection.subtitle && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: promotionSection?.subtitle,
                    }} // Render raw HTML
                    isAlignedRight={
                      getLanguageClass(promotionSection?.subtitle) ===
                      "is-hebrew-text"
                    }
                    mb={{ xs: "20px", md: pxToRem(35) }}
                    className={getLanguageClass(promotionSection?.subtitle)}
                    sx={{
                      "& p": {
                        my: 0
                      }
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:
                      getLanguageClass(promotionSection?.subtitle) ===
                      "is-hebrew-text"
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  {promotionSection && promotionSection.button_text && (
                    <Button
                      variant="green"
                      onClick={() => {
                        promotionSection.button_link &&
                          window.open(
                            promotionSection.button_link,
                            promotionSection.target_new_tab ? "_blank" : "_self"
                          );
                      }}
                    >
                      {promotionSection.button_text}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Promotion;
