import { useState, useRef, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  ThemeProvider,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import bookmarkBlack from "../../images/bookmark-black.svg";
import deleteWithLining from "../../images/delete-with-lining.svg";
import leftArrow from "../../images/left-arrow.svg";
import shapes from "../../images/shapes.svg";
import { ReactComponent as Close } from "../../images/close-circle.svg";

import { pxToRem } from "px2rem2px";
import { rtlTheme } from "../../theme";

const BookmarkItem = ({ bookmark, onDelete, coursesBookmarks }) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const confirmRef = useRef(null);

  const outsideClickRef = useRef(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmRef.current && !confirmRef.current.contains(event.target)) {
        setConfirmDelete(false);
        outsideClickRef.current = true;
      }
    };

    if (confirmDelete) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [confirmDelete]);


  const formatDuration = (durationInSec) => {
    const totalSeconds = Math.floor(durationInSec);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const pad = (num) => num.toString().padStart(2, "0");
    return hours > 0
      ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
      : `${pad(minutes)}:${pad(seconds)}`;
  };


  const navigateToBookmark = () => {
    if (coursesBookmarks) {
      navigate(
        `/course/${bookmark?.course}?lectureId=${bookmark?.lecture_id}&t=${bookmark?.timestamp}`
      );
    } else {
      navigate(`/video/${bookmark?.video_id}?t=${bookmark?.timestamp}`);
    }
  };


  const handleBoxClick = (e) => {
    if (outsideClickRef.current) {
      outsideClickRef.current = false;
      return;
    }
    navigateToBookmark();
  };

  // delete handler
  const handleDelete = async (event) => {
    event.stopPropagation();
    setIsDeleting(true);
    try {
      await onDelete(bookmark?.id);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <Box
      key={bookmark?.id}
      sx={{
        borderRadius: pxToRem(10),
        p: pxToRem(16),
        backgroundColor: "#fff",
        position: "relative",
        transition: "opacity 300ms ease",
        opacity: isDeleting ? 0 : 1,
        "&::before": isDeleting
          ? {}
          : {
              content: "''",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: `calc(100% - ${pxToRem(20)})`,
              height: "100%",
              backgroundColor: "#FCE181",
              borderRadius: pxToRem(8),
              transform: "translate(-50%,-50%)",
              zIndex: -1,
              transition: "0.2s ease",
            },
        "& .delete-icon": {
          transition: "0.2s ease",
        },
        "&:hover": !isDeleting
          ? {
              "&::before": {
                height: `calc(100% + ${pxToRem(12)})`,
              },
              // Show delete icon on hover (or always on mobile)
              "& .delete-icon": {
                opacity: mobile_ssm ? 1 : "1",
              },
            }
          : {},
      }}
      onClick={handleBoxClick}
    >
      <ThemeProvider theme={rtlTheme}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          justifyContent="flex-end"
        >
          {/* Delete Icon Button */}
          <Box
            className="delete-icon"
            flexShrink={0}
            position="absolute"
            left={pxToRem(16)}
            sx={{
              width: { xs: pxToRem(42), ssm: pxToRem(50) },
              height: { xs: pxToRem(42), ssm: pxToRem(50) },
              opacity: mobile_ssm ? 1 : 0,
              backgroundColor: confirmDelete ? "#fff" : "#CA4343",
              borderRadius: pxToRem(50),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid #fff",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setConfirmDelete(true);
            }}
          >
            {confirmDelete ? (
              <Stack
                ref={confirmRef}
                className="deleteBtnGroup"
                flexDirection="row"
                alignItems="center"
                position="absolute"
                top={mobile_ssm ? "-5px" : "-2px"}
                right={mobile_ssm ? "-60px" : "-57px"}
                py={pxToRem(10)}
                zIndex={1}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setConfirmDelete(false);
                  }}
                  sx={{
                    padding: "4px",
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                      "& svg": { color: "#333" },
                    },
                  }}
                >
                  <Close width={18} height={18} />
                </IconButton>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={handleDelete}
                  sx={{
                    backgroundColor: "#CA4343",
                    color: "#fff",
                    fontSize: "12px",
                    padding: "5px 10px",
                    "&:hover": {
                      backgroundColor: "rgb(162, 11, 11)",
                    },
                  }}
                >
                  Delete?
                </Button>
              </Stack>
            ) : (
              <img
                style={{ width: pxToRem(16) }}
                src={deleteWithLining}
                alt="Delete with lining"
              />
            )}
          </Box>

          <Stack
            direction={{ xs: "column", sssm: "row" }}
            alignItems={{ xs: "flex-end", sssm: "center" }}
            gap={{ xs: pxToRem(10), ssm: pxToRem(16) }}
          >
            <Stack
              gap={{ xs: pxToRem(4), ssm: pxToRem(8) }}
              flexGrow={1}
              alignItems="flex-end"
              order={{ xs: 2, ssm: 1 }}
            >
              {coursesBookmarks ? (
                <>
                  <Typography
                    className="rtl-section"
                    fontSize={pxToRem(24)}
                    fontWeight={600}
                    color="#026670"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {bookmark?.course_name}
                  </Typography>
                  <Typography
                    className="rtl-section"
                    fontSize={pxToRem(24)}
                    fontWeight={600}
                    color="#026670"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {bookmark?.lecture_name}
                  </Typography>
                </>
              ) : (
                <>
                  <Stack
                    direction="row"
                    spacing={pxToRem(8)}
                    alignItems="center"
                  >
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={500}
                      color="#222"
                    >
                      {bookmark?.subgroup}
                    </Typography>
                    <img src={leftArrow} alt="Left Arrow" />
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={500}
                      color="#222"
                    >
                      {bookmark?.group}
                    </Typography>
                    <img
                      src={shapes}
                      style={{ width: pxToRem(18), height: pxToRem(18) }}
                      alt="Shape"
                    />
                  </Stack>
                  <Typography
                    className="rtl-section"
                    fontSize={pxToRem(24)}
                    fontWeight={600}
                    color="#026670"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {bookmark?.title}
                  </Typography>
                </>
              )}

              <Stack
                flexDirection="row"
                alignItems="center"
                sx={{
                  backgroundColor: "#EFEFEF",
                  borderRadius: pxToRem(50),
                  px: pxToRem(8),
                  gap: pxToRem(8),
                  minHeight: pxToRem(28),
                }}
              >
                <img
                  style={{ width: pxToRem(11) }}
                  src={bookmarkBlack}
                  alt="Bookmark"
                />
                <Typography
                  fontSize={pxToRem(16)}
                  fontWeight={600}
                  color="#222"
                  letterSpacing={pxToRem(-0.32)}
                >
                  {formatDuration(bookmark?.timestamp)}
                </Typography>
              </Stack>
            </Stack>

            <Box
              sx={{
                maxWidth: { xs: pxToRem(125), ssm: pxToRem(155) },
                width: pxToRem(155),
                height: pxToRem(100),
                borderRadius: pxToRem(10),
                overflow: "hidden",
                position: "relative",
                flexShrink: "0",
                order: { xs: 1, sssm: 2 },
              }}
            >
              <img
                src={bookmark?.thumb_nail}
                alt="Bookmark Thumbnail"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: pxToRem(50),
                  px: pxToRem(8),
                  py: pxToRem(2),
                  position: "absolute",
                  top: pxToRem(8),
                  right: pxToRem(8),
                }}
              >
                <Typography
                  fontSize={pxToRem(14)}
                  fontWeight={500}
                  color="#222"
                  letterSpacing={pxToRem(-0.28)}
                >
                  {bookmark?.category}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </ThemeProvider>
    </Box>
  );
};

export default BookmarkItem;
