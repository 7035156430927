import React, { createContext, useState, useRef, useContext } from "react";
import usePlaybackSettings from "../hooks/usePlaybackSettings";
import { useAuth } from "./AuthContext";


const ToastNotificationContext = createContext();

export const ToastNotificationProvider = ({ children }) => {
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const [toastNotificationOpen, setToastNotificationOpen] = useState(false);
  const [toastWithActionOpen, setToastWithActionOpen] = useState(false);
  const [toastNotificationMessage, setToastNotificationMessage] = useState("");
  const { updatePlaybackSettings } = usePlaybackSettings();
  const [toastNoClose, setToastNoClose] = useState(false);
  const [blockAutoHide, setBlockAutoHide] = useState(false);

  const resetSettings = () => {
    if (!isAuthenticated) {
      localStorage.removeItem("download_mode");
    } else {
      updatePlaybackSettings({ download_mode: "ask" });
    }
    setToastNotificationOpen(false);
  };

  const handleCloseToast = () => {
    setToastNotificationOpen(false);
    setToastNotificationMessage("");
  };

  return (
    <ToastNotificationContext.Provider
      value={{
        toastNotificationOpen,
        setToastNotificationOpen,
        setToastNotificationMessage,
        toastNotificationMessage,
        toastWithActionOpen,
        setToastWithActionOpen,
        resetSettings,
        setToastNoClose,
        toastNoClose,
        blockAutoHide,
        setBlockAutoHide,
        handleCloseToast,
      }}
    >
      {children}
    </ToastNotificationContext.Provider>
  );
};

export const useToastNotification = () => {
  const context = useContext(ToastNotificationContext);
  if (!context) {
    throw new Error(
      "useToastNotification must be used within a ToastNotificationContext"
    );
  }
  return context;
};
