import React, { forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import { pxToRem } from "px2rem2px";
import FormInputText from "./FormInputText";
import { useForm } from "react-hook-form";
import { Stack, styled } from "@mui/system";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNoteDialog = ({
  action,
  open,
  onClose,
  onSave,
  onAction,
  initialTitle,
  initialContent,
}) => {
  const { control, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    if (action === "Edit" && open) {
      reset({
        title: initialTitle || "",
        content: initialContent || "",
      });
    }
  }, [initialTitle, initialContent, open, action, reset]);

  const onSubmit = (data) => {
    onSave(data.title, data.content);
    reset({
      title: "",
      content: "",
    });
  };

  const handleClose = () => {
    reset({
      title: "",
      content: "",
    });
    onClose();
  };

  const ButtonSubmit = styled(Button)({
    width: pxToRem(300),
    height: `${pxToRem(54)} !important`,
    fontSize: pxToRem(14),
    fontWeight: 600,
    boxShadow: "none",
    margin: `${pxToRem(40)} 0`,
  });

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="ssm"
      keepMounted
      scroll={mobile_ssm ? "paper" : "body"}
      TransitionComponent={mobile_ssm ? Transition : undefined}
      sx={{
        "& .MuiDialog-container": {
          alignItems: { xs: "flex-end", ssm: "center" },
        },
        "& .MuiDialog-paper": {
          borderRadius: {
            xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
            ssm: pxToRem(20),
          },
          margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
          maxWidth: { xs: "100%", ssm: "500px" },
          width: { xs: "100%", ssm: "500px" },
          maxHeight: { xs: "85vh", ssm: "none" },
        },
      }}
    >
      <ModalHeader title={`${action==="Edit"? "Update Note":"Add Note"}`} inModal={true} onClose={handleClose} />
      <DialogContent
        sx={{
          minHeight: "240px",
          backgroundColor: "#f7f6f2",
          p: { xs: pxToRem(30), ssm: pxToRem(50) },
        }}
      >
        <Stack display="flex" flexDirection="column" gap={pxToRem(40)}>
          <FormInputText
            name="title"
            placeholder="Note Title"
            control={control}
            setValue={setValue}
            sx={{
              flexGrow: "1",
              width: { xs: "100%", ssm: "auto" },
              "& input": {
                "&:focus": {
                  outline: "none !important",
                },
              },
            }}
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 40,
                message: "Maximum 40 characters",
              },
            }}
            muiProps={{
              type: "text",
            }}
          />

          <FormInputText
            name="content"
            control={control}
            setValue={setValue}
            placeholder="Note Content"
            sx={{
              flexGrow: "1",
              width: { xs: "100%", ssm: "auto" },
              "& .MuiInputBase-root": {
                height: "auto",
                borderRadius: pxToRem(10),
                padding: 0,
              },
              "& textarea": {
                "&:focus": {
                  outline: "none !important",
                },
              },
            }}
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 500,
                message: "Maximum 500 characters",
              },
            }}
            muiProps={{
              type: "text",
              multiline: true,
              rows: 7,
            }}
          />
        </Stack>
        <Box
          sx={{
            backgroundColor: "#f7f6f2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonSubmit
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="yellow"
            disabled={onAction}
          >
            {action === "Edit"? "Update":"Add"} Note
          </ButtonSubmit>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddNoteDialog;
