import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";


const PageTitleHighlighted = styled(Typography)(({ theme }) => ({
  position: "relative",
  fontFamily: "PloniBold",
  fontSize: pxToRem(70),
  lineHeight: pxToRem(60),
  display: "inline-block",
  "& span": {
    position: "relative",
    "&::after": {
      content: "''",
      width: "104%",
      height: "25%",
      position: "absolute",
      left: "50%",
      bottom: pxToRem(10),
      transform: "translateX(-50%)",
      backgroundColor: "rgba(252,225,129,0.5)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: pxToRem(40),
    lineHeight: "40px",
    "& span::after": {
      bottom: "6px",
    },
  },
}));

export default PageTitleHighlighted;
