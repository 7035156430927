import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
const Sidebar = (props) => {

  const isMediumScreen = useMediaQuery("(max-width:900px)");
  const isSmallScreen = useMediaQuery("(max-width:575px)");

  return (
    <Stack
      gap="30px"
      width={isSmallScreen ? "100%" : isMediumScreen ? "auto" : "30%"}
      backgroundColor="#EDECE8"
      borderRadius="40px"
      flexShrink={0}
      padding={{ xs: "50px", md: "50px 30px 50px 30px" }}
      sx={{ alignItems: isMediumScreen ? "space-between" : "end", ...props.sx }}
    >
      {props.children}
    </Stack>
  );
};

export default Sidebar;
