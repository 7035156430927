import { useQuery, useMutation, useQueryClient } from "react-query";
import { editPlayback, getPlayback, getUserInfo } from "../api/student";

const usePlaybackSettings = (isSubmitting) => {
  const userInfo = getUserInfo();
  const queryClient = useQueryClient();

  // fetch
  const {
    data: settings,
    error,
    isLoading,
  } = useQuery(
    ["playbackSettings", userInfo?.id],
    () => getPlayback(userInfo?.id),
    {
      enabled: !!userInfo?.id && !isSubmitting,
      staleTime: Infinity,
    }
  );

  // Mutation
  const mutation = useMutation(editPlayback, {
    onSuccess: (updatedData) => {
      // Update cached data
      queryClient.setQueryData(["playbackSettings", userInfo.id], updatedData);
    },
  });

  const updatePlaybackSettings = async (newSettings) => {
    mutation.mutate(newSettings);
  };

  return { settings, error, isLoading, updatePlaybackSettings, mutation };
};

export default usePlaybackSettings;
