import {
    Box,
    Dialog,
    DialogContent,
    Link,
    Slide,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import { pxToRem } from "px2rem2px";
  import { NavLink } from "react-router-dom";
  import IconBtnCircular from "../../components/IconBtnCircular";
  import { useAuth } from "../../context/AuthContext";
  
  import { ReactComponent as Close } from "../../images/close.svg";
  import { ReactComponent as CloseWhite } from "../../images/cross-white.svg";
  import { getLanguageClass } from "../../utils/languageClass";
  import { forwardRef } from "react";
  import { ReactComponent as Check } from "../../images/check.svg";
  
  const LinkStyled = styled(Link)({
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 600,
    color: "#BFBEBB",
    textAlign: "center",
    "&:hover": {
      color: "#333",
    },
  });

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const ModalLayout = (props) => {
    const theme = useTheme();
    const { isAuthenticated } = useAuth();
    const { headerTitle, ...restProps } = props;
    const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  
    return (
      <Dialog
        {...restProps}
       maxWidth="ssm"
       keepMounted
       scroll={mobile_ssm ? "paper": "body"}
       TransitionComponent={mobile_ssm ? Transition : undefined}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            return;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
          alignItems: {xs: "flex-end", ssm: "center"},
        },
          "& .MuiPaper-root": {
            borderRadius: {xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`, ssm: pxToRem(20)},
            verticalAlign: "center",
            backgroundColor: "#F7F6F2",
            margin: {xs: "0px !important" , ssm: `${pxToRem(32)} !important`},
            maxWidth: { xs: "100%", ssm: "500px" },
            width: { xs: "100%", ssm: "500px" },
            maxHeight: {xs: "85vh", ssm: "none"},
          },
          zIndex: 1500,
        }}
      >
        <Stack
          position="relative"
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={pxToRem(20)}
          bgcolor="#EDECE8"
          p={{ xs: `${pxToRem(30)} ${pxToRem(25)} ${pxToRem(40)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(25)}`, sm: `${pxToRem(40)} ${pxToRem(60)}` }}
          sx={{ "& button": { boxShadow: "none" } }}
        >
          <Typography
            fontSize={{xs: pxToRem(25), sm: pxToRem(30)}}
            lineHeight="30px"
            fontWeight="900"
            fontFamily="PloniBold"
            letterSpacing="-0.44px"
            textAlign="center"
            className={getLanguageClass(headerTitle,false,true)}
          >
  
  
            {props.headerTitle}
  
          </Typography>
          <IconBtnCircular onClick={() => props.onClose()} sx={{width: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}, height: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}}}>
            <Close color="#026670" />
          </IconBtnCircular>
          {props.showHeaderIcon && <Stack
            position="absolute"
            bottom="-27px"
            left="50%"
            alignItems="center"
            justifyContent="center"
            bgcolor={props.checkIcon ? "#026670" : "#C92727"}
            width={pxToRem(54)}
            height={pxToRem(54)}
            borderRadius="100%"
            sx={{ transform: "translateX(-50%)" }}
            zIndex={1}
          >
            {props.checkIcon ? <Check width={16} height={16} color="#FCE181" /> : props.closeIcon ? <CloseWhite width={16} height={16} /> : ""}
          </Stack>}
        </Stack>
        <DialogContent sx={{p: 0}}>
          <Box position="relative" p={{ xs: `${pxToRem(30)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(25)}`, sm: `${pxToRem(40)} ${pxToRem(60)}` }}>
            {props.children}
            <Stack
              flexDirection={{sssm: "row"}}
              alignItems="center"
              justifyContent="center"
              gap={{ xs: pxToRem(20), ssm: pxToRem(40) }}
              borderTop="1px solid #BFBEBB"
              mt={{ xs: pxToRem(30), ssm: pxToRem(40) }}
              pt={{ xs: pxToRem(30), ssm: pxToRem(40) }}
            >
              {isAuthenticated ? (
                <>
                  <LinkStyled to="" component={NavLink} underline="none">
                    Refund Policy
                  </LinkStyled>
                  <LinkStyled to="" component={NavLink} underline="none">
                    Privacy Policy
                  </LinkStyled>
                  <LinkStyled to="" component={NavLink} underline="none">
                    Terms Of Service
                  </LinkStyled>
                </>
              ) : (
                <LinkStyled to="" component={NavLink} underline="none">
                  Need Help?
                </LinkStyled>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default ModalLayout;
  