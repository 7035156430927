import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Stack,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { formatFileSize } from "../../utils/format";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../IconBtnCircular";
import { ReactComponent as Bookmark } from "../../images/bookmark.svg";
import { ReactComponent as Notes } from "../../images/notes.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";
import { ReactComponent as Download } from "../../images/download.svg";
import { ReactComponent as Play } from "../../images/play.svg";

import usePlaybackSettings from "../../hooks/usePlaybackSettings";
import PlayBtn from "../PlayBtn";
import { url } from "../../api/config";
import api from "../../api/api";
import { useToastNotification } from "../../context/ToastNotificationContext";
import { createBookmark, createNote } from "../../api/course";
import { checkAuth } from "../../utils/checkAuth";
import AddNoteDialog from "../AddNoteDialog";
import { MediaPlayButton } from "@vidstack/react";
import { useVideoPlayer } from "../../context/VideoPlayerContext";
import { useParams } from "react-router-dom";
import { getCourse } from "../../api/course";
const SidePanel = ({
  selectedLesson,
  setExitFullScreen,
  drivingMode,
  useCustomPlayer,
  noListen,
  startsPlaying,
  lessonLockedAndSelected,
}) => {
  const {
    currentTime,
    setReservedTime,
    setListenWatchToggle,
    setNoteToastOpen,
    setBookmarkToastOpen,
    setBookmarkBgColor,
    setBookmarkMessage,
    handleListenClicked,
    handleWatchClicked,
    showButtons,
    isListening,
    firstTimeLoaded,

    setBookmarkListChanged,
    setNoteListChanged,

    setToastOpen,
    setToastMessage,
  } = useVideoPlayer();
  const {
    // toastNotificationOpen,
    setToastNotificationOpen,
    setToastNotificationMessage,
    // toastNotificationMessage,
    setToastWithActionOpen,
    setToastNoClose,
    setBlockAutoHide,

    // toastWithActionOpen,
    // resetSettings,
  } = useToastNotification();
  const isAuthenticated = checkAuth();
  //share
  const location = useLocation();
  const theme = useTheme();
  const isMobileSSSMToSSM = useMediaQuery(
    theme.breakpoints.between("sssm", "ssm")
  );
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const { id } = useParams();
  const [isDownloadAllowed, setIsDownloadAllowed] = useState(false);

  const fetchCourseData = () => {
    getCourse(id).then((response) => {
      setIsDownloadAllowed(response.data.is_download_allowed);
    });
  };
  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
        setToastMessage("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  //endShare

  //notes
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { settings, updatePlaybackSettings } = usePlaybackSettings();
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [noteTimestamp, setNoteTimestamp] = useState(0);
  const handleOpenDialog = () => {
    setNoteTimestamp(Math.floor(currentTime?.current));
    setIsDialogOpen(true);
    setExitFullScreen(true);
  };

  const handleCloseDialog = () => {
    setNoteTimestamp(0);
    setIsDialogOpen(false);
  };

  const handleNoteTitleChange = (event) => {
    setNoteTitle(event.target.value);
  };

  const handleNoteContentChange = (event) => {
    setNoteContent(event.target.value);
  };

  const handleSaveNote = async (noteTitle, noteContent) => {
    // console.log("Note saved:", { noteTitle, noteContent });
    setNoteListChanged(true);
    const payload = {
      lesson: selectedLesson?.id,
      title: noteTitle,
      content: noteContent,
      timestamp: noteTimestamp,
    };

    try {
      const response = await createNote(selectedLesson?.id, payload);
      setNoteToastOpen(true);
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      handleCloseDialog();
      setNoteListChanged(false);
    }
  };

  //end notes

  //start: listen / watch

  const handleToggleListening = () => {
    setListenWatchToggle(true);
    setReservedTime(currentTime?.current);
    if (!isListening) {
      handleListenClicked();
    } else {
      // setIsListening(false);
      handleWatchClicked();
    }
  };
  //end: listen/watch

  // bookmarks
  const [bookmarks, setBookmarks] = useState([]);
  const [disableBookmark, setDisableBookmark] = useState(false);

  const handleAddBookmark = async () => {
    setDisableBookmark(true);
    setBookmarkListChanged(true);

    try {
      const response = await createBookmark(
        selectedLesson.id,
        currentTime?.current
      );
      setBookmarks((prevBookmarks) => [...prevBookmarks, response.data]);

      setBookmarkMessage("Bookmark added successfully!");
      setBookmarkToastOpen(true);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      if (
        error?.response?.data?.error ===
        "Maximum bookmarks limit of 10 lectures per course reached."
      ) {
        setBookmarkMessage(
          "You have reached the maximum number of bookmarks for this course."
        );
        setBookmarkToastOpen(true);
        setBookmarkBgColor("red");
      }
    } finally {
      setDisableBookmark(false);
      setBookmarkListChanged(false);
    }
  };

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      await api.delete(`/api/courses/bookmarks/${bookmarkId}/`);
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark.id !== bookmarkId)
      );
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  const isBookmarked = () => {
    return bookmarks.some(
      (bookmark) =>
        bookmark.time === currentTime && bookmark.lesson === selectedLesson.id
    );
  };

  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [downloadPreference, setDownloadPreference] = useState("video");
  const [disableDownload, setDisableDownload] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const [downloadProgress, setDownloadProgress] = useState(0);

  const [rememberChoice, setRememberChoice] = useState(false);
  const downloadFile = async (format, delay) => {
    let endpoint = "";
    if (format === "mp3") {
      endpoint = `${url}/api/parshas/${selectedLesson?.id}/download_audio/`;
    } else if (format === "mp4") {
      endpoint = `${url}/api/parshas/${selectedLesson?.id}/download_video/`;
    }

    setDownloadProgress(0);

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        const errorData = await response.json();
        setToastNotificationMessage(
          errorData.detail || "Error downloading file"
        );
        setToastNotificationOpen(true);
        setIsDownloading(false);
        return;
      }

      // Get the total size (if provided by the server)
      const contentLength = response.headers.get("content-length");
      const total = contentLength ? parseInt(contentLength, 10) : null;
      let loaded = 0;
      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        loaded += value.length;

        // Update progress if total size is known
        if (total) {
          const progress = Math.round((loaded / total) * 100);
          setDownloadProgress(progress);
          if (progress > 0 && progress < 100) {
            setBlockAutoHide(true);
            setToastNotificationMessage(`Downloading ${progress}%...`);
            setToastNotificationOpen(true);
          }
        } else {
          setBlockAutoHide(true);
          setToastNotificationMessage("Downloading...");
          setToastNotificationOpen(true);
        }
      }

      // Combine the chunks into a Blob.
      const blob = new Blob(chunks);
      const urlBlob = window.URL.createObjectURL(blob);

      // Once the download is complete, update the toast message.
      setToastNotificationMessage("Download complete");
      setToastNotificationOpen(true);
      setToastNoClose(true);
      setBlockAutoHide(false);

      // Apply the dynamic delay if provided.
      if (delay) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }

      // Open the file in a new tab.
      window.open(urlBlob, "_blank");

      // Cleanup the blob URL.
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.log("Download error:", error);
      setToastNotificationMessage(
        "An error occurred while downloading the file"
      );
      setToastNotificationOpen(true);
    }
  };

  // download menu

  const handleDownloadClick = async (event) => {
    console.log(selectedLesson);
    if (!isDownloadAllowed) {
      setToastNotificationOpen(true);
      setToastNotificationMessage("Download restricted by owner");
    } else {
      let mode;
      if (!isAuthenticated) {
        mode = localStorage.getItem("download_mode");
      } else {
        mode = settings?.download_mode;
      }

      if (mode === "mp3" || mode === "mp4") {
        showDownloadFeedback(mode);
        const success = await downloadFile(mode, 6000);
      } else {
        setDownloadAnchorEl(event.currentTarget);
      }
    }
  };

  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
    setRememberChoice(false);
  };

  // download btn

  const handleDownload = () => {
    handleDownloadClose();
    if (rememberChoice) {
      const mode = downloadPreference === "audio" ? "mp3" : "mp4";
      if (isAuthenticated) {
        updatePlaybackSettings({ download_mode: mode });
      } else {
        localStorage.setItem("download_mode", mode);
      }
    }
    if (downloadPreference === "video") {
      downloadFile("mp4");
    } else if (downloadPreference === "audio") {
      downloadFile("mp3");
    }
    showFeedback();
  };

  //  download feedback
  const showFeedback = () => {
    setDisableDownload(true);
    setToastNotificationOpen(true);
    setToastNotificationMessage(
      `your download will start soon. Please wait...`
    );
  };

  const showDownloadFeedback = (format) => {
    const mode = format === "mp3" ? "audio" : "video";

    setToastNotificationMessage(`You have a saved ${mode} download mode.`);
    setToastWithActionOpen(true);
  };
  return (
    <>
      <Stack
        className={mobile_ssm ? "" : "media-controls-group"}
        position={{ xs: "relative", ssm: "absolute" }}
        top={{ xs: pxToRem(15), ssm: pxToRem(30), sm: pxToRem(40) }}
        bottom={{ xs: "auto", sssm: "-61px", ssm: "auto" }}
        right={{ xs: "auto", ssm: pxToRem(40) }}
        minHeight={{ xs: "44px", ssm: "auto" }}
        left={{ xs: "50%", ssm: "auto" }}
        gap={{ xs: pxToRem(10), ssm: pxToRem(10) }}
        flexDirection={{ xs: "row", ssm: "column" }}
        width={{ xs: "100%", ssm: "auto" }}
        justifyContent={{ xs: "space-between", ssm: "flex-start" }}
        alignItems={{
          xs: firstTimeLoaded ? "flex-end" : "center",
          ssm: "stretch",
        }}
        px={{ xs: "25px", ssm: "0" }}
        sx={{
          "& button": {
            backgroundColor: "#fff",
          },
          transform: "translateX(-50%)",
          // zIndex: mobile_ssm ? 1 : 9999,
          zIndex: 9999,
        }}
      >
        <Tooltip
          title="Share Now"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
              "& .MuiTooltip-tooltip": {
                display: { xs: "none", ssm: "block" },
              },
            },
          }}
          placement={mobile_ssm ? "bottom" : "left"}
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
            },
          }}
          minHeight={{ xs: "44px", ssm: "auto" }}
          display={{ xs: "flex", ssm: "block" }}
          alignItems={{ xs: "flex-end", ssm: "flex-start" }}
          onClick={handleShareClick}
        >
          <Box>
            <IconBtnCircular
              className={mobile_ssm ? "" : "hover-green"}
              sx={{
                width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                padding: { xs: "0px", ssm: "8px" },
                display: "flex",
                flexDirection: { xs: "column", ssm: "row" },
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: {
                  xs: "transparent !important",
                  ssm: "#fff !important",
                },
                boxShadow: {
                  xs: "none !important",
                  ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.1)`,
                },
                backdropFilter: { xs: "none", ssm: "blur(10px)" },
                "&:hover": {
                  backgroundColor: {
                    xs: "transparent !important",
                    ssm: "#026670 !important",
                  },
                },
              }}
            >
              <Box sx={{ flexShrink: 0, display: "flex" }}>
                <Share
                  color="#026670"
                  style={{ marginRight: { xs: "0px", ssm: pxToRem(1.5) } }}
                />
              </Box>
              {mobile_ssm && (
                <Typography
                  sx={{
                    color: "#026670",
                    fontSize: pxToRem(12),
                    fontWeight: 600,
                    pt: pxToRem(8),
                    lineHeight: "10px",
                  }}
                >
                  Share
                </Typography>
              )}
            </IconBtnCircular>
          </Box>
        </Tooltip>

        {mobile_ssm && showButtons && firstTimeLoaded && !useCustomPlayer ? (
          <Stack
            className="play-btn"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={pxToRem(11)}
            flexShrink={0}
            sx={{
              "& media-play-button": {
                width: "auto",
                height: { xs: "44px", ssm: "auto" },
              },
              zIndex: 9999,
            }}
          >
            {!noListen && !useCustomPlayer && (
              <MediaPlayButton>
                <Button
                  variant="yellow"
                  startIcon={<Volume style={{ width: pxToRem(22) }} />}
                  sx={{
                    height: { xs: "44px", ssm: pxToRem(54) },
                    px: { xs: pxToRem(25), ssm: pxToRem(40) },
                    "&:hover svg": { color: "#026670" },
                    fontSize: pxToRem(14),
                    boxShadow: "0px 3px 6px #0000001A",
                  }}
                  // onClick={handleListenClicked}
                  disabled={lessonLockedAndSelected}
                  onClick={
                    lessonLockedAndSelected ? () => {} : handleListenClicked
                  }
                >
                  Listen
                </Button>
              </MediaPlayButton>
            )}
            {!drivingMode && (
              <MediaPlayButton>
                {useCustomPlayer ? (
                  <PlayBtn
                    width={{ xs: "60px", sm: pxToRem(120) }}
                    height={{ xs: "60px", sm: pxToRem(120) }}
                    onClick={handleWatchClicked}
                    style={{ cursor: "pointer" }}
                  >
                    <Play
                      color="#026670"
                      width={pxToRem(20)}
                      height={pxToRem(24)}
                      style={{ marginLeft: "6%", cursor: "pointer" }}
                      onClick={handleWatchClicked}
                    />
                  </PlayBtn>
                ) : (
                  <>
                    {!startsPlaying && (
                      <Button
                        variant="yellow"
                        startIcon={
                          <CamcorderIcon style={{ width: pxToRem(22) }} />
                        }
                        sx={{
                          height: { xs: "44px", ssm: pxToRem(54) },
                          px: { xs: pxToRem(25), ssm: pxToRem(40) },
                          fontSize: pxToRem(14),
                          "&:hover svg": { color: "#026670" },
                          boxShadow: "0px 3px 6px #0000001A",
                          backgroundColor: "#FCE181 !important",
                          "&:hover": {
                            backgroundColor: "#ebcf6a !important",
                          },
                          "& svg": {
                            transform: "scale(0.9)",
                          },
                        }}
                        onClick={
                          lessonLockedAndSelected
                            ? () => {}
                            : handleWatchClicked
                        }
                        disabled={lessonLockedAndSelected}
                      >
                        {!noListen ? "Watch" : "Watch Preview"}
                      </Button>
                    )}
                  </>
                )}
              </MediaPlayButton>
            )}
          </Stack>
        ) : (
          <>
            <Tooltip
              title={isBookmarked() ? "Remove Bookmark" : "Add Bookmark"}
              enterDelay={0}
              enterTouchDelay={0}
              // PopperProps={{ disablePortal: mobile_ssm ? false : true, sx: {textWrap: "nowrap", "& .MuiTooltip-tooltipPlacementBottom": {mt: {xs: "8px !important", sssm: "12px !important"}}}  }}
              PopperProps={{
                disablePortal: false, // Always use the portal for correct positioning
                sx: {
                  whiteSpace: "nowrap",
                  "& .MuiTooltip-tooltipPlacementBottom": {
                    mt: { xs: "8px !important", sssm: "12px !important" },
                  },
                  "& .MuiTooltip-tooltip": {
                    display: { xs: "none", ssm: "block" },
                  },
                },
              }}
              minHeight={{ xs: "44px", ssm: "auto" }}
              display={{ xs: "flex", ssm: "block" }}
              alignItems={{ xs: "flex-end", ssm: "flex-start" }}
              placement={mobile_ssm ? "bottom" : "left"}
            >
              <Box className="tooltip-fix">
                <IconBtnCircular
                  sx={{
                    width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                    height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                    borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                    padding: { xs: "0px", ssm: "8px" },
                    display: "flex",
                    flexDirection: { xs: "column", ssm: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: {
                      xs: "transparent !important",
                      ssm: "#fff !important",
                    },
                    boxShadow: {
                      xs: "none !important",
                      ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.1)`,
                    },
                    backdropFilter: { xs: "none", ssm: "blur(10px)" },
                    "&:hover": {
                      backgroundColor: {
                        xs: "transparent !important",
                        ssm: "#026670 !important",
                      },
                    },
                  }}
                  className={mobile_ssm ? "" : "hover-green"}
                  onClick={
                    isBookmarked()
                      ? () =>
                          handleRemoveBookmark(
                            bookmarks.find(
                              (bookmark) =>
                                bookmark.timestamp === currentTime &&
                                bookmark.lesson === selectedLesson.id
                            ).id
                          )
                      : handleAddBookmark
                  }
                  disabled={disableBookmark}
                >
                  <Box sx={{ flexShrink: 0, display: "flex" }}>
                    {isBookmarked() ? (
                      <Bookmark color="#026670" />
                    ) : (
                      <Bookmark color="#026670" />
                    )}
                  </Box>
                  {mobile_ssm && (
                    <Typography
                      sx={{
                        color: "#026670",
                        fontSize: pxToRem(12),
                        fontWeight: 600,
                        pt: pxToRem(8),
                        lineHeight: "10px",
                      }}
                    >
                      Bookmark
                    </Typography>
                  )}
                </IconBtnCircular>
              </Box>
            </Tooltip>

            <Tooltip
              title="Add Notes"
              enterDelay={0}
              enterTouchDelay={0}
              PopperProps={{
                disablePortal: mobile_ssm ? false : true,
                sx: {
                  textWrap: "nowrap",
                  "& .MuiTooltip-tooltipPlacementBottom": {
                    mt: { xs: "8px !important", sssm: "12px !important" },
                  },
                  "& .MuiTooltip-tooltip": {
                    display: { xs: "none", ssm: "block" },
                  },
                },
              }}
              minHeight={{ xs: "44px", ssm: "auto" }}
              display={{ xs: "flex", ssm: "block" }}
              alignItems={{ xs: "flex-end", ssm: "flex-start" }}
              placement={mobile_ssm ? "bottom" : "left"}
              onClick={handleOpenDialog}
            >
              <Box>
                <IconBtnCircular
                  className={mobile_ssm ? "" : "hover-green"}
                  sx={{
                    width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                    height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                    borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                    padding: { xs: "0px", ssm: "8px" },
                    display: "flex",
                    flexDirection: { xs: "column", ssm: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: {
                      xs: "transparent !important",
                      ssm: "#fff !important",
                    },
                    boxShadow: {
                      xs: "none !important",
                      ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.1)`,
                    },
                    backdropFilter: { xs: "none", ssm: "blur(10px)" },
                    "&:hover": {
                      backgroundColor: {
                        xs: "transparent !important",
                        ssm: "#026670 !important",
                      },
                    },
                  }}
                >
                  <Box sx={{ flexShrink: 0, display: "flex" }}>
                    <Notes height="20.8px" color="#026670" />
                  </Box>
                  {mobile_ssm && (
                    <Typography
                      sx={{
                        color: "#026670",
                        fontSize: pxToRem(12),
                        fontWeight: 600,
                        pt: pxToRem(8),
                        lineHeight: "10px",
                      }}
                    >
                      Note
                    </Typography>
                  )}
                </IconBtnCircular>
              </Box>
            </Tooltip>
            <Box
              position="relative"
              onClick={handleToggleListening}
              minHeight={{ xs: "44px", ssm: "auto" }}
              display={{ xs: "flex", ssm: "block" }}
              alignItems={{ xs: "flex-end", ssm: "flex-start" }}
            >
              <IconBtnCircular
                sx={{
                  flexDirection: { xs: "column", ssm: "row" },
                  width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                  height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                  padding: { xs: "0px", ssm: "8px" },
                  backgroundColor: {
                    xs: "transparent !important",
                    ssm: "#fce181 !important",
                  },
                  boxShadow: {
                    xs: "none !important",
                    ssm: `0px ${pxToRem(10)} ${pxToRem(20)} rgba(0,0,0,0.1)`,
                  },
                  backdropFilter: { xs: "none", ssm: "blur(10px)" },
                  "&:hover": {
                    backgroundColor: {
                      xs: "transparent !important",
                      ssm: "#ebcf6a !important",
                    },
                  },
                }}
                className="yellow-ic"
              >
                <Box
                  sx={{
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& svg": {
                      transform: isListening ? "scale(0.9)" : "scale(1)",
                    },
                  }}
                >
                  {isListening ? (
                    <CamcorderIcon color="#026670" />
                  ) : (
                    <Volume color="#026670" />
                  )}
                </Box>
                {mobile_ssm && (
                  <Typography
                    sx={{
                      color: "#026670",
                      fontSize: pxToRem(12),
                      fontWeight: 600,
                      pt: pxToRem(8),
                      lineHeight: "10px",
                    }}
                  >
                    {isListening ? "Watch" : "Listen"}
                  </Typography>
                )}
              </IconBtnCircular>
              <Box
                className="toggle-tooltip"
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: { ssm: pxToRem(62) },
                  left: { xs: "50%", ssm: "auto" },
                  transform: {
                    xs: "translate(-50%, 144%)",
                    sssm: "translate(-50%, 155%)",
                    ssm: "translateY(-50%)",
                  },
                  display: { xs: "none", ssm: "block" },
                  fontSize: pxToRem(12),
                  fontWeight: 600,
                  color: "#026670",
                  borderRadius: pxToRem(10),
                  backgroundColor: "#FCE181",
                  whiteSpace: "nowrap",
                  padding: `${pxToRem(1)} ${pxToRem(10)}`,
                  opacity: 0,
                  transition: "opacity 0.1s ease",
                }}
              >
                {isListening ? "Switch to Video" : "Switch to Audio"}
                {/* Listen / Watch */}
              </Box>
            </Box>
            {/* 
            <Box>
              <IconBtnCircular
                onClick={handleDownloadClick}
                className={
                  isDownloadAllowed ? "hover-green" : "hover-disabled-green" // disabled download
                }
                sx={{
                  width: pxToRem(48),
                  height: pxToRem(48),
                  borderRadius: "50%",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                }}
              >
                <Download
                  color={
                    // selectedLesson?.is_download_allowed ? "#026670" : "#BFBEBB"
                    "#026670"
                  }
                  style={{ marginRight: pxToRem(1.5) }}
                />
              </IconBtnCircular>
            </Box> */}
          </>
        )}
      </Stack>
      <Menu
        anchorEl={downloadAnchorEl}
        open={Boolean(downloadAnchorEl)}
        onClose={handleDownloadClose}
        sx={{
          "& .MuiPaper-root": {
            mt: 1,
            padding: 2,
            borderRadius: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            minWidth: "280px",
          },
          "& .MuiList-root": {
            padding: 0,
          },
        }}
      >
        <Stack spacing={2.5}>
          <Typography
            variant="h6"
            sx={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              color: "#026670",
              mb: 1,
            }}
          >
            Download Options
          </Typography>

          <FormControl sx={{ mt: "10px !important" }}>
            <RadioGroup
              value={downloadPreference}
              onChange={(e) => setDownloadPreference(e.target.value)}
            >
              <FormControlLabel
                sx={{
                  py: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: pxToRem(15),
                    fontWeight: 500,
                    color: "#333333",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(2, 102, 112, 0.04)",
                    borderRadius: "8px",
                  },
                }}
                value="video"
                control={
                  <Radio
                    sx={{
                      color: "#026670",
                      p: 1,
                      "&.Mui-checked": { color: "#026670" },
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  />
                }
                label={
                  <Stack>
                    <Typography sx={{ fontWeight: 500 }}>
                      Download Video
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#666" }}>
                      {formatFileSize(selectedLesson?.video_size)} • MP4 Format
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                sx={{
                  py: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: pxToRem(15),
                    fontWeight: 500,
                    color: "#333333",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(2, 102, 112, 0.04)",
                    borderRadius: "8px",
                  },
                }}
                value="audio"
                control={
                  <Radio
                    sx={{
                      color: "#026670",
                      p: 1,
                      "&.Mui-checked": { color: "#026670" },
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  />
                }
                label={
                  <Stack>
                    <Typography sx={{ fontWeight: 500 }}>
                      Download Audio
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#666" }}>
                      {formatFileSize(selectedLesson?.audio_size)} • MP3 Format
                    </Typography>
                  </Stack>
                }
              />
            </RadioGroup>
          </FormControl>

          <FormControlLabel
            sx={{
              borderTop: "1px solid rgba(2, 102, 112, 0.12)",
              pt: "10px !important",
              mt: "10px !important",
              "& .MuiFormControlLabel-label": {
                fontSize: pxToRem(14),
                fontWeight: 500,
                color: "#333333",
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#026670",
                  "&.Mui-checked": {
                    color: "#026670",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
                checked={rememberChoice}
                onChange={(e) => setRememberChoice(e.target.checked)}
              />
            }
            label="Remember my choice"
          />

          <Stack
            direction="row"
            spacing={2}
            sx={{ pt: "16px !important", mt: "0 !important" }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ px: `${pxToRem(28)} !important`, flexGrow: 1 }}
              onClick={handleDownloadClose}
            >
              Cancel
            </Button>
            <Button
              variant="green"
              size="small"
              sx={{ px: `${pxToRem(28)} !important`, flexGrow: 1 }}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Stack>
        </Stack>
      </Menu>
      <AddNoteDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveNote}
      />
    </>
  );
};

export default SidePanel;
