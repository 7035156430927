import { Box, Button, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import Header from "../../components/Header";
import GradientAnimation from "../../components/GradientAnimation";
import { getHeroSection } from "../../api/cmsApi";
import TreeImg from "../../images/footer-tree.svg";
import { useState, useEffect } from "react";
import highlightHTML from "../../utils/highlightHTML";

const Hero = () => {
  const [heroSection, setHeroSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentParagraph, setCurrentParagraph] = useState(0);
  const [visibleLetters, setVisibleLetters] = useState(0);

  const getParagraphs = (htmlArray) => {
    return htmlArray.map((html, index) => {
      const tempEl = document.createElement("div");
      tempEl.innerHTML = html;
      const text = tempEl.textContent.trim();

      const firstChild = tempEl.firstElementChild;
      return {
        id: index,
        tag: firstChild ? firstChild.tagName.toLowerCase() : "div",
        html,
        text,
        class: firstChild ? firstChild.className : "",
      };
    });
  };
  useEffect(() => {
    if (heroSection?.title) {
      const paragraphs = getParagraphs(heroSection?.title);
      const currentText = paragraphs[currentParagraph]?.text || "";

      if (visibleLetters < currentText.length) {
        const timer = setTimeout(() => {
          setVisibleLetters((prev) => prev + 1);
        }, 30);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          if (currentParagraph >= paragraphs.length - 1) {
            setCurrentParagraph(0);
          } else {
            setCurrentParagraph((prev) => prev + 1);
          }
          setVisibleLetters(0);
        }, 2000);
        return () => clearTimeout(timer);
      }
    }
  }, [heroSection, currentParagraph, visibleLetters]);

  const renderParagraphWithHighlight = (paragraph, index) => {
    if (index !== currentParagraph) {
      return null;
    }

    const tempElement = document.createElement("div");
    tempElement.innerHTML = highlightHTML(paragraph.html, true);

    const wrapTextNodesWithOpacity = (node, currentLength = 0) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent;
        const wrapper = document.createElement("span");
        wrapper.innerHTML = text
          .split("")
          .map((char, charIndex) => {
            const absoluteIndex = currentLength + charIndex;
            const opacity = absoluteIndex < visibleLetters ? 1 : 0.15;
            return `<span style="opacity: ${opacity}; transition: opacity 0.3s ease">${char}</span>`;
          })
          .join("");
        node.parentNode.replaceChild(wrapper, node);
        return currentLength + text.length;
      } else {
        let length = currentLength;
        Array.from(node.childNodes).forEach((child) => {
          length = wrapTextNodesWithOpacity(child, length);
        });
        return length;
      }
    };

    wrapTextNodesWithOpacity(tempElement.firstChild);

    return (
      <div
        key={index}
        className={`${paragraph.class} paragraph-animation`}
        style={{
          opacity: 1,
          transform: "translateY(0)",
          transition: "all 0.5s ease",
        }}
        dangerouslySetInnerHTML={{
          __html: tempElement.innerHTML,
        }}
      />
    );
  };
  const fetchHeroSection = async () => {
    try {
      const data = await getHeroSection();
      setHeroSection(data);
    } catch (error) {
      console.error("Error fetching hero section:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHeroSection();
  }, []);

  return (
    <Box
      position="relative"
      pt={{ xs: pxToRem(20), sm: pxToRem(30) }}
      pb={pxToRem(40)}
      // minHeight={{xs: pxToRem(700), sm: pxToRem(835)}}
      sx={{
        borderRadius: { xs: 0, md: pxToRem(40) },
        paddingBottom: { xs: pxToRem(1), md: pxToRem(40) },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "clip",
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: pxToRem(30),
            left: "50%",
            transform: "translateX(-125%)",
            background: `url(${TreeImg})`,
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(702),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      ></Box>
      <GradientAnimation id="gradient-canvas-hero" />
      <Header menuVisible />

      <Box
        className="rtl-section header-padding"
        position="relative"
        maxWidth={pxToRem(1000)}
        mt={{ md: pxToRem(50) }}
        mb={{ xs: pxToRem(70), ssm: pxToRem(100), md: pxToRem(70) }}
        mx={{ md: "auto" }}
        ml="auto"
        zIndex={1}

        // backgroundColor="red"
      >
        {isLoading ? (
          <Box sx={{ px: "30px" }}>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: { xs: "30px", ssm: "40px" } }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: { xs: "30px", ssm: "40px" }, width: "65%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: { xs: "30px", ssm: "40px" }, width: "95%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: { xs: "30px", ssm: "40px" }, width: "80%" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: { xs: "30px", ssm: "40px" }, width: "90%" }}
            />
          </Box>
        ) : (
          <>
            {heroSection && heroSection?.title && (
              <Box
                fontFamily="PloniBold"
                fontSize={{ xs: "20px", sm: pxToRem(40) }}
                lineHeight={{ xs: "10px", sm: pxToRem(75) }}
                sx={{
                  fontWeight: "500",
                  fontFamily: "PloniBold",
                  px: { xs: "30px", sm: "30px" },
                  height: { xs: "250px", ssm: "200px", sm: "300px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getParagraphs(heroSection.title).map((paragraph, index) =>
                  renderParagraphWithHighlight(paragraph, index)
                )}
              </Box>
            )}
            {heroSection?.button_text && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  px: "30px",
                  mt: { xs: "2px", sm: "5px" },
                }}
              >
                <Button
                  variant="white"
                  onClick={() =>
                    window.open(
                      heroSection.button_link,
                      heroSection.target_new_tab ? "_blank" : "_self"
                    )
                  }
                  sx={{
                    textTransform: "none",
                    "@media(max-width: 767px)": {
                      height: pxToRem(54),
                      paddingLeft: "40px",
                      paddingRight: "40px",
                    },
                    "@media(max-width: 575px)": {
                      height: "44px",
                      padding: "10px 26px",
                    },
                  }}
                >
                  {heroSection?.button_text}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Hero;
