import { useState, useEffect } from "react";
import { Stack, Box, Typography, CircularProgress } from "@mui/material";
import { pxToRem } from "px2rem2px";

import playWithCircle from "../../../images/play-with-circle.svg";
import { getVideos } from "../../../api/parsha";
import { ReactComponent as Eye } from "../../../images/eye.svg";
export default function VideosTab({
  tab,
  search,
  setVideosCount,
  videosCount,
}) {
  const [videos, setVideos] = useState([]);
  const [videosShown, setVideosShown] = useState(5);

  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchVideos = () => {
    setLoading(true);
    getVideos(100, search)
      .then((res) => {
        setVideos(res.data.results);
        setVideosCount(res.data.count);
        console.log("videos res", res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchVideos();
  }, [search]);

  const handleViewMore = () => {
    setVideosShown((prev) => prev + 5);
  };
  const handleViewLess = () => {
    setVideosShown(5);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", height: "50px" }}>
          <CircularProgress sx={{ color: "#036670" }} />
        </Box>
      ) : (
        <>
          {" "}
          <Stack
            sx={{
              //padding: `${pxToRem(20)} 0 ${pxToRem(20)} ${pxToRem(12)}`,
              rowGap: pxToRem(16),
              pt: tab === 0 ? pxToRem(20) : pxToRem(10),
            }}
          >
            {videos.slice(0, videosShown).map((videoDetails, index) => (
              <Stack
                key={index}
                direction="row"
                gap={pxToRem(15)}
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "&:hover .title": { color: "#026670" },
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    borderRadius: pxToRem(6),
                    width: pxToRem(92),
                    height: pxToRem(60),
                    position: "relative",
                    flex: 1,
                  }}
                >
                  <img
                    src={videoDetails.thumb_nail}
                    alt=""
                    style={{
                      objectFit: "fill",
                      borderRadius: pxToRem(5),
                      width: pxToRem(92),
                      height: pxToRem(50),
                    }}
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: pxToRem(24),
                      height: pxToRem(24),
                    }}
                  >
                    <img src={playWithCircle} alt="" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 4.4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="large"
                    fontWeight="600"
                    component="p"
                    className="title"
                    style={{
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      position: "relative",
                      marginTop: "-13px",
                    }}
                    data-fullname={videoDetails.title}
                  >
                    {videoDetails.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: "5px",
                    }}
                  >
                    <Typography
                      variant="small"
                      fontWeight="400"
                      component="p"
                      color="#C0C0C0"
                    >
                      {videoDetails.views}
                    </Typography>
                    <Eye
                      width={pxToRem(14)}
                      height={pxToRem(14)}
                      style={{ marginBottom: "1px" }}
                    />
                  </Box>
                </Box>
              </Stack>
            ))}
            {videos.length === 0 && (
              <Typography
                variant="large"
                fontWeight="600"
                component="p"
                color="#C0C0C0"
                sx={{ textAlign: "center", pt: "20px" }}
              >
                No Shiurim Found
              </Typography>
            )}
          </Stack>
          {tab === 0 && videos.length > 2 && (
            <Typography
              variant="button"
              component="p"
              sx={{
                cursor: "pointer",
                fontWeight: "500",
                textTransform: "capitalize",
                textDecoration: "underline",
                "&:hover": { color: "#026670" },
                py: "15px",
                px: "2px",
              }}
              onClick={
                videosShown >= videosCount ? handleViewLess : handleViewMore
              }
            >
              {videosShown >= videosCount ? "Show Less" : "View More"}
            </Typography>
          )}
          {tab !== 0 && videos.length > 4 && (
            <Stack alignItems="center">
              <Box
                sx={{
                  borderRadius: pxToRem(50),
                  backgroundColor: "#E9E9E9",
                  p: `${pxToRem(8)} ${pxToRem(18)}`,
                  display: "inline-block",
                  mt: pxToRem(10),
                }}
                onClick={
                  videosShown >= videosCount ? handleViewLess : handleViewMore
                }
              >
                <Typography
                  variant="button"
                  component="p"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    "&:hover": { color: "#026670" },
                  }}
                >
                  {videosShown >= videosCount
                    ? "Show Less"
                    : "View More Results"}
                </Typography>
              </Box>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
