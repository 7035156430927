import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Dialog,
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  Rating,
  Slide,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { pxToRem } from "px2rem2px";

import Switcher from "../Switcher";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { postCourseRatings } from "../../api/course";
import { getStudent } from "../../api/student";
import Toast from "../Toast";
import ModalHeader from "../ModalHeader";
import capitalizeFirstLetter from "../../utils/capitalize";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RateNudge = ({
  open,
  setOpen,
  course,
  updateRatings,
  lastLesson,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [student, setStudent] = useState({
    avatar: "",
    user: "",
    nickname: "",
  });
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(5);
  const [isSaving, setIsSaving] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipButtonRef = useRef(null);

  const fetchStudent = () => {
    getStudent()
      .then((res) => {
        setStudent({
          avatar: res.data.avatar,
          user: `${capitalizeFirstLetter(
            res.data.user.first_name
          )} ${capitalizeFirstLetter(res.data.user.last_name)}`,
          nickname: capitalizeFirstLetter(res.data.nickname),
        });
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  const handleSave = () => {
    setIsSaving(true);
    const payload = { rating, description: review, is_annonymous: isAnonymous };
    postCourseRatings(course.id, payload)
      .then((res) => {
        setIsSaving(false);
        handleClose();
        setToastOpen(true);
        setToastMessage("Rating saved successfully!");
        updateRatings();
      })
      .catch((err) => {
        setIsSaving(false);
        setToastOpen(true);
        setToastMessage("Failed to save rating");
      });
  };

  const handleClose = () => {
    setOpen(false);
    setReview("");
    setRating(0);
    setIsAnonymous(false);
  };

  const handleTooltipClick = () => (e) => {
    e.stopPropagation();
    if (isTablet) {
      setTooltipOpen((prev) => !prev);
    }
  };

  const handleClickOutside = (e) => {
    if (
      tooltipButtonRef.current &&
      !tooltipButtonRef.current.contains(e.target)
    ) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Dialog
        {...props}
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="ssm"
        keepMounted
        scroll={mobile_ssm ? "paper" : "body"}
        TransitionComponent={mobile_ssm ? Transition : undefined}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: {
              xs: "100%",
              ssm: `${pxToRem(600)} !important`,
              md: `${pxToRem(600)} !important`,
            },
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            height: "auto",
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            backgroundColor: "#F7F6F2",
            maxHeight: { xs: "85vh", ssm: "none" },
          },
          zIndex: 1500,
        }}
      >
        <ModalHeader
          title={lastLesson ? "Enjoyed the course?" : "Enjoying the course?"}
          inModal={true}
          onClose={handleClose}
        ></ModalHeader>

        <DialogContent sx={{ p: 0, backgroundColor: "#F7F6F2" }}>
          <Stack alignItems="center" spacing={1} mt={pxToRem(40)}>
            <Typography
              fontSize={{ xs: pxToRem(20), ssm: pxToRem(25) }}
              fontWeight={700}
              textAlign="center"
            >
              {lastLesson
                ? "You've Completed This Course!"
                : "You are almost done!"}
            </Typography>
            <Typography
              fontSize={{ xs: pxToRem(21), ssm: pxToRem(25) }}
              fontWeight={700}
              textAlign="center"
            >
              Leave a quick rating and review
            </Typography>
          </Stack>
          <Box p={`${pxToRem(20)} ${pxToRem(40)} ${pxToRem(30)}`}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Rating
                value={rating}
                onChange={(e, newValue) => setRating(newValue)}
                sx={{
                  fontSize: "50px",
                  mb: "25px",
                  "& .MuiRating-iconFilled": { color: "#fde181" },
                  "& .MuiRating-iconHover": { color: "#fde181" },
                }}
              />
            </Box>

            <Box mb={pxToRem(40)}>
              <Box
                bgcolor="#EDECE8"
                borderRadius={pxToRem(10)}
                p={{ xs: pxToRem(10), ssm: `${pxToRem(22)} ${pxToRem(26)}` }}
                mb={pxToRem(10)}
              >
                <TextField
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  placeholder="Write your review..."
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "transparent",
                      borderRadius: pxToRem(10),
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontStyle: "italic",
                      fontWeight: 400,
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: pxToRem(65),
                }}
              >
                <Switcher
                  name="anonymous"
                  label="Post Anonymously"
                  checked={isAnonymous}
                  onChange={(e) => setIsAnonymous(e.target.checked)}
                />
                <Tooltip
                  open={isTablet ? tooltipOpen : undefined}
                  title={`Enable to post your rating as ${
                    student?.nickname || "User"
                  }. Disable to show your full name (${student?.user})`}
                  enterDelay={0}
                  enterTouchDelay={0}
                  PopperProps={{ disablePortal: true }}
                  placement="bottom"
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      mt: pxToRem(4),
                      py: pxToRem(4),
                    },
                  }}
                >
                  <Box
                    flexShrink={0}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      ref={tooltipButtonRef}
                      startIcon={
                        <HelpOutlineIcon sx={{ fontSize: "16px !important" }} />
                      }
                      sx={{ color: "#BFBEBB", textAlign: "center" }}
                      onClick={handleTooltipClick()}
                    />
                  </Box>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Button
                  sx={{ width: { xs: "50%", ssm: "50%" } }}
                  variant="yellow"
                  size="large"
                  disabled={isSaving}
                  onClick={handleSave}
                >
                  <span style={{ fontSize: "16px" }}>
                    {isSaving ? "Submitting" : "Submit"}
                  </span>
                </Button>
                {!lastLesson && (
                  <Typography
                    sx={{
                      caretColor: "transparent",
                      cursor: "pointer",
                      color: "#BFBEBB",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                    onClick={handleClose}
                  >
                    Continue Watching
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default RateNudge;
