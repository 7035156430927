import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import InputGroup from "./InputGroup";

const PersonalDetails = (
  {fullName,
  setFullName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  comment,
  setComment,
  serverErrors,
 }
) => {


  const handleFullNameChange = (val) => {
    console.log("Full name changed:", val);
    setFullName(val);
  };

  const handleEmailChange = (val) => {
    console.log("Email changed:", val);
    setEmail(val);
  };

  const handleCommentChange = (val) => {
    console.log("Comment changed:", val);
    setComment(val);
  };
  
 

  console.log('full name: ', fullName)
  console.log('phone number: ', phoneNumber)
  console.log('email: ', email)
  console.log('comment: ', comment)



  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: "1px solid rgba(191,190,187,0.5)",
        padding: { xs: "32px 0", sm: "60px 0 80px" },
      }}
    >
      <Grid item xs={12} md={3}>
        <Typography
          variant="h6"
          gutterBottom
          fontSize="24px"
          lineHeight="30px"
          fontWeight="700"
        >
          Personal Details
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack gap="20px">
          <InputGroup
            label="Full Name"
            value={fullName}
            onChange={handleFullNameChange}
            type="text"
            rules={{
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
            serverError={serverErrors?.full_name?.[0]}
            required
          />
          <InputGroup
            phone
            label="Phone"
            phoneValue={phoneNumber}
            onChangePhoneNumber={(val) => setPhoneNumber(val)}
            serverError={serverErrors?.phone_number?.[0]}
          />

          <InputGroup
            label="Your Email Address"
            type="email"
            value={email}
            onChange={handleEmailChange}
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 100,
                message: "Maximum 100 characters",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            serverError={serverErrors?.email?.[0]}
            required
          />
          <InputGroup
            label="Comment & Dedication (optional)"
            value={comment}
            onChange={handleCommentChange}
            type="text"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
