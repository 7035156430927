import { Pagination } from "@mui/material";


const BookmarksPagination = ({ count, page, onChange }) => {
  return (
    <Pagination
      count={Math.ceil(count)}
      page={page}
      onChange={onChange}
      sx={{
        "& .MuiPagination-ul": {
          flexWrap: "nowrap",
        },
        "& .MuiPaginationItem-root": {
          width: "40px",
          height: "40px",
          fontSize: "1rem",
          fontWeight: 600,
          borderRadius: "50%",
          color: "#026670",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          display: "flex", 
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "#026670",
            color: "#fff",
          },
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "#026670",
          color: "#FCE181",
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
          backgroundColor: "#026670",
          color: "#FCE181",
        },
        "& .MuiPaginationItem-ellipsis": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    />
  );
};

export default BookmarksPagination;
