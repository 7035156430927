import React, { useRef } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import GradientAnimation from "../../components/GradientAnimation";
import Header from "../../components/Header";
import DonationAmount from "./DonationAmount";
import SustainerOptions from "./SustainerOptions";
import TreeImg from "../../images/footer-tree.svg";
import PersonalDetails from "./PersonalDetails";
import { useModal } from "mui-modal-provider";
import ThankModal from "./ThankModal";
import CheckModal from "./CheckModal";
import ErrorModal from "./ErrorModal";
import { useEffect, useState } from "react";
import { createDonationCheckoutSession } from "../../api/payment";
import { useSearchParams } from "react-router-dom";
import PageTitleHighlighted from "../../components/PageTitleHighlighted";
import ContentSlider from "../../components/courses/ContentSlider";
const TotalAmount = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    height: pxToRem(72),
    borderRadius: "calc(infinity * 1px)",
    backgroundColor: "#EDECE8",
    fontSize: pxToRem(40),
    fontWeight: 700,
    color: "#026670",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      height: "48px",
    },
    [theme.breakpoints.down("ssm")]: {
      width: "100%",
    },
  };
});

const formatCurrency = (value) => {
  const numericValue = parseFloat(value) || 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericValue);
};

const Donate = () => {
  const { showModal } = useModal();
  const donateFormRef = useRef(null);
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [recurrenceRate, setRecurrenceRate] = useState(
    "Until I Cancel The Subscription"
  );

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const [customAmount, setCustomAmount] = useState(100.0);
  const [frequency, setFrequency] = useState("One Time");
  const [notification, setNotification] = useState(false);

  const [serverErrors, setServerErrors] = useState({});

  const [donating, setDonating] = useState(false);

  useEffect(() => {
    document.title = "Make a Donation - Reb Yakov Moshe";

    // Check for query params
    const queryParams = new URLSearchParams(window.location.search);
    const fullName = queryParams.get("full_name");
    const success = queryParams.get("success");
    const cancel = queryParams.get("cancel");

    if (success === "yes" && fullName) {
      showModal(ThankModal, { fullName, onClose });
    } else if (cancel === "yes" && fullName) {
      showModal(ErrorModal, { fullName, onClose });
    }
  }, [showModal]);

  useEffect(() => {
    const storedData = localStorage.getItem("studentData");
    if (storedData) {
      try {
        const parsed = JSON.parse(storedData);
        const email = parsed?.user?.email || "";
        const firstName = parsed?.user?.first_name || "";
        const lastName = parsed?.user?.last_name || "";

        setEmail(email);
        setFullName(`${firstName} ${lastName}`.trim());
      } catch (e) {
        console.error("Error parsing studentData from localStorage:", e);
      }
    }
  }, []);

  const handleDonateNowClick = async () => {
    setDonating(true);
    let errors = {};
  
    // Validate full name
    if (fullName.trim() === "") {
      errors.full_name = ["Full name is required"];
    }
  
    // Validate phone number
    if (!phoneNumber || phoneNumber.trim() === "+1") {
      errors.phone_number = ["Phone number is required"];
    }
  
    // Validate email: check if provided and if valid format
    if (email.trim() === "") {
      errors.email = ["Email is required"];
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        errors.email = ["Please enter a valid email address"];
      }
    }
  
    // If there are any errors, set them and return early
    if (Object.keys(errors).length > 0) {
      setServerErrors(errors);
      setDonating(false);
      return;
    }
  
    // Use customAmount if "Other" is selected
    const donationAmount = selectedAmount === "Other" ? customAmount : selectedAmount;
  
    try {
      const response = await createDonationCheckoutSession(
        parseFloat(donationAmount || 0).toFixed(2),
        frequency,
        recurrenceRate,
        notification,
        email,
        fullName,
        phoneNumber,
        comment
      );
  
      const checkoutUrl = response.data.checkout_url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
      if (error?.response?.data) {
        setServerErrors(error.response.data);
      }
    } finally {
      setDonating(false);
    }
  };
  

  const onClose = () => {
    setServerErrors({});
    setNotification(false);
    setFrequency("One Time");
    setRecurrenceRate("Until I Cancel The Subscription");
    setComment("");
    setSelectedAmount(100);
    setCustomAmount(100);
  };

  const handleScrollToForm = () => {
    donateFormRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    console.log("selectedAmount", selectedAmount, "customAmount", customAmount);
  }, [selectedAmount, customAmount]);

  return (
    <>
      <Box
        position="relative"
        pt={{ xs: pxToRem(20), sm: pxToRem(30) }}
        pb={pxToRem(40)}
        minHeight={{ xs: "400px", sm: "540px" }}
        sx={{
          borderRadius: { xs: 0, md: pxToRem(40) },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowX: "clip",
            "&::before": {
              content: "''",
              position: "absolute",
              bottom: pxToRem(30),
              left: "50%",
              transform: "translateX(-125%)",
              background: `url(${TreeImg})`,
              zIndex: 1,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              width: pxToRem(520),
              height: pxToRem(600),
              opacity: 0.4,
              "@media(max-width: 1300px)": {
                left: "30px",
                transform: "none",
              },
              "@media(max-width: 899px)": {
                left: "50%",
                transform: "translateX(-50%)",
              },
            },
          }}
        ></Box>
        <GradientAnimation />
        <Header menuVisible />
        <Stack
          className="header-padding"
          position="relative"
          maxWidth="683px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          zIndex={2}
          margin={{ xs: "20px auto 0", md: "64px auto 0" }}
          padding="0 25px"
        >
          <Typography
            component="h1"
            fontFamily="PloniBold"
            fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
            lineHeight={{ xs: "40px", sm: pxToRem(60) }}
            mb={{ xs: "70px", md: pxToRem(100) }}
          >
            שענק א{" "}
            <PageTitleHighlighted component="span">
              <span className="highlighted">ניי לעבן</span>
            </PageTitleHighlighted>{" "}
            פאר אנדערע אידן
          </Typography>
          <Button
            variant="green"
            sx={{ color: "white" }}
            onClick={handleScrollToForm}
          >
            Donate
          </Button>
        </Stack>
      </Box>

      <Container
        disableGutters
        sx={{ my: { xs: "50px", sm: "100px" } }}
        ref={donateFormRef}
      >
        <DonationAmount
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
          customAmount={customAmount}
          setCustomAmount={setCustomAmount}
        />
        <SustainerOptions
          frequency={frequency}
          setFrequency={setFrequency}
          recurrenceRate={recurrenceRate}
          setRecurrenceRate={setRecurrenceRate}
          setNotification={setNotification}
          notification={notification}
          serverErrors={serverErrors}
        />
        <PersonalDetails
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          fullName={fullName}
          setFullName={setFullName}
          comment={comment}
          setComment={setComment}
          serverErrors={serverErrors}
        />
        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          justifyContent="flex-end"
          gap="20px"
          mt={{ xs: "37px", smm: "60px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TotalAmount
              sx={{
                border: serverErrors?.amount_error ? "2px solid red" : "none",
                position: "relative",
                transition: "border 0.3s ease",
              }}
            >
              {selectedAmount === "Other" ? (
                <>
                  <span>{`${formatCurrency(customAmount)}`}</span>
                  {["Daily", "Weekly", "Monthly", "Annually"].includes(
                    frequency
                  ) && <span style={{ marginLeft: "8px" }}>{frequency}</span>}
                </>
              ) : (
                <>
                  <span>{`${formatCurrency(selectedAmount)}`}</span>
                  {["Daily", "Weekly", "Monthly", "Annually"].includes(
                    frequency
                  ) && <span style={{ marginLeft: "8px" }}>{frequency}</span>}
                </>
              )}
            </TotalAmount>
            {serverErrors?.amount_error && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                  marginTop: "8px",
                  lineHeight: "1.2",
                  maxWidth: "100%",
                  wordWrap: "break-word",
                }}
              >
                {serverErrors.amount_error[0]}
              </Typography>
            )}
          </Box>

          <Button
            variant="green"
            onClick={handleDonateNowClick}
            sx={{
              borderRadius: "calc(infinity * 1px)",
              fontSize: { xs: "18px", sm: pxToRem(20) },
              height: { xs: "48px", sm: pxToRem(72) },
              flexShrink: 0,
            }}
            disabled={donating}
          >
            Donate Now!
          </Button>
        </Stack>
      </Container>
      <Container disableGutters>
        <ContentSlider page={"Donate"} />
      </Container>
      <ThankModal />
      <CheckModal />
      <ErrorModal />
    </>
  );
};

export default Donate;
