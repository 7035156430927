import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { formatTimeAgo } from "../../../utils/format";
import { styled, useMediaQuery } from "@mui/system";
import { useTheme } from "@emotion/react";

const commonButtonStyles = {
  height: { xs: pxToRem(50), sm: pxToRem(50) },
  width: pxToRem(110),
  color: "white",
  boxShadow: "none",
  textTransform: "none",
  fontSize: pxToRem(16),
  fontWeight: 600,
};

const Alert = (props) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const navigate = useNavigate();
  const bgColor =
    props.severity === "error"
      ? "#FFEDED"
      : props.severity === "success"
      ? "#EAF7EE"
      : props.severity === "warning"
      ? "#FFF8E6"
      : "black";

  const borderColor =
    props.severity === "error"
      ? "#F6CCC4"
      : props.severity === "success"
      ? "#BFE4C9"
      : props.severity === "warning"
      ? "#EFA08C"
      : "black";

      console.log("props?.link && props?.isBlog",props?.link && props?.isBlog)


  return (
    <Stack
      position="relative"
      flexDirection={mobile_ssm ? "column" : "row"}
      alignItems={mobile_ssm ? "start" : "center"}
      // alignItems="center"
      // alignItems={{ xs: "start", sm: "center" }}
      justifyContent="space-between"
      gap={pxToRem(20)}
      border="2px solid"
      borderRadius="10px"
      borderColor={borderColor}
      bgcolor={bgColor}
      py={pxToRem(24)}
      px={pxToRem(40)}
    >
      <Box>
        <Typography
          component="p"
          fontSize={pxToRem(18)}
          fontWeight={600}
          mb={pxToRem(5)}
          pr={{ xs: "28px", lg: 0 }}
        >
          {props.title}
        </Typography>
        <Typography
          component="p"
          fontSize={pxToRem(16)}
          lineHeight="20px"
          fontWeight={500}
        >
          {props.children}
        </Typography>

        <Typography
          component="span"
          fontSize={pxToRem(14)}
          color="text.secondary"
          fontWeight={400}
        >
          {formatTimeAgo(props.time)}
        </Typography>
      </Box>

      <Stack
        flexDirection="row"
        alignItems="center"
        // justifyContent="center"
        gap={pxToRem(30)}
        sx={{
          "& button": {
            m: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",

            flexDirection: mobile_ssm ? "row" : "column",

            gap: 1,
          }}
        >
          {!props?.isInvitation && (
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: (theme) => theme.palette.error.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.error.dark,
                },
              }}
              onClick={() => props?.handleHide(props?.id)}
            >
              Dismiss
            </Button>
          )}
          {props?.isReminder && (
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: "#026670",
                "&:hover": {
                  backgroundColor: "#00545d",
                },
              }}
              onClick={() => {
                navigate(props?.link);
              }}
            >
              Watch
            </Button>
          )}
          {props?.link && props?.isBlog && (
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: "#026670",
                "&:hover": {
                  backgroundColor: "#00545d",
                },
              }}
              onClick={() => {
                navigate(props?.link);
              }}
            >
              Read
            </Button>
          )}
          {/* {props?.link &&  !props?.isBlog &&(
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: "#026670",
                "&:hover": {
                  backgroundColor: "#00545d",
                },
              }}
              onClick={() => {
                navigate(props?.link);
              }}
            >
              Watch
            </Button>
          )} */}
          {props?.isInvitation && (
            <>
              <Button
                variant="contained"
                sx={{
                  ...commonButtonStyles,
                  backgroundColor: (theme) => theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.error.dark,
                  },
                }}
                onClick={() => props?.handleReject(props?.id)}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                sx={{
                  ...commonButtonStyles,
                  backgroundColor: "#026670",
                  "&:hover": {
                    backgroundColor: "#00545d",
                  },
                }}
                onClick={() => props?.handleAccept(props?.id)}
              >
                Accept
              </Button>
            </>
          )}
        </Box>
        {/* {props?.isInvitation && (
          <Box
            sx={{
              display: "flex",
              flexDirection: mobile_ssm ? "row" : "column",
              justifyContent: mobile_ssm ? "flex-end" : "center",
              gap: 1,
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: (theme) => theme.palette.error.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.error.dark,
                },
              }}
              onClick={() => props?.handleReject(props?.id)}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              sx={{
                ...commonButtonStyles,
                backgroundColor: "#026670",
                "&:hover": {
                  backgroundColor: "#00545d",
                },
              }}
              onClick={() => props?.handleAccept(props?.id)}
            >
              Accept
            </Button>
          </Box>

          // <Box
          //   sx={{
          //     display: "flex",
          //     flexDirection: mobile_ssm ? "row" : "column",
          //     justifyContent: mobile_ssm ? "flex-end" : "center",
          //     gap: 1,
          //     backgroundColor:"red",

          //   }}
          // >
          //   <Button
          //     variant="contained"
          //     sx={{
          //       ...commonButtonStyles,
          //       backgroundColor: (theme) => theme.palette.error.main,
          //       "&:hover": {
          //         backgroundColor: (theme) => theme.palette.error.dark,
          //       },
          //     }}
          //     onClick={() => props?.handleReject(props?.id)}
          //   >
          //     Reject
          //   </Button>
          //   <Button
          //     variant="contained"
          //     sx={{
          //       ...commonButtonStyles,
          //       backgroundColor: "#026670",
          //       "&:hover": {
          //         backgroundColor: "#00545d",
          //       },
          //     }}
          //     onClick={() => props?.handleAccept(props?.id)}
          //   >
          //     Accept
          //   </Button>
          // </Box>
        )} */}
      </Stack>
    </Stack>
  );
};

export default Alert;
