import { IconButton, Stack, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { ReactComponent as LikeFilled } from "../images/like-filled.svg";
import { ReactComponent as Like } from "../images/like.svg";

const IconButtonStyled = styled(IconButton)(() => {
  return {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      "& svg": {
        color: "#026670",
      },
    },
  };
});

const Likes = ({ number, isLiked, disabled, onClick, iconHeight = 18 }) => {
  return (
    <Stack flexDirection="row" alignItems="center" columnGap={pxToRem(8)}>
      <Typography
        fontSize={pxToRem(14)}
        fontWeight={600}
        color={isLiked ? "#026670" : "#BFBEBB"}
        mt={pxToRem(1)}
      >
        {number}
      </Typography>
      <Stack flexDirection="row" alignItems="center" columnGap={pxToRem(7)} >
        <IconButtonStyled
          onClick={onClick}
          sx={{ marginBottom: pxToRem(6) }}
          disabled={disabled}
        >
          <Box width={"100%"}>
            {isLiked ? (
              <LikeFilled color="#026670" height={iconHeight} />
            ) : (
              <Like color="#BFBEBB" height={iconHeight} />
            )}
          </Box>
        </IconButtonStyled>
      </Stack>
    </Stack>
  );
};

export default Likes;
