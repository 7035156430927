import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { SwiperSlide } from "swiper/react";
import Slider from "../../components/Slider";
import TextLink from "../../components/TextLink";
import Slide from "../../components/Slide";
import { listCourses } from "../../api/course";
import { useEffect, useState } from "react";
import { getFeaturedCourses } from "../../api/cmsApi";
import Course from "../courses/Course";
import { formatDate, formatDuration } from "../../utils/format";

const Courses = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const sliderChange = useMediaQuery("(min-width:826px)");
  const [courses, setCourses] = useState([]);

  const fetchFeaturedCourses = () => {
    getFeaturedCourses()
      .then((res) => {
        setCourses(res.data);
        console.log("featured courses are", res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchFeaturedCourses();
  }, []);

  return (
    <Container
      disableGutters
      sx={{
        mb: { xs: pxToRem(70), ssm: "86px", sm: "100px", md: pxToRem(150) },
        mt: { xs: pxToRem(70), ssm: "80px", sm: 0 },
      }}
    >
      <Stack
        alignItems="flex-start"
        gap="15px"
        mb={{ xs: "33px", sm: pxToRem(57.79) }}
      >
        <Typography variant="sectionTitle" component="h2">
          Free <span className="highlighted">courses</span>
        </Typography>
        {mobile ? <SectionLink /> : null}
      </Stack>
      {courses.length > 0 ? (
        <>
          {sliderChange ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: pxToRem(20),
                mb: pxToRem(50),
                "& > :nth-of-type(even)": {
                  justifySelf: "end",
                },
              }}
              padding={{
                xs: pxToRem(0),
                ssm: pxToRem(0),
                md: pxToRem(0),
                lg: pxToRem(80),
              }}
              rowGap={{
                xs: pxToRem(0),
                ssm: pxToRem(40),
                md: pxToRem(0),
                lg: pxToRem(80),
              }}
            >
              {courses?.map((course) => (
                <Course
                  image={course?.thumb_nail}
                  title={course?.name}
                  isTitleRtl={course?.is_name_rtl}
                  subtitle={course?.subtitle}
                  isSubtitleRtl={course?.is_subtitle_rtl}
                  text={course?.description}
                  subscriptionStatus={course?.user_subscription?.status}
                  linkUrl={`/course/${course.slug}`}
                  date={formatDate(course.release_date || course.updated_at)}
                  subscribers={course.subscriber_count}
                  duration={formatDuration(course.length)}
                  labels={course.labels.map((label) => label.name)}
                  // onFavoriteClick={() => onFavoriteClick(course.id)}
                  course={course}
                  // isAuthenticated={isAuthenticated}
                  courseProgress={course?.progress}
                  isHomePage
                />
              ))}
            </Box>
          ) : (
            <Box
              mx="-25px"
              sx={{
                // height: "320px",
                // backgroundColor: "green",
              }}
            >
              <Slider
                slidesPerView={1}
                spaceBetween={20}
                arrows
                // breakpoints={{
                //   630: {
                //     slidesPerView: 2,
                //   },
                // }}
                breakpoints={{
                  562: { slidesPerView: 1 },
                  820: { slidesPerView: 2 },
                }}
                style={{
                  padding: `${pxToRem(30)} ${pxToRem(20)}`,
                  margin: `0 ${pxToRem(-20)}`,
                  height: "100%",
                }}
              >
                {courses?.map((course) => (
                  <SwiperSlide
                    key={course?.id}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Course
                      image={course?.thumb_nail}
                      title={course?.name}
                      isTitleRtl={course?.is_name_rtl}
                      subtitle={course?.subtitle}
                      isSubtitleRtl={course?.is_subtitle_rtl}
                      text={course?.description}
                      subscriptionStatus={course?.user_subscription?.status}
                      linkUrl={`/course/${course.slug}`}
                      date={formatDate(
                        course.release_date || course.updated_at
                      )}
                      subscribers={course.subscriber_count}
                      duration={formatDuration(course.length)}
                      labels={course.labels.map((label) => label.name)}
                      // onFavoriteClick={() => onFavoriteClick(course.id)}
                      course={course}
                      // isAuthenticated={isAuthenticated}
                      courseProgress={course?.progress}
                      isHomePage
                    />
                    {/* <Slide
                      key={course?.id}
                      image={course?.thumb_nail}
                      title={course?.name}
                      text={course?.subtitle}
                      videoLink={
                        course?.is_subscribed
                          ? `/course/${course.slug}`
                          : `/course/${course.slug}/presubscribe`
                      }
                      date={course?.release_date}
                      subscribers={course?.subscriber_count}
                      rating={course?.rating}
                      includeCourseDetails
                    /> */}
                  </SwiperSlide>
                ))}
              </Slider>
            </Box>
          )}
        </>
      ) : (
        <Typography variant="body1" style={{ padding: "0 0 30px 0" }}>
          No free courses available at the moment...
        </Typography>
      )}
      {mobile ? null : <SectionLink />}
    </Container>
  );
};
const SectionLink = (props) => {
  return (
    <TextLink href={"/courses"} {...props}>
      View All Courses
    </TextLink>
  );
};

export default Courses;
