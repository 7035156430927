import api from "./api";
import axios from "axios";
import { url } from "./config";
import { checkAuth } from "../utils/checkAuth";

export async function getCategories() {
  const res = await axios.get(`${url}/api/parshas/students_categories/`);
  return res.data;
}

export async function getSubgroupVideos(subgroupId) {
  if (checkAuth()) {
    const res = await api.get(`/api/parshas/${subgroupId}/student_videos/`);

    return res;
  } else {
    const res = await axios.get(
      `${url}/api/parshas/${subgroupId}/student_videos/`
    );

    return res;
  }
}

export async function getVideo(videoId) {
  if (checkAuth()) {
    const res = await api.get(`/api/parshas/videos/${videoId}/`);
    return res.data;
  } else {
    const res = await axios.get(`${url}/api/parshas/videos/${videoId}/`);
    return res.data;
  }
}

//bookmarks

export async function listParshaBookmarks(page, pageSize, videoId, ordering) {
  if (checkAuth()) {
    const res = await api.get(
      `/api/parshas/bookmarks/?page=${page}&page_size=${pageSize}&video=${videoId}&ordering=${ordering}`
    );
    return res;
  }
}
export async function listBookmarkedVideos(search) {
  if (checkAuth()) {
    const res = await api.get(
      `/api/parshas/bookmarked_videos/?search=${search}`
    );
    return res;
  }
}

export async function createParshaBookmark(
  videoId,
  timestamp,
  categoryId,
  groupId,
  subgroupId
) {
  if (checkAuth()) {
    const payload = {
      timestamp,
      category: categoryId,
      group: groupId,
      sub_group: subgroupId,
    };
    const res = await api.post(`/api/parshas/${videoId}/bookmark/`, payload);
    return res;
  }
}

export async function deleteVideoBookmark(bookmarkId) {
  if (checkAuth()) {
    const res = await api.delete(`/api/parshas/${bookmarkId}/delete_bookmark/`);
    return res;
  }
}
// likes
export async function postVideoLike(videoId) {
  if (checkAuth()) {
    const res = await api.post(`api/parshas/videos/${videoId}/like/`);
    return res;
  } else {
    const res = await axios.post(`${url}/api/parshas/videos/${videoId}/like/`);
    return res;
  }
}
// favorite click
export async function postVideoFavorite(videoId) {
  if (checkAuth()) {
    const res = await api.post(`api/parshas/${videoId}/favorite/`);
    return res;
  }
}

export async function getVideos(pageSize = 4, search = "") {
  const res = await axios.get(
    `${url}/api/parshas/videos/?page_size=${pageSize}&page=1&ordering=-uploaded_at&search=${search}`
  );
  return res;
}

// post progress
export async function updateVideoProgress(payload) {
  if (checkAuth()) {
    const res = await api.post(`/api/parshas/progress/`, payload);
    return res;
  } else {
    const res = await axios.post(`${url}/api/parshas/progress/`, payload);
    return res;
  }
}

export async function downloadVideo(videoId) {
  const mimeType = "video/mp4";
  if (checkAuth()) {
    const res = await api.get(`/api/parshas/${videoId}/download_video/`, {
      responseType: "arraybuffer",
    });
    return new Blob([res.data], { type: mimeType });
  } else {
    const res = await axios.get(
      `${url}/api/parshas/${videoId}/download_video/`,
      {
        responseType: "arraybuffer",
      }
    );
    return new Blob([res.data], { type: mimeType });
  }
}

export async function downloadAudio(videoId) {
  const mimeType = "audio/mpeg";
  if (checkAuth()) {
    const res = await api.get(`/api/parshas/${videoId}/download_audio/`, {
      responseType: "arraybuffer",
    });
    return new Blob([res.data], { type: mimeType });
  } else {
    const res = await axios.get(
      `${url}/api/parshas/${videoId}/download_audio/`,
      {
        responseType: "arraybuffer",
      }
    );
    return new Blob([res.data], { type: mimeType });
  }
}

export async function postVideoInteraction(videoId) {
  if (checkAuth()) {
    const res = await api.post(`api/parshas/parsha_interactions/`, {
      parsha: videoId,
    });
    return res;
  }
}
