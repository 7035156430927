import { Skeleton, Box, Typography, Stack, Container } from "@mui/material";
import { pxToRem } from "px2rem2px";
export default function BlogSkeleton() {
  return (
    <Container
      sx={{
        px: pxToRem(30),
        py: { xs: pxToRem(77), md: pxToRem(120) },
      }}
      disableGutters
    >
      {/* Title Skeleton */}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: pxToRem(30),
            fontWeight: 700,
            lineHeight: pxToRem(40),
            marginBottom: pxToRem(20),
            width: "10%",
          }}
        />
      </Box>

      {/* Author & Date Skeleton */}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: pxToRem(16),
            lineHeight: pxToRem(25),
            fontWeight: 600,
            color: "#BFBEBB",
            marginBottom: pxToRem(90),
            width: "50%",
          }}
        />
      </Box>

      {/* Video / Image Skeleton */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          borderRadius: pxToRem(20),
          overflow: "hidden",
          height: { xs: pxToRem(250), md: pxToRem(400) },
          backgroundColor: "#EDEDED",
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: pxToRem(20) }}
        />
      </Box>

      {/* Content Skeleton */}
      <Stack gap={pxToRem(30)} sx={{ marginTop: pxToRem(60) }}>
        {Array.from(new Array(3)).map((_, index) => (
          <Skeleton
            key={index}
            variant="text"
            sx={{
              fontSize: pxToRem(18),
              fontWeight: 500,
              lineHeight: pxToRem(30),
              width: index === 2 ? "70%" : "100%",
            }}
          />
        ))}
      </Stack>
    </Container>
  );
}
