import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useRouter from "./routing";
import theme from "./theme";
import ModalProvider from "mui-modal-provider";
import { ToastNotificationProvider } from "./context/ToastNotificationContext";
import { AuthProvider } from "./context/AuthContext";
import { CMSDataProvider } from "./context/CMSDataContext";
import { PathProvider } from "./context/PathContext";
import { CoursesProvider } from "./context/CoursesContext";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";

const App = () => {
  const router = useRouter();
  const queryClient = new QueryClient();
  
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ToastNotificationProvider>
              <CssBaseline />
              <CMSDataProvider>
                <ModalProvider>
                  <PathProvider>
                    <CoursesProvider>
                      <RouterProvider router={router} />
                    </CoursesProvider>
                  </PathProvider>
                </ModalProvider>
              </CMSDataProvider>
            </ToastNotificationProvider>
          </AuthProvider>
        </ThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
};

export default App;
