import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  DialogContent,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReCAPTCHA from "react-google-recaptcha";
import {
  requestResendPassword,
  requestResetPassword,
  resetPassword,
} from "../../api/student";
import getErrorsArray from "../../utils/getErrorsArray";
import FormInputPhone from "../../components/FormInputPhone";
import ModalHeader from "../../components/ModalHeader";
import { captchaKey } from "../../api/auth";
import Toast from "../../components/Toast";
import { pxToRem } from "px2rem2px";
import ResendCode from "../../components/ResendCode";
import { NavLink } from "react-router-dom";

// import FingerprintJS from "@fingerprintjs/fingerprintjs";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)({
  position: "relative",
  width: pxToRem(300),
  justifyContent: "space-between",
  backgroundColor: "#BFBEBB",
  borderRadius: pxToRem(30),
  padding: pxToRem(2),
  "&::before": {
    content: "''",
    width: pxToRem(147),
    height: pxToRem(50),
    position: "absolute",
    left: pxToRem(2),
    backgroundColor: "#EDECE8",
    borderRadius: pxToRem(30),
    transition: "left 0.2s ease-in-out",
  },
  "&.active::before": {
    left: pxToRem(151),
  },
});
const ToggleButtonStyled = styled(ToggleButton)({
  color: "#EDECE8",
  width: pxToRem(147),
  height: pxToRem(50),
  borderRadius: `${pxToRem(30)} !important`,
  fontSize: pxToRem(16),
  fontWeight: 600,
  textTransform: "capitalize",
  border: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#026670",
  },
  "&.Mui-selected": {
    color: "#026670",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const ButtonStyled = styled(Button)({
  width: "200px",
  height: "54px",
  fontSize: "14px",
  fontWeight: 600,
  margin: "10px 0",
});

const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  marginTop: pxToRem(20),
});

export default function PasswordResetRequest({
  setAction,
  setOpenSuccess,

  toastOpen,
  setToastOpen,
  setToastMessage,
  ...props
}) {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState("");
  const [errors, setErrors] = useState({});
  const [useEmail, setUseEmail] = useState(false);
  const [usePhoneCall, setUsePhoneCall] = useState(false);
  const [touched, setTouched] = useState({
    contactInfo: false,
    code: false,
    password: false,
    confirmPassword: false,
  });
  const captchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    code: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (touched.contactInfo && useEmail) {
      const emailValid = validateEmail(contactInfo);
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: emailValid ? "" : "Invalid email address",
      }));
    }
  }, [contactInfo, useEmail, touched.contactInfo]);

  useEffect(() => {
    if (touched.password || touched.confirmPassword) {
      const passwordValid = verifyForm.password.length >= 6;
      const passwordsMatch = verifyForm.password === verifyForm.confirmPassword;
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordValid ? "" : "Password must be at least 6 characters",
        confirmPassword: passwordsMatch ? "" : "Passwords do not match",
      }));
    }
  }, [
    verifyForm.password,
    verifyForm.confirmPassword,
    touched.password,
    touched.confirmPassword,
  ]);

  useEffect(() => {
    if (touched.code) {
      const codeValid = verifyForm.code.length > 0;
      setErrors((prevErrors) => ({
        ...prevErrors,
        code: codeValid ? "" : "Verification code cannot be empty",
      }));
    }
  }, [verifyForm.code, touched.code]);

  const handleCaptchaChange = (e) => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  const handleTabChange = () => {
    setUseEmail(!useEmail);
    setContactInfo("");
    setErrors({});
    setTouched({ ...touched, contactInfo: false });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    console.log("contactInfosjsnjsn", contactInfo);
  }, [contactInfo]);

  const handleResetRequest = () => {
    setTouched({ ...touched, contactInfo: true });
    if (useEmail && !validateEmail(contactInfo)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: "Invalid email address",
      }));
      return;
    }

    if (contactInfo.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactInfo: "This field is required",
      }));
      return;
    }

    const payload = {
      email: useEmail ? contactInfo : null,
      phone_number: useEmail ? null : contactInfo,
      method: useEmail ? null : usePhoneCall ? "call" : "sms",
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    requestResetPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setStep(2);
        setToastOpen(true);
        setToastMessage("Verification code was sent to your email");
      })
      .catch((e) => {
        const err = e.response.data;
        setErrors((prevErrors) => ({
          ...prevErrors,
          contactInfo: err.email ? err.email[0] : "",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        captchaRef.current.reset();
      });
  };

  const handleVerify = () => {
    setTouched({
      ...touched,
      code: true,
      password: true,
      confirmPassword: true,
    });

    if (
      errors.password ||
      errors.confirmPassword ||
      errors.code ||
      verifyForm.code.length === 0
    ) {
      return;
    }

    const payload = {
      reset_code: verifyForm.code,
      new_password: verifyForm.password,
      confirm_password: verifyForm.confirmPassword,
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    resetPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setToastOpen(true);
        setToastMessage("Your password has been successfully reset");
        setAction("login");
      })
      .catch((e) => {
        const err = e.response.data;
        console.log("reset password error:", err);
        setErrors((prevErrors) => ({
          ...prevErrors,
          code: err.non_field_errors ? err.non_field_errors[0] : "",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        captchaRef.current.reset();
      });
  };

  // resend code
  const [startTimer, setStartTimer] = useState(false);

  const handleResendCode = () => {
    const payload = {
      [useEmail ? "email" : "phone"]: contactInfo,
      captcha_value: captchaValue,
    };

    setIsLoading(true);
    requestResendPassword(payload)
      .then((res) => {
        setIsLoading(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Verification code resent successfully.",
        }));
        setToastOpen(true);
        setToastMessage("Verification code was sent to your email");
        setStartTimer(true);
      })
      .catch((e) => {
        const err = e.response.data;
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: err.email
            ? err.email[0]
            : "Failed to resend verification code.",
        }));
        setIsLoading(false);
      })
      .finally(() => {
        captchaRef.current.reset();
      });
  };

  return (
    <>
      <ModalHeader
        title={step === 1 ? "Request Password Reset" : "Reset Your Password"}
        inModal={props?.inModal}
        onClose={() => props.onClose()}
      >
        {step === 1 && (
          <Box
            position="absolute"
            textAlign="center"
            left="50%"
            sx={{
              transform: "translateX(-50%)",
            }}
            bottom={pxToRem(-27)}
            zIndex={1}
          >
            <ToggleButtonGroupStyled
              className={`${!useEmail ? "" : "active"}`}
              value={useEmail ? "email" : "phone"}
              exclusive
              onChange={handleTabChange}
            >
              <ToggleButtonStyled value="phone">Phone</ToggleButtonStyled>
              <ToggleButtonStyled value="email">Email</ToggleButtonStyled>
            </ToggleButtonGroupStyled>
          </Box>
        )}
      </ModalHeader>
      <DialogContent sx={{ p: "0" }}>
        <Box
          sx={{
            minWidth: { xs: "100%", ssm: "400px" },
            borderRadius: "10px",
            backgroundColor: "#f7f6f2",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              backgroundColor: "#f7f6f2",
              padding: { xs: ` 40px ${pxToRem(30)}`, ssm: "40px" },
              borderRadius: "10px",

              maxWidth: { sssm: "400px" },
              width: "100%",
              margin: "auto",
            }}
          >
            {step === 1 ? (
              <>
                {useEmail ? (
                  <>
                    <Typography
                      sx={{
                        mb: "10px",
                        marginTop: "10px",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        "@media(max-width: 380px)": {
                          fontSize: "16px",
                        },
                      }}
                    >
                      Enter your Email address to reset your password
                    </Typography>
                    <TextFieldStyled
                      placeholder="Email"
                      value={contactInfo}
                      onChange={(e) => setContactInfo(e.target.value)}
                      onBlur={() =>
                        setTouched({ ...touched, contactInfo: true })
                      }
                      error={touched.contactInfo && Boolean(errors.contactInfo)}
                      helperText={touched.contactInfo && errors.contactInfo}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        mb: "10px",
                        marginTop: "10px",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        "@media(max-width: 380px)": {
                          fontSize: "16px",
                        },
                      }}
                    >
                      Enter your Phone number to reset your password
                    </Typography>
                    <FormInputPhone
                      name="phone"
                      placeholder="Phone"
                      value={contactInfo}
                      onChange={(data) => setContactInfo(data.phone)}
                    />
                  </>
                )}

                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={captchaKey}
                  onChange={handleCaptchaChange}
                />
                {useEmail ? (
                  <ButtonLogin
                    disabled={isLoading}
                    onClick={handleResetRequest}
                    variant="green"
                    color="primary"
                  >
                    Email me a Code
                  </ButtonLogin>
                ) : (
                  <>
                    <ButtonLogin
                      disabled={isLoading}
                      variant="green"
                      color="primary"
                      onClick={handleResetRequest}
                    >
                      {usePhoneCall ? "Call me with a Code" : "Send SMS Code"}
                    </ButtonLogin>
                    <Button
                      disabled={isLoading}
                      onClick={() => setUsePhoneCall(!usePhoneCall)}
                      color="primary"
                    >
                      {usePhoneCall ? "Switch to SMS" : "Switch to Phone Call"}
                    </Button>

                    {!usePhoneCall && (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: "#BFBEBB",
                          // cursor: "pointer",
                          caretColor: "transparent",
                          textAlign: "center",
                          // margin: "20px 0px",

                          "@media(max-width: 380px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        By clicking ‘Send SMS Code,’ I agree to receive SMS
                        messages for account verification purposes. Messages
                        will be sent as needed for account activity. Message and
                        data rates may apply. Reply STOP to opt out.
                      </Typography>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    mb: "10px",
                    marginTop: "10px",
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "center",
                    "@media(max-width: 380px)": {
                      fontSize: "16px",
                    },
                  }}
                >
                  Enter your verification code and type your new password
                </Typography>

                <ResendCode
                  startTimer={startTimer}
                  setStartTimer={setStartTimer}
                  handleResend={handleResendCode}
                  isLoading={isLoading}
                />

                <TextFieldStyled
                  placeholder="Verification Code"
                  autoComplete="off"
                  value={verifyForm.code}
                  onChange={(e) =>
                    setVerifyForm({
                      ...verifyForm,
                      code: e.target.value.slice(0, 6),
                    })
                  }
                  onBlur={() => setTouched({ ...touched, code: true })}
                  inputProps={{ maxLength: 6 }}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                />
                <TextFieldStyled
                  placeholder="New Password"
                  autoComplete="new-password"
                  value={verifyForm.password}
                  onChange={(e) =>
                    setVerifyForm({ ...verifyForm, password: e.target.value })
                  }
                  onBlur={() => setTouched({ ...touched, password: true })}
                  type="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
                <TextFieldStyled
                  placeholder="Confirm New Password"
                  autoComplete="new-password"
                  value={verifyForm.confirmPassword}
                  onChange={(e) => {
                    const confirmPasswordError =
                      e.target.value !== verifyForm.password;
                    setVerifyForm({
                      ...verifyForm,
                      confirmPassword: e.target.value,
                    });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      confirmPassword: confirmPasswordError
                        ? "Passwords do not match"
                        : "",
                    }));
                  }}
                  onBlur={() =>
                    setTouched({ ...touched, confirmPassword: true })
                  }
                  type="password"
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={captchaKey}
                  onChange={handleCaptchaChange}
                />
                <ButtonLogin
                  disabled={isLoading}
                  onClick={handleVerify}
                  variant="yellow"
                  color="primary"
                >
                  Update Password
                </ButtonLogin>
              </>
            )}
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "green",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: pxToRem(15), 
                // py: pxToRem(15), 
                marginTop:"20px",
              }}
            >
              <Typography
                onClick={() => setAction("login")}
                underline="hover"
                sx={{
                  fontWeight: 600,
                  lineHeight: pxToRem(18),
                  color: "#BFBEBB",
                  cursor: "pointer",
                  caretColor: "transparent",
                  textAlign: "center",
                  fontSize: "12.6px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Go back to Login
              </Typography>

              <Divider sx={{ width: "100%", borderColor: "#BFBEBB" }} />

              <Typography
                maxWidth={pxToRem(300)}
                fontSize="12.6px"
                fontWeight={600}
                lineHeight={pxToRem(18)}
                color="#BFBEBB"
                textAlign="center"
              >
                <Link
                  component={NavLink}
                  to="/privacy-policy"
                  underline="hover"
                  color="#026670"
                  onClick={() => props.onClose()}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
}
