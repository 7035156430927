import { url } from "./config";
import axios from "axios";

export const getBlogs = async (pageSize = 7, page = 1) => {
  const response = await axios.get(
    `${url}/api/blogs?page_size=${pageSize}&page=${page}&ordering=-published_at`
  );
  return response;
};
export const getAllBlogs = async (pageSize = 100, search) => {
  const response = await axios.get(
    `${url}/api/blogs?page=1&page_size=${100}&search=${search}&ordering=-published_at`
  );
  return response;
};

export const getBlog = (id) => {
  return axios
    .get(`${url}/api/blogs/${id}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        console.error(`Blog with ID ${id} not found.`);
        window.location.href = "/404"; // Redirect to the 404 page
      } else {
        console.error("Error fetching blog:", error);
        throw error;
      }
    });
};
