import { Box, Container, Stack, Typography } from "@mui/material";

import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { getSubgroupVideos } from "../../api/parsha";
import { SwiperSlide } from "swiper/react";

import { formatDuration } from "../../utils/format";
import useLikeHandler from "../../hooks/useLikeHandler";

import { postVideoLike } from "../../api/parsha";
import Slider from "../../components/Slider";
import ParshaCard from "../../components/parsha/ParshaCard";
import Toast from "../../components/Toast";

import { useToastNotification } from "../../context/ToastNotificationContext";

const YouMayAlsoLike = ({ videoId, categoryId, groupId, subgroupId,currentlyPlayingId,setCurrentlyPlayingId }) => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);


  // like
  const { handleLikeClick } = useLikeHandler({
    videos,
    setVideos,
    isAuthenticated,
    postVideoLike,
  });

  //top panel feedback
  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    toastNotificationMessage,
    setToastWithActionOpen,
    toastWithActionOpen,
    resetSettings,
  } = useToastNotification();

  const fetchVideos = async () => {
    setIsLoading(true);
    try {
      const res = await getSubgroupVideos(subgroupId);
      let videosData = res.data.results;

      if (!isAuthenticated) {
        const likedVideos =
          JSON.parse(localStorage.getItem("likedVideos")) || {};

        videosData = videosData.map((video) => {
          if (likedVideos.hasOwnProperty(video.id)) {
            return {
              ...video,
              is_liked: likedVideos[video.id],
              likes:
                likedVideos[video.id] && !video.is_liked
                  ? video.likes + 1
                  : video.likes,
            };
          }
          return video;
        });
      }

      const videoIdNum = Number(videoId);

      videosData = videosData.filter((video) => video.id !== videoIdNum);

      setVideos(videosData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  if (videos.length === 0) {
    return null;
  }

  return (
    <>
      <Box
        // display={{ xs: "none", ssm: "block" }}
        pb={{ xs: pxToRem(90), md: pxToRem(110) }}
      >
        <Container disableGutters>
          <Stack
            flexDirection={{ xs: "column", ssm: "row" }}
            alignItems={{ xs: "flex-start", ssm: "center" }}
            gap={{ xs: "20px", md: "0" }}
            justifyContent="space-between"
          >
            <Typography variant="sectionTitle" component="h2">
              You <span className="highlighted">may</span> also{" "}
              <span className="highlighted">like</span>
            </Typography>
            <TextLink href="/videos">View all</TextLink>
          </Stack>
        </Container>
        <Box
          sx={{
            height: "430px",
          }}
        >
          <Slider
            slidesPerView={1}
            spaceBetween={20}
            arrows
            breakpoints={{
              540: { slidesPerView: 2 },
              820: { slidesPerView: 3 },
            }}
            style={{
              padding: `${pxToRem(30)} ${pxToRem(20)}`,
              margin: `0 ${pxToRem(-20)}`,
              height: "100%",
            }}
          >
            {videos?.map((video) => (
              <SwiperSlide
                key={video.id}
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <ParshaCard
                  video={video}
                  text={video.text}
                  subscribers={video.subscribers}
                  duration={formatDuration(video.duration)}
                  labels={video.labels}
                  isPlaying={currentlyPlayingId === video.id}
                  setIsPlaying={setIsPlaying}
                  currentlyPlayingId={currentlyPlayingId}
                  setCurrentlyPlayingId={setCurrentlyPlayingId}
                  isVideoLibrary={true}
                  onLikeClick={handleLikeClick}
                  videoLink={{
                    category: categoryId,
                    group: groupId,
                    subgroup: subgroupId,
                  }}
                  isHomePage
                />
              </SwiperSlide>
            ))}
          </Slider>
        </Box>
      </Box>
      <Toast
        open={toastNotificationOpen}
        onClose={() => setToastNotificationOpen(false)}
        message={toastNotificationMessage}
      />
      <Toast
        open={toastWithActionOpen}
        onClose={() => setToastWithActionOpen(false)}
        message={toastNotificationMessage}
        button={"Change Download Option"}
        onUndo={resetSettings}
      />
    </>
  );
};

export default YouMayAlsoLike;
