import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PaypalCheckout = ({ amount, onSuccess }) => {
  const initialOptions = {
    "client-id": "ASfSot6rEmhXYgarmArCXPd-q9JazkbqRnPvjFAs27J4GNisdFQcwUOnBAgfm7fUTPj8P_YP2K-KHGKy",
    currency: "USD",
    intent: "capture",
    "disable-funding": "card",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={{
            layout: "vertical", 
            color: "blue",   
            shape: "rect",   
            label: "paypal",
            tagline: false, 
            height: 40,
            width: 75,
        }}
        createOrder={(data, actions) => {
            return actions.order.create({
            purchase_units: [
                {
                amount: {
                    value: "10.00",
                },
                },
            ],
            });
        }}
        onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
            console.log("Transaction completed by " + details.payer.name.given_name);
            });
        }}
        onError={(err) => {
            console.error("PayPal Checkout error", err);
        }}
        />
    </PayPalScriptProvider>
  );
};

export default PaypalCheckout;
