import confetti from "canvas-confetti";

const getOriginFromEvent = (event) => {
  if (event && event.currentTarget) {
    const rect = event.currentTarget.getBoundingClientRect();
    return {
      x: (rect.left + rect.width / 2) / window.innerWidth,
      y: (rect.top + rect.height / 2) / window.innerHeight,
    };
  }
  return { x: 0.5, y: 0.6 };
};

const updateLocalStorage = (videoId, newLikedStatus) => {
  const likedVideos = JSON.parse(localStorage.getItem("likedVideos")) || {};

  if (newLikedStatus) {
    likedVideos[videoId] = true;
  } else {
    delete likedVideos[videoId];
  }
  localStorage.setItem("likedVideos", JSON.stringify(likedVideos));
};

const updateVideosState = (videoId, likeDelta, newLikedStatus, setVideos) => {
  setVideos((prevVideos) =>
    prevVideos.map((v) =>
      v.id === videoId
        ? { ...v, likes: v.likes + likeDelta, is_liked: newLikedStatus }
        : v
    )
  );
};

const triggerConfetti = (newLikedStatus, origin) => {
  if (newLikedStatus) {
    confetti({
      particleCount: 50,
      spread: 10,
      origin,
      zIndex: 9999,
    });
  }
};

const useLikeHandler = ({
  videos,
  setVideos,

  video,
  setVideo,
  isAuthenticated,
  postVideoLike,
  isSingleVideo = false,
}) => {
  const handleLikeClick = async (videoId, event) => {
    const origin = getOriginFromEvent(event);
    let newLikedStatus, likeDelta;

    if (isSingleVideo) {
      if (!video) return;
      newLikedStatus = !video.is_liked;
      likeDelta = newLikedStatus ? 1 : -1;

      if (!isAuthenticated) {
        if (video.is_liked) {
          const likedVideos =
            JSON.parse(localStorage.getItem("likedVideos")) || {};
          delete likedVideos[videoId];
          localStorage.setItem("likedVideos", JSON.stringify(likedVideos));
          setVideo({
            ...video,
            likes: video.likes + likeDelta,
            is_liked: newLikedStatus,
          });

          return;
        } else {
          updateLocalStorage(videoId, newLikedStatus);
        }
      }

      try {
        await postVideoLike(videoId);
        setVideo({
          ...video,
          likes: video.likes + likeDelta,
          is_liked: newLikedStatus,
        });
        triggerConfetti(newLikedStatus, origin);
      } catch (error) {
        console.error("Error updating like status", error);
      }
    } else {
      const foundVideo = videos.find((v) => v.id === videoId);
      if (!foundVideo) return;
      newLikedStatus = !foundVideo.is_liked;
      likeDelta = newLikedStatus ? 1 : -1;

      if (!isAuthenticated) {
        if (foundVideo.is_liked) {
          const likedVideos =
            JSON.parse(localStorage.getItem("likedVideos")) || {};
          delete likedVideos[videoId];
          localStorage.setItem("likedVideos", JSON.stringify(likedVideos));
          updateVideosState(videoId, likeDelta, newLikedStatus, setVideos);

          return;
        } else {
          updateLocalStorage(videoId, newLikedStatus);
        }
      }

      try {
        await postVideoLike(videoId);
        updateVideosState(videoId, likeDelta, newLikedStatus, setVideos);
        triggerConfetti(newLikedStatus, origin);
      } catch (error) {
        console.error("Error updating like status", error);
      }
    }
  };

  return { handleLikeClick };
};

export default useLikeHandler;
