import { Stack, Typography, Divider, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import TextLink from "../../components/TextLink";
import Rating from "../../components/Rating";
import RichTextDisplay from "../../components/displayRichText";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Box, useMediaQuery } from "@mui/system";
import { formatDate, formatDuration } from "../../utils/format";

import CourseRatingsComponent from "../../components/CourseRatingsComponent";
import { CourseContext } from "../course";

import { getLanguageClass } from "../../utils/languageClass";

const TypographyStyled = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: pxToRem(2),
  fontSize: pxToRem(14),
  fontWeight: 600,
  lineHeight: pxToRem(18),
  color: "#BFBEBB",
  "& span": {
    color: "#333",
  },
});

const OverviewTab = ({
  subscribed,
  progress,
  reviews,
  isReviewedByMe,
  currentReview,
  fetchRatings,
}) => {
  const { course } = useContext(CourseContext);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  const is_mobile = useMediaQuery("(max-width: 575px)");

  const descriptionRef = useRef(null);

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    if (showFullDescription) {
      descriptionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setTimeout(() => {
      setShowFullDescription((prev) => !prev);
    }, 500);
  };

  // const handleContentExceedsMaxHeight = (exceedsMaxHeight) => {
  //   setShowReadMore(exceedsMaxHeight);
  // };

  const handleContentExceedsMaxHeight = useCallback((exceedsMaxHeight) => {
    setShowReadMore((prev) => {
      if (prev === exceedsMaxHeight) return prev;
      return exceedsMaxHeight;
    });
  }, []);





  return (
    <>
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize={{ xs: pxToRem(20), ssm: pxToRem(35) }}
        mb={{ xs: pxToRem(15), ssm: pxToRem(20) }}
        mt={{ xs: pxToRem(24), ssm: 0 }}
        // order="3"
        ref={descriptionRef}
      >
        Course Description
      </Typography>

      {course?.description ? (
        <>
          <Box sx={{ "& p": { margin: 0 } }}>
            <RichTextDisplay
              str={course?.description}
              showFull={showFullDescription}
              onContentExceedsMaxHeight={handleContentExceedsMaxHeight}
            />
          </Box>

          {showReadMore && (
            <Box
              sx={{
                display: "flex",
                justifyContent:
                  getLanguageClass(course?.description) === "is-hebrew-text"
                    ? "flex-end"
                    : "flex-start",
                mt: 1,
              }}
              onClick={handleReadMoreClick}
            >
              <TextLink
                href=""
              
                showFullDescription={showFullDescription}
              >
                {showFullDescription ? "Show Less" : "Read More"}
              </TextLink>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="90%" />
        </Box>
      )}

      <Stack
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
        columnGap={{ xs: pxToRem(30), md: pxToRem(20) }}
        rowGap={pxToRem(10)}
        mt={{ xs: pxToRem(20), ssm: pxToRem(40) }}
        order="1"
        display={{ xs: "grid", ssm: "flex" }}
        gridTemplateColumns={{ xs: "auto auto", sssm: "1fr 1fr", ssm: "unset" }}
        justifyContent={{ xs: "space-between", ssm: "unset" }}
      >
        <TypographyStyled>
          Length:{" "}
          <span style={{ marginLeft: "4px" }}>
            {course?.length ? (
              formatDuration(course?.length)
            ) : (
              <Skeleton width={50} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Release:{" "}
          <span style={{ marginLeft: "4px" }}>
            {course?.release_date ? (
              formatDate(course?.release_date)
            ) : (
              <Skeleton width={100} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Subscribers:{" "}
          <span style={{ marginLeft: "4px" }}>
            {course?.subscriber_count !== undefined ? (
              course?.subscriber_count
            ) : (
              <Skeleton width={30} />
            )}
          </span>
        </TypographyStyled>
        <TypographyStyled>
          Lectures:{" "}
          <span style={{ marginLeft: "4px" }}>
            {course?.lessons_count !== undefined ? (
              course?.lessons_count
            ) : (
              <Skeleton width={30} />
            )}
          </span>
        </TypographyStyled>
      </Stack>
      <Divider
        sx={{
          backgroundColor: "#BFBEBB",
          mb: { xs: pxToRem(38), ssm: pxToRem(60) },
          mt: { xs: pxToRem(20), ssm: pxToRem(15) },
          order: "2",
        }}
      />
      {!is_mobile && (
        <CourseRatingsComponent
          course={course}
          subscribed={subscribed}
          progress={progress}

          reviews={reviews}
          isReviewedByMe={isReviewedByMe}
          currentReview={currentReview}
          fetchRatings={fetchRatings}
        />
      )}
    </>
  );
};

export default OverviewTab;
