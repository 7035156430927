import React from "react";
import { Modal, Box, Typography, Divider } from "@mui/material";
import { ReactComponent as Unsubscribe } from "../images/unsubscribe.svg";
import { useCMSData } from "../context/CMSDataContext";
import { useNavigate } from "react-router-dom";

const UnsubscribeFromNotifications = ({
  open,
  isAlreadyUnsubscribed,
  token,
  notificationType,
  successfullySubsucribed,
  unsubscribeHelperFun,
  handleClose,
}) => {
  const { logo } = useCMSData();
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "320px", md: "600px" },
          height: { xs: "568px", md: "545px" },
          backgroundColor: "#f9f9f9",
          boxShadow: 24,
          p: 4,
          borderRadius: 0,
          outline: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {logo && (
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: "100px", height: "auto" }}
              />
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 4 }}>
            <Unsubscribe />
          </Box>

          <Box sx={{ textAlign: "center" }}>
            <Typography
              id="unsubscribe-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: 700, mb: 1 }}
            >
              {isAlreadyUnsubscribed
                ? "You’re already unsubscribed"
                : successfullySubsucribed
                ? "Subscribed successfully"
                : "Unsubscribed Successfully!"}
            </Typography>
            <Typography
              id="unsubscribe-modal-description"
              sx={{ mt: 1, mb: 2 }}
            >
              {successfullySubsucribed ? (
                <>
                  {" "}
                  You have successfully resubscribed to "{notificationType}"
                  emails.
                </>
              ) : (
                <>
                  {" "}
                  You'll no longer receive "{notificationType}" emails. Changed
                  your mind?{" "}
                  <span
                    onClick={() => unsubscribeHelperFun(token, true)}
                    style={{
                      color: "#026670",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                  >
                    Resubscribe
                  </span>
                </>
              )}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Divider sx={{ mb: 2 }} />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "22.4px",
              letterSpacing: "0",
              textAlign: "center",
              color: "#026670",
              cursor: "pointer",
            }}
            onClick={() => navigate("/user/notifications")}
          >
            Manage all subscriptions from RYM
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnsubscribeFromNotifications;
