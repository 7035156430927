import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getStudent,
  editProfile,
  editProfilePicture,
} from "../api/student"; // adjust import paths as needed

export function useStudentProfile() {
  const queryClient = useQueryClient();

  // 1) Fetch once, cache indefinitely
  const {
    data: studentData,
    isLoading: isStudentLoading,
    isError: isStudentError,
    refetch,
  } = useQuery("studentProfile", getStudent, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });



  // 2) Mutation to edit text fields
  const { mutateAsync: updateProfile } = useMutation(editProfile, {
    onSuccess: (response) => {
      // Manually update the cache so we see new data immediately
      queryClient.setQueryData("studentProfile", (oldData) => {
        if (!oldData) return response;
        return {
          ...oldData,
          data: {
            ...oldData.data,
            ...response.data, // merges the new fields from server
          },
        };
      });
    },
  });

  // 3) Mutation to edit the profile picture
  const { mutateAsync: updateProfilePicture } = useMutation(
    editProfilePicture,
    {
      onSuccess: (response) => {
        queryClient.setQueryData("studentProfile", (oldData) => {
          if (!oldData) return response;
          return {
            ...oldData,
            data: {
              ...oldData.data,
              ...response.data,
            },
          };
        });
      },
    }
  );

  return {
    student: studentData?.data || {}, // Student object
    isStudentLoading,
    isStudentError,
    refetch, // If you need to force a fresh server fetch
    updateProfile,
    updateProfilePicture,
  };
}
