import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Collapse,
  Fab,
  Divider,
} from "@mui/material";
import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import ParshaSection from "./ParshaSection";
import { pxToRem } from "px2rem2px";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReactComponent as Bookmarks } from "../../images/bookmarks.svg";
import BookmarksModal from "./BookmarksModal";
import Toast from "../../components/Toast";
import { useAuth } from "../../context/AuthContext";
import LoginSubscribeModal from "../preSubscribe/LoginSubscribeModal";
import LoginModal from "../auth/LoginModal";
import { useModal } from "mui-modal-provider";
import { useNotificationSettings } from "../../hooks/useNotificationSettings";
import { getVideos } from "../../api/parsha";
import AuthReminderModal from "../../components/AuthReminderModal";
import { useToastNotification } from "../../context/ToastNotificationContext";
const SidebarCourses = ({
  isSmallScreen,
  isMediumScreen,
  handleOpen,
  setTurnOnNotificationFeedback,
}) => {
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const { showModal, hideModal } = useModal();

  const [recentlyAddedVideos, setRecentlyAddedVideos] = useState([]);
  const [openReminder, setOpenReminder] = useState(false);
  const { notifications: fetchedNotifications, updateNotifications } =
    useNotificationSettings();

  const [notifications, setNotifications] = useState({});
  const [isNotificationOn, setIsNotificationOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turnedOnSuccessfully, setTurnedOnSuccessfully] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const popupShown = localStorage.getItem("popupShown");
      if (popupShown === null || popupShown === "false") {
        setOpenReminder(true);
        localStorage.setItem("popupShown", "true");
      }
    }, 20000);
    return () => clearTimeout(timeout);
  }, []);
  const fetchRecentlyAddedVideos = () => {
    getVideos()
      .then((res) => {
        setRecentlyAddedVideos(res.data.results);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    document.title = "Weekly Shiurim - Reb Yakov Moshe";
    fetchRecentlyAddedVideos();
  }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      fetchedNotifications &&
      Object.keys(fetchedNotifications).length > 0
    ) {
      setNotifications(fetchedNotifications);
      setIsNotificationOn(fetchedNotifications.new_parsha_lecture);
      console.log(
        "fetched new_parsha_lecture:",
        fetchedNotifications.new_parsha_lecture
      );
    }
  }, [fetchedNotifications, isAuthenticated]);

  const handleGetNotified = async () => {
    setLoading(true);
    if (isAuthenticated) {
      try {
        const updatedNotifications = {
          ...notifications,
          new_parsha_lecture: true,
        };
        // Use the hook to update
        await updateNotifications({
          preferences: updatedNotifications,
        });
        setTurnOnNotificationFeedback(true);
        setTurnedOnSuccessfully(true);
      } catch (error) {
        console.log("Subscription failed:", error);
      }
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal, {
            onRedirectActivateNotification: "new_parsha_lecture",
          });
        },
        loginMessage:
          "You need to log in first to enable notifications for new Shiurim.",
        hideModal: () => hideModal(modal.id),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const activateAfterLogin = localStorage.getItem(
      "onRedirectActivateNotification"
    );
    if (activateAfterLogin && isAuthenticated) {
      localStorage.removeItem("onRedirectActivateNotification");
      handleGetNotified();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (turnedOnSuccessfully) {
      const timeout = setTimeout(() => {
        setIsNotificationOn(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [turnedOnSuccessfully]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "180px" },
          flexDirection: isSmallScreen
            ? "column"
            : isMediumScreen
            ? "row"
            : "column",
          px: { xs: pxToRem(10), md: 0 },
          mt: { xs: pxToRem(10), lg: 0 },
        }}
      >
        {/* Example "Recently Added" NavGroup - you'll fill with real data */}
        <NavGroup
          title="Recently Added"
          sx={{ borderBottom: "none", mb: "0px" }}
          noTitle
        >
          {recentlyAddedVideos.map((video) => (
            <MenuDropdownLink
              key={video.id}
              to={`/video/${video.id}`}
              sx={{ fontSize: "14px" }}
            >
              {video.title}
            </MenuDropdownLink>
          ))}
        </NavGroup>

        <Box display={{ xs: "none", md: "block" }}>
          <Typography
            fontSize="14px"
            lineHeight="18px"
            fontWeight={600}
            mb="20px"
          >
            Your Bookmarks
          </Typography>
          <Button
            fullWidth
            variant="green"
            size="small"
            sx={{
              backgroundColor: "#026670",
              color: "#FCE181",
              fontSize: "14px !important",
            }}
            onClick={handleOpen}
          >
            <Bookmarks style={{ marginRight: "8px" }} />
            Bookmarks
          </Button>
        </Box>

        {!isNotificationOn && (
          <>
            <Divider
              sx={{
                my: { xs: "20px", md: pxToRem(30) },
                display: { xs: "none", md: "block" },
              }}
            />
            {!isMediumScreen && (
              <Box>
                <Typography
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight={600}
                  mb="20px"
                >
                  Receive notifications for new Shiurim!
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "14px !important",
                    "&:hover .MuiSvgIcon-root": {
                      color: "#FCE181",
                      transition: "all 200ms ease-out",
                    },
                  }}
                  onClick={turnedOnSuccessfully ? () => {} : handleGetNotified}
                  disabled={loading}
                >
                  <NotificationsIcon
                    sx={{
                      color: "#026670",
                      mr: "8px",
                      width: "26px",
                      height: "30px",
                    }}
                  />
                  {turnedOnSuccessfully ? "Notification On" : "Get Notified"}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      <AuthReminderModal open={openReminder} setOpen={setOpenReminder} />
    </>
  );
};

const Parsha = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery("(max-width:899px)");
  const isLargeScreen = useMediaQuery("(min-width:1227px)");
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //new parsha notification
  const [turnOnNotificationFeedback, setTurnOnNotificationFeedback] =
    useState(false);

  // bookmarks
  const { showModal, hideModal } = useModal();
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();
  const [openBookmarks, setOpenBookmarks] = useState(false);

  const [bookmarkFeedback, setBookmarkFeedback] = useState(false);
  const [bookmarkFeedbackMessage, setBookmarkFeedbackMessage] = useState("");
  const {
    toastNotificationOpen,
    setToastNotificationOpen,
    setToastNotificationMessage,
    toastNotificationMessage,
  } = useToastNotification();

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //start: bookmarks
  const handleOpen = () => {
    if (isAuthenticated) {
      setOpenBookmarks(true);
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
        },
        loginMessage: "You need to log in first to see your bookmarks.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  const handleClose = () => {
    setOpenBookmarks(false);
  };
  //end: bookmarks

  const hanldeCloseBookmarkFeedback = () => {
    setBookmarkFeedback(false);
    setBookmarkFeedbackMessage("");
  };

  return (
    <>
      <Toast
        open={turnOnNotificationFeedback}
        onClose={() => {
          setTurnOnNotificationFeedback(false);
        }}
        message={"Notification for new Shiurim turned on successfully!"}
      />
      <Box position="relative">
        {mobile_ssm && (
          <Box>
            <Fab
              size="small"
              aria-label="scroll back to top"
              onClick={scrollToTop}
              sx={{
                position: "fixed",
                bottom: 17,
                right: 17,
                zIndex: 50,
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                boxShadow: "0px 10px 20px #00000012",
                backgroundColor: "#FCE181",
                color: "#026670",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#026670",
                  color: "#FCE181",
                },
              }}
            >
              <KeyboardArrowUpIcon style={{ width: "28px", height: "28px" }} />
            </Fab>
          </Box>
        )}
        <Hero
          title="Weekly Shiurim"
          sx={{
            marginBottom: { xs: pxToRem(63), md: pxToRem(100) },
            overflow: isLargeScreen ? "visible" : "hidden",
          }}
        />

        <Box position="relative">
          <Box
            sx={{
              width: { xs: "100%", lg: "90%" },
              margin: isMediumScreen ? "auto" : undefined,
            }}
          >
            <Stack
              flexDirection={isMediumScreen ? "column" : "row"}
              justifyContent="space-between"
              gap="5%"
              mb="100px"
              width="100%"
              maxWidth="1367px"
              position="relative"
            >
              {mobile_ssm && <Box sx={{ height: "70px" }}></Box>}
              <Sidebar
                display="flex"
                sx={{
                  gap: "0",
                  padding: isMediumScreen ? "20px 25px" : pxToRem(50),
                  position: { xs: "absolute", ssm: "static" },
                  zIndex: { xs: 2, ssm: 0 },
                  mx: { ssm: "25px", md: 0 },
                }}
              >
                {isMediumScreen ? (
                  <>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: "pointer" }}
                      onClick={handleToggle}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <Typography sx={{ fontWeight: 600, fontSize: "17px" }}>
                          Recently Added
                        </Typography>
                      </Stack>
                      <KeyboardArrowDownIcon
                        sx={{
                          width: pxToRem(31),
                          height: pxToRem(31),
                          transition: "all 300ms ease-in-out",
                          transform: isExpanded
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                        }}
                      />
                    </Stack>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <SidebarCourses
                        isSmallScreen={isSmallScreen}
                        isMediumScreen={isMediumScreen}
                        handleOpen={handleOpen}
                        setTurnOnNotificationFeedback={
                          setTurnOnNotificationFeedback
                        }
                      />
                    </Collapse>
                  </>
                ) : (
                  <SidebarCourses
                    isSmallScreen={isSmallScreen}
                    isMediumScreen={isMediumScreen}
                    handleOpen={handleOpen}
                    setTurnOnNotificationFeedback={
                      setTurnOnNotificationFeedback
                    }
                  />
                )}
              </Sidebar>
              <Box
                mx={{ xs: "25px", md: 0 }}
                mt={{ xs: "20px", md: 0 }}
                marginBottom={{ xs: pxToRem(63), ssm: pxToRem(94), md: 0 }}
                display={{ md: "none" }}
              >
                <Button
                  fullWidth
                  variant={isMediumScreen ? "outlined" : "green"}
                  size="small"
                  sx={{
                    // backgroundColor: "#026670",
                    // color: "#FCE181",
                    borderWidth: `${pxToRem(3)} !important`,
                    fontSize: "14px !important",
                    transition: "all",
                    "&:hover svg": {
                      color: "#FCE181 !important",
                    },
                  }}
                  onClick={handleOpen}
                >
                  <Bookmarks
                    style={{
                      marginRight: "8px",
                      color: isMediumScreen ? "#026670" : "#FCE181",
                    }}
                  />
                  Bookmarks
                </Button>
              </Box>
              <Box
                flex={1}
                padding={{ xs: "0 25px", md: "0" }}
                width={isMediumScreen ? "100%" : "65%"}
              >
                <CoursesSlider type="video" />
                <ParshaSection />
              </Box>
            </Stack>
          </Box>
        </Box>

        <Container disableGutters>
          <ContentSlider page={"Weekly Shiurim"} />
        </Container>
      </Box>

      <BookmarksModal
        open={openBookmarks}
        onClose={handleClose}
        onOpen={handleOpen}
      />

      <Toast
        open={toastNotificationOpen}
        onClose={setToastNotificationOpen}
        message={toastNotificationMessage}
      />
    </>
  );
};

export default Parsha;
