import { useState, useEffect } from "react";
import { Stack, Box, Typography, CircularProgress } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { getAllBlogs } from "../../../api/blogs";
import dayjs from "dayjs";

export default function BlogsTab({ tab, search, setBlogsCount, blogsCount }) {
  const [blogs, setBlogs] = useState([]);
  const [blogsShown, setBlogsShown] = useState(5);
  const [loading, setLoading] = useState(false);

  const fetchBlogs = () => {
    setLoading(true);
    getAllBlogs(100, search)
      .then((res) => {
        setBlogs(res.data.results);
        setBlogsCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchBlogs();
  }, [search]);

  const handleViewMore = () => {
    setBlogsShown((prev) => prev + 5);
  };
  const handleViewLess = () => {
    setBlogsShown(5);
  };
  console.log("blogs are ", blogs);

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", height: "50px" }}>
          <CircularProgress sx={{ color: "#036670" }} />
        </Box>
      ) : (
        <>
          {" "}
          <Stack
            sx={{
              p: 0,
              //padding: `${pxToRem(20)} 0 ${pxToRem(20)} ${pxToRem(12)}`,
              rowGap: pxToRem(16),
              pt: tab === 0 ? pxToRem(20) : pxToRem(10),
            }}
          >
            {blogs.slice(0, blogsShown).map((blog, index) => (
              <Box
                gap={pxToRem(15)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover .title": { color: "#026670" },
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    borderRadius: pxToRem(5),
                    width: pxToRem(92),
                    height: pxToRem(60),
                    flex: 1,
                  }}
                >
                  <img
                    src={blog?.video ? blog?.thumbnail : blog.image}
                    alt=""
                    style={{
                      objectFit: "cover",
                      borderRadius: pxToRem(5),
                      width: pxToRem(92),
                      height: pxToRem(50),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 4.4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "space-between",
                  }}
                >
                  <Typography
                    variant="large"
                    fontWeight="600"
                    component="p"
                    className="title"
                    style={{
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      position: "relative",
                      marginTop: "-17px",
                    }}
                    data-fullname={blog.title}
                  >
                    {blog.title}
                  </Typography>
                  <Box sx={{ mt: "10px" }}>
                    <Typography
                      variant="small"
                      fontWeight="400"
                      component="p"
                      color="#C0C0C0"
                    >
                      Published {dayjs(blog.published_at).format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
            {blogs.length === 0 && (
              <Typography
                variant="large"
                fontWeight="600"
                component="p"
                color="#C0C0C0"
                sx={{ textAlign: "center", pt: "20px" }}
              >
                No Blogs Found
              </Typography>
            )}
          </Stack>
          {tab === 0 && blogs.length > 2 && (
            <Typography
              variant="button"
              component="p"
              sx={{
                cursor: "pointer",
                fontWeight: "500",
                textTransform: "capitalize",
                textDecoration: "underline",
                "&:hover": { color: "#026670" },
                py: "15px",
                px: "2px",
              }}
              onClick={
                blogsShown >= blogsCount ? handleViewLess : handleViewMore
              }
            >
              {blogsShown >= blogsCount ? "Show Less" : "View More"}
            </Typography>
          )}
          {tab !== 0 && blogs.length > 4 && (
            <Stack alignItems="center">
              <Box
                sx={{
                  borderRadius: pxToRem(50),
                  backgroundColor: "#E9E9E9",
                  p: `${pxToRem(8)} ${pxToRem(18)}`,
                  display: "inline-block",
                  mt: pxToRem(10),
                }}
                onClick={
                  blogsShown >= blogsCount ? handleViewLess : handleViewMore
                }
              >
                <Typography
                  variant="button"
                  component="p"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    "&:hover": { color: "#026670" },
                  }}
                >
                  {blogsShown >= blogsCount ? "Show Less" : "View More Results"}
                </Typography>
              </Box>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
