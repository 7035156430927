import {
  Skeleton,
  Box,
  Grid,
  Typography,
  Stack,
  Container,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
export default function BlogsSkeleton() {
  return (
    <Container
      sx={{ px: pxToRem(30), py: { xs: pxToRem(77), md: pxToRem(120) } }}
      disableGutters
    >
      <Grid
        container
        spacing={{ xs: pxToRem(60), ssm: pxToRem(80) }}
        columns={{ xs: 12, lg: 11 }}
      >
        {/* Featured Post Skeleton */}
        <Grid item xs={12} lg={7}>
          <Skeleton variant="rectangular" width="100%" height={pxToRem(350)} />
          <Skeleton width="80%" height={pxToRem(40)} sx={{ mt: 2 }} />
          <Skeleton width="60%" height={pxToRem(20)} sx={{ mt: 1 }} />
        </Grid>

        {/* Sidebar Skeleton for Top Posts */}
        <Grid item xs={12} lg={4}>
          <Typography
            sx={{
              marginBottom: pxToRem(20),
              color: "#333333",
              fontWeight: 700,
              fontSize: { xs: pxToRem(22), ssm: pxToRem(25) },
              lineHeight: pxToRem(32),
            }}
          >
            {/* Top Posts */}
          </Typography>
          <Grid container columnSpacing={pxToRem(20)} rowSpacing={pxToRem(12)}>
            {Array.from(new Array(5)).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Stack direction="row" spacing={2}>
                  <Skeleton
                    variant="rectangular"
                    width={pxToRem(100)}
                    height={pxToRem(70)}
                  />
                  <Box sx={{ width: "100%" }}>
                    <Skeleton width="90%" height={pxToRem(20)} />
                    <Skeleton width="60%" height={pxToRem(16)} sx={{ mt: 1 }} />
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Blog Cards Skeleton */}
      <Grid
        container
        columnSpacing={pxToRem(20)}
        rowSpacing={pxToRem(40)}
        sx={{ mt: pxToRem(40) }}
      >
        {Array.from(new Array(6)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={pxToRem(250)}
            />
            <Skeleton width="80%" height={pxToRem(20)} sx={{ mt: 2 }} />
            <Skeleton width="60%" height={pxToRem(16)} sx={{ mt: 1 }} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
