export default function shouldNavigateToPresubscribe(subscriptionStatus) {
  const presubscribeStatuses = [
    "Deleted",
    "Inactive",
    "Failed",
    "Unsubscribed",
    "Anonymous",
  ];

  return (
    subscriptionStatus === null ||
    subscriptionStatus === undefined ||
    presubscribeStatuses.includes(subscriptionStatus)
  );
}
