import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import CloseBtn from "./CloseBtn";
import { getCoursesNotes } from "../../api/student";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { saveStudentLocal, useAuth } from "../../context/AuthContext";
import { getUserInfo } from "../../api/student";

import { getTruncatedText } from "../../utils/truncatedName";
import capitalizeFirstLetter from "../../utils/capitalize";

const UserMenu = (props) => {
  const navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState();
  const { logout } = useAuth();
  const userInfo = getUserInfo();
  const handleItemClick = (route) => {
    navigate(route);
    props.setIsMenuVisible(false);
    setActiveRoute(route);
  };
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getCoursesNotes()
      .then((res) => {
        console.log("fdsf", res.data.results);
        setNotes(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //Truncated user name ...
  const firstName = userInfo?.user?.first_name || "";
  const lastName = userInfo?.user?.last_name || "";
  const fullName = `${firstName} ${lastName}`;
  const [displayName, setDisplayName] = useState("");
  const linkRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (linkRef.current) {
        const truncatedText = getTruncatedText(
          linkRef.current,
          capitalizeFirstLetter(userInfo?.nickname) ||
            capitalizeFirstLetter(firstName),
          15,
          700,
          100,
          3
        );
        setDisplayName(truncatedText);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userInfo, fullName]);

  useEffect(() => {
    if (userInfo && userInfo.timestamp) {
      const currentTime = Date.now();
      const diff = currentTime - userInfo.timestamp;
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      const fiftyMinutesInMs = 50 * 60 * 1000;

      if (diff < fiftyMinutesInMs) {
        // console.log("UserInfo timestamp is less than 30 days old.");
      } else {
        // console.log("UserInfo timestamp is more than 30 days old.");

        saveStudentLocal()
          .then(() => {
            // console.log("Student data saved locally successfully.");
          })
          .catch((error) => {
            console.error("Failed to save student data locally:", error);
          });
      }
    } else {
      // console.log("userInfo or timestamp is missing.");
    }
  }, [userInfo]);

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ "& .MuiIconButton-root": { width: "36px", height: "36px" } }}
      >
        <Link
          ref={linkRef}
          onClick={() => handleItemClick("/user/dashboard")}
          underline="none"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: pxToRem(15),
            fontSize: pxToRem(20),
            fontWeight: "bold",
            mb: pxToRem(20),
            width: "100%",
            padding: "4px 0",

          }}
        >
          <Avatar
            sx={{
              width: pxToRem(64),
              height: pxToRem(64),
              backgroundColor: "#fff",
            }}
          >
            <img src={userInfo?.avatar} alt="" />
          </Avatar>{" "}
          {userInfo && userInfo.user && (
            <Typography
              fontSize="15px"
              fontWeight={700}
             // mt="10px"
              whiteSpace="nowrap"
            >
              Hi, {displayName}
            </Typography>
          )}
        </Link>

        <CloseBtn />
      </Stack>

      <Stack
        flexDirection={{ xs: "row", sm: "column", md: "row" }}
        justifyContent={{ xs: "flex-start", sm: "space-beetwen" }}
        gap="5px"
        // sx={{ "& button": { maxWidth: "150px" } }}
      >
        <Button
          fullWidth
          onClick={() => handleItemClick("/user/courses")}
          size="small"
          variant="outlined"
          sx={{
            maxWidth: "150px",
          }}
        >
          My Courses
        </Button>
        <Button
          fullWidth
          onClick={() => handleItemClick("/user/favorites")}
          size="small"
          variant="outlined"
          sx={{
            maxWidth: "150px",
          }}
        >
          Favorites
        </Button>
      </Stack>

      <List
        sx={{
          my: pxToRem(40),
          p: 0,
          borderTop: "1px solid rgba(191,190,187, 0.5)",
        }}
      >
        <MenuItem
          onClick={() => handleItemClick("/user/dashboard")}
          isActive={activeRoute === "/user/dashboard"}
          title="Dashboard"
          notificationQuantity={2}
        ></MenuItem>

        <MenuItem
          onClick={() => handleItemClick("/user/account-settings")}
          isActive={activeRoute === "/user/account-settings"}
          title="User Profile"
        ></MenuItem>
        {notes.length > 0 && (
          <MenuItem
            onClick={() => handleItemClick("/user/activity")}
            isActive={activeRoute === "/user/activity"}
            title="Personal Data"
          ></MenuItem>
        )}

        <MenuItem
          onClick={() => handleItemClick("/user/notifications")}
          isActive={activeRoute === "/user/notifications"}
          title="Notifications"
        ></MenuItem>
        <MenuItem
          onClick={() => handleItemClick("/user/purchase-history")}
          isActive={activeRoute === "/user/purchase-history"}
          title="Purchase History"
        ></MenuItem>
        <MenuItem
          onClick={() => handleItemClick("/user/playback")}
          isActive={activeRoute === "/user/playback"}
          title="Playback Options"
        ></MenuItem>
        {/* <MenuItem to="/general" title="General"></MenuItem> */}
      </List>

      <Stack
        gap={pxToRem(20)}
        pb={pxToRem(40)}
        borderBottom="1px solid rgba(191,190,187, 0.5)"
      >
        <TextLink to="/contact">Help & Support</TextLink>
        <TextLink to="/about-us">About Us</TextLink>
      </Stack>

      <Button
        onClick={() => logout()}
        size="small"
        variant="outlined"
        sx={{ mt: pxToRem(40) }}
      >
        Sign Out
      </Button>
    </>
  );
};

const MenuItem = (props) => {
  return (
    <ListItem
      sx={{
        p: 0,
        borderBottom: "1px solid rgba(191,190,187, 0.5)",
        justifyContent: "space-between",
        "& .MuiLink-root": {
          display: "block",
          width: "100%",
          textAlign: "left",
          color: props.isActive ? "#333" : "#026670",
          py: pxToRem(20),
          "& .MuiTypography-root::before": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: pxToRem(-8),
            height: "2px",
            width: 0,
            bgcolor: props.footerLink ? "#fff" : "primary.main",
            transition: "0.2s ease",
          },
          "&:hover": {
            color: "#333",
            "& .MuiTypography-root::before": {
              width: "100%",
            },
          },
        },
        "& .chevron svg": {
          color: props.isActive ? "#333" : "#BFBEBB",
        },
        "& .link-wrapper::before": {
          content: "''",
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Link component="button" onClick={props.onClick}>
        <Badge
          color="secondary"
          badgeContent={props.notificationQuantity}
          sx={{
            "& .MuiBadge-badge": {
              right: "-10px",
            },
          }}
        >
          <Typography component="span" position="relative" fontWeight={700}>
            {props.title}
          </Typography>
        </Badge>
      </Link>
      <Box className="chevron" display={{ xs: "block", sm: "none" }}>
        <Chevron color="#BFBEBB" />
      </Box>
    </ListItem>
  );
};

const TextLink = (props) => {
  return (
    <Link
      to={props.to}
      component={NavLink}
      underline="none"
      sx={{
        fontSize: pxToRem(14),
        fontWeight: 600,
        "&:hover": { color: "#026670" },
      }}
    >
      {props.children}
    </Link>
  );
};

export default UserMenu;
