import {
  Dialog,
  DialogContent,
  Box,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";

export default function AuthReminderModal({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        keepMounted
        sx={{
          "& .MuiDialog-container": {
            alignItems: { xs: "flex-end", ssm: "center" },
          },
          "& .MuiDialog-paper": {
            borderRadius: {
              xs: `${pxToRem(20)} ${pxToRem(20)} 0 0`,
              ssm: pxToRem(20),
            },
            verticalAlign: "center",
            backgroundColor: "#F7F6F2",
            margin: { xs: "0px !important", ssm: `${pxToRem(32)} !important` },
            maxWidth: { xs: "100%", ssm: "500px" },
            width: { xs: "100%", ssm: "500px" },
            maxHeight: { xs: "85vh", ssm: "none" },
          },
        }}
      >
        {/* Modal Header */}
        <Stack position="relative" bgcolor="#EDECE8">
          <Stack
            direction="row"
            columnGap={pxToRem(20)}
            alignItems="center"
            justifyContent="space-between"
            p={{
              xs: `${pxToRem(30)} ${pxToRem(25)}`,
              ssm: `${pxToRem(40)} ${pxToRem(40)}`,
              sm: `${pxToRem(40)} ${pxToRem(60)}`,
            }}
          >
            <Typography
              fontSize={{ xs: pxToRem(20), sm: pxToRem(25) }}
              fontWeight="bold"
              fontFamily="PloniBold"
            >
              Keep Your Progress
            </Typography>
            <IconBtnCircular
              onClick={handleClose}
              sx={{
                width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
                height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
              }}
            >
              <Close color="#026670" />
            </IconBtnCircular>
          </Stack>
        </Stack>

        {/* Modal Content */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: {
              xs: `${pxToRem(30)} ${pxToRem(25)}`,
              ssm: `${pxToRem(40)} ${pxToRem(40)}`,
              sm: `${pxToRem(40)} ${pxToRem(60)}`,
            },
            backgroundColor: "#f7f6f2",
            overflow: "visible",
            position: "relative",
          }}
        >
          <Typography fontSize={pxToRem(20)} fontWeight={400} mb={pxToRem(20)}>
            Sign in, and we’ll track your shiurim automatically—pick up where
            you left off anytime!
          </Typography>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
            }}
            mt={pxToRem(20)}
          >
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Button onClick={() => {}} variant="green" size="small" sx={{}}>
                Cancel
              </Button>
              <Button onClick={() => {}} variant="yellow" size="small" sx={{}}>
                Sign-In
              </Button>
            </Box>

            <Typography
              sx={{
                fontSize: "12px",
                cursor: "pointer",
              }}
              color="grey"
            >
              You don't have an account ?
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
