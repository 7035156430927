import { useState, useEffect } from "react";

import { Stack, Box, Typography, CircularProgress, Chip } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { listCourses } from "../../../api/course";

export default function CoursesTab({
  tab,
  search,
  setCoursesCount,
  coursesCount,
}) {
  const [courses, setCourses] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coursesShown, setCoursesShown] = useState(5);
  const fetchCourses = () => {
    setLoading(true);
    listCourses(100, 1, search)
      .then((res) => {
        setCourses(res.data.results);
        setCoursesCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("courses are", courses);
  useEffect(() => {
    fetchCourses();
  }, [search]);

  const handleViewMore = () => {
    setCoursesShown((prev) => prev + 5);
  };
  const handleViewLess = () => {
    setCoursesShown(5);
  };
  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", height: "50px" }}>
          <CircularProgress sx={{ color: "#036670" }} />
        </Box>
      ) : (
        <>
          <Stack
            sx={{
              //padding: `${pxToRem(20)} 0 ${pxToRem(20)} ${pxToRem(12)}`,
              rowGap: pxToRem(16),
              pt: tab === 0 ? pxToRem(20) : pxToRem(10),
            }}
          >
            {courses.slice(0, coursesShown).map((course, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  gap={pxToRem(15)}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    width: "100%",
                    cursor: "pointer",
                    "&:hover .title": { color: "#026670" },
                  }}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: pxToRem(5),
                      width: pxToRem(92),
                      height: pxToRem(60),
                    }}
                  >
                    <img
                      src={course?.thumb_nail}
                      alt=""
                      style={{
                        objectFit: "cover",
                        borderRadius: pxToRem(5),
                        width: pxToRem(92),
                        height: pxToRem(50),
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="large"
                      fontWeight="600"
                      component="p"
                      className="title"
                      style={{
                        maxWidth: "250px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        position: "relative",
                        marginTop: "-5px",
                      }}
                      data-fullname={course?.name}
                    >
                      {course?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        mt: "5px",
                      }}
                    >
                      <Typography
                        variant="small"
                        fontWeight="400"
                        component="p"
                        color="#C0C0C0"
                      >
                        {course?.subscriber_count} Subscriber
                        {course?.subscriber_count > 1 ? "s" : ""}
                      </Typography>
                      <Chip size="small" label={course.category.name} />
                    </Box>
                  </Box>
                </Box>
                {/* <Box>
              <TestimonialMenu sx={{"& svg": {color: "#D9D9D9"}, transform: "rotate(90deg)", margin: 0}}>
                <MenuItem onClick={() => {}}>Submenu link 01</MenuItem>
                <MenuItem onClick={() => {}}>Another link 02</MenuItem>
              </TestimonialMenu>
            </Box> */}
              </Stack>
            ))}
            {courses.length === 0 && (
              <Typography
                variant="large"
                fontWeight="600"
                component="p"
                color="#C0C0C0"
                sx={{ textAlign: "center", pt: "20px" }}
              >
                No Courses Found
              </Typography>
            )}
          </Stack>

          {tab === 0 && courses.length > 2 && (
            <Typography
              variant="button"
              component="p"
              sx={{
                cursor: "pointer",
                fontWeight: "500",
                textTransform: "capitalize",
                textDecoration: "underline",
                "&:hover": { color: "#026670" },
                py: "15px",
                px: "2px",
              }}
              onClick={
                coursesShown >= coursesCount ? handleViewLess : handleViewMore
              }
            >
              {coursesShown >= coursesCount ? "Show Less" : "View More"}
            </Typography>
          )}

          {tab !== 0 && courses.length > 4 && (
            <Stack alignItems="center">
              <Box
                sx={{
                  borderRadius: pxToRem(50),
                  backgroundColor: "#E9E9E9",
                  p: `${pxToRem(8)} ${pxToRem(18)}`,
                  display: "inline-block",
                  mt: pxToRem(10),
                }}
                onClick={
                  coursesShown >= coursesCount ? handleViewLess : handleViewMore
                }
              >
                <Typography
                  variant="button"
                  component="p"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    "&:hover": { color: "#026670" },
                  }}
                >
                  {coursesShown >= coursesCount
                    ? "Show Less"
                    : "View More results"}
                </Typography>
              </Box>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
