import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import Header from "../Header";
import Search from "./Search";
import TreeImg from "../../images/footer-tree.svg";
import manWhite from "../../images/man-white.png";
import GradientAnimation from "../GradientAnimation";
import { useEffect } from "react";
import PageTitleHighlighted from "../PageTitleHighlighted";
import { getLanguageClass } from "../../utils/languageClass";

const Hero = ({
  title,
  onSearch,
  courses,
  setCourses,
  setIsOnSearchMode,
  setHasMoreActive,
  setFilterMode,
  isOnSearchMode,
  setPage,
  sx,
}) => {
  const theme = useTheme();
  const tablet = useMediaQuery("(max-width:950px)");
  const isLargeScreen = useMediaQuery("(min-width:1227px)");

  useEffect(() => {
    if (tablet) {
      console.log("true");
    }
  }, [tablet]);

  return (
    <Box
      position="relative"
      pt={{xs: pxToRem(20), sm: pxToRem(30)}}
      minHeight="540px"
      mb={pxToRem(100)}
      sx={{
        ...sx,
        zIndex: 4,
        borderRadius: tablet ? 0 : pxToRem(40),
      }}
      pb={title === " Weekly Shiurim" && isLargeScreen ? pxToRem(40) :0}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "clip",
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: pxToRem(30),
            left: "50%",
            transform: "translateX(-125%)",
            background: `url(${TreeImg})`,
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(600),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      ></Box>
      <GradientAnimation />
      <Header menuVisible />
      {isLargeScreen && (
        <Box
          sx={{

            position: "absolute",
            bottom: "0",
            right: "0",
            height: "60%",
            width: "300px",
            overflow: "hidden",
            borderRadius: "inherit",
            "@media (max-width: 1300px)": {
              width: "400px",
              height: "45%",
            },
            "@media (max-width: 1226px)": {
              width: "350px",
              height: "60%",
            },
          }}
        >
          <img
            src={manWhite}
            alt="man"
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
      )}

      <Stack
        className="header-padding"
        position="relative"
        maxWidth="900px"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        zIndex={2}
        margin={{ xs: "20px auto 0", md: "64px auto 0" }}
        padding="0 25px"
      >
       {/*  <Typography
          variant="sectionTitle"
          fontSize={`${pxToRem(45)} !important`}
          component="h2"
          mb={{ xs: "30px", ssm: "10px" }}
        >
          {title}
        </Typography> */}

        {title === "Weekly Shiurim" ? (
          <>
            <Typography
              component="h1"
              fontFamily="PloniBold"
              fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
              lineHeight={{ xs: "40px", sm: pxToRem(60) }}
              mb={isLargeScreen ? pxToRem(100) : "0"}
              className={getLanguageClass("קורצע", false, true)}
              textAlign="center"
            >
              קורצע{" "}
              <PageTitleHighlighted component="span">
                <span>שיעורים</span>
              </PageTitleHighlighted>
              <br />
              איבער אלערליי אידישקייט טעמעס
              <br />
              אלעס ארום: וויאזוי צו ווערן א{" "}
              <PageTitleHighlighted component="span">
                <span>בעסערער איד</span>
              </PageTitleHighlighted>
            </Typography>
          </>
        ) : (
          <>
            <Typography
              component="h1"
              fontFamily="PloniBold"
              fontSize={{ xs: pxToRem(40), sm: pxToRem(70) }}
              lineHeight={{ xs: "40px", sm: pxToRem(60) }}
              mb={isLargeScreen ? pxToRem(100) : "0"}
            >
              דא הייבט זיך אן דיין{" "}
              <PageTitleHighlighted component="span">
                <span>נייע לעבן</span>
              </PageTitleHighlighted>{" "}
              דו וועסט אלעמאל זיין דאנקבאר פאר דער איצטיגער מינוט
            </Typography>
          </>
        )}

        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          {isLargeScreen && (
            <Search
              onSearch={onSearch}
              courses={courses}
              setCourses={setCourses}
              setIsOnSearchMode={setIsOnSearchMode}
              isOnSearchMode={isOnSearchMode}
              setPage={setPage}
              setHasMoreActive={setHasMoreActive}
              setFilterMode={setFilterMode}
            />
          )}
          {!isLargeScreen && (
            <Box
              sx={{
                height: { xs: "300px", sssm: "400px" },
                overflow: "hidden",
              }}
            >
              <img
                src={manWhite}
                alt="man"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Hero;
