import { Link, useLocation } from "react-router-dom";
import {
  Link as MuiLink,
  Tooltip,
  tooltipClasses,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { useRef, useEffect, useState } from "react";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#026670",
    padding: pxToRem(10),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#026670",
  },
});

const MenuDropdownLink = ({
  to,
  isSideBar,
  closeDrawer,
  children,
  ...props
}) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef(null);
  const theme = useTheme();
  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const location = useLocation();

  const getTooltipPlacement = () => (mobile__ssm ? "bottom" : "right");

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflow(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const handleClick = (event) => {
    if (location.pathname === to && isSideBar && closeDrawer) {
      event.preventDefault();
      closeDrawer();
    }
  };

  const linkContent = (
    <span
      ref={textRef}
      className={props?.noOverflow ? "" : "overflow-ellipsis"}
    >
      {children}
    </span>
  );

  return isOverflow ? (
    <CustomTooltip title={children} placement={getTooltipPlacement()}>
      <MuiLink
        component={Link}
        to={to}
        underline="none"
        onClick={handleClick}
        sx={{
          fontSize: pxToRem(15),
          fontWeight: 600,
          color: "#333",
          "&:hover": { color: "secondary.main" },
        }}
        {...props}
      >
        {linkContent}
      </MuiLink>
    </CustomTooltip>
  ) : (
    <MuiLink
      component={Link}
      to={to}
      underline="none"
      onClick={handleClick}
      sx={{
        fontSize: pxToRem(15),
        fontWeight: 600,
        color: "#333",
        "&:hover": { color: "secondary.main" },
      }}
      {...props}
    >
      {linkContent}
    </MuiLink>
  );
};

export default MenuDropdownLink;
